// libraries
import { useMemo } from 'react'

// utils
import { useAuthStateValue } from 'contexts'
import { isSharedEntity } from 'helpers/utils'

import type { Entity } from 'types/entity'

export const useListItemStatus = ({
  listItem,
}: {
  listItem?: Partial<Entity>
}): {
  isMine: boolean
  isShared: boolean
  isSuperAdminRole: boolean
} => {
  const { currentUser, isSuperAdminRole } = useAuthStateValue()
  const { username } = listItem?.owner?.user || {}

  const isMine = useMemo(
    () => username === currentUser?.username,
    [username, currentUser]
  )

  const isShared = useMemo(() => isSharedEntity(listItem), [listItem])

  return {
    isMine,
    isShared,
    isSuperAdminRole,
  }
}

export default useListItemStatus
