import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useToggle } from 'react-use'
import _ from 'lodash'

// constants
import { ANCILLARY_DATA_GEOJSON_TYPE } from 'constants/ancillaryData'
import { BUTTON_VARIANTS } from 'components/common/Button'

// context
import { useStateValue } from 'contexts'

// components
import { Button, MultiSelect } from 'components/common'
import AncillaryDataModal from 'components/ancillaryData/Modal'
import { Title } from 'components/common/Form/StyledField'
// style
import scss from './index.module.scss'

const getOptionLabel = ({ value, name, thumbnailUrl }) => (
  <div style={{ alignItems: 'center', display: 'flex' }} key={value}>
    <div
      style={{
        backgroundImage: `url("${
          thumbnailUrl || '/assets/default-thumbnail.webp'
        }")`,
      }}
      className={scss.optionImage}
      alt={name}
    />
    <span className={scss.optionLabel}>{name}</span>
  </div>
)

const filterOptions = (candidate, input) => {
  return input
    ? _.get(candidate, 'data.name', '')
        .toLowerCase()
        .includes(input.toLowerCase())
    : true
}

const AncillaryDataSelect = ({
  input,
  label,
  required,
  labelClassName,
  titleClassName,
  ...rest
}) => {
  const {
    actions: {
      ancillaryDataActions: { updateAncillaryData, updateAncillaryDataContent },
    },
  } = useStateValue()

  const { onChange, value } = input

  const [isShowing, toggleModal] = useToggle(false)

  const onChangeHandler = useCallback(
    v => {
      onChange(v?.value)
    },
    [onChange]
  )

  const onDataAdded = useCallback(
    (type, asset) => {
      updateAncillaryData(asset)
      updateAncillaryDataContent(asset)
      onChange(asset.id)
    },
    [onChange, updateAncillaryData, updateAncillaryDataContent]
  )

  return (
    <>
      <div className='groupOption'>
        <div className={`${titleClassName} d-flex justify-content-between`}>
          <Title
            label={label}
            required={required}
            labelClassName={labelClassName}
          />
          <Button variant={BUTTON_VARIANTS.secondary} onClick={toggleModal}>
            New geofence
          </Button>
        </div>
        <div className='groupOptionContent'>
          <MultiSelect
            formatOptionLabel={getOptionLabel}
            onChange={onChangeHandler}
            value={value}
            isMulti={false}
            filterOption={filterOptions}
            {...rest}
          />
        </div>
      </div>
      <AncillaryDataModal
        onChange={onDataAdded}
        toggleModal={toggleModal}
        isShowing={isShowing}
        assetType={ANCILLARY_DATA_GEOJSON_TYPE.geofence}
        isEditable
        isLoading={false}
      />
    </>
  )
}

AncillaryDataSelect.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  required: PropTypes.bool,
  labelClassName: PropTypes.string,
  titleClassName: PropTypes.string,
}

AncillaryDataSelect.defaultProps = {
  required: false,
  className: '',
  labelClassName: 'groupOptionLabel',
  titleClassName: undefined,
}

export default AncillaryDataSelect
