import { CSSProperties, ReactElement } from 'react'
import _ from 'lodash'
import styled from '@emotion/styled'
import { useMount } from 'react-use'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'
import { DATE_TIME_COMPARISON_EDITOR_LAST_UNITS } from 'constants/workflow'

// utils
import { getOptions } from 'helpers/utils'

// components
import { StyledField } from 'components/common/Form'
import { Dropdown, IconButton, NumericInput } from 'components/common'

import type { Options, BrandingColours } from 'types/common'

const RELATIVE_TIME_UNIT_OPTIONS = getOptions(
  DATE_TIME_COMPARISON_EDITOR_LAST_UNITS
)

const DEFAULT_RELATIVE_TIME = {
  time: 24,
  unit: DATE_TIME_COMPARISON_EDITOR_LAST_UNITS.HOUR,
}

const StyledText = styled.span<{ theme?: BrandingColours }>`
  color: ${props => props.theme.primary};
`

export type RelativeTimeValue = { time: number; unit: string }

const RelativeTimeField = ({
  input,
  label,
  required = false,
  description,
  groupOptionStyle = false,
  style,
  labelClassName = 'form-label',
  isDisabled,
  options,
  defaultTime,
  inputAddon,
}: {
  input: {
    name: string
    value: RelativeTimeValue
    onChange: (v: RelativeTimeValue) => void
    onBlur: (v: RelativeTimeValue) => void
    onFocus: (v: RelativeTimeValue) => void
  }
  defaultTime: RelativeTimeValue
  label: string
  required: boolean
  description: string
  groupOptionStyle: boolean
  labelClassName: string
  style: CSSProperties
  isDisabled: boolean
  options?: Options
  inputAddon?: () => JSX.Element
}): ReactElement => {
  const { value: inputValue, onChange, name } = input

  const { time, unit } = inputValue

  useMount(() => {
    _.delay(() => {
      onChange({
        time: time || defaultTime?.time || DEFAULT_RELATIVE_TIME.time,
        unit: unit || defaultTime?.unit || DEFAULT_RELATIVE_TIME.unit,
      })
    }, 1)
  })

  return (
    <StyledField
      name={name}
      groupOptionStyle={groupOptionStyle}
      label={label}
      required={required}
      description={description}
      style={style}
      labelClassName={labelClassName}
    >
      <div className='d-flex align-items-center'>
        <div className='col-6 position-relative'>
          <NumericInput
            value={time}
            disabled={isDisabled}
            onChange={(newValue: number) => {
              onChange({ time: newValue, unit })
            }}
            className='form-control'
            step={1}
            min={1}
          />
          {inputAddon}
        </div>
        <Dropdown
          containerClassName='ms-2'
          placement={TOOLTIP_PLACEMENT.bottom}
          toggleComponent={() => {
            return (
              <div className='smallText defaultText cursor-pointer'>
                <StyledText>{unit}</StyledText>
                <IconButton icon='GoTriangleDown' />
              </div>
            )
          }}
          onChange={(newUnit: string) => {
            onChange({ time, unit: newUnit })
          }}
          options={options || RELATIVE_TIME_UNIT_OPTIONS}
          isDisabled={isDisabled}
        />
      </div>
    </StyledField>
  )
}

export default RelativeTimeField
