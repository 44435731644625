import { useState, CSSProperties } from 'react'
import { useMount } from 'react-use'
import { useTheme } from '@emotion/react'

const useRangeSliderStyle = (type: string): CSSProperties => {
  const { primary } = useTheme()

  const [style, setStyle] = useState()

  useMount(() => {
    const commonStyle = {
      handleStyle: {
        borderColor: primary,
        height: 18,
        width: 18,
        backgroundColor: '#fff',
        marginTop: '-8px',
      },
      railStyle: { backgroundColor: '#E1DFDF', height: 2 },
      className: 'rangeSlider',
    }
    const bgColour = { backgroundColor: primary, height: 2 }
    const styleOverride = {
      ...commonStyle,
      trackStyle: type === 'slider' ? bgColour : [bgColour, bgColour],
    }
    setStyle(styleOverride)
  })

  return style
}

export default useRangeSliderStyle
