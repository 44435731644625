import { useCallback, ReactElement, FocusEvent, FocusEventHandler } from 'react'
import _ from 'lodash'
import { StyledField } from 'components/common/Form'

// utils
import { sanitizeString } from 'helpers/utils'

// scss
import scss from 'components/common/Form/index.module.scss'

export type TextareaProps = {
  label?: string
  type?: string
  input: {
    name: string
    value: string
    type: string
    onChange: (v: string) => void
    onBlur: FocusEventHandler<HTMLTextAreaElement>
    onFocus: FocusEventHandler<HTMLTextAreaElement>
  }
  className?: string
  required?: boolean
  autoComplete?: string
  groupOptionStyle?: boolean
  inline?: boolean
  testId?: string
  placeholder?: string
}

const Textarea = (props: TextareaProps): ReactElement => {
  const {
    className,
    input,
    label,
    required,
    groupOptionStyle,
    inline,
    testId,
  } = props

  const inputProps = {
    ..._.pick(props, [
      'id',
      'placeholder',
      'required',
      'type',
      'max',
      'min',
      'readOnly',
      'autoComplete',
    ]),
    ...input,
    className,
  }

  const { onBlur, onChange, onFocus, name } = input

  const onTextChange = useCallback(
    (event: FocusEvent<HTMLTextAreaElement>) => {
      onFocus(event)
      const cleanText = sanitizeString(event.target.value) || ''
      onChange(cleanText)
      onBlur(event)
    },
    [onBlur, onChange, onFocus]
  )

  return (
    <StyledField
      name={name}
      groupOptionStyle={groupOptionStyle}
      label={label}
      required={required}
      inline={inline}
    >
      <textarea {...inputProps} onBlur={onTextChange} data-testid={testId} />
    </StyledField>
  )
}

Textarea.defaultProps = {
  label: '',
  type: 'text',
  className: `${scss.textarea}`,
  required: false,
  autoComplete: 'on',
  groupOptionStyle: false,
  inline: false,
  testId: 'default-textarea',
}

export default Textarea
