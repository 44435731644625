// libraries
import { ArcLayer as DeckGlArcLayer } from 'deck.gl'
import _ from 'lodash'
import centerOfMass from '@turf/center-of-mass'
import { polygon } from '@turf/helpers'

// utils
import { switchcaseF } from 'helpers/utils'

// constants
import { LAYER_TYPES, GEOMETRY_TYPES } from 'constants/map'

// components
import Layer from '../baseLayer'

export const arcVisConfigs = {
  opacity: 'opacity',
  lineWidth: 'lineWidth',
  lineWidthProperty: 'lineWidthProperty',
  heightPropertyValue: 'heightPropertyValue',
  arcHeight: 'arcHeight',
  colourType: 'colourType',
}

export default class ArcLayer extends Layer {
  constructor(props) {
    super(props)

    this.registerVisConfig(arcVisConfigs, props.style[this.type])
  }

  get type() {
    return LAYER_TYPES.arc
  }

  renderLayer = ({ layerData, profileHandler, selectedDateTimeRange }) => {
    const {
      visConfig: {
        opacity,
        lineWidthProperty,
        colourType,
        lineWidth,
        heightPropertyValue,
        arcHeight,
      },
      fillColour,
    } = this.config

    const basicProps = this.getLayerBasicProps({ layerData })

    const firstDataTime = this.getTimeFromFirstData(layerData)

    const profileProps = this.getProfileConfig(profileHandler)

    const timeFilterProps = this.getTimeFilterProps(
      selectedDateTimeRange,
      firstDataTime
    )

    const isSimpleFillColour = this.isSimpleColourType(colourType)

    const stylingProps = {
      opacity: this.getOpacity(opacity),
      widthUnits: 'meters',
      widthScale: lineWidth,
    }

    const getPosition = position => d => {
      const getCoordinate = () => _[position](d.geometry.coordinates)

      const getPolygonCenterCoordinate = () => {
        const geoPolygon = polygon(getCoordinate())
        const center = centerOfMass(geoPolygon)
        return center.geometry.coordinates
      }

      return switchcaseF({
        [GEOMETRY_TYPES.LineString]: getCoordinate,
        [GEOMETRY_TYPES.MultiPoint]: getCoordinate,
        [GEOMETRY_TYPES.MultiPolygon]: getPolygonCenterCoordinate,
      })(getCoordinate)(d.geometry.type)
    }

    const getColour = isSimpleFillColour ? fillColour : d => d.fillColour

    const dataAccessors = {
      getWidth: lineWidthProperty ? d => d.lineWidth : lineWidth,
      getSourcePosition: getPosition('first'),
      getTargetPosition: getPosition('last'),
      getSourceColor: getColour,
      getTargetColor: getColour,
      getHeight: heightPropertyValue ? d => d.height * arcHeight : arcHeight,
    }

    return [
      new DeckGlArcLayer({
        ...basicProps,
        ...stylingProps,
        ...dataAccessors,
        ...profileProps,
        ...timeFilterProps,
      }),
    ]
  }
}
