import { useState, useCallback, ReactElement } from 'react'
import CreatableSelect from 'react-select/creatable'
import _ from 'lodash'
import { StyledField } from 'components/common/Form'

// utils
import { getUserOptionLabel } from 'components/common/UsersPicker'
import { validateEmail } from 'helpers/validators'
import { useStateValue } from 'contexts'
import useSelectStyles from 'components/common/MultiSelect/useSelectStyles'
import { getMultiSelectCommonProps } from 'components/common/MultiSelect'

import type { WorkflowFormWidgetSendEmail } from 'types/workflow'
import type { Email } from 'types/user'
import type { UserEmailOption } from 'contexts/selectors/user'

export type RecipientOptions = WorkflowFormWidgetSendEmail['recipients']

export const createOption = (email: string): UserEmailOption => ({
  label: email,
  value: email,
  isValid: validateEmail(email),
})

export const createOptions = (options: string[]): RecipientOptions =>
  _.isEmpty(options) ? [] : options.map(createOption)

const getValues = (emailOptions: RecipientOptions) =>
  _.map(emailOptions, 'value')

export const getResultOptions = (
  emailOptions: RecipientOptions,
  newEmail: Email
): RecipientOptions => {
  const emailsArr = _(newEmail).split(',').map(_.trim).compact().value()
  const differentEmails = _.isEmpty(getValues(emailOptions))
    ? emailsArr
    : _.difference(emailsArr, getValues(emailOptions))

  return [...(emailOptions || []), ...createOptions(differentEmails)]
}

const EmailSelect = ({
  input,
  label,
  placeholder,
  readOnly,
  className,
  required = false,
  groupOptionStyle = false,
}: {
  input: {
    name: string
    value: RecipientOptions
    onChange: (v: RecipientOptions) => void
  }
  label: string
  placeholder: string
  readOnly: boolean
  className: string
  required: boolean
  groupOptionStyle: boolean
}): ReactElement => {
  const {
    selectors: {
      userSelectors: { userEmailOptions },
    },
  } = useStateValue()

  const { name, onChange, value } = input

  const [inputValue, setInputValue] = useState('')
  const [emailOptions, setValuesArr] = useState(value)

  const handleInput = useCallback(
    (v: Email, event: KeyboardEvent) => {
      const newOptions = getResultOptions(emailOptions, v)
      setValuesArr(newOptions)
      onChange(newOptions)
      event.preventDefault()
    },
    [onChange, emailOptions]
  )

  const handleChange = useCallback(
    (val: RecipientOptions): void => {
      setValuesArr(val)
      onChange(val)
    },
    [onChange]
  )

  const handleBlur = useCallback(
    (event: KeyboardEvent) => {
      handleInput(event?.target?.value, event)
    },
    [handleInput]
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!inputValue) return

      switch (event.key) {
        case 'Tab':
        case 'Space':
        case 'Enter':
          setInputValue('')
          handleInput(inputValue, event)
          break

        default:
      }
    },
    [handleInput, inputValue]
  )

  const { customStyles } = useSelectStyles({ withBorder: true })

  return (
    <>
      <StyledField
        name={name}
        groupOptionStyle={groupOptionStyle}
        label={label}
        required={required}
      >
        <CreatableSelect
          inputValue={inputValue}
          isClearable
          isSearchable
          onChange={handleChange}
          onInputChange={setInputValue}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          placeholder={placeholder}
          value={emailOptions}
          isDisabled={readOnly}
          options={userEmailOptions}
          formatOptionLabel={getUserOptionLabel()}
          {...getMultiSelectCommonProps({
            isMulti: true,
            className,
            value: emailOptions,
            customStyles,
          })}
        />
      </StyledField>
    </>
  )
}

export default EmailSelect
