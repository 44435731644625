import _ from 'lodash'
import keymirror from 'keymirror'
import Color from 'color'

export const OPERATION_OPTIONS = keymirror({
  view: null,
  clone: null,
  delete: null,
  share: null,
  update: null,
  create: null,
})

export const PLACEMENT = keymirror({
  bottom: null,
  top: null,
  right: null,
  left: null,
  topRight: null,
})

export const GALLERY_LIST_FILTER_TYPES = keymirror({
  group: null,
  username: null,
  isPrivate: null,
  isFavorite: null,
  status: null,
  severity: null,
  modifiedDatetime: null,
  assignedToUsername: null,
  active: null,
})

export const DEFAULT_LIST_FILTERS = [
  GALLERY_LIST_FILTER_TYPES.group,
  GALLERY_LIST_FILTER_TYPES.username,
  GALLERY_LIST_FILTER_TYPES.isPrivate,
]

export const ENTITY_SHARED_STATUS = keymirror({
  private: null,
  shared: null,
})

export const ENTITY_ACTIVE_STATUS = keymirror({
  active: null,
  inactive: null,
})

export const DOMAINS = keymirror({ issues: null })

export const ENTITIES = keymirror({
  map: null,
  layer: null,
  user: null,
  session: null,
  asset: null,
  workflow: null,
  issue: null,
  pipe: null,
  site: null,
  event: null,
  ancillaryData: null,
  assetProfile: null,
  form: null,
})

export const BACKEND_ENTITY_TYPES = keymirror({
  map: null,
  user: null,
  asset: null,
  site: null,
  rule: null,
})

export const ENTITY_TYPE_TO_ENTITY = {
  [BACKEND_ENTITY_TYPES.map]: ENTITIES.map,
  [BACKEND_ENTITY_TYPES.rule]: ENTITIES.workflow,
  [BACKEND_ENTITY_TYPES.asset]: ENTITIES.ancillaryData,
  [BACKEND_ENTITY_TYPES.site]: ENTITIES.site,
}

export const ENTITY_NAME = {
  [ENTITIES.map]: 'map',
  [ENTITIES.workflow]: 'workflow',
  [ENTITIES.ancillaryData]: 'ancillary data',
  [ENTITIES.site]: 'site',
}

export const ACTIONS = keymirror({
  delete: null,
  transfer: null,
})

export const FILE_TYPES = keymirror({
  csv: null,
  json: null,
  xlsx: null,
  pdf: null,
})

export const FILE_TYPE_ICON_MAPPINGS = {
  [FILE_TYPES.csv]: 'CSVFileIcon',
  [FILE_TYPES.xlsx]: 'FaFileExcel',
  [FILE_TYPES.json]: 'FaFileCode',
  [FILE_TYPES.pdf]: 'FaFilePdf',
}

/**
 * Delay time in milliseconds
 */
export const THROTTLE_DELAY = 10

export const WIDGET_RENDER_DEBOUNCE_DELAY = 50

export const MAP_VIEWPORT_BOUNDS_DEBOUNCE_DELAY = 50

export const UPDATED_VIEW_STATE_QUERY_PARAMS_DELAY = 1000

export const FILTER_LIMIT_EXCEEDED_WARNING =
  'The number of Issues in the selected time range exceeds the amount that can be processed. Narrow your time range and try again.'

export const LOAD_BUTTON_CONTENT = 'Load More'

export const INFINITE_SCROLLABLE_DIV_ID = 'scrollableDiv'

export const DEFAULT_IDENTITY_PROPERTY = 'name'

export const DEFAULT_TIME_PROPERTY = 'time'

export const PROPERTY_TIME = `properties.${DEFAULT_TIME_PROPERTY}`

export const PROPERTY_NAME = `properties.${DEFAULT_IDENTITY_PROPERTY}`

export const ENTITY_USERNAME_PATH = 'owner.user.username'
export const ENTITY_GROUP_PATH = 'owner.group'

export const SORT_OPTIONS = {
  name: { value: 'name', label: 'Name' },
  username: { value: ENTITY_USERNAME_PATH, label: 'Author' },
  updatedTime: { value: 'audit.updatedTime', label: 'Last updated time' },
  createdTime: { value: 'audit.createdTime', label: 'Created time' },
  group: { value: ENTITY_GROUP_PATH, label: 'Group' },
  isActive: { value: 'isActive', label: 'Status' },
}

export const VISIBLE = {
  isVisible: true,
}

export const NON_VISIBLE = {
  isVisible: false,
}

export const APPLICATION_NAME = 'su-explorer'

export const MFA_CODE_INPUT_COUNT = 6

export const MFA_TYPES = keymirror({
  SMS_MFA: null,
  SOFTWARE_TOKEN_MFA: null,
  NOMFA: null,
})

export const MFA_OPTIONS = [
  {
    value: MFA_TYPES.SMS_MFA,
    label: 'Text message',
    icon: 'TextMessageIcon',
  },
  {
    value: MFA_TYPES.SOFTWARE_TOKEN_MFA,
    label: 'Security app',
    icon: 'SecureAppIcon',
  },
]

export const AUTH_CHALLENGE_TYPES = keymirror({
  ..._.omit(MFA_TYPES, 'NOMFA'),
  MFA_SETUP: null,
  RESET_PASSWORD: null,
  NEW_PASSWORD_REQUIRED: null,
})

export const DISPLAY_ISSUE_IN_MAP = false

export const PAGE_FILTER_PLACEHOLDER = 'CHOOSE'

export const HOME_PAGE_URL = '/'

export const SPEC_PARAMETERS_FORMATS = keymirror({
  dateTime: null,
  image: null,
})

export const SPEC_PARAMETERS_TYPES = keymirror({
  number: null,
  string: null,
  boolean: null,
  enum: null,
})

export const AUTH_ERROR_CODES = keymirror({
  USER_NOT_CONFIRMED: null,
  PASSWORD_RESET_REQUIRED: null,
  DEVICE_NOT_EXIST: null,
  VERIFY_SMS_FAILED: null,
  VERIFY_TOTP_FAILED: null,
  INVALID_MFA_CODE: null,
  CONFIRM_SIGNIN_FAILED: null,
  UNAUTHENTICATED: null,
})

export const PAGE_GALLERY = 'Gallery'

export const MAP_STYLE_SIZES = keymirror({
  small: null,
  default: null,
  large: null,
})

export const BLADE_SIZES = keymirror({
  small: null,
  default: null,
  medium: null,
  large: null,
  modal: null,
})

export const BLADE_POSITION = keymirror({
  left: null,
  right: null,
})

export const BLADE_VARIANT = keymirror({
  docked: null,
  floating: null,
})

export const PAGE_HEADER_TAB_POSITION = keymirror({
  left: null,
  middle: null,
})

export const NO_AVAILABLE_DATA = 'N/A'

export const EXTERNAL_URL_LABEL = 'Open External Link'

export const IMAGE_GALLERY_TYPES = keymirror({
  grid: null,
  column: null,
  slider: null,
})

export const FILE_MIME_TYPE_EXTENSIONS_MAPPING = {
  image: { 'image/*': [] },
  geojson: { 'application/json': ['.json', '.geojson'] },
  xlsx: {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
    ],
  },
}

export const DEFAULT_PRIMARY_COLOUR = '#1791EA'

export const DEFAULT_PRIMARY_COLOUR_ARRAY = Color(
  DEFAULT_PRIMARY_COLOUR
).array()

export const ENTITY_NAME_CHARACTER_LIMIT = 40

export const STICKY_HEADER_Z_INDEX = 1020

export const GALLERY_LIST_TYPES = keymirror({
  table: null,
  card: null,
  map: null,
})

export const GALLERY_LIST_TYPES_ICONS = {
  [GALLERY_LIST_TYPES.table]: 'TableView',
  [GALLERY_LIST_TYPES.card]: 'MdViewModule',
  [GALLERY_LIST_TYPES.map]: 'MdMap',
}

export const BADGE_TYPES = keymirror({
  default: null,
  error: null,
  warning: null,
  success: null,

  primary: null,
  lightPrimary: null,

  infoGrey: null,
  infoBlue: null,
})

// https://github.com/graphql/express-graphql/blob/28e4c2924ea6984bf918465cefdadae340d8780e/src/parseBody.ts#L96
export const BACKEND_REQUEST_SIZE = 100 * 1024

export const TABLE_COLUMN_SUPPORT_GROUP_BY_KEY = 'groupable'
