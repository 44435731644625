// libraries
import { ReactElement } from 'react'
import styled from '@emotion/styled'

// components
import { Button } from 'components/common'

// types
import type { BrandingColours } from 'types/common'

import scss from '../index.module.scss'

const ProgressBar = styled.div<{ theme: BrandingColours }>`
  background-color: ${props => props.theme.primary};
`

const getReadableTime = (seconds: number): string => {
  if (seconds < 60) {
    return `${seconds} second(s)`
  }
  return `${seconds / 60} minute(s)`
}

const SubmitButton = ({
  disabled,
  submitting,
  progress,
  seconds,
}: {
  disabled: boolean
  submitting: boolean
  progress: number
  seconds: number
}): ReactElement => {
  return (
    <>
      <div className={scss.submit}>
        <Button
          type='submit'
          disabled={disabled || submitting}
          isLoading={submitting}
          block
          className='extraSmallText'
        >
          RUN OPTIMIZATION
        </Button>
        {submitting && (
          <div className='progress mt-2' style={{ height: '1px' }}>
            <ProgressBar
              className='progress-bar'
              role='progressbar'
              style={{ width: `${progress}%` }}
              aria-valuenow={progress}
              aria-valuemin='0'
              aria-valuemax='100'
            />
          </div>
        )}
      </div>
      {submitting && (
        <div className='text-secondary smallText'>
          Based on your file size, the optimization will take{' '}
          {getReadableTime(seconds)}
        </div>
      )}
    </>
  )
}

export default SubmitButton
