import { ReactElement, useState } from 'react'
import _ from 'lodash'
import { Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { useMount } from 'react-use'

// No subscription. We only use Field to get to the change function
const ConditionalResetField = ({
  watch,
  condition = _.constant(true),
  reset,
}: {
  // The field to test the condition against
  watch: string
  // The condition to test the new value against
  condition: (value: unknown) => boolean
  // The field to reset
  reset: string
}): ReactElement => {
  const [lastWatchId, setLastWatchId] = useState<string>()
  useMount(() => {
    setLastWatchId(watch)
  })

  return (
    <Field name={reset} subscription={{}}>
      {({ input, meta }) => {
        return (
          <OnChange name={watch}>
            {(value, previous) => {
              setLastWatchId(watch)
              if (
                condition(value) &&
                previous?.value !== value?.value &&
                lastWatchId === watch
              ) {
                input.onChange(meta.initial)
              }
            }}
          </OnChange>
        )
      }}
    </Field>
  )
}

export default ConditionalResetField
