// libraries
import { useMemo, ReactElement } from 'react'
import _ from 'lodash'
import { NavLink, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { useToggle } from 'react-use'

// constants
import { URLS } from 'constants/route'
import { FEATURES, SETTINGS_TYPES, USER_PREFERENCES } from 'constants/settings'
import { HOME_PAGE_URL } from 'constants/common'

// utils
import { useAuthStateValue } from 'contexts'
import Auth from 'services/authentication'
import {
  useBranding,
  useFeatureFlag,
  usePlugins,
  useRoutesAbility,
} from 'hooks'
import { isDevEnvironment, getRouteUrlWithValues } from 'helpers/utils'

// components
import * as Icons from 'components/icons'
import { Avatar } from 'components/common'

// types
import { BrandingColours } from 'types/common'

import scss from './index.module.scss'

const isDevEnv = isDevEnvironment()

const StyledSidebar = styled.div<{ theme: BrandingColours }>`
  background-color: ${props => props.theme['primary-900']};
`

const Sidebar = (): ReactElement => {
  const [isPickerVisible, togglePickerVisible] = useToggle(false)
  const { currentUser, isExternal } = useAuthStateValue()
  const navigate = useNavigate()
  const SettingIcon = Icons.AiFillSetting
  const { [USER_PREFERENCES.sidebarRoutes]: sidebarRoutes = [] } =
    currentUser.preferences

  const { externalDashboardUrl } = Auth

  const { logo } = useBranding()

  const { navbarLogo } = logo

  const logoURL = useMemo(() => _.get(logo, navbarLogo), [logo, navbarLogo])

  const pluginState = usePlugins()

  const {
    [FEATURES.ANCILLARY_DATA]: ancillaryDataEnabled,
    [FEATURES.ASSET]: assetsEnabled,
    [FEATURES.ISSUE]: issuesEnabled,
    [FEATURES.SITE]: sitesEnabled,
    [FEATURES.DYNAMIC_SCHEDULING]: dynamicSchedulingEnabled,
    [FEATURES.JSON_FORM]: jsonFormEnabled,
  } = useFeatureFlag()

  const { canManageUser, canManageAssetProfile } = useRoutesAbility()

  const navLinkSpecs = useMemo(
    () => [
      {
        name: 'Maps',
        path: URLS.MAPS,
        icon: 'MapIcon',
        enabled: true,
      },
      {
        name: 'Workflows',
        path: URLS.WORKFLOWS,
        icon: 'WorkflowIcon',
        iconSize: 16,
        enabled: true,
      },
      {
        name: 'Data',
        path: URLS.ANCILLARY_DATA,
        icon: 'MdCloudUpload',
        enabled: ancillaryDataEnabled,
      },
      {
        name: 'Profiles',
        path: URLS.ASSETS_PROFILES,
        icon: 'ProfileIcon',
        enabled: assetsEnabled && canManageAssetProfile,
      },
      {
        name: 'Issues',
        path: URLS.ISSUES,
        icon: 'GoIssueOpened',
        enabled: issuesEnabled,
      },
      {
        name: 'Sites',
        path: URLS.SITES,
        icon: 'BiBuildings',
        enabled: sitesEnabled,
      },
      {
        name: 'Forms',
        path: URLS.FORMS,
        icon: 'BiBuildings',
        enabled: jsonFormEnabled
          ? _.includes(sidebarRoutes, SETTINGS_TYPES.formDesigner)
          : false,
      },
      {
        name: 'Schedule',
        path: URLS.SCHEDULE_OPTIMIZATION,
        icon: 'FaRoute',
        enabled: isDevEnv && dynamicSchedulingEnabled,
      },
      {
        name: 'Users',
        path: URLS.USERS,
        icon: 'FaUsersCog',
        enabled: canManageUser,
      },

      {
        name: 'UI',
        path: URLS.DEV_UI_PLAYGROUND,
        icon: 'AiFillAppstore',
        enabled: isDevEnv,
      },
      ...pluginState.sidebarRoute.map(
        (entry: {
          pluginDisplayName: string
          normalizedRoutePath: string
          iconCode: string
          showInSidebar: boolean
        }) => {
          return {
            name: entry.pluginDisplayName,
            path: entry.normalizedRoutePath,
            icon: entry.iconCode,
            enabled: entry.showInSidebar,
          }
        }
      ),
    ],
    [
      ancillaryDataEnabled,
      assetsEnabled,
      canManageAssetProfile,
      issuesEnabled,
      sitesEnabled,
      jsonFormEnabled,
      sidebarRoutes,
      dynamicSchedulingEnabled,
      canManageUser,
      pluginState.sidebarRoute,
    ]
  )

  const memoizedNavLinks = useMemo(() => {
    const navbarLogoIcon = (
      <img
        alt='logo'
        className={scss.sidebarLogo}
        src={`${window.location.origin}${logoURL}`}
      />
    )

    return (
      <>
        {isExternal ? (
          <div
            className={scss.link}
            onClick={() => window.location.replace(externalDashboardUrl)}
          >
            {navbarLogoIcon}
          </div>
        ) : (
          <NavLink to={HOME_PAGE_URL}>{navbarLogoIcon}</NavLink>
        )}
        {_.map(navLinkSpecs, link => {
          const { name, path, icon, iconSize = 19, enabled } = link
          const Icon = Icons[icon]
          return (
            enabled && (
              <NavLink
                data-testid={name}
                to={path}
                key={name}
                className={({ isActive }) =>
                  `${scss.navlink} ${isActive && scss.active}`
                }
              >
                <div className={scss.sidebarIcon}>
                  <Icon size={iconSize} color='#fff' />
                  <div
                    data-testid={`${name}_Label`}
                    className={`${scss.sidebarLabel} navText`}
                  >
                    {name}
                  </div>
                </div>
              </NavLink>
            )
          )
        })}
      </>
    )
  }, [externalDashboardUrl, isExternal, logoURL, navLinkSpecs])

  const currentUserProfile = (
    <>
      {currentUser && (
        <div
          data-testid='sidebar-user-avatar'
          className='cursorPointer'
          onClick={() =>
            navigate(getRouteUrlWithValues(URLS.SETTINGS, { currentUser }))
          }
        >
          <Avatar
            className={scss.sidebarAvatar}
            user={currentUser}
            tooltip={false}
            onHover={togglePickerVisible}
            hover={isPickerVisible}
            hoverIcon={<SettingIcon size={24} />}
          />
        </div>
      )}
    </>
  )

  const sensorupLogo = (
    <a
      href='https://sensorup.com'
      target='_blank'
      rel='noreferrer'
      className={scss.poweredBy}
    >
      Powered by
      <img src='/assets/logo/sensorup-logo-name.svg' alt='SensorUp' />
    </a>
  )

  return (
    <StyledSidebar className={scss.sidebar}>
      {memoizedNavLinks}
      <div className={scss.bottomWrapper}>
        {currentUserProfile}
        {sensorupLogo}
      </div>
    </StyledSidebar>
  )
}

export default Sidebar
