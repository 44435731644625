import { ReactElement, ReactNode } from 'react'

import { TOOLTIP_PLACEMENT } from 'constants/settings'
import type { TooltipPlacement, Value, ThemeType } from 'types/common'

import Button, { ButtonProps } from 'components/common/Button'
import Dropdown, { DropdownOption } from '../Dropdown'

export type ActionButtonDropdownProps = {
  options?: DropdownOption[]
  dpTheme?: ThemeType
  dpClassName?: string
  dpContainerClassName?: string
  dpPlacement?: TooltipPlacement
  dpBody?: ReactNode
  onChange?: (v?: Value) => void
}

export type ActionButtonProps = ButtonProps & ActionButtonDropdownProps

const ActionButton = ({
  options,
  dpTheme,
  dpClassName,
  dpContainerClassName,
  dpPlacement = TOOLTIP_PLACEMENT.bottom,
  dpBody,
  onChange,
  icon = 'IoChevronDown',
  children,
  ...buttonProps
}: ActionButtonProps): ReactElement => (
  <Dropdown
    options={options}
    theme={dpTheme}
    className={dpClassName}
    containerClassName={dpContainerClassName}
    placement={dpPlacement}
    onChange={onChange}
    isDisabled={buttonProps.disabled}
    toggleComponent={() => (
      <Button icon={icon} {...buttonProps}>
        {children}
      </Button>
    )}
  >
    {dpBody}
  </Dropdown>
)

export default ActionButton
