import { ReactElement } from 'react'
import { FieldTemplateProps } from '@rjsf/core'
import {
  FormControl,
  FormHelperText,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import { isJsonFormWidgetHidden } from 'helpers/formBuilder'
import WrapIfAdditional from './WrapIfAdditional'

const FieldTemplate = ({
  id,
  children,
  classNames,
  disabled,
  displayLabel,
  hidden,
  label,
  onDropPropertyClick,
  onKeyChange,
  readonly,
  required,
  rawErrors = [],
  rawHelp,
  rawDescription,
  schema,
  uiSchema,
}: FieldTemplateProps): ReactElement => {
  const isHidden = isJsonFormWidgetHidden(uiSchema) || hidden

  if (isHidden) {
    return children
  }

  return (
    <WrapIfAdditional
      classNames={classNames}
      disabled={disabled}
      id={id}
      label={label}
      onDropPropertyClick={onDropPropertyClick}
      onKeyChange={onKeyChange}
      readonly={readonly}
      required={required}
      schema={schema}
    >
      <FormControl
        fullWidth
        margin='normal'
        error={!!rawErrors.length}
        required={required}
      >
        {children}
        {displayLabel && rawDescription ? (
          <Typography variant='caption' color='text-secondary'>
            {rawDescription}
          </Typography>
        ) : null}
        {rawErrors.length > 0 && (
          <List dense disablePadding>
            {rawErrors.map(error => {
              return (
                <ListItem key={error} disableGutters>
                  <FormHelperText
                    id={id}
                    sx={{
                      margin: 0,
                    }}
                  >
                    {error}
                  </FormHelperText>
                </ListItem>
              )
            })}
          </List>
        )}
        {rawHelp && <FormHelperText id={id}>{rawHelp}</FormHelperText>}
      </FormControl>
    </WrapIfAdditional>
  )
}

export default FieldTemplate
