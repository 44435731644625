import { useRef, ReactElement } from 'react'
import _ from 'lodash'
import { useSize } from 'ahooks'

// components
import { IconButton } from 'components/common'
import useImageCarousel from 'components/common/Image/useImageCarousel'

// types
import type { WidgetProps } from '@rjsf/core'

import scss from './index.module.scss'

const ImageWidget = ({
  schema,
  value = [],
  readonly,
  formContext,
}: WidgetProps): ReactElement => {
  const { title, maxItems = 1 } = schema || {}
  const { imageResources } = formContext || {}
  const { renderCarousel, renderImages } = useImageCarousel({ imageResources })

  const ref = useRef(null)
  const size = useSize(ref)

  return (
    <>
      <div className='d-flex justify-content-between' ref={ref}>
        <div className={scss.label}>{title}</div>
        <div>
          <IconButton
            icon='FileUploaderIcon'
            size={24}
            className='me-2 p-0 d-inline-block'
            outline
            disabled
          />
          <IconButton
            icon='TakePhotoIcon'
            size={24}
            className='p-0 d-inline-block'
            outline
            disabled
          />
        </div>
      </div>
      {!readonly && (
        <div className='text-secondary my-2'>
          {value?.length || 0}/{maxItems} photos
        </div>
      )}
      {!_.isEmpty(value) &&
        !_.isEmpty(size) &&
        renderImages(value, size.width / 2 - 20)}
      {renderCarousel()}
    </>
  )
}

export default ImageWidget
