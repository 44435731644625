// libraries
import { useMemo, useCallback, ReactElement } from 'react'
import { Form, Field, FormSpy } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import to from 'await-to-js'
import { useToggle } from 'react-use'
import _ from 'lodash'
import isEqual from 'fast-deep-equal'

// utils
import {
  createAncillaryData,
  updateAncillaryData,
} from 'services/api/ancillaryData'
import { showError, showCrudResponseMessage } from 'helpers/message'
import { getFileSizeFormBase64String } from 'helpers/utils'

// constants
import { MESSAGE_ENTITIES, MESSAGE_STATUS } from 'constants/message'
import { DEFAULT_ANCILLARY_DATA } from 'constants/ancillaryData'
import { BACKEND_REQUEST_SIZE, OPERATION_OPTIONS } from 'constants/common'
import { BUTTON_VARIANTS } from 'components/common/Button'

// component
import { Button, Toggle, Loading } from 'components/common'
import { GeofenceField, Input, GeoJSONEditor } from 'components/common/Form'

import type { GeojsonData } from 'types/map'
import type { Payload } from 'types/common'
import type { AncillaryData } from 'types/ancillaryData'
import type { OnListItemChange } from 'components/common/List/hooks/useListItemActions'

import scss from './index.module.scss'

const getFileName = (name: string) => name.split('.').slice(0, -1).join('.')

const isValueChanged = (oldValue: unknown, newValue: unknown): boolean =>
  !_.isEmpty(newValue) && (_.isEmpty(oldValue) || !isEqual(oldValue, newValue))

export const shouldEnableSubmitButton = (
  oldName: string,
  newName: string,
  oldGeojson: GeojsonData,
  newGeojson: GeojsonData
): boolean => {
  const isNameChanged = isValueChanged(oldName, newName)
  const isGeojsonChanged = isValueChanged(oldGeojson, newGeojson)

  return (isNameChanged || isGeojsonChanged) && !_.isEmpty(newGeojson)
}

const validate = (values: Payload): Payload => {
  const errors = {}

  if (!values.name) {
    errors.name = 'Name is required'
  }

  if (_.isEmpty(values.geofence)) {
    errors.geofence = 'Geojson is required'
  }

  return errors
}

export type AncillaryDataGeofenceProps = {
  ancillaryData: AncillaryData
  isLoading?: boolean
  isEditable: boolean
  onCancel: () => void
  onChange: OnListItemChange<AncillaryData>
}

const AncillaryDataGeofence = ({
  ancillaryData,
  onChange,
  onCancel,
  isEditable = true,
  isLoading = false,
}: AncillaryDataGeofenceProps): ReactElement => {
  const { id, name, assetName, assetContent, owner } = ancillaryData || {}

  const [isJsonViewActive, toggleJsonViewActive] = useToggle(false)

  const initialValues = useMemo(
    () =>
      assetContent || name || owner
        ? {
            name,
            geofence: { geojson: assetContent },
            owner,
          }
        : null,
    [assetContent, name, owner]
  )

  const onSubmit = useCallback(
    async (values: AncillaryData) => {
      const isPartialUpdating =
        !_.isEmpty(assetContent) &&
        isEqual(values.geofence.geojson, assetContent)
      const newName = values.name || name || DEFAULT_ANCILLARY_DATA.name
      const newOwner = values.owner || owner
      const shouldAttachThumbnail =
        values.geofence.thumbnail &&
        getFileSizeFormBase64String(values.geofence.thumbnail) <
          BACKEND_REQUEST_SIZE

      const payload = isPartialUpdating
        ? {
            name: newName,
            owner: newOwner,
            ...(!assetName && {
              assetName:
                `${values.name}.geojson` || DEFAULT_ANCILLARY_DATA.assetName,
            }),
          }
        : {
            name: newName,
            owner: newOwner,
            assetName: values.geofence.file
              ? values.geofence.file
              : `${values.name}.geojson`,
            assetContent: values.geofence.geojson,
            ...(shouldAttachThumbnail && {
              thumbnailContent: values.geofence.thumbnail,
              thumbnailContentType: 'image/png',
            }),
            assetType: DEFAULT_ANCILLARY_DATA.assetType,
          }
      const entity = `${MESSAGE_ENTITIES.ancillaryData} ${newName}`

      const [err, result] = id
        ? await to(updateAncillaryData(id, payload))
        : await to(
            createAncillaryData({ ...DEFAULT_ANCILLARY_DATA, ...payload })
          )

      showCrudResponseMessage({
        status: id ? MESSAGE_STATUS.updated : MESSAGE_STATUS.saved,
        entity,
        error: err,
        subject: result,
      })
      if (!err) {
        onChange(id ? OPERATION_OPTIONS.update : OPERATION_OPTIONS.create, {
          ...payload,
          ...result,
        })
      }
    },
    [assetContent, assetName, id, name, onChange, owner]
  )

  const render = ({ handleSubmit, submitError, submitting, values = {} }) => {
    if (submitError) {
      showError(submitError)
    }

    const { name: newName, geofence: { geojson } = {} } = values

    const enableSubmitButton =
      !!id || shouldEnableSubmitButton(name, newName, assetContent, geojson)

    return (
      <form onSubmit={handleSubmit} className={scss.form}>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='flex-fill'>
            <Field name='name' subscription={{}}>
              {({ input: { onChange: onSetChange, value } }) => (
                <FormSpy subscription={{}}>
                  {() => (
                    <OnChange name='geofence.file'>
                      {fieldValue => {
                        if (_.isEmpty(value) && !_.isEmpty(fieldValue)) {
                          onSetChange(getFileName(fieldValue))
                        }
                      }}
                    </OnChange>
                  )}
                </FormSpy>
              )}
            </Field>
            <Field
              component={Input}
              name='name'
              placeholder='Enter a name for your Geofence'
              required
              className={scss.name}
              data-testid='geofence-name'
            />
          </div>
          <div className={`d-flex align-items-center ${scss.toggle}`}>
            <Toggle
              label='GeoJSON View'
              checked={isJsonViewActive}
              onToggle={() => toggleJsonViewActive(!isJsonViewActive)}
              data-testid='geofence-view'
            />
          </div>
        </div>
        <hr className={scss.hr} />
        {isJsonViewActive ? (
          <Field
            component={GeoJSONEditor}
            name='geofence'
            required
            isDisabled={submitting}
            className={scss.editor}
          />
        ) : (
          <Field
            component={GeofenceField}
            name='geofence'
            required
            isDisabled={submitting}
            className={scss.editor}
            enableStylesControl
          />
        )}
        <hr className={scss.hr} />
        <div className='text-end'>
          <Button variant={BUTTON_VARIANTS.secondary} onClick={onCancel}>
            Cancel
          </Button>
          {isEditable && (
            <Button
              variant={BUTTON_VARIANTS.primary}
              type='submit'
              disabled={submitting || !enableSubmitButton || !isEditable}
              isLoading={submitting}
              className='ms-2'
              testId='modal-submit'
            >
              Submit
            </Button>
          )}
        </div>
      </form>
    )
  }
  return isLoading ? (
    <div className={scss.loading}>
      <Loading />
    </div>
  ) : (
    <Form
      {...(initialValues && { initialValues })}
      validate={validate}
      onSubmit={onSubmit}
      render={render}
    />
  )
}

export default AncillaryDataGeofence
