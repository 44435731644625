// libraries
import { memo, useMemo, ReactElement, ReactNode } from 'react'
import _ from 'lodash'

// constants
import { GALLERY_LIST_TYPES } from 'constants/common'
import { THEMES } from 'constants/colour'

// components
import { DataTable, EmptyList, Loading, EmptySearch } from 'components/common'

import type { Item } from 'types/common'
import type { TableOptions } from 'components/common/List/Table'
import type { DataTableProps } from 'components/common/DataTable'

// utils
import { sortListByProperty } from 'helpers/utils'
import { Entity } from 'types/entity'
import useFetchList from './hooks/useFetchList'
import useProcessList from './hooks/useProcessList'
import useListItemActions, {
  OnListItemChange,
} from './hooks/useListItemActions'
import useListItemStatus from './hooks/useListItemStatus'
import useListFilter, { useGalleryListFilter } from './hooks/useListFilter'

export {
  useFetchList,
  useListItemActions,
  useProcessList,
  useListFilter,
  useGalleryListFilter,
  useListItemStatus,
}

export type ListOptions = Partial<{
  enableTitle: boolean
  listType: string
  tableOptions: TableOptions
  sortField: string
  ascOrder: boolean
  enableBulkEdit: boolean
  search: string
  isSearching: boolean
  sortKeyAccessor: (sortField: string) => string
}>

type ListProps<T extends Entity = Entity> = Partial<DataTableProps> & {
  title: string
  options?: ListOptions
  list?: T[]
  activeItem?: T
  listItem: ReactNode
  onChange: OnListItemChange<T>
  setActiveItem?: (v: T) => void
}

const CardsViewList = ({
  list,
  listItem: CardListItem,
  ...rest
}: Pick<ListProps, 'list' | 'onChange' | 'listItem'>): ReactElement => {
  return (
    <>
      {_(list)
        .compact()
        .map(item => <CardListItem {...rest} key={item.id} item={item} />)
        .value()}
    </>
  )
}

const List = memo(
  ({ title, options = {}, list = [], ...rest }: ListProps): ReactElement => {
    const {
      ascOrder,
      enableTitle = false,
      listType = GALLERY_LIST_TYPES.card,
    } = options

    const isViewCard = useMemo(
      () => listType === GALLERY_LIST_TYPES.card,
      [listType]
    )

    const sortedList = useMemo(() => {
      const isSorted = !!options.sortField
      return isViewCard || !isSorted
        ? list
        : (sortListByProperty({
            ...options,
            list,
          }) as Item[])
    }, [isViewCard, list, options])

    const commonProps = {
      ...rest,
      ...options,
      list: sortedList,
    }

    return (
      <>
        {enableTitle && (
          <div className='row gridRow rowHeading'>
            {enableTitle && <h4>{title}</h4>}
          </div>
        )}
        {_.isEmpty(list) ? (
          options.isSearching ? (
            <EmptySearch />
          ) : (
            <EmptyList content={title} theme={THEMES.light} />
          )
        ) : (
          <>
            {isViewCard ? (
              <div className='row g-4 h-auto'>
                <CardsViewList {...commonProps} />
              </div>
            ) : (
              <DataTable {...commonProps} sortOrder={ascOrder} />
            )}
          </>
        )}
      </>
    )
  }
)

const ListContainer = ({
  loading,
  onCancel,
  ...rest
}: ListProps & {
  loading?: boolean
  onCancel?: () => void
}): ReactElement => {
  return loading ? (
    <Loading onClick={onCancel} buttonContent='Pause Loading' />
  ) : (
    <List {...rest} />
  )
}

export default ListContainer
