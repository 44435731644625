// libraries
import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  ReactElement,
  PropsWithChildren,
} from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import _ from 'lodash'
import isEqual from 'fast-deep-equal'
import Color from 'color'

// constants
import { BASE_LAYER_TYPES } from 'constants/map'

// components
import { removePopup } from 'components/map/controls/MapPopup/mapPopupUtil'
import { MapPopup, MapWidgets } from 'components/map/controls'
import { MdClose } from 'components/icons'
import { IconButton, TextInput } from 'components/common'

// utils
import { useMapStateValue } from 'contexts'
import { useBranding } from 'hooks'
import type { MapLayer, MapPickedFeature } from 'types/map'
import scss from './index.module.scss'

export const getVisibleAssetsProfileIds = (layers: MapLayer[]): string[] =>
  _(layers)
    .filter({ baseType: BASE_LAYER_TYPES.asset, isVisible: true })
    .map(layer => _.get(layer, 'profile.assetProfileId'))
    .compact()
    .uniq()
    .value()

const CustomTab = ({
  children,
  selected = false,
}: PropsWithChildren<{ selected?: boolean }>): ReactElement => {
  const {
    colour: { primary },
  } = useBranding()

  const style = useMemo(() => {
    const faded = Color(primary).fade(0.8).string()
    return { borderColor: selected ? primary : faded }
  }, [primary, selected])

  return (
    <Tab className={scss.tab} style={style}>
      {children}
    </Tab>
  )
}

CustomTab.tabsRole = 'Tab'

const TAB_INDEXES = {
  performance: 0,
  activity: 1,
}

const SearchBox = ({
  mapProfileSearch,
  setMapProfileSearch,
  className,
}): ReactElement => {
  const {
    colour: { primary },
  } = useBranding()

  return (
    <div className='position-relative me-2'>
      <TextInput
        value={mapProfileSearch}
        placeholder='Search'
        onChange={(val: string) => setMapProfileSearch(val)}
        className={className}
        style={{
          border: `1px solid ${primary}`,
          color: '#fff',
          backgroundColor: 'transparent',
        }}
        changeOnBlur={false}
        type='search'
      />
      {mapProfileSearch && (
        <IconButton
          icon='MdClose'
          onClick={() => setMapProfileSearch()}
          size={16}
          style={{ position: 'absolute', right: 0, top: 4 }}
        />
      )}
    </div>
  )
}

const SEARCH_KEY = '_search'

export const searchFeatures = (
  mapPickedFeatures: MapPickedFeature[],
  searchString?: string
): MapPickedFeature[] => {
  if (_.isNil(searchString)) return mapPickedFeatures

  return _.filter(mapPickedFeatures, feature => {
    return _.includes(feature[SEARCH_KEY], searchString.toLowerCase())
  })
}

const MapRightPanel = () => {
  const {
    getMapClickedFeatures,
    setMapClickedFeatures,
    setSearchFeatureSelected,
  } = useMapStateValue()

  const mapClickedFeatures = getMapClickedFeatures()

  const [mapProfileSearch, setMapProfileSearch] = useState<string>()

  const [mapProfiles, setMapProfiles] = useState(mapClickedFeatures)

  const [tabIndex, setTabIndex] = useState(0)

  const featuresForSearch = useMemo(() => {
    return _.map(mapClickedFeatures, feature => {
      const { properties } = feature
      return {
        ...feature,
        [SEARCH_KEY]: _(properties)
          .filter('isVisible')
          .map('value')
          .join()
          .toLowerCase(),
      }
    })
  }, [mapClickedFeatures])

  useEffect(() => {
    setMapProfiles(searchFeatures(featuresForSearch, mapProfileSearch))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresForSearch, mapProfileSearch])

  const onClickPopupClose = useCallback(
    (id: string) => {
      const oldLength = mapClickedFeatures.length
      const newMapClickedFeatures = removePopup(id, mapClickedFeatures)
      if (newMapClickedFeatures.length < oldLength) {
        setSearchFeatureSelected({})
      }
      if (isEqual(newMapClickedFeatures, mapClickedFeatures)) return

      setMapClickedFeatures(newMapClickedFeatures)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapClickedFeatures]
  )

  useEffect(() => {
    if (!_.isEmpty(mapClickedFeatures)) {
      setTabIndex(TAB_INDEXES.activity)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapClickedFeatures])

  const renderMapClickedFeatures = useCallback(
    () =>
      _.map(mapProfiles, mapClickedFeature => (
        <MapPopup
          key={mapClickedFeature.id}
          popup={mapClickedFeature}
          onClose={onClickPopupClose}
        />
      )),
    [mapProfiles, onClickPopupClose]
  )

  const sharedTabPanelProps = {
    style: { height: 'calc(100vh - 110px)' },
  }

  return (
    <div
      className={scss.right}
      id='map-right-panel'
      style={{
        backgroundColor: '#051b32',
      }}
    >
      <Tabs selectedIndex={tabIndex} onSelect={setTabIndex} className='h-100'>
        <TabList className={scss.tabList}>
          <CustomTab>Performance</CustomTab>
          <CustomTab>Activity feed</CustomTab>
        </TabList>
        <TabPanel {...sharedTabPanelProps}>
          <div className={scss.panelContainer}>
            <MapWidgets />
          </div>
        </TabPanel>
        <TabPanel {...sharedTabPanelProps}>
          {!_.isEmpty(mapClickedFeatures) && (
            <div className='d-flex justify-content-between mb-1'>
              <button
                className={`${scss.button} btn-block`}
                type='button'
                onClick={() => setMapClickedFeatures([])}
              >
                <MdClose className={scss.icon} size={16} />
                Clear All
              </button>
              <SearchBox
                className={scss.search}
                mapProfileSearch={mapProfileSearch}
                setMapProfileSearch={setMapProfileSearch}
              />
            </div>
          )}
          <div className={`${scss.featuresContainer} ${scss.panelContainer}`}>
            {renderMapClickedFeatures()}
          </div>
        </TabPanel>
      </Tabs>
    </div>
  )
}

const MapRightPanelContainer = (): ReactElement | null => {
  const { mapRightPanelExpanded, getMapClickedFeatures, toggleMapRightPanel } =
    useMapStateValue()

  useEffect(() => {
    if (_.isEmpty(getMapClickedFeatures())) return
    toggleMapRightPanel(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMapClickedFeatures()])

  return mapRightPanelExpanded ? <MapRightPanel /> : null
}

export default MapRightPanelContainer
