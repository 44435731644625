import _ from 'lodash'

import type { Payload } from 'types/common'
import type { PageInfo } from 'types/graphql'
import type { Entity } from 'types/entity'
import type { QueryParams, Fields, SelectedFields } from 'types/services'

import {
  getQuery,
  getMutationQuery,
  getArgs,
  getEdges,
  getPageInfo,
  getConnectionData,
  SuGraphqlError,
} from 'helpers/graphql'
import log from 'helpers/log'
import GraphqlApi from './graphql'

type GetFieldsFn = ({ omitFields, pickFields }: SelectedFields) => Payload

const getFnName = ({
  queryDomain,
  queryName,
  separator = '.',
}: {
  queryDomain?: string
  queryName?: string | null
  separator?: string
}) => _.compact([queryDomain, queryName]).join(separator)

type QueryProp<T> = {
  queryParams?: QueryParams<T>
} & SelectedFields

type GetQueryFn<T> = ({
  queryParams,
  omitFields,
  pickFields,
}: QueryProp<T>) => Payload

type GetQueryByIdFn = (
  id?: string,
  omitFields?: Fields,
  pickFields?: Fields
) => Payload

export type RelayStyleData<T> = {
  data: T[]
  error?: string
  pageInfo: PageInfo
}

export type ListRelayStyleData<T> = {
  queryDomain: string
  fnName: string
  onBatch?: (data: T[]) => void
  getQueryFn: GetQueryFn<T>
  enableLoadMore?: boolean
  queryParams?: QueryParams<T>
  initPageInfo?: PageInfo
  abortController?: AbortController
  queryDisplayName?: string
} & SelectedFields

export const listRelayStyleData = async <T>({
  queryDomain,
  fnName,
  onBatch,
  getQueryFn,
  enableLoadMore = true,
  queryParams = {},
  initPageInfo,
  abortController,
  omitFields,
  pickFields,
  queryDisplayName,
}: ListRelayStyleData<T>): Promise<RelayStyleData<T>> => {
  let pageInfo = (initPageInfo || {}) as PageInfo
  let response
  let data = [] as T[]
  let error
  do {
    const { endCursor } = pageInfo

    const queryParamsWithPageInfo = {
      ...queryParams,
      ...(endCursor && { after: endCursor }),
    }

    const query = getQuery(
      getQueryFn({
        queryParams: queryParamsWithPageInfo,
        omitFields,
        pickFields,
      }),
      queryDisplayName || fnName
    )

    response = await GraphqlApi.fetch({
      query,
      queryDomain,
      fnName,
      abortController,
      variables: queryParamsWithPageInfo,
    })
    error = error || response.error
    const responseData = _.get(response, fnName) || response
    const batchedData = getConnectionData<T>(responseData)
    if (onBatch) {
      onBatch(batchedData)
    }
    data = _.concat(data, batchedData)
    log.info(`[${queryDomain}]: Received ${data.length} lines of data`)
    pageInfo = responseData.pageInfo || {}
  } while (enableLoadMore && pageInfo.hasNextPage)

  if (pageInfo.hasNextPage && _.isEmpty(data)) {
    log.warn(`[${queryDomain}] The current page has no data`)
  }

  return { data, error, pageInfo }
}

export const getResponseData = (
  response: { error: string },
  path: string | Fields,
  ignoreError = false
): unknown => {
  const { error } = response
  const data = _.get(response, path)
  if (!ignoreError && _.isNil(data) && error) throw new Error(error)

  return data
}

export const deserializeEntityData = (data: Entity): Entity => {
  if (!data) return {} as Entity

  return data
}

const DEFAULT_GET_ENTITY_QUERY_NAME = 'byId'

const DEFAULT_LIST_ENTITIES_QUERY_NAME = 'all'

export const getEntityQuery =
  ({
    queryDomain,
    getFieldsFn,
    queryName = DEFAULT_GET_ENTITY_QUERY_NAME,
    identifier = 'id',
  }: {
    getFieldsFn: GetFieldsFn
    queryDomain?: string
    queryName?: string
    identifier?: string
  }) =>
  (
    id: string,
    omitFields?: Fields,
    pickFields?: Fields
  ): Record<string, Payload> => {
    const query = {
      [queryName]: {
        ...getArgs(identifier ? { [identifier]: id } : undefined),
        ...getFieldsFn({ omitFields, pickFields }),
      },
    }

    return queryDomain
      ? {
          [queryDomain]: query,
        }
      : query
  }

export const getEntitiesQuery =
  <T>({
    queryDomain,
    getFieldsFn,
    queryName = DEFAULT_LIST_ENTITIES_QUERY_NAME,
    enablePaging = true,
    noQueryName = false,
  }: {
    queryDomain: string
    getFieldsFn: GetFieldsFn
    queryName?: string
    enablePaging?: boolean
    noQueryName?: boolean
  }) =>
  ({
    queryParams,
    omitFields,
    pickFields,
  }: QueryProp<T>): Record<string, Payload> => {
    const query = {
      ...getArgs(queryParams),
      ...getEdges(getFieldsFn({ omitFields, pickFields })),
      ...(enablePaging && getPageInfo()),
    }

    return {
      [queryDomain]: noQueryName
        ? query
        : {
            [queryName]: query,
          },
    }
  }

type PostProcessFn<T> = (data: T) => T

export const getGraphql =
  <T>({
    queryDomain,
    getQueryFn,
    omitFields,
    pickFields,
    queryDisplayName,
    postProcessFn,
    fnName,
    ignoreError,
  }: {
    queryDomain: string
    getQueryFn: GetQueryFn<T>
    queryDisplayName: string
    postProcessFn?: PostProcessFn<T>
    fnName: string
    ignoreError?: boolean
  } & SelectedFields) =>
  async (variables?: QueryParams<T>): Promise<T> => {
    const query = getQuery(
      getQueryFn({ ...variables, omitFields, pickFields }),
      queryDisplayName
    )
    const response = await GraphqlApi.fetch({
      query,
      queryDomain,
      fnName,
      variables,
      ignoreError,
    })
    const data = (getResponseData(response, fnName) || {}) as T
    return postProcessFn ? postProcessFn(data) : data
  }

export const getEntityGraphql =
  <T>({
    queryDomain,
    getQueryFn,
    omitFields,
    pickFields,
    queryDisplayName,
    postProcessFn = deserializeEntityData,
    queryName = DEFAULT_GET_ENTITY_QUERY_NAME,
  }: {
    queryDomain: string
    getQueryFn: GetQueryByIdFn
    queryDisplayName: string
    postProcessFn?: PostProcessFn<T>
    queryName?: string | null
  } & SelectedFields) =>
  async (id?: string): Promise<T> => {
    const fnName = getFnName({ queryDomain, queryName })
    return getGraphql({
      queryDomain,
      omitFields,
      pickFields,
      queryDisplayName,
      postProcessFn,
      fnName,
      getQueryFn: () => getQueryFn(id, omitFields, pickFields),
    })({ id })
  }

export const listEntitiesGraphql =
  <T>({
    queryDomain,
    getQueryFn,
    queryDisplayName,
    defaultOmitFields,
    defaultPickFields,
    isSingleEntityPostProcessFn = true,
    postProcessFn = deserializeEntityData,
    queryName = DEFAULT_LIST_ENTITIES_QUERY_NAME,
    enableLoadMore,
  }: {
    defaultOmitFields?: Fields
    defaultPickFields?: Fields
    isSingleEntityPostProcessFn?: boolean
    postProcessFn?: PostProcessFn<T>
    queryName?: string | null
  } & ListRelayStyleData<T>) =>
  async ({
    omitFields,
    pickFields,
    ...rest
  }: Omit<
    ListRelayStyleData<T>,
    'getQueryFn' | 'queryDomain' | 'fnName'
  > = {}): Promise<RelayStyleData<T>> => {
    const fnName = getFnName({ queryDomain, queryName })

    const response = await listRelayStyleData<T>({
      ...rest,
      enableLoadMore,
      queryDomain,
      fnName,
      getQueryFn,
      queryDisplayName,
      pickFields: pickFields || defaultPickFields,
      omitFields: omitFields || defaultOmitFields,
    })
    const data = (getResponseData(response, 'data') || {}) as T[]

    return postProcessFn
      ? {
          ...response,
          data: isSingleEntityPostProcessFn
            ? _.map(data, postProcessFn)
            : postProcessFn(data, response),
        }
      : response
  }

export const getResponseError = ({
  isDelete,
  error,
  data,
}: {
  isDelete: boolean
  error?: string
  data?: unknown
}): string | undefined => {
  if (error) {
    log.error('Server error: ', error)
  }
  if (isDelete) {
    if (error) {
      return 'Something went wrong.'
    }
  } else if (error || _.isNil(data)) {
    return 'Something went wrong.'
  }
  return undefined
}

export type MutateEntity = {
  queryDomain: string
  fnName: string
  mutationName?: string
  variableFormat?: string
  responseFields?: Payload
  argsKey?: string | null
  identifier?: string
  responsePath?: Fields
  withIdentifier?: boolean
  ignoreError?: boolean
  isDelete?: boolean
  postProcessFn?: ((data: Payload) => Payload) | null
}

export const mutateEntity =
  <T = unknown>({
    queryDomain,
    fnName,
    mutationName,
    variableFormat,
    responseFields,
    argsKey = 'input',
    identifier = 'id',
    responsePath = [],
    withIdentifier = true,
    ignoreError = false,
    isDelete = false,
    postProcessFn = deserializeEntityData,
  }: MutateEntity) =>
  async (id?: string | null, args?: QueryParams): Promise<T> => {
    const fields = responseFields
    const argsPayload = withIdentifier ? { [identifier]: id, ...args } : args

    const query = getMutationQuery({
      fields,
      variableFormat,
      args: argsPayload,
      variableKey: argsKey,
      mutationName: mutationName || fnName,
    })

    const variables = argsKey ? { [argsKey]: argsPayload } : argsPayload

    const response = await GraphqlApi.fetch({
      query,
      queryDomain,
      fnName,
      variables,
      ignoreError,
    })

    const data =
      getResponseData(response, [fnName, ...responsePath], true) || {}
    const { error, code } = response

    const responseError = getResponseError({ isDelete, data, error })
    const deserializedData = postProcessFn ? postProcessFn(data) : data

    if (ignoreError) {
      return { error: responseError, data: deserializedData }
    }
    if (responseError) {
      throw new SuGraphqlError({ error: responseError, code })
    }

    return deserializedData
  }
