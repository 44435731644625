import { ReactElement } from 'react'
import { utils, WidgetProps } from '@rjsf/core'

import { isJsonFormWidgetHidden } from 'helpers/formBuilder'

const { localToUTC, utcToLocal } = utils

const DateTimeWidget = ({
  value,
  registry,
  onChange,
  uiSchema,
  ...rest
}: WidgetProps): ReactElement => {
  const isHidden = isJsonFormWidgetHidden(uiSchema)

  const { TextWidget } = registry.widgets
  const newValue = utcToLocal(value)
  const handleChange = (v: string): void => {
    onChange(localToUTC(v))
  }

  return isHidden ? (
    <></>
  ) : (
    <TextWidget
      type='datetime-local'
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
      value={newValue}
      onChange={handleChange}
    />
  )
}

export default DateTimeWidget
