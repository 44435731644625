import { useMemo, useState, Dispatch } from 'react'
import _ from 'lodash'
import { useMount } from 'react-use'

// types
import { User, Filters } from 'types/user'

// constants
import { GALLERY_LIST_FILTER_TYPES } from 'constants/common'

// utils
import { isUser } from 'helpers/user'
import { useAuthStateValue } from 'contexts'

type Keys = string[]

type UseListFilterProps = {
  filters: Filters
  defaultFilters?: Filters
  customizedFiltersKeys?: Keys
}

type UseGalleryListFilterProps = {
  entity: string
  customizedFiltersKeys?: Keys
  customizedDefaultFilters?: Filters
}

type PickValidFiltersProps = {
  filters: Filters | undefined
  keys: Keys | undefined
  defaultFilters?: Filters
}

type UseListFilterReturn = {
  filterValues: Filters
  setFilterValues: Dispatch<Filters>
}

type UseGalleryListFilterReturn = UseListFilterReturn

export const pickValidFilters = ({
  filters,
  keys,
  defaultFilters = {},
}: PickValidFiltersProps): Filters => {
  if (filters && _.isEmpty(filters)) return {}
  if (keys && _.isEmpty(keys)) return {}

  const validFilters = filters || defaultFilters

  return keys ? _.pick(validFilters, keys) : validFilters
}

const useListFilter = ({
  filters,
  defaultFilters = {},
  customizedFiltersKeys,
}: UseListFilterProps): UseListFilterReturn => {
  const [filterValues, setFilterValues] = useState<Filters>({})

  useMount(() => {
    const newFilterValues = pickValidFilters({
      filters,
      defaultFilters,
      keys: customizedFiltersKeys,
    })

    setFilterValues(newFilterValues)
  })

  return { filterValues, setFilterValues }
}

export const useGalleryListFilter = ({
  entity,
  customizedFiltersKeys,
  customizedDefaultFilters = {},
}: UseGalleryListFilterProps): UseGalleryListFilterReturn => {
  const { currentUser } = useAuthStateValue()
  const { group: currentUserGroup, role, preferences }: User = currentUser

  const defaultFilters = useMemo(() => {
    return {
      ...customizedDefaultFilters,
      ...(!isUser(role) && {
        [GALLERY_LIST_FILTER_TYPES.group]: [currentUserGroup],
        [GALLERY_LIST_FILTER_TYPES.isPrivate]: [false],
      }),
    }
  }, [currentUserGroup, role, customizedDefaultFilters])

  const filters = useMemo(() => {
    return preferences?.filters?.[entity]
  }, [entity, preferences])

  return useListFilter({
    filters,
    defaultFilters,
    customizedFiltersKeys,
  })
}

export default useListFilter
