// libraries
import _ from 'lodash'

// constants
import { GEOJSON_TYPES } from 'constants/map'
import { ENTITIES, DISPLAY_ISSUE_IN_MAP } from 'constants/common'
import { ENTITY_OWNER_FIELDS } from 'constants/graphql'

import type { Feature, Geometry } from 'geojson'
import type { Asset, AssetProfile } from 'types/asset'
import type { QueryParams } from 'types/services'
import type { Payload } from 'types/common'

// utils
import {
  getArgs,
  getEdges,
  getQuery,
  getPageInfo,
  getQueryFields,
} from 'helpers/graphql'
import {
  mutateEntity,
  listRelayStyleData,
  listEntitiesGraphql,
  getEntitiesQuery,
  getEntityQuery,
  getEntityGraphql,
} from 'services/api/utils'
import { getValidGeometry } from 'helpers/geojson'
import { getIssueGeojsonRow, isListAllowedIssue } from 'helpers/issue'
import { showSuccess, showError, showInfo } from 'helpers/message'
import { getIssuesQueryFields } from './issue'
import GraphqlApi from './graphql'

const domain = ENTITIES.asset
const queryDomain = `${domain}s`
const assetProfileQueryDomain = `${ENTITIES.assetProfile}s`

const getValidGeometryWithoutAltitude = (geometry: Geometry) => {
  const validGeometry = getValidGeometry(geometry)
  const { coordinates, type } = validGeometry
  if (type !== GEOJSON_TYPES.Point) {
    return validGeometry
  }
  const [longitude, latitude] = coordinates
  return { coordinates: [longitude, latitude], type }
}

const getGeojsonAsset = (asset: Feature) => {
  const { properties, geometry } = asset
  return {
    ...asset,
    properties,
    geometry: getValidGeometryWithoutAltitude(geometry),
    type: 'Feature',
  }
}

const getAssetFields = (withIssues: boolean) => {
  return {
    ...(withIssues && {
      issueReferences: { issue: getIssuesQueryFields() },
    }),
    id: true,
    displayName: true,
    profile: true,
    geometry: {
      type: true,
      coordinates: true,
    },
    properties: true,
  }
}

const getAssetByProfileIdQuery = ({
  queryParams: { profileId, after, first, withIssues },
}) => {
  const type = profileId
  const args = { profile: profileId }
  const allArgs = { ...(first && { first }), ...(after && { after }) }

  const assetField = getAssetFields(withIssues)

  return {
    [type]: {
      __aliasFor: 'assets',
      ...getArgs(args),
      all: {
        ...getArgs(allArgs),
        ...getEdges(assetField),
        ...getPageInfo(),
      },
    },
  }
}

const getAssetIssues = ({
  withIssues,
  issueReferences,
  displayName,
  geometry,
  issueAssigneesOptions,
  issueSeverityOptions,
  assetType,
}: {
  withIssues: boolean
}) => {
  return withIssues
    ? _(issueReferences)
        .flatMap('issue')
        .filter(isListAllowedIssue)
        .map(assetIssue => {
          return {
            ...getIssueGeojsonRow(
              issueAssigneesOptions,
              issueSeverityOptions
            )(assetIssue),
            assetName: displayName,
            assetId: assetIssue?.subject?.assetReference?.assetId,
            geometry,
            type: 'Feature',
            assetType,
          }
        })
        .value()
    : []
}

const getAssetWithIssues = ({
  data,
  withIssues,
  issueAssigneesOptions,
  issueSeverityOptions,
  assetType,
}: {
  withIssues: boolean
}) => {
  return data.reduce(
    (acc, asset) => {
      const assetItem = {
        ...asset,
        ...getGeojsonAsset(asset),
      }
      const issues = getAssetIssues({
        ...assetItem,
        assetType,
        withIssues,
        issueAssigneesOptions,
        issueSeverityOptions,
      })
      return {
        ...acc,
        assets: [...acc.assets, assetItem],
        assetIssues: [...acc.assetIssues, ...issues],
      }
    },
    { assets: [], assetIssues: [] }
  )
}

export const getAssetByProfileId = async ({
  onBatch,
  profileId,
  issueAssigneesOptions,
  issueSeverityOptions,
  withIssues = DISPLAY_ISSUE_IN_MAP,
  enableLoadMore = true,
  first = 250,
}: {
  withIssues: boolean
}): Promise<Asset | undefined> => {
  if (!profileId || !_.isString(profileId)) return undefined

  const fnName = `${profileId}.all`
  let loadedRowsLength = 0

  const queryParams = { profileId, withIssues, first }

  if (onBatch) {
    const onAssetBatch = (data: Asset) => {
      const result = getAssetWithIssues({
        data,
        withIssues,
        issueAssigneesOptions,
        issueSeverityOptions,
        assetType: profileId,
      })
      onBatch(result)
      loadedRowsLength += result.assets.length
    }

    const { error } = await listRelayStyleData({
      queryDomain,
      fnName,
      queryParams,
      enableLoadMore,
      getQueryFn: getAssetByProfileIdQuery,
      onBatch: onAssetBatch,
    })

    const assetProfileName = `Asset:${_.capitalize(profileId)}`
    if (error) {
      if (loadedRowsLength > 0) {
        showInfo(
          `An error occurred fetching data. Received ${loadedRowsLength} rows of ${assetProfileName}.`
        )
      } else {
        showError(`Failed to load ${assetProfileName}`)
      }
    } else {
      showSuccess(`Received ${loadedRowsLength} rows of ${assetProfileName}.`)
    }

    onBatch({ loading: false })
    return undefined
  }
  const { data } = await listRelayStyleData({
    queryParams,
    queryDomain,
    fnName,
    getQueryFn: getAssetByProfileIdQuery,
  })

  return getAssetWithIssues({
    data,
    withIssues,
    issueAssigneesOptions,
    issueSeverityOptions,
    assetType: profileId,
  })
}

const getAssetProfileFields = (props?: Payload) => {
  const fields = {
    profile: true,
    title: true,
    properties: {
      name: true,
      displayName: true,
      type: true,
      format: true,
      terms: { key: true, displayName: true },
      termsCompleteness: true,
    },
    ...ENTITY_OWNER_FIELDS,
  }

  const viewConfigurations = {
    id: true,
    name: true,
    map: {
      type: true,
      style: true,
    },
    layouts: {
      mediaType: true,
      widgets: {
        id: true,
        layout: {
          x: true,
          y: true,
          w: true,
          h: true,
        },
        name: true,
        settings: true,
        widget: true,
      },
    },
  }

  const relationships = {
    relationship: true,
    profile: true,
    type: true,
    assetProfile: fields,
  }

  return getQueryFields({ ...fields, relationships, viewConfigurations })(props)
}

const getAssetProfileByIdQuery = getEntityQuery({
  queryDomain: assetProfileQueryDomain,
  getFieldsFn: getAssetProfileFields,
  identifier: 'profile',
})

const getAssetProfilesQuery = getEntitiesQuery<AssetProfile>({
  queryDomain: assetProfileQueryDomain,
  getFieldsFn: getAssetProfileFields,
})

export const listAssetProfiles = listEntitiesGraphql<AssetProfile>({
  queryDomain: assetProfileQueryDomain,
  getQueryFn: getAssetProfilesQuery,
  queryDisplayName: 'GetAllAssetProfiles',
})

const getSampleAssets = ({
  queryParams: { profileId, first },
}: {
  queryParams: QueryParams
}) => {
  const type = profileId
  const args = { profile: profileId }
  const assetField = getAssetFields(false)
  return {
    [type]: {
      __aliasFor: 'assets',
      ...getArgs(args),
      all: {
        ...getArgs({ first }),
        ...getEdges(assetField),
      },
    },
  }
}

export const getSampleAssetByProfileId = async ({
  profile,
  first = 5,
}: {
  profile: string
  first?: number
}): Promise<Asset> => {
  const fnName = `${profile}.all`
  const queryParams = { profileId: profile, first }

  const { data } = await listRelayStyleData({
    queryParams,
    queryDomain,
    fnName,
    getQueryFn: getSampleAssets,
  })

  return _.map(data, asset => {
    return {
      ...asset,
      ...getGeojsonAsset(asset),
    }
  })
}

const getAssetsOptions = ({ queryParams: { profileId, after, first } }) => {
  const type = profileId
  const args = { profile: profileId }
  const allArgs = { ...(first && { first }), ...(after && { after }) }

  return {
    [type]: {
      __aliasFor: 'assets',
      ...getArgs(args),
      all: {
        ...getArgs(allArgs),
        ...getEdges({
          value: { __aliasFor: 'id' },
          label: { __aliasFor: 'displayName' },
        }),
        ...getPageInfo(),
      },
    },
  }
}

export const getAssetsOptionByProfileId = async (
  profileId: string
): Promise<AssetProfile[]> => {
  const fnName = `${profileId}.all`
  const queryParams = { profileId, first: 500 }
  const { data } = await listRelayStyleData<AssetProfile>({
    queryParams,
    queryDomain,
    fnName,
    getQueryFn: getAssetsOptions,
  })
  return data
}

const getAssetRelationshipFields = (relationship: string) => {
  const assetFields = {
    id: true,
    properties: true,
    displayName: true,
  }
  return {
    related: {
      ...getArgs({ relationship }),
      __on: [
        {
          __typeName: 'AssetRelationshipMany',
          assets: assetFields,
        },
        {
          __typeName: 'AssetRelationshipOne',
          asset: assetFields,
        },
      ],
    },
  }
}

const getAssetsQueryFields = ({
  relationship,
  withIssues = false,
  issuePickFields = [],
  omitFields,
} = {}) => {
  const fields = {
    id: true,
    displayName: true,
    properties: true,
    ...(withIssues && {
      issueReferences: {
        issue: getIssuesQueryFields({
          ...(_.isEmpty(issuePickFields)
            ? {
                omitFields: ['annotations', 'statesData', 'statesParameter'],
              }
            : { pickFields: issuePickFields }),
        }),
      },
    }),
    ...(relationship && getAssetRelationshipFields(relationship)),
  }
  return getQueryFields(fields)({ omitFields })
}

const getAssetQuery = ({
  id,
  profile,
  relationship,
  withIssues,
  issuePickFields,
  relationshipProfile,
}) => {
  return {
    assets: {
      ...getArgs({ profile }),
      byId: {
        ...getArgs({ id }),
        ...getAssetsQueryFields({ relationship, withIssues, issuePickFields }),
      },
    },
    ...getAssetProfileByIdQuery(relationshipProfile || profile),
  }
}

export const getAssetById = async (props: Payload): Promise<Asset> => {
  const { withIssues, ...variables } = props
  const fnName = 'assets.byId'
  const query = getQuery(
    getAssetQuery(props),
    `getAssetById${withIssues ? 'WithIssues' : ''}`
  )

  const response = await GraphqlApi.fetch({
    query,
    queryDomain,
    fnName,
    variables,
  })
  const asset = _.get(response, fnName)
  const assetProfile = _.get(response, 'assetProfiles.byId') || {}
  return { ...asset, assetProfile }
}

export const getAssetProfileById = getEntityGraphql<AssetProfile>({
  queryDomain: assetProfileQueryDomain,
  getQueryFn: getAssetProfileByIdQuery,
  queryDisplayName: 'GetAssetProfileById',
})

export const updateAssetProfileViewLayout = async (
  args: QueryParams
): Promise<AssetProfile> => {
  const fnName = 'updateAssetProfileViewConfiguration'
  const responseFields = {
    viewConfiguration: {
      layouts: {
        mediaType: true,
        widgets: {
          id: true,
          name: true,
        },
      },
    },
  }
  return mutateEntity<AssetProfile>({
    queryDomain,
    fnName,
    argsKey: null,
    responseFields,
    ignoreError: true,
    withIdentifier: false,
  })(null, args)
}
