import { useCallback, ReactElement, MouseEvent, KeyboardEvent } from 'react'
import _ from 'lodash'
import styled from '@emotion/styled'

// components
import { StyledField } from 'components/common/Form'

// utils
import {
  getConvertedStringValue,
  sanitizeString,
  isEnterKey,
} from 'helpers/utils'

import type { BrandingColours } from 'types/common'

// scss
import scss from 'components/common/Form/index.module.scss'

const StyledInput = styled.input<{ theme: BrandingColours }>`
  cursor: ${props => (props.readOnly ? 'default' : 'text')};
  &:active,
  &:focus {
    border-color: ${props => (props.readOnly ? '#ddd' : props.theme.primary)};
  }
`

export const numberValueLimit = (
  val: number | string,
  min = -Infinity,
  max = Infinity
): number | null => {
  if (_.isNil(val) || val === '') return null

  const newValue = val < min ? min : val > max ? max : val
  return Number(newValue)
}

export type InputProps = {
  id?: string
  label?: string
  type?: string
  input: {
    name: string
    value: string | number
    onChange: (v: string | number) => void
    onBlur: (v: MouseEvent | KeyboardEvent) => void
    onFocus: (v: MouseEvent | KeyboardEvent) => void
    type: string
  }
  className?: string
  required?: boolean
  autoComplete?: string
  groupOptionStyle?: boolean
  inline?: boolean
  labelClassName?: string
  convertStringType?: string
  addon?: ReactElement
  min?: number
  max?: number
  'data-testid'?: string
  defaultValue?: string
  autoFocus?: boolean
  placeholder?: string
}

const Input = (props: InputProps): ReactElement => {
  const {
    id,
    className,
    input,
    label,
    convertStringType,
    groupOptionStyle,
    required,
    defaultValue,
    autoFocus,
    ...rest
  } = props

  const inputProps = {
    ..._.pick(props, [
      'id',
      'placeholder',
      'required',
      'type',
      'step',
      'max',
      'min',
      'disabled',
      'readOnly',
      'data-testid',
      'autoComplete',
    ]),
    ...input,
    className,
  }

  const { onBlur, onChange, onFocus, name, value = defaultValue } = input

  const onTextChange = useCallback(
    (event: MouseEvent | KeyboardEvent) => {
      onFocus(event)
      const cleanText = sanitizeString(event.target.value) || ''

      const newValue =
        inputProps.type === 'number'
          ? numberValueLimit(cleanText, inputProps.min, inputProps.max)
          : getConvertedStringValue(cleanText.trim(), convertStringType)

      onChange(newValue)
      onBlur(event)
    },
    [
      convertStringType,
      inputProps.max,
      inputProps.min,
      inputProps.type,
      onBlur,
      onChange,
      onFocus,
    ]
  )

  const onKeyPress = (event: KeyboardEvent) => {
    onFocus(event)

    if (isEnterKey(event)) {
      onTextChange(event)
    }
  }

  return (
    <StyledField
      name={name}
      groupOptionStyle={groupOptionStyle}
      label={label}
      required={required}
      {...rest}
    >
      <StyledInput
        {...inputProps}
        value={value}
        onBlur={onTextChange}
        onKeyPress={onKeyPress}
        autoFocus={autoFocus}
      />
    </StyledField>
  )
}

Input.defaultProps = {
  id: undefined,
  label: '',
  type: 'text',
  className: `form-control ${scss.input}`,
  required: false,
  autoComplete: 'on',
  groupOptionStyle: false,
  inline: false,
  labelClassName: 'form-label',
  convertStringType: undefined,
  addon: undefined,
  autoFocus: false,
}

export default Input
