// libraries
import styled from '@emotion/styled'

import type { StyledComponentProps } from 'types/common'

import { TABLE_HEADER_HEIGHT } from './constants'

export const SelectRowCheckBox = styled.div<StyledComponentProps>`
  button {
    color: ${props => props.theme.primary};
  }
`

export const StyledDataTable = styled.div<StyledComponentProps>`
  .p-component {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: normal;
  }

  tbody {
    max-width: 100%; //fix mis-alignment
    // Subtract a table header height from the block height to fix the scrollbar
    min-height: calc(100% - ${TABLE_HEADER_HEIGHT}px);
  }

  .p-datatable .p-datatable-tbody > tr {
    background: ${props => (props.isLightTheme ? props.theme.dark : '#fff')};
    color: ${props => (props.isLightTheme ? '#e3e4e5' : '#222527')};
    transition: box-shadow 0.2s;
  }

  .p-datatable-tbody .p-virtualscroller-content {
    max-width: 100%;
  }

  .p-datatable .p-datatable-thead > tr > th {
    background: ${props =>
      props.isLightTheme ? `#01060a !important` : '#fafafa !important'};

    border: none;
    border-bottom: ${props =>
      props.isLightTheme
        ? `1px solid ${props.theme['primary-800']}`
        : '1px solid #dee2e6'};
    color: ${props => (props.isLightTheme ? '#e3e4e5' : '#222527')};
  }

  .p-datatable .p-datatable-tbody > tr .userPicker {
    color: ${props => (props.isLightTheme ? '#e3e4e5' : '#222527')};
  }

  .p-datatable .p-datatable-tbody > tr > td {
    border: none;
    border-bottom: ${props =>
      props.isLightTheme
        ? `1px solid ${props.theme['primary-800']}`
        : '1px solid #dee2e6'};
  }

  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: ${props =>
      props.isLightTheme
        ? props.theme['primary-900']
        : props.theme['primary-100']};
    color: ${props => props.theme.primary};
  }

  .p-datatable.p-datatable-selectable
    .p-datatable-tbody
    > tr.p-selectable-row:focus {
    outline: 0 none;
  }

  .p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 0.15rem ${props => props.theme.primary};
    outline: 0 none;
  }

  .actionMenu {
    display: none;
  }

  .p-datatable.p-datatable-selectable
    .p-datatable-tbody
    > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    background: ${props =>
      props.isLightTheme
        ? props.theme['primary-900']
        : props.theme['primary-100']};
    color: ${props => props.theme.primary};

    .actionMenu {
      display: block;
    }
  }

  .p-datatable.p-datatable-selectable
    .p-datatable-tbody
    > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover
    .userPicker {
    color: ${props => props.theme.primary};
  }

  .p-datatable
    .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover
    .p-sortable-column-icon {
    color: ${props => props.theme.primary};
  }

  .p-datatable
    .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: ${props => props.theme['primary-100']};
    color: ${props => props.theme.primary};
  }

  .p-datatable .p-sortable-column.p-highlight {
    background: ${props => props.theme['primary-100']};
    color: ${props => props.theme.primary};
  }

  .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: ${props => props.theme['primary-100']};
    color: ${props => props.theme.primary};
  }

  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: ${props => props.theme.primary};
  }

  .p-datatable
    .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover
    .p-sortable-column-icon {
    color: ${props => props.theme.primary};
  }

  .p-datatable .p-datatable-loading-icon {
    font-size: 2rem;
    color: ${props => props.theme.primary};
  }
`

export const DropdownItem = styled.div<StyledComponentProps>`
  :hover {
    background: ${props => props.theme['primary-100']};
  }
`
