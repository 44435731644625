// libraries
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import {
  DataTable as ReactDataTable,
  DataTableProps as ReactDataTableProps,
  DataTableRowGroupHeaderTemplateOptions,
  DataTableRowToggleParams,
  DataTableSelectParams,
} from 'primereact/datatable'
import { Column, ColumnSortParams } from 'primereact/column'

// constants
import { THEMES } from 'constants/colour'
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// utils
import {
  sortListByProperty,
  stopEventDefaultAndPropagation,
} from 'helpers/utils'

// components
import { CardOptions, IconButton } from 'components/common'
import EmptyColumnsList from 'components/common/List/EmptyColumnsList'

import type { ThemeType, Item } from 'types/common'
import type { SelectedItemsActions } from 'components/common/List/hooks/useBulkUpdateList'
import type { CardActions } from 'components/common/List/Card/CardOptions'
import type {
  ColumnOptions,
  SetVisibleColumns,
} from 'components/common/DataTable/useDataTableColumns'

import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primeicons/primeicons.css'
import { SelectRowCheckBox, StyledDataTable } from './styles'
import { GroupHeaderTemplate } from './CellTemplates'

export interface DataTableProps
  extends Omit<ReactDataTableProps, 'sortOrder' | 'list' | 'onRowSelect'> {
  list: Item[]
  loading: boolean
  columns: ColumnOptions[]
  allAvailableColumns: ColumnOptions[]
  setVisibleColumns: SetVisibleColumns
  sortField?: string
  sortOrder?: boolean
  isMultiSort?: boolean
  theme?: ThemeType
  primaryFields?: string[]
  enableBulkEdit?: boolean
  itemActions: CardActions
  selectedIdsSet: Set<string>
  selectedItemsActions: SelectedItemsActions
  enableActions?: boolean
  renderSelectAllCheckbox: (displayLabel: boolean) => void
  tableGroupedBy?: string
}

const BodyActionsTemplate =
  (itemActions: DataTableProps['itemActions']) => (rowData: Item) => {
    return (
      !_.isEmpty(itemActions) && (
        <CardOptions
          {...itemActions}
          subject={rowData}
          className='actionMenu'
          placement={TOOLTIP_PLACEMENT.bottomRight}
        />
      )
    )
  }

const BodySelectTemplate =
  (selectedItemsActions: SelectedItemsActions) => (rowData: Item) => {
    const { id } = rowData
    return (
      <SelectRowCheckBox>
        <IconButton
          icon={
            selectedItemsActions.has(id)
              ? 'MdCheckBox'
              : 'MdCheckBoxOutlineBlank'
          }
          onClick={e => {
            selectedItemsActions.toggle(id)
            stopEventDefaultAndPropagation(e)
          }}
          size={24}
        />
      </SelectRowCheckBox>
    )
  }

const sortFunction = (event: ColumnSortParams) => {
  const { data, field, order } = event
  return sortListByProperty({
    list: data,
    sortField: field,
    ascOrder: order === 1,
  })
}

const DataTable = ({
  list,
  columns = [],
  allAvailableColumns,
  setVisibleColumns,
  sortField,
  sortOrder,
  isMultiSort = false,
  primaryFields,
  itemActions = {},
  selectedIdsSet,
  selectedItemsActions,
  theme = THEMES.light,
  enableBulkEdit = false,
  enableActions = true,
  tableGroupedBy,
}: DataTableProps): ReactElement => {
  const isLightTheme = useMemo(() => theme === THEMES.dark, [theme])
  const { onSelect, onView, onEdit } = itemActions || {}

  const [expandedRows, setExpandedRows] = useState<Item[]>([])

  useEffect(() => {
    if (tableGroupedBy && !_.isEmpty(expandedRows)) {
      // when tableGroupedBy changed, clear out the current expanded rows
      setExpandedRows([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableGroupedBy])

  const validColumnsSpecs = useMemo(() => {
    const commonProps = {
      headerStyle: { width: '3rem', textAlign: 'center' },
      bodyStyle: { textAlign: 'center', overflow: 'visible' },
      field: undefined,
      sortable: false,
      resizeable: false,
    }

    const selectColumnStyle = {
      justifyContent: 'center',
      maxWidth: '75px',
    }

    const selectColumn = enableBulkEdit
      ? {
          ...commonProps,
          header: !_.isEmpty(selectedIdsSet) && (
            <IconButton
              icon='MdCheckBox'
              onClick={() => {
                selectedItemsActions.reset()
              }}
              size={24}
            />
          ),
          body: BodySelectTemplate(selectedItemsActions),
          headerStyle: {
            ...selectColumnStyle,
            height: '60px',
          },
          style: selectColumnStyle,
        }
      : undefined

    const actionColumn =
      enableActions && !_.isEmpty(_.omit(itemActions, 'onChange'))
        ? {
            ...commonProps,
            header: '',
            body: BodyActionsTemplate(itemActions),
          }
        : undefined

    return _.compact([selectColumn, ...columns, actionColumn])
  }, [
    columns,
    enableActions,
    enableBulkEdit,
    itemActions,
    selectedIdsSet,
    selectedItemsActions,
  ])

  const renderColumns = useCallback(() => {
    return _.map(validColumnsSpecs, col => {
      const { header, field, bodyStyle } = col
      return (
        <Column
          key={header as React.Key}
          {...col}
          // !group by can't work together with sort
          {...(tableGroupedBy
            ? { sortable: false }
            : {
                sortField: field,
                sortFunction,
              })}
          bodyStyle={
            _.includes(primaryFields, header)
              ? { fontWeight: 'bold' }
              : bodyStyle
          }
        />
      )
    })
  }, [validColumnsSpecs, primaryFields, tableGroupedBy])

  const onRowSelect = useCallback(
    (e: DataTableSelectParams) => {
      const select = onSelect || onView || onEdit
      if (!_.isFunction(select)) return
      // The onRowSelect event is being triggered when the Modal is on
      // for example, table -> row action -> transfer modal
      // couldn't find a better way to stop that
      // compare the target to allow the click event for the table td
      if (e.originalEvent.target.nodeName !== 'TD') return

      select(e.data)
    },
    [onEdit, onSelect, onView]
  )

  const dataGroupedBy = useMemo(() => {
    return tableGroupedBy
      ? _(list).groupBy(tableGroupedBy).mapValues(_.size).value()
      : {}
  }, [list, tableGroupedBy])

  const footerTemplate = (data: Item) => {
    return (
      <>
        <td colSpan={4} style={{ textAlign: 'right' }}>
          Total
        </td>
        <td>{dataGroupedBy[_.get(data, tableGroupedBy)]}</td>
      </>
    )
  }

  const rowGroupHeaderTemplate = useCallback(
    (
      data: Item,
      groupHeaderOptions: DataTableRowGroupHeaderTemplateOptions
    ) => {
      // It's important to set this boolean to 'true'
      // For the reference: https://github.com/primefaces/primereact/commit/161cdccc1a779c17e3c4e94d6f029b31fbba6971
      groupHeaderOptions.customRendering = true
      const options = { field: tableGroupedBy }
      const template = _.find(columns, options)?.body

      const content = template ? (
        template(data, options)
      ) : (
        <span>{_.get(data, tableGroupedBy || '')}</span>
      )

      return (
        <GroupHeaderTemplate
          data={data}
          allColumnsCount={validColumnsSpecs.length}
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
        >
          {content}
        </GroupHeaderTemplate>
      )
    },
    [
      columns,
      validColumnsSpecs.length,
      tableGroupedBy,
      expandedRows,
      setExpandedRows,
    ]
  )

  const expandableProps = {
    // !key is important otherwise the data will have wrong groups when switching the tableGroupedBy property
    key: tableGroupedBy,
    rowGroupMode: 'subheader',
    groupRowsBy: tableGroupedBy,
    // perhaps a bug from the library
    // !sortField has to be same as the groupRowsBy
    // otherwise there will be duplicate groups
    sortField: tableGroupedBy,
    expandableRowGroups: true,
    expandedRows,
    rowGroupHeaderTemplate,
    rowGroupFooterTemplate: footerTemplate,
    onRowToggle: (e: DataTableRowToggleParams) =>
      setExpandedRows(e.data as Item[]),
    virtualScrollerOptions: undefined,
  }

  const isColumnsListEmpty =
    // if there are no columns
    validColumnsSpecs.length === 0 ||
    // or there is only 1 columns – checkboxes
    (validColumnsSpecs.length === 1 && !validColumnsSpecs[0].field)

  if (isColumnsListEmpty) {
    return (
      <EmptyColumnsList
        allAvailableColumns={allAvailableColumns}
        setVisibleColumns={setVisibleColumns}
      />
    )
  }

  return (
    <>
      <StyledDataTable isLightTheme={isLightTheme} className='w-100 h-100'>
        <ReactDataTable
          value={list}
          sortOrder={sortOrder ? 1 : -1}
          {...(isMultiSort
            ? { sortMode: 'multiple' }
            : { sortMode: 'single', sortField })}
          scrollable
          scrollHeight='flex'
          virtualScrollerOptions={{ itemSize: 50 }}
          dataKey='id'
          rowHover
          responsiveLayout='scroll'
          emptyMessage='No items'
          selectionMode='single'
          resizableColumns
          showGridlines
          onRowSelect={onRowSelect}
          // !!https://github.com/primefaces/primereact/issues/3470
          // when columns are reordered, duplicate columns are added
          // reorderableColumns
          {...(tableGroupedBy && { ...expandableProps })}
        >
          {renderColumns()}
        </ReactDataTable>
      </StyledDataTable>
    </>
  )
}

export default DataTable
