import { Ref, CSSProperties, ReactElement, forwardRef, memo } from 'react'
import styled from '@emotion/styled'
import _ from 'lodash'

// components
import { TextInput, IconButton } from 'components/common'
import { HandleIcon, TrashIcon } from 'svg'

import type { DraggableSyntheticListeners } from '@dnd-kit/core'
import type { Transform } from '@dnd-kit/utilities'

// scss
import scss from './index.module.scss'

export interface Props {
  onChange: ({
    index,
    value,
    label,
  }: {
    index: number
    value: string
    label: string
  }) => void
  dragOverlay?: boolean
  color?: string
  disabled?: boolean
  dragging?: boolean
  handle?: boolean
  handleProps?: unknown
  height?: number
  index: number
  fadeIn?: boolean
  transform?: Transform | null
  listeners?: DraggableSyntheticListeners
  sorting?: boolean
  style?: CSSProperties
  transition?: string | null
  wrapperStyle?: CSSProperties
  item: { label: string; value: string; id: string }[]
  onRemove?(): void
  renderItem?(args: {
    dragOverlay: boolean
    dragging: boolean
    sorting: boolean
    index: number
    fadeIn: boolean
    listeners: DraggableSyntheticListeners
    ref: Ref<HTMLElement>
    style: CSSProperties | undefined
    transform: Props['transform']
    transition: Props['transition']
    value: Props['value']
  }): ReactElement
  isCheckboxList?: boolean
}

const Container = styled.li`
  .row {
    background-color: ${(props: Props) =>
      props.dragging ? props.theme['secondary-light-500'] : 'white'};
    input {
      background: transparent;
    }
  }

  .handle,
  .trashIcon {
    display: none;
  }

  &:hover,
  &:focus {
    .trashIcon,
    .handle {
      display: block;
    }
  }
`

const Item = memo(
  forwardRef<HTMLLIElement, Props>(
    (
      {
        dragging,
        index,
        listeners,
        disabled,
        onRemove = _.noop,
        style,
        item,
        onChange,
        isCheckboxList,
      },
      ref
    ) => {
      const { label, value: optionValue } = item || {}

      return (
        <Container
          ref={ref}
          className={scss.item}
          dragging={dragging}
          style={style}
        >
          <div className={`handle ${scss.handle}`} {...listeners}>
            <HandleIcon />
          </div>
          <div className={`row g-0 ${scss.row}`}>
            {isCheckboxList ? (
              <div className='col-12 d-flex border'>
                <IconButton icon='MdCheckBoxOutlineBlank' disabled />
                <TextInput
                  className='ms-2 form-control border-0'
                  value={optionValue}
                  onChange={newValue =>
                    onChange({
                      ...item,
                      index,
                      value: newValue,
                      label: newValue,
                    })
                  }
                  placeholder='Add value'
                  disabled={disabled}
                />
              </div>
            ) : (
              <>
                <div className='col-6'>
                  <TextInput
                    className='form-control'
                    value={label}
                    onChange={newLabel => {
                      onChange({
                        ...item,
                        index,
                        label: newLabel,
                        value: item.value ? item.value : _.snakeCase(newLabel),
                      })
                    }}
                    placeholder='Add option'
                    disabled={disabled}
                  />
                </div>
                <div className='col-6'>
                  <TextInput
                    className='form-control'
                    value={optionValue}
                    onChange={newValue =>
                      onChange({ ...item, index, value: newValue })
                    }
                    placeholder='Add value'
                    disabled={disabled}
                  />
                </div>
              </>
            )}
          </div>
          <button
            type='button'
            className={`trashIcon ${scss.trashIcon}`}
            onClick={onRemove}
          >
            <TrashIcon />
          </button>
        </Container>
      )
    }
  )
)

export default Item
