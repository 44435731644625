import { getQueryFields } from 'helpers/graphql'
import { getEntityGraphql, getEntityQuery } from 'services/api/utils'

const queryDomain = 'userGroups'

const getUsersGroupFields = getQueryFields({
  'value:name': true,
  label: true,
  preferredRole: true,
  upAuthSuSession: true,
})

const GET_USER_GROUP_QUERY_NAME = 'byName'

const getUserGroupQuery = getEntityQuery({
  queryDomain,
  identifier: 'name',
  getFieldsFn: getUsersGroupFields,
  queryName: GET_USER_GROUP_QUERY_NAME,
})

export const getUserGroup = getEntityGraphql({
  queryDomain,
  getQueryFn: getUserGroupQuery,
  queryName: GET_USER_GROUP_QUERY_NAME,
})

const LIST_USER_GROUPS_QUERY_NAME = 'all'

// listUserGroups is a non-relay style query
const getUserGroupsQuery = getEntityQuery({
  queryDomain,
  identifier: null,
  getFieldsFn: getUsersGroupFields,
  queryName: LIST_USER_GROUPS_QUERY_NAME,
})

export const listUserGroups = getEntityGraphql({
  queryDomain,
  postProcessFn: null,
  getFieldsFn: getUsersGroupFields,
  queryName: LIST_USER_GROUPS_QUERY_NAME,
  getQueryFn: getUserGroupsQuery,
  omitFields: ['upAuthSuSession', 'preferredRole'],
  queryDisplayName: 'GetAllUserGroupsQuery',
})
