// libraries
import { useCallback, useState, useEffect } from 'react'
import _ from 'lodash'
import { useRecoilValue } from 'recoil'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAsync } from 'react-use'

// constants
import { URLS } from 'constants/route'
import { ISSUE_TASK_DATA_COLLECTION_FORM_TYPES } from 'constants/issue'

// utils
import { useProcessList } from 'components/common/List'
import {
  IssueTaskDataCollectionFormsOption,
  issueTaskDataCollectionFormsOptionsState,
} from 'recoilStore/issuesStore'
import { useCreateEntity } from 'hooks'
import { updateList } from 'helpers/utils'
import { useFetchAppSupportData } from 'contexts/hooks'
import { assetsProfilesOptionsSelector } from 'recoilStore/assetsStore'
import { useDataTableColumns } from 'components/common/DataTable/useDataTableColumns'

// components
import { List, ErrorMessage } from 'components/common'
import { NewFormGraphicArtwork } from 'components/icons'
import FormListItem from 'routers/pages/FormGallery/FormListItem'

import type { Payload, UseListProps, UseListState } from 'types/common'
import type { DataCollectionForm } from 'types/issue'
import type { AssetProfileOption } from 'types/workflow'
import type { Entity } from 'types/entity'

import { TABLE_COLUMNS, PAGE_OPTIONS } from './constant'
import useItemActions from './useItemActions'

export type NewDataCollectionFormNavigateState = DataCollectionForm & {
  isNew: boolean
  pathname: string
}

const { entity } = PAGE_OPTIONS

const getFormList = (
  forms: IssueTaskDataCollectionFormsOption[],
  assetProfileOptions: AssetProfileOption[]
): (IssueTaskDataCollectionFormsOption &
  Entity & { secondaryName: string })[] =>
  _(forms)
    .filter({ formType: ISSUE_TASK_DATA_COLLECTION_FORM_TYPES.JSON_FORM })
    .map(({ value, label, modifiedAt, subjectAssetProfile, ...rest }) => {
      const option = _.find(assetProfileOptions, { value: subjectAssetProfile })
      const secondaryName =
        option?.label ||
        subjectAssetProfile ||
        'Form (No subject asset profile)'
      return {
        ...rest,
        id: value,
        name: label,
        audit: {
          updatedTime: modifiedAt,
        },
        type: entity,
        secondaryName,
      }
    })
    .value()

const useList = ({
  listConditions,
}: UseListProps<DataCollectionForm>): UseListState<DataCollectionForm> => {
  const { pathname } = useLocation()

  const navigate = useNavigate()

  const { toggleCreateEntityModal, renderCreateEntityModal } = useCreateEntity()

  const { fetchIssueTaskDataCollectionFormMetadata } = useFetchAppSupportData()

  const assetProfileOptions = useRecoilValue(assetsProfilesOptionsSelector)

  const listState = useAsync(async () => {
    await fetchIssueTaskDataCollectionFormMetadata(true)
  }, [])

  const forms = useRecoilValue(issueTaskDataCollectionFormsOptionsState)

  const [formList, setFormList] = useState<DataCollectionForm[]>(() =>
    getFormList(forms, assetProfileOptions)
  )

  useEffect(() => {
    setFormList(getFormList(forms, assetProfileOptions))
  }, [assetProfileOptions, forms])

  const processedList = useProcessList({
    list: formList,
    conditions: listConditions,
  })

  const onChange = (type: string, payload?: Partial<DataCollectionForm>) => {
    setFormList((oldFormList: DataCollectionForm[]) => {
      return updateList(oldFormList, type, payload)
    })
  }

  const createNewForm = useCallback(
    async (params: Payload) => {
      navigate(URLS.FORM_DESIGNER_NEW, {
        state: {
          ...params,
          pathname,
        } as NewDataCollectionFormNavigateState,
      })
    },
    [navigate, pathname]
  )

  const { columns, visibleColumns, setVisibleColumns } = useDataTableColumns({
    columns: TABLE_COLUMNS,
  })

  const itemActions = useItemActions({ onChange })

  const { error, loading } = listState

  const renderContent = () => {
    return (
      <>
        {error ? (
          <ErrorMessage error={error} />
        ) : (
          <List
            title={entity}
            loading={loading}
            list={processedList}
            listItem={FormListItem}
            onChange={onChange}
            columns={visibleColumns}
            allAvailableColumns={columns}
            setVisibleColumns={setVisibleColumns}
            options={listConditions}
            itemActions={itemActions}
          />
        )}
        {renderCreateEntityModal({
          entity,
          onSubmit: createNewForm,
          image: NewFormGraphicArtwork,
          fields: ['name', 'subjectAssetProfile', 'description', 'group'],
        })}
      </>
    )
  }

  return {
    listState,
    onChange,
    renderContent,
    toggleCreateEntityModal,
    list: processedList,
    columns,
    visibleColumns,
    setVisibleColumns,
  }
}

export default useList
