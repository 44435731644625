import { ReactElement, ReactNode } from 'react'
import { Field } from 'react-final-form'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// components
import { Input } from 'components/common/Form'
import { PageHeader, IconButton, Button, Tooltip } from 'components/common'

import scss from './index.module.scss'
import type { PageHeaderTabsProps } from '..'

const EditorPageHeader = ({
  onReturn,
  className,
  submitting,
  pristine,
  isDisabled,
  titleName,
  titlePlaceholder,
  descriptionName,
  descriptionPlaceholder,
  children,
  displaySubmit = true,
  submitProps,
  returnTooltip,
  tabsProps,
}: {
  onReturn?: () => void
  className?: string
  submitting?: boolean
  pristine?: boolean
  errors?: Record<string, string>
  isDisabled?: boolean
  titleName: string
  titlePlaceholder?: string
  descriptionName?: string
  descriptionPlaceholder?: string
  children?: ReactNode
  displaySubmit?: boolean
  submitProps?: {
    content?: string
    disabled?: boolean
    onSubmit?: () => void
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  }
  returnTooltip?: string
  tabsProps?: PageHeaderTabsProps
}): ReactElement => {
  return (
    <PageHeader
      className={className}
      tabsProps={tabsProps}
      {...(onReturn && {
        iconComponent: (
          <>
            {returnTooltip ? (
              <Tooltip
                placement={TOOLTIP_PLACEMENT.right}
                trigger={['hover']}
                overlay={<span>{returnTooltip}</span>}
              >
                <IconButton
                  testId='arrow-return'
                  icon='FiArrowLeft'
                  size={32}
                  onClick={onReturn}
                />
              </Tooltip>
            ) : (
              <IconButton
                testId='arrow-return'
                icon='FiArrowLeft'
                size={32}
                onClick={onReturn}
              />
            )}
          </>
        ),
      })}
      titleComponent={
        <Field
          component={Input}
          name={titleName}
          placeholder={titlePlaceholder}
          className={scss.input}
          required
          autoComplete='off'
          disabled={isDisabled}
          data-testid='editor-title'
        />
      }
      titleFullWidth
      subtitleComponent={
        <Field
          component={Input}
          name={descriptionName}
          {...(!isDisabled && { placeholder: descriptionPlaceholder })}
          className={`${scss.input} subNav`}
          autoComplete='off'
          disabled={isDisabled}
          data-testid='editor-subTitle'
        />
      }
      {...(displaySubmit && {
        rightComponent: (
          <Button
            type='submit'
            className='ms-2'
            disabled={isDisabled || submitting || pristine}
            isLoading={submitting}
            testId='editor-save'
            {...submitProps}
          >
            {submitProps?.content || 'Save'}
          </Button>
        ),
      })}
    >
      {children}
    </PageHeader>
  )
}

export default EditorPageHeader
