import _ from 'lodash'
import isURL from 'validator/lib/isURL'

// constants
import { DEFAULT_IDENTITY_PROPERTY } from 'constants/common'

// utils
import { getDatasetIdentifier } from 'helpers/unipipe'
import { getQueryFields } from 'helpers/graphql'
import { removeNullValues } from 'helpers/utils'
import { reportErrors } from 'helpers/log'
import { getEntitiesQuery, listEntitiesGraphql } from 'services/api/utils'

const queryDomain = 'catalogs'

const getCatalogFields = getQueryFields({
  catalogId: true,
  group: true,
  datasets: {
    catalogId: true,
    dataset: true,
    displayName: true,
    baseSpecification: true,
    endpoint: true,
    catalogRoleArn: true,
    dynamicMetadata: true,
    properties: {
      name: true,
      displayName: true,
      type: true,
      format: true,
      terms: { key: true, displayName: true },
      termsCompleteness: true,
    },
    specificationParameters: {
      name: true,
      displayName: true,
      type: true,
      format: true,
      necessity: true,
      options: { key: true, displayName: true },
    },
    timeliness: true,
    hints: true,
    assetProfile: true,
    identityProperty: true,
  },
})

const getCatalogsQuery = getEntitiesQuery({
  queryDomain,
  getFieldsFn: getCatalogFields,
})

export const getSpecificationParameters = spec => {
  return _.reduce(
    spec,
    (acc, value) => {
      const { name } = value
      return { ...acc, [name]: removeNullValues(value) }
    },
    {}
  )
}

// example catalog item https://des.dev.sensorup.com/catalog/ct-speed-live-concrete
const deserializeCatalog = ({ group, datasets }) => {
  return _.map(datasets, datasetItem => {
    const { catalogId, dataset, specificationParameters, endpoint } =
      datasetItem

    const isEndpointValid = endpoint && isURL(endpoint)

    if (!isEndpointValid) {
      const errorMessage = `${_.upperFirst(
        queryDomain
      )} Endpoint of the dataset[${dataset}] is not valid and the dataset will be hidden`
      reportErrors(errorMessage, { datasetItem })
    }

    const datasetIdentifier = getDatasetIdentifier(catalogId, dataset)

    return {
      [`${datasetIdentifier}`]: {
        assetProfile: undefined,
        identityProperty: DEFAULT_IDENTITY_PROPERTY,
        ...datasetItem,
        specificationParameters: getSpecificationParameters(
          specificationParameters
        ),
        group,
      },
    }
  })
}

const deserializeCatalogs = catalogs => {
  return _.assign({}, ..._.flatMap(catalogs, deserializeCatalog))
}

export const listCatalogs = listEntitiesGraphql({
  queryDomain,
  getQueryFn: getCatalogsQuery,
  queryDisplayName: 'GetAllCatalogs',
  postProcessFn: deserializeCatalogs,
  isSingleEntityPostProcessFn: false,
})
