import {
  ReactElement,
  LazyExoticComponent,
  ComponentType,
  useMemo,
} from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'

// constants
import { LOGIN_URL } from 'constants/route'

// utils
import { isServerRequestForPDFGenerating } from 'services/authentication'
import { useAuthStateValue } from 'contexts'

// components
import { MainLayout } from 'routers/layouts'

import type { LayoutFC } from 'types/common'

type LayoutOptions = {
  showSideBar: boolean
  showToggle: boolean
}

const ProtectedElement = ({
  component: Component,
  componentProps = {},
  layout,
  layoutOptions,
  isTokenProtected = false,
}: {
  component: LazyExoticComponent<ComponentType<unknown>>
  layout?: LayoutFC
  layoutOptions?: LayoutOptions
  isTokenProtected?: boolean
  componentProps?: Record<string, unknown>
}): ReactElement => {
  const { isUserSignedIn, currentUser } = useAuthStateValue()

  const Layout = layout || MainLayout

  const hasValidToken = useMemo(
    () => isTokenProtected && isServerRequestForPDFGenerating(),
    [isTokenProtected]
  )

  const location = useLocation()
  const { pathname, hash } = location
  const { access_token: cognitoAccessToken, id_token: cognitoIdToken } =
    useMemo(() => queryString.parse(hash), [hash])

  if (hasValidToken || (currentUser && isUserSignedIn)) {
    return (
      <Layout options={layoutOptions}>
        <Component {...componentProps} />
      </Layout>
    )
  }

  const isFederatedUserSignedInRedirect = cognitoAccessToken && cognitoIdToken
  if (isFederatedUserSignedInRedirect) {
    window.location.href = `${pathname}${hash}`
    return <></>
  }

  return (
    <Navigate
      to={{
        pathname: LOGIN_URL,
      }}
      state={{ referrer: location }}
      replace
    />
  )
}

export default ProtectedElement
