// libraries
import { ReactElement, useMemo, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import _ from 'lodash'
import { saveAs } from 'file-saver'

// constants
import { FILE_MIME_TYPE_EXTENSIONS_MAPPING } from 'constants/common'

// utils
import { formatBytes } from 'helpers/utils'

// components
import { IconButton } from 'components/common'
import { SpreadsheetIcon } from 'components/icons'

import scss from '../index.module.scss'

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

const UploadFile = ({
  onChange,
  files = [],
  disabled,
}: {
  onChange: (v: File[]) => void
  files?: []
  disabled: boolean
}): ReactElement => {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    // open,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    accept: FILE_MIME_TYPE_EXTENSIONS_MAPPING.xlsx,
  })

  const [uploadedFiles, setUploadedFiles] = useState<File[]>(files)

  const currentFiles = _.map(uploadedFiles, ({ path, name, size }) => (
    <div
      className={`${scss.filePreview} d-flex justify-content-between align-items-start`}
      key={path}
    >
      <div className='d-flex'>
        <SpreadsheetIcon />
        <div className='ms-2'>
          <div className='defaultText'>{name}</div>
          <div className='text-secondary'>{formatBytes(size)}</div>
        </div>
      </div>
      <IconButton
        icon='FiX'
        onClick={() => {
          setUploadedFiles(oldFiles => _.reject(oldFiles, { path }))
        }}
        disabled={disabled}
      />
    </div>
  ))

  useEffect(() => {
    if (_.isEmpty(acceptedFiles)) return

    setUploadedFiles(acceptedFiles)
  }, [acceptedFiles])

  useEffect(() => {
    onChange(uploadedFiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles])

  const style = useMemo(
    () => ({
      ...(isFocused && focusedStyle),
      ...(isDragAccept && acceptStyle),
      ...(isDragReject && rejectStyle),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  const downloadTemplate = () => {
    saveAs(
      `${process.env.PUBLIC_URL}/assets/file/dynamic_scheduling_template.xlsx`,
      'dynamic_scheduling_template.xlsx'
    )
  }

  return (
    <div>
      {_.isEmpty(currentFiles) ? (
        <div {...getRootProps({ className: `${scss.dropZone}`, style })}>
          <input {...getInputProps()} />
          <div>Drag and drop .xlsx here</div>
          {/* https://github.com/react-dropzone/react-dropzone/issues/1239 */}
          {/* <div>
            Or{' '}
            <span className={scss.textButton} onClick={open}>
              click here
            </span>
          </div> */}

          <div className={scss.textButton} onClick={downloadTemplate}>
            Use template
          </div>
        </div>
      ) : (
        <div>{currentFiles}</div>
      )}
    </div>
  )
}

export default UploadFile
