import { CompositeLayer, ScatterplotLayer, TextLayer } from 'deck.gl'
import { getTextSubLayerProps } from 'components/map/layers/deckLayers/textLayerHelper'

export default class LabeledScatterplotLayer extends CompositeLayer {
  renderLayers() {
    const {
      id,
      data,
      visible,
      radiusScale,
      opacity,
      radiusMinPixels,
      radiusMaxPixels,
      pickable,
      autoHighlight,
      highlightedObjectIndex,
      getPosition,
      getRadius,
      getFillColor,
      updateTriggers,
    } = this.props

    return [
      // the bg icons
      new ScatterplotLayer(
        this.getSubLayerProps({
          // `getSubLayerProps` will concat the parent layer id with this id
          id: `${id}-point`,
          data,
          visible,

          radiusScale,
          opacity,
          radiusMinPixels,
          radiusMaxPixels,

          getPosition,
          getRadius,
          getFillColor,

          pickable,
          autoHighlight,
          highlightedObjectIndex,

          updateTriggers: {
            getPosition: updateTriggers.getPosition,
            getRadius: updateTriggers.getRadius,
            getFillColor: updateTriggers.getFillColor,
          },
        })
      ),

      new TextLayer(this.getSubLayerProps(getTextSubLayerProps(this.props))),
    ]
  }
}

LabeledScatterplotLayer.layerName = ' LabeledScatterplotLayer'

LabeledScatterplotLayer.defaultProps = {
  // points
  getPosition: { type: 'accessor', value: d => d.geometry.coordinates },
  radiusScale: { type: 'number', min: 0, value: 1 },
  radiusMinPixels: { type: 'number', min: 0, value: 0 }, //  min point radius in pixels
  radiusMaxPixels: { type: 'number', min: 0, value: Number.MAX_SAFE_INTEGER }, // max point radius in pixels
  getRadius: { type: 'accessor', value: 1 },
  getFillColor: { type: 'accessor', value: [0, 0, 0, 255] },
  pickable: true,
  autoHighlight: true,
}
