import { PropsWithChildren, ReactElement, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import _ from 'lodash'

// utility
import { stopEventDefaultAndPropagation } from 'helpers/utils'
import type { Value } from 'types/common'
import type { DropdownOption } from '..'

import scss from './index.module.scss'

const LIST_BORDER_RADIUS = 6

const StyledItem = styled.li<{
  isLightTheme?: boolean
}>`
  &:first-of-type {
    border-top-left-radius: ${LIST_BORDER_RADIUS}px;
    border-top-right-radius: ${LIST_BORDER_RADIUS}px;
  }
  &:last-child {
    border-bottom-left-radius: ${LIST_BORDER_RADIUS}px;
    border-bottom-right-radius: ${LIST_BORDER_RADIUS}px;
  }
  &:hover {
    background-color: ${props =>
      props.isLightTheme ? props.theme['secondary-light-500'] : '#495057'};
  }
`

const DropdownBody = ({
  className,
  onChange,
  toggleListVisible,
  isLightTheme,
  options,
}: PropsWithChildren<{
  className?: string
  onChange?: (v?: Value) => void
  toggleListVisible: (nextValue?: boolean) => void
  isLightTheme?: boolean
  options?: DropdownOption[]
}>): ReactElement => {
  const onClickHandler = useCallback(
    (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>,
      value?: Value,
      onClick?: DropdownOption['onClick']
    ) => {
      stopEventDefaultAndPropagation(event)
      if (_.isFunction(onClick)) {
        onClick()
      } else if (onChange) {
        onChange(value)
      }

      toggleListVisible(false)
    },
    [onChange, toggleListVisible]
  )

  const optionList = useMemo(
    () =>
      _(options)
        .reject('disabled')
        .map(({ label, key, value, onClick, dataTestid }) => {
          const optionKey = key || `${value}`
          return (
            <StyledItem
              isLightTheme={isLightTheme}
              key={optionKey}
              onClick={event => onClickHandler(event, value, onClick)}
              className={scss.item}
              data-testid={dataTestid || `dropdown-options-${optionKey}`}
            >
              {label || value}
            </StyledItem>
          )
        })
        .value(),
    [isLightTheme, onClickHandler, options]
  )

  return <ul className={`${scss.optionsList} ${className}`}>{optionList}</ul>
}

export default DropdownBody
