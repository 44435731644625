// libraries
/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import { jsx } from '@emotion/react'
import React, { useRef, useMemo } from 'react'
import { useToggle } from 'react-use'
import { GiEarthAmerica } from 'react-icons/gi'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

// scss
import scss from './index.module.scss'

const MapStyleControl = ({ className, children }) => {
  const [isStylePickerVisible, toggleStylePickerVisible] = useToggle(false)
  const iconRef = useRef()

  const { current } = iconRef

  const { x, y } = current ? current.getBoundingClientRect() : {}

  const css = useMemo(() => {
    return {
      left: x,
      top: y,
    }
  }, [x, y])

  return (
    <>
      {isStylePickerVisible && x && y
        ? ReactDOM.createPortal(
            <div
              className={`${scss.container} d-flex align-items-center`}
              css={css}
              onMouseLeave={() => toggleStylePickerVisible(false)}
            >
              {children}
            </div>,
            document.body
          )
        : null}
      <button
        type='button'
        className={`${scss.expandButton} ${className}`}
        onClick={() => toggleStylePickerVisible()}
        onMouseEnter={() => toggleStylePickerVisible(true)}
        ref={iconRef}
      >
        <GiEarthAmerica className={scss.expandIcon} />
      </button>
    </>
  )
}

MapStyleControl.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

MapStyleControl.defaultProps = {
  className: '',
  children: undefined,
}

export default MapStyleControl
