import React, { useMemo, useCallback, useState } from 'react'
import { useMount } from 'react-use'
import PropTypes from 'prop-types'
import _ from 'lodash'

// utils
import { getWidgetLineOptions } from 'components/widget/common/widgetRenderHelper/line'
import { getPropertyOptions } from 'components/assets/assetsProfile/hooks/useBaseAssetWidgetPanel'

// constants
import { WIDGET_TYPES } from 'constants/widget'
import { THEMES } from 'constants/colour'
import { ASSET_PROFILE_MEDIA_TYPES } from 'constants/assets'

// components
import { LineWidget, NoAvailableWidget } from 'components/widget'
import { BaseWidgetWithAssetState } from 'components/assets/assetsProfile/widgets/BaseWidget'

const DisplayRelatedAssetsValues = ({ value, timezone, settings, theme }) => {
  const { assets, asset } = value?.related || {}
  const { properties } = value?.assetProfile || {}

  const [props, setProps] = useState(null)

  const { series, options } = props || {}

  const propertyOptions = useMemo(
    () => getPropertyOptions(properties),
    [properties]
  )

  useMount(() => {
    const getProps = async () => {
      const { type } = settings
      const result = await getWidgetLineOptions({
        style: settings[type],
        widgetData: assets || [asset],
        dataMerge: false,
        timezone,
        propertyOptions,
        lineType: settings.type,
        xAxisPropertyPath: 'displayName',
      })

      setProps(result)
    }
    getProps()
  })

  return options && !_.isEmpty(series) ? (
    <LineWidget series={series} {...options} theme={theme} />
  ) : (
    <NoAvailableWidget widgetType={WIDGET_TYPES.line} content='no data' />
  )
}

DisplayRelatedAssetsValues.propTypes = {
  value: PropTypes.shape({
    related: PropTypes.shape({
      asset: PropTypes.shape({
        id: PropTypes.string,
        displayName: PropTypes.string,
        properties: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      assets: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          displayName: PropTypes.string,
          properties: PropTypes.arrayOf(PropTypes.shape({})),
        })
      ),
    }),
    assetProfile: PropTypes.shape({
      properties: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  timezone: PropTypes.string,
  settings: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
}

DisplayRelatedAssetsValues.defaultProps = {
  timezone: undefined,
  theme: THEMES.dark,
}

const AssetLineWidget = ({
  assetId,
  settings,
  theme,
  name,
  onWidgetLoad,
  mediaType,
}) => {
  const isPrintable = useMemo(
    () => mediaType === ASSET_PROFILE_MEDIA_TYPES.PRINTABLE,
    [mediaType]
  )

  const render = useCallback(
    props => {
      const { relatedAssetRelationshipId } = settings

      const sharedProps = {
        ...props,
        settings,
        theme,
      }
      return relatedAssetRelationshipId ? (
        <DisplayRelatedAssetsValues {...sharedProps} />
      ) : (
        <NoAvailableWidget
          widgetType={WIDGET_TYPES.line}
          content='related asset Id is missing'
        />
      )
    },
    [settings, theme]
  )

  return (
    <BaseWidgetWithAssetState
      assetId={assetId}
      settings={settings}
      render={render}
      name={name}
      theme={theme}
      onWidgetLoad={onWidgetLoad}
      isPrintable={isPrintable}
    />
  )
}

AssetLineWidget.propTypes = {
  assetId: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    assetProfileId: PropTypes.string.isRequired,
    relatedAssetRelationshipId: PropTypes.string,
    relatedAssetProfileId: PropTypes.string,
    properties: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
  name: PropTypes.string,
  onWidgetLoad: PropTypes.func,
  mediaType: PropTypes.string.isRequired,
}

AssetLineWidget.defaultProps = {
  theme: THEMES.dark,
  name: undefined,
  onWidgetLoad: undefined,
}

export default AssetLineWidget
