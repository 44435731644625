import { ReactElement, useMemo } from 'react'
import styled from '@emotion/styled'
import { CSSTransition } from 'react-transition-group'
import { useToggle, useUpdateEffect } from 'react-use'
// utils
import { IconButton } from 'components/common'

import { BrandingColours } from 'types/common'
import scss from './index.module.scss'

const AccordionHeader = styled.div(
  ({
    theme,
    expanded,
    flush,
    content,
  }: {
    theme: BrandingColours
    expanded: boolean
    flush: boolean
    content?: ReactElement | string
  }) => {
    return {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderBottom: '1px solid #eaeaea',
      backgroundColor: expanded
        ? theme['primary-100']
        : flush
        ? '#fff'
        : theme.light,
      '&:hover': {
        backgroundColor: content ? theme['primary-100'] : '#fff',
      },
    }
  }
)

const AccordionCollapseDiv = styled.div`
  width: 50px;
  text-align: center;
  margin-left: 8px;
  margin-right: -8px;
  border-left: 1px solid #eaeaea;
  .svgButton {
    cursor: pointer !important;
  }
`

const Accordion = ({
  title,
  addOn,
  content,
  expanded,
  className = '',
  titleClassName = '',
  bodyClassName = '',
  isLoading = false,
  collapsible = true,
  status,
  flush = false,
  toggleExpand = undefined,
  testId = '',
}: {
  title: ReactElement | string
  addOn?: ReactElement | string
  content?: ReactElement | string
  status?: ReactElement | string
  expanded?: boolean
  className?: string
  titleClassName?: string
  bodyClassName?: string
  isLoading?: boolean
  collapsible?: boolean
  flush?: boolean
  toggleExpand?: (v: boolean) => void
  testId?: string
}): ReactElement => {
  const [expandedValue, onToggle] = useToggle(expanded || false)

  const icon = useMemo(() => {
    if (isLoading) return 'FaSpinner'
    return expandedValue ? 'MdKeyboardArrowUp' : 'MdKeyboardArrowDown'
  }, [expandedValue, isLoading])

  useUpdateEffect(() => {
    onToggle(expanded)
  }, [expanded])

  const handleToggle = (flag: boolean) => {
    if (toggleExpand) {
      toggleExpand(flag)
    } else {
      onToggle()
    }
  }

  const accordionFlag = expanded || false
  return (
    <div className={`${className || scss.container}`}>
      <AccordionHeader
        {...(collapsible && { onClick: () => handleToggle(!accordionFlag) })}
        className={`${scss.title} ${titleClassName} ${
          collapsible ? 'cursor-pointer' : ''
        }`}
        expanded={expandedValue}
        flush={flush}
        data-testid={testId}
        content={content}
      >
        <div className='row g-2'>
          <div className='col-md-7 col-sm-6 align-self-center'>{title}</div>
          <div className='col-md-5 col-sm-6 d-flex align-items-center justify-content-end align-self-center'>
            {status && <div className='status'>{status}</div>}
            {addOn}
            {collapsible && (
              <AccordionCollapseDiv>
                <IconButton icon={icon} size={24} />
              </AccordionCollapseDiv>
            )}
          </div>
        </div>
      </AccordionHeader>
      {collapsible && (
        <CSSTransition
          in={expandedValue}
          mountOnEnter
          unmountOnExit
          timeout={300}
          classNames='accToggleableContent'
        >
          <div className={`${bodyClassName}`}>
            <div className={`${scss.body}`}>{content}</div>
          </div>
        </CSSTransition>
      )}
    </div>
  )
}

export default Accordion
