// libraries
import { ReactElement, useMemo, useState } from 'react'
import _ from 'lodash'
import { useAsyncFn, useToggle } from 'react-use'

// components
import { Dropdown, DeleteModal, TransferModal } from 'components/common'
import { TransferOwner } from 'components/common/Modal/TransferModal'
import * as Icons from 'components/icons'

// utils
import { useAbility, useBranding } from 'hooks'
import { useListItemStatus } from 'components/common/List'

import type { Payload, ToggleFn, TooltipPlacement } from 'types/common'
import type { GalleryItem, Owner } from 'types/entity'
import type { OnListItemChange } from '../../hooks/useListItemActions'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isDisabled = (ability?: boolean, func?: (...args: any[]) => any) =>
  !ability || !_.isFunction(func)

const Option = ({
  icon,
  label,
}: {
  icon?: string
  label: string
}): ReactElement => {
  const Icon = _.get(Icons, icon || 'MdEdit') || Icons.MdEdit
  return (
    <>
      <Icon />
      <span className='ms-1'>{label}</span>
    </>
  )
}

export type CardActions<T = unknown> = Partial<{
  onView: (item: T, getUrlOnly?: boolean) => void
  onEdit: (item: T, getUrlOnly?: boolean) => void
  onDelete: (item: T) => Promise<void>
  onChange: OnListItemChange<T>
  onFavorite: (item: T) => Promise<void>
  onTransfer: (item: T, owner: Owner) => Promise<void>
  onShare: (item: T, unShare: boolean) => Promise<void>
  onClone: (item: T) => Promise<void>
  onPreDelete: (item: T) => Promise<Payload>
  onSelect: (item: T) => void
}>

export type CardOptionsProps<T> = CardActions<T> & {
  subject: T
  placement?: TooltipPlacement
  className?: string
  toggleComponent?: ToggleFn
}

export const CardOptions = ({
  subject,
  onShare,
  onDelete,
  onPreDelete,
  onView,
  onEdit,
  onTransfer,
  onClone,
  placement,
  toggleComponent,
  className,
}: CardOptionsProps<GalleryItem>): ReactElement => {
  const abilities = useAbility(subject)

  const { isMine, isShared } = useListItemStatus({
    listItem: subject,
  })

  const { canRead, canUpdate, canDelete } = abilities

  const [additionalOptions, setAdditionalOptions] = useState({})

  const {
    menus: { disableInsteadOfHide },
  } = useBranding()

  const [showDeleteModal, toggleDeleteModal] = useToggle(false)

  const [showTransferModal, toggleTransferModal] = useToggle(false)

  const memorizedOptions = useMemo(() => {
    const { id } = subject

    const allOptions = _.map(
      {
        View: {
          icon: 'MdPageview',
          onClick: () => {
            if (onView) {
              onView(subject)
            }
          },
          disabled: isDisabled(canRead, onView),
        },
        Edit: {
          icon: 'MdEdit',
          onClick: () => {
            if (onEdit) {
              onEdit(subject)
            }
          },
          disabled: isDisabled(canUpdate, onEdit),
          dataTestid: 'dropdown-options-edit',
        },
        Clone: {
          value: 'Clone',
          icon: 'MdContentCopy',
          onClick: () => onClone && onClone(subject),
          disabled: isDisabled(canRead, onClone),
          dataTestid: 'dropdown-options-delete',
        },
        Share: {
          ...(isShared
            ? { icon: 'MdStopScreenShare', value: 'Unshare' }
            : { icon: 'MdScreenShare', value: 'Share' }),
          onClick: async () => {
            if (onShare) {
              await onShare(subject, isShared)
            }
          },
          disabled: isDisabled(canUpdate, onShare),
        },
        Transfer: {
          icon: 'RiFileTransferLine',
          onClick: () => {
            toggleTransferModal(true)
          },
          disabled: isDisabled(canUpdate, onTransfer),
        },
        Delete: {
          icon: 'MdDelete',
          onClick: async () => {
            if (_.isFunction(onPreDelete)) {
              setAdditionalOptions(await onPreDelete(subject))
            }
            toggleDeleteModal(true)
          },
          disabled: isDisabled(canDelete, onDelete),
        },
      },
      ({ icon, value, ...rest }, key) => {
        const label = value || key
        return {
          ...rest,
          value: label,
          key: `${id}-option-${label}`,
          label: <Option icon={icon} label={label} />,
        }
      }
    )
    return disableInsteadOfHide ? allOptions : _.reject(allOptions, 'disabled')
  }, [
    onView,
    onEdit,
    canRead,
    onClone,
    canUpdate,
    canDelete,
    onDelete,
    isShared,
    onShare,
    onTransfer,
    disableInsteadOfHide,
    onPreDelete,
    toggleDeleteModal,
    subject,
    toggleTransferModal,
  ])

  const [deleteState, deleteItem] = useAsyncFn(async () => {
    if (!onDelete) return undefined

    return onDelete(subject)
  }, [onDelete, subject])

  const [transferState, transferWithOwner] = useAsyncFn(
    async (owner: TransferOwner) => {
      if (!onTransfer) return undefined

      const response = await onTransfer(subject, owner)
      toggleTransferModal(false)
      return response
    },
    [onTransfer]
  )

  return (
    <>
      {!_.isEmpty(memorizedOptions) && (
        <Dropdown
          placement={placement}
          size={16}
          toggleComponent={toggleComponent}
          containerClassName={className}
          icon='FaEllipsisV'
          options={memorizedOptions}
          testId='ellipsis-dropdown-button'
        />
      )}

      {!isDisabled(canDelete, onDelete) && (
        <DeleteModal
          isLoading={deleteState.loading}
          isShowing={showDeleteModal}
          toggle={toggleDeleteModal}
          deleteItem={async () => {
            await deleteItem()
            toggleDeleteModal(false)
            setAdditionalOptions({})
          }}
          isMine={isMine}
          subject={subject}
          {...(additionalOptions || {})}
        />
      )}
      {!isDisabled(canUpdate, onTransfer) && (
        <TransferModal
          isLoading={transferState.loading}
          isShowing={showTransferModal}
          toggle={toggleTransferModal}
          transferWithOwner={transferWithOwner}
          subject={subject}
        />
      )}
    </>
  )
}

export default CardOptions
