// libraries
import { Component, ErrorInfo, ReactElement } from 'react'
import { Navigate } from 'react-router-dom'

// constants
import { HOME_PAGE_URL } from 'constants/common'

// utils
import log, { reportErrors } from 'helpers/log'
import { isDevEnvironment } from 'helpers/utils'
import { showError } from 'helpers/message'

import type { To } from 'history'

type ErrorBoundaryProps = {
  enable?: boolean
  children: ReactElement
  redirectUrl?: To
}

type ErrorBoundaryState = Pick<ErrorBoundaryProps, 'enable' | 'redirectUrl'> & {
  errorInfo?: ErrorInfo
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      enable: props.enable || !isDevEnvironment(),
      redirectUrl: props.redirectUrl,
    }
  }

  componentDidCatch(
    error: Error,
    errorInfo: ErrorBoundaryState['errorInfo']
  ): void {
    // Catch errors in any components below and re-render with error message
    this.setState({
      errorInfo,
    })

    const message = `Something went wrong. ${error.toString()}`
    showError(message)
    log.debug(`[ErrorBoundary]${message}`, errorInfo?.componentStack)
    reportErrors(error)
  }

  render(): ReactElement {
    const { enable, errorInfo, redirectUrl = HOME_PAGE_URL } = this.state
    if (enable && errorInfo) {
      return <Navigate to={redirectUrl} replace />
    }

    const { children } = this.props
    return children
  }
}

export default ErrorBoundary
