// libraries
import { useMemo, ReactElement } from 'react'
import { ToastContainer, Slide, toast } from 'react-toastify'
import { useMount } from 'react-use'
import { EventBus } from 'ts-bus'
import { BusProvider } from 'ts-bus/react'
import { Helmet } from 'react-helmet'
import * as Sentry from '@sentry/react'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from '@emotion/react'
import _ from 'lodash'

// utils
import {
  StateProvider,
  ConfigProvider,
  AbilityProvider,
  AuthenticationProvider,
} from 'contexts'
import { getBrandingColours } from 'helpers/colour'
import { useBranding } from 'hooks'
import { deleteAllDatabaseAtCurrentHost } from 'helpers/db'

// constants
import { NOTIFICATION_CLOSE_TIME } from 'constants/settings'

// components
import SuRoutes from 'routers/routes'
import { AppError } from 'components/common'

import 'mapbox-gl/dist/mapbox-gl.css'
import type { BrandingColours } from 'types/common'

import variables from 'SUExplorer.module.scss'

const sharedToastContainerProps = {
  autoClose: NOTIFICATION_CLOSE_TIME,
  newestOnTop: true,
  transition: Slide,
  pauseOnHover: false,
  closeOnClick: true,
  hideProgressBar: true,
  draggable: false,
  limit: 3,
  theme: 'light',
}
// global bus
const bus = new EventBus()

const SUExplorer = () => {
  const { title, colour = {} } = useBranding()

  const adjustedColour = useMemo(() => {
    const { primary } = colour || {}

    return {
      ...colour,
      ...getBrandingColours(primary),
      ..._.omit(variables, 'app'),
    } as BrandingColours
  }, [colour])

  useMount(() => {
    deleteAllDatabaseAtCurrentHost()
  })

  return (
    <ThemeProvider theme={adjustedColour}>
      <AuthenticationProvider>
        <>
          <Helmet>
            <title>{title || 'SensorUp Explorer'}</title>
          </Helmet>
          <BusProvider value={bus}>
            <SuRoutes />
          </BusProvider>
        </>
      </AuthenticationProvider>
    </ThemeProvider>
  )
}

const SUExplorerContainer = (): ReactElement => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        return <AppError message={error.toString()} />
      }}
    >
      <ToastContainer
        {...sharedToastContainerProps}
        position={toast.POSITION.TOP_CENTER}
        data-testid='toast-message'
      />
      <RecoilRoot>
        <ConfigProvider>
          <StateProvider>
            <AbilityProvider>
              <SUExplorer />
            </AbilityProvider>
          </StateProvider>
        </ConfigProvider>
      </RecoilRoot>
    </Sentry.ErrorBoundary>
  )
}

export default SUExplorerContainer
