import { forwardRef, ReactElement } from 'react'
import styled from '@emotion/styled'

import { BrandingColours } from 'types/common'

const StyledUL = styled.ul<{
  active: boolean
  borderLeft: boolean
  theme: BrandingColours
}>`
  font-size: 12px;
  border-left: ${props =>
    props.borderLeft
      ? props.active
        ? `3px solid ${props.theme.primary}`
        : '3px solid #d9d9d9'
      : 'none'};
`

const ListGroup = forwardRef(
  (
    {
      active = true,
      borderLeft = false,
      className,
      children,
      ...rest
    }: {
      active?: boolean
      className?: string
      borderLeft?: boolean
      children?: ReactElement
      bgColour?: boolean
    },
    ref
  ) => {
    return (
      <StyledUL
        ref={ref}
        className={`list-group ${className}`}
        active={active}
        borderLeft={borderLeft}
        {...rest}
      >
        {children}
      </StyledUL>
    )
  }
)

export default ListGroup
