import { ReactElement, ChangeEvent, FocusEvent } from 'react'
import { WidgetProps } from '@rjsf/core'
import { FormLabel, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import _ from 'lodash'

import type { Option, Options } from 'types/common'

const selectValue = (value: string, selected: string, all: string[]) => {
  const at = all.indexOf(value)
  const updated = selected.slice(0, at).concat(value, selected.slice(at))

  // As inserting values at predefined index positions doesn't work with empty
  // arrays, we need to reorder the updated selection to match the initial order
  return updated.sort((a: string, b: string) => all.indexOf(a) > all.indexOf(b))
}

const deselectValue = (value: string, selected: string) => {
  return selected.filter((v: string) => v !== value)
}

const CheckboxesWidget = ({
  schema,
  label,
  id,
  disabled,
  options,
  value,
  autofocus,
  readonly,
  required,
  onChange,
  onBlur,
  onFocus,
}: WidgetProps): ReactElement => {
  const { enumOptions = [], enumDisabled, inline } = options || {}

  const handleChange =
    (option: Option) =>
    ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
      const all = (enumOptions as Options).map(
        ({ value: newValue }) => newValue
      )

      if (checked) {
        onChange(selectValue(option.value, value, all))
      } else {
        onChange(deselectValue(option.value, value))
      }
    }

  const handleBlur = ({
    target: { value: newValue },
  }: FocusEvent<HTMLButtonElement>) => onBlur(id, newValue)
  const handleFocus = ({
    target: { value: newValue },
  }: FocusEvent<HTMLButtonElement>) => onFocus(id, newValue)

  return (
    <>
      <FormLabel required={required} htmlFor={id}>
        {label || schema.title}
      </FormLabel>
      <FormGroup row={!!inline}>
        {(enumOptions as Options).map((option: Option, index: number) => {
          const checked = _.includes(value, option.value)

          const itemDisabled =
            enumDisabled && _.includes(enumDisabled as Options, option.value)

          const checkbox = (
            <Checkbox
              id={`${id}_${index}`}
              checked={checked}
              disabled={disabled || itemDisabled || readonly}
              autoFocus={autofocus && index === 0}
              onChange={handleChange(option)}
              onBlur={handleBlur}
              onFocus={handleFocus}
            />
          )
          return (
            <FormControlLabel
              control={checkbox}
              key={`${id}_${option.value}`}
              label={option.label}
            />
          )
        })}
      </FormGroup>
    </>
  )
}

export default CheckboxesWidget
