// libraries
import { ReactNode } from 'react'
import PropTypes from 'prop-types'

// component
import { Avatar } from 'components/common'

// types
import type { User } from 'types/user'
import type { Function } from 'types/common'

// scss
import scss from './index.module.scss'

type Props = {
  user: User,
  titleClassName: string,
  avatarSize: string,
  children: ReactNode,
  onClickAvatar: Function,
}

const UserMedia = ({
  user,
  titleClassName,
  avatarSize,
  children,
  onClickAvatar,
}: Props) => {
  const { id, username, email, familyName, givenName } = user

  return (
    <div className='d-flex flex-grow-1'>
      <div
        className='flex-shrink-0'
        {...(onClickAvatar ? { onClick: onClickAvatar } : {})}
      >
        <Avatar user={user} key={username || id} size={avatarSize} />
      </div>
      <div className='flex-grow-1 ms-3'>
        <div
          data-testid='user-name'
          data-user-id={username}
          className={`mt-0 ${titleClassName} ${scss.name} ${scss.ellipsis}`}
        >
          {givenName} {familyName}
        </div>
        <div className={`${scss.email} ${scss.ellipsis}`}>{email}</div>
        {children}
      </div>
    </div>
  )
}

UserMedia.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string,
    familyName: PropTypes.string.isRequired,
    givenName: PropTypes.string.isRequired,
    role: PropTypes.string,
    externalUsername: PropTypes.string,
  }).isRequired,
  titleClassName: PropTypes.string,
  avatarSize: PropTypes.string,
  children: PropTypes.node,
  onClickAvatar: PropTypes.func,
}

UserMedia.defaultProps = {
  titleClassName: '',
  avatarSize: undefined,
  children: undefined,
  onClickAvatar: undefined,
}

export default UserMedia
