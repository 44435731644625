import { memo, ReactElement, useState, useMemo } from 'react'
import styled from '@emotion/styled'
import _ from 'lodash'
import { useMount } from 'react-use'
import CssFilterConverter from 'css-filter-converter'
import { useTheme } from '@emotion/react'

import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'

import { BrandingColours } from 'types/common'

import scss from './index.module.scss'

export type IconPosition = {
  x: number
  y: number
}

export type IconItem = {
  id: number
  x: number
  y: number
}

export const IconSvgButton = styled.svg<
  {
    colourFilter: string
  } & IconPosition
>`
  background-image: url('${process.env.PUBLIC_URL}/assets/icons/sprite.svg');
  background-position: -${props => props.x}px -${props => props.y}px;
  filter: ${props => props.colourFilter};
`

const DEFAULT_ICON_CSS_FILTER = `invert(86%) sepia(24%) saturate(11%) hue-rotate(281deg) brightness(78%) contrast(102%)`

export const DEFAULT_ACTIVE_ICON_CSS_FILTER = `invert(51%) sepia(56%) saturate(3048%) hue-rotate(181deg)
    brightness(93%) contrast(96%)`

const IconPicker = ({
  iconPosition,
  onIconChange,
}: {
  iconPosition?: IconPosition
  onIconChange: (newPosition: { x: number; y: number }) => void
}): ReactElement => {
  const theme = useTheme() as BrandingColours

  const [icons, setIcons] = useState<IconItem[][]>([])
  const [selectedIconPosition, setSelectedIconPosition] =
    useState<IconPosition>(iconPosition)

  const onIconClick = (x: number, y: number) => {
    const newIconPosition = { x, y }
    setSelectedIconPosition(newIconPosition)
    onIconChange(newIconPosition)
  }

  const activeColourFilter = useMemo((): string => {
    const { color, error } = CssFilterConverter.hexToFilter(theme.primary)
    if (!error) return color as string
    return DEFAULT_ACTIVE_ICON_CSS_FILTER
  }, [theme.primary])

  const renderIconItem = ({ id, x, y }: IconItem) => {
    const isIconSelected =
      x === selectedIconPosition?.x && y === selectedIconPosition?.y

    return (
      <li
        key={id}
        className={scss.iconCollectionBlock}
        onClick={() => onIconClick(x, y)}
      >
        <IconSvgButton
          className={scss.iconCollectionItem}
          colourFilter={
            isIconSelected ? activeColourFilter : DEFAULT_ICON_CSS_FILTER
          }
          x={x}
          y={y}
        />
      </li>
    )
  }

  const renderIconRow = (row: IconItem[], index: number) => {
    return (
      <ul
        className={`${scss.iconCollectionRow} d-flex justify-content-between`}
        key={`iconrow-${index}`}
      >
        {row.map(renderIconItem)}
      </ul>
    )
  }

  const renderIcons = () => {
    const total = 99
    const cols = 16
    const rows = 7
    const offset = 144
    const iconList = []

    for (let i = 0; i < rows; i += 1) {
      for (let j = 0; j < cols; j += 1) {
        const index = i * cols + j

        if (index === total) {
          break
        }
        if (index > 1) {
          const icon = { id: index, x: j * offset, y: i * offset }
          iconList.push(icon)
        }
      }
    }
    return iconList
  }

  useMount(() => {
    const result = renderIcons()
    const chunkResult = _.chunk(result, 6)
    setIcons(chunkResult)
  })

  return (
    <>
      <div className={scss.iconCollection}>
        {icons.length > 0 && icons.map(renderIconRow)}
      </div>
    </>
  )
}

IconPicker.defaultProps = {
  iconPosition: LAYER_VIS_CONFIGS.iconPosition.defaultValue,
}

export default memo(IconPicker)
