import { ReactElement, ChangeEvent, FocusEvent } from 'react'
import { WidgetProps, utils } from '@rjsf/core'
import { TextField, MenuItem } from '@mui/material'
import _ from 'lodash'

// utils
import { getValueWhenSwitchingBetweenSingleSelectAndMultiSelect } from 'helpers/formBuilder'

// types
import type { SingleSelectWidget, MultiSelectWidget } from 'types/formBuilder'
import type { Options, Option } from 'types/common'

const { asNumber, guessType } = utils

const nums = new Set(['number', 'integer'])

/**
 * This is a silly limitation in the DOM where option change event values are
 * always retrieved as strings.
 */
const processValue = (
  schema: SingleSelectWidget & MultiSelectWidget,
  value: string
) => {
  const { type, items, enum: schemaEnum } = schema

  if (value === '') {
    return undefined
  }

  if (type === 'array' && !!items) {
    if (nums.has(items.type)) {
      return _.map(value, asNumber)
    }
    return _.map(value)
  }

  if (type === 'boolean') {
    return value === 'true'
  }

  if (type === 'number') {
    return asNumber(value)
  }

  // If type is undefined, but an enum is present, try and infer the type from
  // the enum values
  if (schemaEnum) {
    if (schemaEnum.every((x: string | number) => guessType(x) === 'number')) {
      return asNumber(value)
    }
    if (schemaEnum.every((x: string | number) => guessType(x) === 'boolean')) {
      return value === 'true'
    }
  }

  return value
}

const SelectWidget = ({
  schema,
  id,
  options,
  label,
  required,
  disabled,
  readonly,
  value,
  multiple,
  autofocus,
  onChange,
  onBlur,
  onFocus,
  rawErrors = [],
}: WidgetProps): ReactElement => {
  const { enumOptions, enumDisabled } = options

  const emptyValue = multiple ? [] : ''

  const updatedValue = getValueWhenSwitchingBetweenSingleSelectAndMultiSelect(
    value,
    multiple
  )

  const initialValue = _.isUndefined(updatedValue) ? emptyValue : updatedValue

  const handleChange = ({
    target: { value: newValue },
  }: ChangeEvent<{ name?: string; value: unknown }>) =>
    onChange(processValue(schema, newValue))

  const handleBlur = ({
    target: { value: newValue },
  }: FocusEvent<HTMLInputElement>) => onBlur(id, processValue(schema, newValue))

  const handleFocus = ({
    target: { value: newValue },
  }: FocusEvent<HTMLInputElement>) =>
    onFocus(id, processValue(schema, newValue))

  return (
    <TextField
      id={id}
      label={label || schema.title}
      select
      value={initialValue}
      required={required}
      disabled={disabled || readonly}
      autoFocus={autofocus}
      error={rawErrors.length > 0}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      InputLabelProps={{
        shrink: true,
      }}
      SelectProps={{
        multiple: _.isUndefined(value) ? false : multiple,
      }}
    >
      {(enumOptions as Options).map(
        ({ value: optionValue, label: optionLabel }: Option, i: number) => {
          const isDisabled =
            enumDisabled && _.includes(enumDisabled, optionValue)

          return (
            <MenuItem
              key={optionValue || i}
              value={optionValue}
              disabled={isDisabled}
            >
              {optionLabel}
            </MenuItem>
          )
        }
      )}
    </TextField>
  )
}

export default SelectWidget
