import { ReactElement } from 'react'
import { WidgetProps, utils } from '@rjsf/core'
import { Box, Button } from '@mui/material'

const { getSubmitButtonOptions } = utils
const SubmitButton = ({ uiSchema }: WidgetProps): ReactElement => {
  const {
    submitText,
    norender,
    props: submitButtonProps,
  } = getSubmitButtonOptions(uiSchema)
  if (norender) return null

  return (
    <Box marginTop={3}>
      <Button
        type='submit'
        variant='contained'
        color='primary'
        {...submitButtonProps}
      >
        {submitText}
      </Button>
    </Box>
  )
}

export default SubmitButton
