// libraries
import React, { useMemo, useState, useEffect } from 'react'
import _ from 'lodash'
import { useMountedState } from 'react-use'
import isEqual from 'fast-deep-equal'

// components
import Widget from 'components/widget'

// utils
import { getLayerFilteredData } from 'helpers/filter'
import { useTimezone } from 'hooks'
import { reportMessage } from 'helpers/log'
import { getVisiblePropertiesValuesSet } from 'helpers/layerProfile'

// contexts
import { useStateValue, useMapStateValue } from 'contexts'
import {
  isLiveDataset,
  getDatasetOptionByDatasetNameAndCatalogId,
} from 'helpers/unipipe'

const MapWidgets = () => {
  const isMounted = useMountedState()

  const {
    selectors: {
      unipipeSelectors: { mapEligibleDatasetOptions },
    },
  } = useStateValue()

  const { map, getLayersFilteredData, viewportBounds, isSpatialFilterEnabled } =
    useMapStateValue()

  const { layers, selectedDateTimeRange } = map

  const [layerWithWidgets, setLayerWithWidgets] = useState([])
  const { timezone } = useTimezone()

  /**
   * get visible widgets list from visible layers
   */
  useEffect(
    () => {
      const layersFilteredData = getLayersFilteredData()
      Promise.all(
        _(layers)
          .filter('isVisible')
          .map(async layer => {
            const { timeliness, widgets } = layer
            const visibleWidgets = _.filter(widgets, 'isVisible')
            const data = getLayerFilteredData({
              layer,
              layersFilteredData,
              bbox: viewportBounds,
              isSpatialFilterEnabled,
              pickFields: ['properties'],
            })
            return { ...layer, widgets: visibleWidgets, timeliness, data }
          })
          .compact()
          .value()
      )
        .then(widgets => {
          if (isMounted()) {
            setLayerWithWidgets(oldWidgets =>
              isEqual(oldWidgets, widgets) ? oldWidgets : widgets
            )
          }
        })
        .catch(e =>
          reportMessage(
            `[Map Widgets]Something wrong when processing widgets data.${e.message}`
          )
        )
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      layers,
      getLayersFilteredData(),
      selectedDateTimeRange,
      viewportBounds,
      isSpatialFilterEnabled,
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  )

  return useMemo(
    () =>
      _.flatMap(layerWithWidgets, layer => {
        const {
          widgets,
          timeliness,
          data,
          dataset,
          catalogId,
          selectedProperties: {
            enable: enableSelectedProperties,
            properties,
          } = {},
        } = layer

        const datasetOption = getDatasetOptionByDatasetNameAndCatalogId(
          mapEligibleDatasetOptions,
          dataset,
          catalogId
        )

        const { identityProperty } = datasetOption || {}

        const visibleProperties = getVisiblePropertiesValuesSet(
          properties,
          identityProperty,
          false
        )

        const nonLiveDataset = !isLiveDataset(timeliness)
        return widgets.map(({ id, name, settings, type }) => (
          <Widget
            {...(enableSelectedProperties && { visibleProperties })}
            {...(nonLiveDataset && { selectedDateTimeRange })}
            key={id}
            id={id}
            title={name}
            settings={settings}
            type={type}
            data={data}
            nonLiveDataset={nonLiveDataset}
            timezone={timezone}
            propertyOptions={datasetOption?.properties}
            identityProperty={identityProperty}
          />
        ))
      }),

    [
      layerWithWidgets,
      mapEligibleDatasetOptions,
      selectedDateTimeRange,
      timezone,
    ]
  )
}

export default MapWidgets
