// libraries
import _ from 'lodash'
import { GeoJsonLayer } from 'deck.gl'

// constants
import { MAP_TEMPORAL_SETTING_KEY, LAYER_TYPES } from 'constants/map'
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'

// utils
import { getHighlightedObjectIndex } from 'helpers/map'
import { getOpacity } from 'components/map/layers/deckLayers/baseLayer'

// components
import AggregationLayer from '../aggregationLayer'

export const getPolygonLayerProps = ({
  basicProps,
  config,
  profileProps = {},
  timeFilterProps = {},
  highlightedObjectIndex,
}) => {
  const {
    visConfig: {
      opacity,
      elevationScale,
      isStroked,
      colourPropertyValue,
      aggregationForColour,
    },
  } = config
  const isExtruded = elevationScale !== 0
  const stylingProps = {
    opacity: getOpacity(opacity),
    elevationScale,
    extruded: isExtruded,
    stroked: isStroked,
    filled: true,
    wireframe: true,
    lineWidthMinPixels: 1,
    getLineColor: [80, 80, 80],
    getLineWidth: 1,
    autoHighlight: true,
    ...getHighlightedObjectIndex(highlightedObjectIndex),
  }

  const dataAccessors = {
    getPolygon: d => d.geometry.coordinates,
    getFillColor:
      colourPropertyValue || aggregationForColour
        ? d => d.fillColour
        : LAYER_VIS_CONFIGS.fillColour.defaultValue,
    getElevation: d => d.elevation,
  }

  return {
    ...basicProps,
    ...stylingProps,
    ...profileProps,
    ...dataAccessors,
    ...timeFilterProps,
  }
}

export const polygonVisConfigs = {
  opacity: 'opacity',
  colourRange: 'colourRange',
  colourClasses: 'colourClasses',
  valueRangeForColour: 'valueRangeForColour',
  aggregationForColour: 'aggregationForColour',
  elevationScale: 'elevationScale',
  valueRangeForHeight: 'valueRangeForHeight',
  aggregationForHeight: 'aggregationForHeight',
  cache: MAP_TEMPORAL_SETTING_KEY,
  isStroked: 'isStroked',
}

export default class PolygonLayer extends AggregationLayer {
  constructor(props) {
    super(props)
    this.registerVisConfig(polygonVisConfigs, props.style[this.type])
  }

  get type() {
    return LAYER_TYPES.polygon
  }

  renderLayer = ({ layerData, profileHandler }) => {
    if (_.isEmpty(layerData)) return null

    const profileProps = this.getProfileConfig(profileHandler)
    const basicProps = this.getLayerBasicProps({ layerData })
    const layerProps = getPolygonLayerProps({
      config: this.config,
      basicProps,
      profileProps,
    })

    return [new GeoJsonLayer(layerProps)]
  }
}
