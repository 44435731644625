import _ from 'lodash'

export const DYNAMIC_SCHEDULING_CONFIGS = {
  minVisitPerWorker: {
    type: 'number',
    defaultValue: 0,
    displayName: 'Minimum visits per worker',
    range: [0, 10],
    step: 1,
  },
}

export const DYNAMIC_SCHEDULING_SETTINGS =
  DYNAMIC_SCHEDULING_CONFIGS.minVisitPerWorker

export const DYNAMIC_SCHEDULING_SETTINGS_DEFAULTS = _.reduce(
  DYNAMIC_SCHEDULING_CONFIGS,
  (acc, cur, key) => {
    const { defaultValue } = cur
    return { ...acc, [key]: defaultValue }
  },
  {}
)
