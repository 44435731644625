import { ReactElement, CSSProperties } from 'react'
import _ from 'lodash'
import { Toggle } from 'components/common'
import { StyledField } from 'components/common/Form'

const FormToggle = (props: {
  labelClassName?: string
  style?: CSSProperties
  description?: string
  required?: boolean
  groupOptionStyle?: boolean
  name: string
  input: {
    value: boolean
    onChange: (v: boolean) => void
    onBlur: (v: boolean) => void
  }
  label: string
  toggleLabel: string
  inline: boolean
  defaultValue?: boolean
  testId?: string
}): ReactElement => {
  const {
    labelClassName,
    style,
    description,
    required,
    groupOptionStyle,
    name,
    input,
    label,
    toggleLabel,
    inline = false,
    defaultValue = false,
    testId = 'toggle-switch',
  } = props

  const { value, onBlur = _.noop, onChange } = input

  const inputProps = {
    ..._.pick(props, ['id', 'disabled']),
    ...input,
  }

  return (
    <StyledField
      name={name}
      groupOptionStyle={groupOptionStyle}
      label={label}
      required={required}
      description={description}
      style={style}
      labelClassName={labelClassName}
      inline={inline}
    >
      <Toggle
        {...inputProps}
        label={toggleLabel}
        onBlur={onBlur}
        checked={value || defaultValue}
        onToggle={onChange}
        data-testid={testId}
      />
    </StyledField>
  )
}

export default FormToggle
