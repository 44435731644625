// libraries
import _ from 'lodash'
import to from 'await-to-js'
import keymirror from 'keymirror'

// constants
import {
  AUDITS_FIELDS,
  ENTITY_OWNER_FIELDS,
  GEOMETRY_FIELDS,
  BASIC_MUTATION_FIELDS,
} from 'constants/graphql'
import {
  SITE_FEATURE_TYPES,
  LOCATION_MAX_DEPTH,
  DEFAULT_SITE_THUMB_URL,
  AUTO_UPDATE_SITE_DEBOUNCE_IN_MS,
} from 'constants/site'
import { OPERATION_OPTIONS, ENTITIES } from 'constants/common'
import { MESSAGE_STATUS } from 'constants/message'

// utils
import {
  getEdges,
  getQuery,
  getMutationQuery,
  reportGraphqlError,
  getQueryFields,
} from 'helpers/graphql'
import { capitalizeFirstLetter } from 'helpers/utils'
import { isSite } from 'helpers/site'
import { showCrudResponseMessage } from 'helpers/message'
import {
  getEntitiesQuery,
  listEntitiesGraphql,
  getEntityGraphql,
  getEntityQuery,
} from 'services/api/utils'

import type { Payload } from 'types/common'
import type { Fields, SelectedFields } from 'types/services'
import type { Site, SiteFeature, SiteFeatureType } from 'types/sites'

import GraphqlApi from './graphql'

const domain = ENTITIES.site
const queryDomain = `${domain}s`

export const reportSiteError = reportGraphqlError(queryDomain)

const getSiteFeatureProperties = (properties = {}) => {
  return {
    name: true,
    description: true,
    userProperties: true,
    ...properties,
  }
}

const getSiteFeatureCommonFields = (properties: Payload) => ({
  id: true,
  featureType: true,
  type: true,
  properties: getSiteFeatureProperties(properties),
})

const SITE_FIELDS = {
  ...getSiteFeatureCommonFields({ category: true }),
  ...ENTITY_OWNER_FIELDS,
  ...AUDITS_FIELDS,
  thumbUrl: true,
  active: true,
  ...GEOMETRY_FIELDS,
}

const getFeatureQuery = (
  featureType: SiteFeatureType,
  featureSpecificFields = {}
) => {
  const mergedFields = _.merge(
    {},
    getSiteFeatureCommonFields({ parentId: true }),
    GEOMETRY_FIELDS,
    featureSpecificFields
  )
  return {
    [`${featureType}s`]: getEdges(mergedFields),
  }
}

const BEACON_SPECIFIC_FIELDS = {
  properties: {
    deviceId: true,
    active: true,
    sensitivity: true,
    brand: true,
    UUID: true,
    macAddress: true,
    major: true,
    minor: true,
  },
}

const UNITS_SPECIFIC_FIELDS = {
  properties: {
    category: true,
    height: true,
  },
}

const LOCATIONS_SPECIFIC_FIELDS = {
  properties: {
    category: true,
    department: true,
  },
}

const LEVEL_SPECIFIC_FIELDS = {
  properties: {
    ordinal: true,
    floorHeight: true,
    height: true,
    category: true,
  },
}

const BUILDING_SPECIFIC_FIELDS = {
  properties: {
    height: true,
    category: true,
  },
}

const getCommonChildFeatureQuery = () => {
  const beacons = getFeatureQuery(
    SITE_FEATURE_TYPES.beacon,
    BEACON_SPECIFIC_FIELDS
  )
  const pathwayFields = { ...beacons }

  return {
    ...beacons,
    ...getFeatureQuery(SITE_FEATURE_TYPES.pathway, pathwayFields),
  }
}

const getUnitsQuery = () => {
  const unitFields = {
    ...UNITS_SPECIFIC_FIELDS,
    ...getCommonChildFeatureQuery(),
  }
  return getFeatureQuery(SITE_FEATURE_TYPES.unit, unitFields)
}

const getLevelsQuery = () => {
  const units = getUnitsQuery()
  const levelFields = {
    ...units,
    ...LEVEL_SPECIFIC_FIELDS,
    ...getCommonChildFeatureQuery(),
  }
  return getFeatureQuery(SITE_FEATURE_TYPES.level, levelFields)
}

const getBuildingsQuery = () => {
  const levels = getLevelsQuery()
  const buildingFields = {
    ...levels,
    ...BUILDING_SPECIFIC_FIELDS,
    ...getCommonChildFeatureQuery(),
  }
  return getFeatureQuery(SITE_FEATURE_TYPES.building, buildingFields)
}

export const getLocationsQuery = (depth = 1): Payload => {
  if (!depth) return {}
  const beacons = getFeatureQuery(
    SITE_FEATURE_TYPES.beacon,
    BEACON_SPECIFIC_FIELDS
  )
  const locations = getLocationsQuery(depth - 1)

  const locationFields = {
    ...locations,
    ...beacons,
    ...LOCATIONS_SPECIFIC_FIELDS,
  }
  return getFeatureQuery(SITE_FEATURE_TYPES.location, locationFields)
}

const deserializeSite = (site: Site): Site => {
  const { thumbUrl, properties, ...rest } = site
  return {
    ...rest,
    ...(properties || {}),
    type: SITE_FEATURE_TYPES.site,
    thumbUrl: thumbUrl || DEFAULT_SITE_THUMB_URL,
  }
}

const getSiteFields = getQueryFields({
  ...SITE_FIELDS,
  ...GEOMETRY_FIELDS,
  ...getCommonChildFeatureQuery(),
  ...getBuildingsQuery(),
  ...getLocationsQuery(LOCATION_MAX_DEPTH),
  thumbUrl: false,
  observations: true,
  observation: true,
})

const listSitesQuery = getEntitiesQuery({
  queryDomain,
  getFieldsFn: getQueryFields(SITE_FIELDS),
  noQueryName: true,
})

const commonListSitesProps = {
  queryDomain,
  queryName: null,
  getQueryFn: listSitesQuery,
}

export const listSites = listEntitiesGraphql<Site>({
  ...commonListSitesProps,
  queryDisplayName: 'GetAllSites',
  defaultOmitFields: [
    'type',
    'geometry',
    'featureType',
    'properties.userProperties',
    'properties.category',
  ],
  postProcessFn: deserializeSite,
  isSingleEntityPostProcessFn: true,
  enableLoadMore: false,
})

export const listSiteIds = listEntitiesGraphql<Site>({
  ...commonListSitesProps,
  queryDisplayName: 'GetAllSiteIds',
  defaultPickFields: ['id'],
})

export const listSitesNames = listEntitiesGraphql<Site>({
  ...commonListSitesProps,
  queryDisplayName: 'GetSitesNames',
  defaultPickFields: ['id', 'properties.name', 'active'],
})

const getCapitalizedFeatureType = (featureType: string): string =>
  isSite(featureType) ? '' : _.capitalize(featureType)

const getSiteKeysQueryFnName = _.curry(
  (keyName: string, featureType: string) =>
    `site${getCapitalizedFeatureType(featureType)}${keyName}`
)

const SITE_KEY_TYPES = keymirror({
  Categories: null,
  UserPropertiesKeys: null,
})

const getSiteKeys =
  (keyName: string) =>
  async (featureTypes: string[]): Promise<Record<string, string[]>> => {
    const fnName = `getSiteFeature${keyName}`
    const query = _.reduce(
      featureTypes,
      (result, featureType) => {
        const queryFnName = getSiteKeysQueryFnName(keyName, featureType)
        return {
          ...result,
          [featureType]: { __aliasFor: `${queryFnName}`, value: true },
        }
      },
      {}
    )

    const response = await GraphqlApi.fetch({
      query: getQuery(query, fnName),
      queryDomain: domain,
      fnName,
    })
    return _.reduce(
      _.omit(response, 'error'),
      (acc, cur, key) => {
        return { ...acc, [key]: _.map(cur, 'value') }
      },
      []
    )
  }

export const getSiteFeatureCategories = getSiteKeys(SITE_KEY_TYPES.Categories)

export const getSiteFeatureUserPropertiesKeys = getSiteKeys(
  SITE_KEY_TYPES.UserPropertiesKeys
)

const getSiteByIdQuery = getEntityQuery({
  queryName: domain,
  getFieldsFn: getSiteFields,
})

export const getSite = ({ pickFields, omitFields }: SelectedFields = {}): ((
  id: string
) => Promise<Site>) =>
  getEntityGraphql<Site>({
    queryDomain: domain,
    getQueryFn: getSiteByIdQuery,
    queryDisplayName: 'GetSiteById',
    pickFields,
    omitFields,
    queryName: null,
  })

const siteFeatureMutationFactory =
  ({
    mutationType,
    path = [],
    variableKey = 'input',
    variableFormat,
  }: {
    mutationType: keyof typeof OPERATION_OPTIONS
    path?: string[]
    variableKey?: string
    variableFormat?: string
  }) =>
  (featureType?: SiteFeatureType, fields = {}) =>
  async (argsValue: Payload | string) => {
    const capitalizedFeatureType = isSite(featureType)
      ? ''
      : _.capitalize(featureType)
    const fnName = `${mutationType}Site${capitalizedFeatureType}`
    const query = getMutationQuery({
      fields: { ...BASIC_MUTATION_FIELDS, ...fields },
      variableKey,
      mutationName: fnName,
      variableFormat:
        variableFormat || `${capitalizeFirstLetter(fnName)}Input!`,
    })

    const variables = { [variableKey]: argsValue }
    const response = await GraphqlApi.fetch({
      query,
      variables,
      queryDomain,
      fnName,
    })
    const result = _.get(response, [fnName, ...path]) || {}
    const { success, message } = result
    const error = success ? undefined : message
    if (error) {
      reportSiteError(error, fnName, query, variables)
    }
    return { ...result, error }
  }

export const createSiteFeature = siteFeatureMutationFactory({
  mutationType: OPERATION_OPTIONS.create,
})

export const createSite = createSiteFeature(undefined, {
  [SITE_FEATURE_TYPES.site]: { id: true },
})

export const updateSiteFeature = siteFeatureMutationFactory({
  mutationType: OPERATION_OPTIONS.update,
})

const ID_VARIABLE_INPUT = {
  variableKey: 'id',
  variableFormat: 'ID!',
}

export const cloneSite = siteFeatureMutationFactory({
  ...ID_VARIABLE_INPUT,
  mutationType: OPERATION_OPTIONS.clone,
})(undefined, { [SITE_FEATURE_TYPES.site]: SITE_FIELDS })

export const updateSite = updateSiteFeature(undefined, {
  [SITE_FEATURE_TYPES.site]: {
    id: true,
    ...ENTITY_OWNER_FIELDS,
    ...AUDITS_FIELDS,
  },
})

export const getSiteFeatureQueryError = (
  result: [null, { error: string }] | [Error, undefined]
): Partial<Error> | null => {
  const [err, res] = result
  if (err) return err
  return res?.error ? { message: res.error } : null
}

export const mutateSiteFeatureFactory =
  (
    status: string,
    fn: (
      featureType: SiteFeatureType,
      fields: Fields
    ) => (p: Payload) => Promise<SiteFeature>,
    onlyToastOnErrors = true
  ) =>
  async ({
    featureType,
    payload,
    fields,
    onSuccess = _.noop,
  }: {
    featureType: SiteFeatureType
    payload: Payload
    fields: Fields
    onSuccess: (p: Payload) => void
  }): ReturnType<typeof to> => {
    const entity = featureType
    const result = await to(fn(featureType, fields)(payload))
    const error = getSiteFeatureQueryError(result)
    showCrudResponseMessage({
      status,
      entity,
      error,
      onlyToastOnErrors,
      subject: { name: 'New' },
    })

    if (!error) {
      onSuccess(payload)
    }

    return result
  }

export const deleteSiteFeature = siteFeatureMutationFactory({
  ...ID_VARIABLE_INPUT,
  mutationType: OPERATION_OPTIONS.delete,
})

export const createSiteFeatureData = mutateSiteFeatureFactory(
  MESSAGE_STATUS.created,
  createSiteFeature,
  false
)

export const updateSiteFeatureData = mutateSiteFeatureFactory(
  MESSAGE_STATUS.updated,
  updateSiteFeature
)

export const debouncedUpdateSiteFeatureData = _.debounce(
  updateSiteFeatureData,
  AUTO_UPDATE_SITE_DEBOUNCE_IN_MS
)

export const deleteSiteFeatureData = mutateSiteFeatureFactory(
  MESSAGE_STATUS.deleted,
  deleteSiteFeature,
  false
)
