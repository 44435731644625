import _ from 'lodash'
import to from 'await-to-js'

// utils
import log, { reportException } from 'helpers/log'
import {
  saveItemsToLocalStorage,
  getItemsToLocalStorage,
  removeItemsFromLocalStorage,
} from 'helpers/storage'
import * as AuthApi from 'services/api/auth'
import * as UserApi from 'services/api/user'

import type { Payload, AuthenticationConfig } from 'types/common'
import type { User, MFATypes } from 'types/user'
import type {
  AuthSession,
  AuthCredentials,
  AuthMFAPreference,
} from 'types/auth'

export const getSuSession = (): {
  backendStorage?: string
  sessionToken?: string
} => {
  try {
    const suSessionKey = 'su-session'
    const { [suSessionKey]: suSessions } = getItemsToLocalStorage([
      suSessionKey,
    ])
    return suSessions ? JSON.parse(suSessions) : {}
  } catch (e) {
    log.error(e)
    return {}
  }
}

export const isServerRequestForPDFGenerating = (): boolean => {
  const { backendStorage } = getSuSession()
  return backendStorage === 'generatePdf'
}

export class AuthenticationError extends Error {
  method: string | undefined

  url: string | undefined

  constructor(error: Error | string, method?: string, url?: string) {
    const message =
      _.isObject(error) && !_.isNull(error)
        ? error.message || JSON.stringify(error)
        : error
    super(message)
    this.name = 'AuthenticationError'
    this.message = message
    this.method = method
    this.url = url
  }
}

class AuthenticationService {
  readonly deviceInfoKeys = ['deviceKey', 'deviceGroupKey', 'devicePassword']

  currentUser?: User

  userPoolId?: string

  clientId?: string

  session?: AuthSession

  ready = false

  externalDashboardUrl: string | undefined

  configure = (config: AuthenticationConfig) => {
    this.userPoolId = config.cognito_user_pool_id
    this.clientId = config.cognito_user_pool_client_id
    this.ready = true
  }

  checkReady = () => {
    if (!this.ready) {
      throw new AuthenticationError(
        'AuthenticationService is not configured for use'
      )
    }
  }

  getLocalStorageKeyPrefix = (email?: string) => {
    this.checkReady()

    const validEmail = email || _.get(this.session, 'attributes.email')
    return validEmail ? ['su', this.clientId, validEmail].join('.') : undefined
  }

  getUserContextData(username: string) {
    this.checkReady()

    return AmazonCognitoAdvancedSecurityData?.getData(
      username,
      this.userPoolId,
      this.clientId
    )
  }

  signIn = async ({
    username,
    password,
  }: {
    username: string
    password: string
  }): Promise<AuthSession> => {
    this.checkReady()

    const deviceInfo = this.getDeviceInfo({ email: username })
    const encodedDeviceFingerprint = this.getUserContextData(username)
    const session = await AuthApi.signIn({
      ...deviceInfo,
      ...(encodedDeviceFingerprint && { encodedDeviceFingerprint }),
      loginUsername: username,
      password,
    })
    this.session = session
    return session
  }

  federatedSignIn = async ({
    cognitoIdToken,
    cognitoAccessToken,
  }: {
    cognitoIdToken: string
    cognitoAccessToken: string
  }): Promise<AuthSession> => {
    this.checkReady()
    const session = await AuthApi.federatedSignIn({
      clientApplicationType: 'EXPLORER',
      cognitoIdToken,
      cognitoAccessToken,
    })
    this.session = session
    return session
  }

  signOut = async (): Promise<void> => {
    this.checkReady()
    this.session = await AuthApi.signOut()

    log.info(`Authenticated user sign out`)
  }

  globalSignOut = async (): Promise<void> => {
    this.checkReady()
    this.session = await AuthApi.globalSignOut()
    log.info(`Authenticated user global sign out`)
  }

  forgotPassword = (props: { loginUsername: string }): Promise<AuthSession> => {
    this.checkReady()

    return AuthApi.forgotPassword(props)
  }

  getCurrentUser = async (): Promise<User> => {
    this.checkReady()
    const newUser = await UserApi.getCurrentUser()
    this.currentUser = newUser
    return newUser
  }

  updateCurrentUser = async (id: string, args: Payload): Promise<User> => {
    this.checkReady()

    const newUser = await UserApi.updateCurrentUser(id, args)
    this.currentUser = newUser
    return newUser
  }

  getSession = async (): Promise<AuthSession> => {
    this.checkReady()

    const [, session] = await to(AuthApi.getSession())
    this.session = session

    return session
  }

  isAuthenticated = (): boolean => {
    return !!this.session?.authenticated
  }

  getCredentials = async (): Promise<AuthCredentials> => {
    this.checkReady()

    const [, credentials] = await to(AuthApi.getCredentials())
    return credentials
  }

  forgotPasswordSubmit = async (props: {
    loginUsername: string
    confirmationCode: string
    password: string
  }): Promise<AuthSession> => {
    this.checkReady()

    const session = await AuthApi.forgotPasswordSubmit(props)
    this.session = session
    return session
  }

  completeNewPassword = async (props: {
    password: string
  }): Promise<AuthSession> => {
    this.checkReady()

    const session = await AuthApi.completeNewPassword(props)
    this.session = session
    return session
  }

  confirmSignIn = async (props: {
    code: string
    mfaType: MFATypes
    rememberDevice?: boolean
  }): Promise<AuthSession> => {
    this.checkReady()

    const response = await AuthApi.confirmSignIn(props)
    const { session } = response
    this.session = session

    const { rememberDevice } = props

    if (rememberDevice) {
      const isSaved = saveItemsToLocalStorage(
        _.pick(response, this.deviceInfoKeys),
        this.getLocalStorageKeyPrefix()
      )
      log.debug('response: ', response)
      log.debug(
        `Save device info to the LocalStorage ${
          isSaved ? 'successfully' : 'failed'
        }`,
        this.getDeviceInfo()
      )
    }

    return session
  }

  getDeviceInfo = ({
    keys,
    email,
  }: {
    keys?: string[]
    email?: string
  } = {}) => {
    this.checkReady()

    return _.omitBy(
      getItemsToLocalStorage(
        keys || this.deviceInfoKeys,
        this.getLocalStorageKeyPrefix(email)
      ),
      val => _.isNil(val) || val === ''
    )
  }

  verifyAndSetTOTP = async (props: { code: string }): Promise<AuthSession> => {
    this.checkReady()

    const session = await AuthApi.verifyAndSetTOTP(props)
    this.session = session
    return session
  }

  sendCodeToCurrentUserPhone = async (phone: string): Promise<AuthSession> => {
    this.checkReady()

    if (
      this.currentUser &&
      phone !== this.currentUser.phone &&
      this.currentUser.username
    ) {
      log.info('User phone number changed. Will update user phone number.')
      await this.updateCurrentUser(this.currentUser.username, { phone })
    }
    return AuthApi.generateSMSCode()
  }

  verifyAndSetSMS = async (props: { code: string }): Promise<AuthSession> => {
    this.checkReady()

    const session = await AuthApi.verifyAndSetSMS(props)
    this.session = session
    return session
  }

  adminSetUserMFAPreference = (props: {
    username: string
    SMAMFASettings?: AuthMFAPreference
    softwareTokenMFASettings?: AuthMFAPreference
  }): Promise<AuthSession> => {
    this.checkReady()

    return AuthApi.adminSetUserMFAPreference(props)
  }

  forgetDevices = (deviceKeys: string[]): Promise<boolean> => {
    this.checkReady()

    return AuthApi.forgetDevices({
      deviceKeys: _(deviceKeys).compact().uniq().value(),
    })
  }

  forgetDevice = (deviceKey: string, email: string): Promise<boolean> => {
    this.checkReady()

    const deviceInfo = this.getDeviceInfo({ email })

    if (deviceKey === deviceInfo.deviceKey) {
      const isRemoved = removeItemsFromLocalStorage(
        this.deviceInfoKeys,
        this.getLocalStorageKeyPrefix(email)
      )

      log.debug(
        `Remove device info from LocalStorage ${
          isRemoved ? 'successfully' : 'failed'
        }`,
        isRemoved
      )
    }

    return this.forgetDevices([deviceKey])
  }

  getTOTPCode = async (): Promise<{ qrCode: string; qrImage: string }> => {
    this.checkReady()

    const { qrCode, qrImage } = await AuthApi.getTOTPCode()
    if (!qrCode || !qrImage) {
      reportException('No valid qrCode or qrImage for MFA setup', {
        qrCode,
        qrImage,
      })
      this.getTOTPCode()
    }
    return { qrCode, qrImage }
  }
}

const instance = new AuthenticationService()

export default instance
