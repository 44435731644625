// constants
import {
  ENTITIES,
  GALLERY_LIST_TYPES,
  TABLE_COLUMN_SUPPORT_GROUP_BY_KEY,
} from 'constants/common'

// utils
import { getPageSortOptions } from 'helpers/utils'
import {
  ColumnsWithGroupable,
  getTableGroupableColumns,
} from 'components/common/DataTable/useDataTableColumns'

import {
  ActiveTemplate,
  DateTimeTemplate,
  GroupTemplate,
} from 'components/common/DataTable/CellTemplates'

const enableListTypes = [GALLERY_LIST_TYPES.card, GALLERY_LIST_TYPES.table]

export const TABLE_COLUMNS: ColumnsWithGroupable = [
  {
    header: 'Title',
    field: 'name',
  },
  {
    header: 'Asset Profile',
    field: 'secondaryName',
    [TABLE_COLUMN_SUPPORT_GROUP_BY_KEY]: true,
  },
  {
    header: 'Description',
    field: 'description',
  },
  {
    header: 'Group',
    field: 'owner.group',
    body: GroupTemplate,
    [TABLE_COLUMN_SUPPORT_GROUP_BY_KEY]: true,
  },
  {
    header: 'Last updated',
    body: DateTimeTemplate,
    field: 'audit.updatedTime',
  },
  {
    header: 'Active',
    field: 'isActive',
    body: ActiveTemplate,
    [TABLE_COLUMN_SUPPORT_GROUP_BY_KEY]: true,
  },
]

export const PAGE_OPTIONS = {
  entity: ENTITIES.form,
  listConditions: {
    sortField: 'audit.updatedTime',
    ascOrder: false,
    listType: enableListTypes[0],
  },
  customFiltersSpecs: [],
  toolboxOptions: {
    sortOptions: getPageSortOptions([
      'group',
      'name',
      'updatedTime',
      'isActive',
    ]),
    enableListTypes,
    tableGroupableColumns: getTableGroupableColumns(TABLE_COLUMNS),
  },
}
