import { ReactElement } from 'react'
import { Box, Grid, Paper } from '@mui/material'
import { ArrayFieldTemplateProps, IdSchema, utils } from '@rjsf/core'
import { IconButton } from 'components/common'

const { isMultiSelect, getDefaultRegistry } = utils

type ArrayFieldTitleProps = {
  TitleField: ReactElement
  idSchema: IdSchema
  title: string
  required: boolean
}

const ArrayFieldTitle = ({
  TitleField,
  idSchema,
  title,

  required,
}: ArrayFieldTitleProps): ReactElement => {
  if (!title) {
    return <></>
  }

  const id = `${idSchema.$id}__title`
  return <TitleField id={id} title={title} required={required} />
}

type ArrayFieldDescriptionProps = {
  DescriptionField: ReactElement
  idSchema: IdSchema
  description: string
}

const ArrayFieldDescription = ({
  DescriptionField,
  idSchema,
  description,
}: ArrayFieldDescriptionProps): ReactElement => {
  if (!description) {
    return <></>
  }

  const id = `${idSchema.$id}__description`
  return <DescriptionField id={id} description={description} />
}

// Used in the two templates
const DefaultArrayItem = ({
  key,
  children,
  hasToolbar,
  hasMoveUp,
  hasMoveDown,
  hasRemove,
  disabled,
  readonly,
  onDropIndexClick,
  onReorderClick,
  index,
}: {
  key: string
  children: ReactElement
  hasToolbar: boolean
  hasMoveUp: boolean
  hasMoveDown: boolean
  hasRemove: boolean
  disabled: boolean
  readonly: boolean
  onReorderClick: () => void
  onDropIndexClick: () => void
  index: number
}): ReactElement => {
  const btnStyle = {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: 'bold',
    minWidth: 0,
  }
  return (
    <Grid container key={key} alignItems='center'>
      <Grid item xs style={{ overflow: 'auto' }}>
        <Box mb={2}>
          <Paper elevation={2}>
            <Box p={2}>{children}</Box>
          </Paper>
        </Box>
      </Grid>

      {hasToolbar && (
        <Grid item>
          {(hasMoveUp || hasMoveDown) && (
            <IconButton
              icon='MdKeyboardArrowUp'
              className='array-item-move-up'
              tabIndex={-1}
              style={btnStyle}
              disabled={disabled || readonly || !hasMoveUp}
              onClick={onReorderClick(index, index - 1)}
            />
          )}

          {(hasMoveUp || hasMoveDown) && (
            <IconButton
              icon='MdKeyboardArrowDown'
              tabIndex={-1}
              style={btnStyle}
              disabled={disabled || readonly || !hasMoveDown}
              onClick={onReorderClick(index, index + 1)}
            />
          )}

          {hasRemove && (
            <IconButton
              icon='FaTrash'
              tabIndex={-1}
              style={btnStyle}
              disabled={disabled || readonly}
              onClick={onDropIndexClick(index)}
            />
          )}
        </Grid>
      )}
    </Grid>
  )
}

const DefaultFixedArrayFieldTemplate = ({
  className,
  uiSchema,
  idSchema,
  title,
  schema,
  items,
  canAdd,
  TitleField,
  required,
  onAddClick,
  readonly,
  disabled,
}: ArrayFieldTemplateProps): ReactElement => {
  return (
    <fieldset className={className}>
      <ArrayFieldTitle
        key={`array-field-title-${idSchema.$id}`}
        TitleField={TitleField}
        idSchema={idSchema}
        title={uiSchema['ui:title'] || title}
        required={required}
      />

      {(uiSchema['ui:description'] || schema.description) && (
        <div
          className='field-description'
          key={`field-description-${idSchema.$id}`}
        >
          {uiSchema['ui:description'] || schema.description}
        </div>
      )}

      <div
        className='row array-item-list'
        key={`array-item-list-${idSchema.$id}`}
      >
        {items && items.map(DefaultArrayItem)}
      </div>

      {canAdd && (
        <IconButton
          icon='AiOutlinePlus'
          onClick={onAddClick}
          disabled={disabled || readonly}
        />
      )}
    </fieldset>
  )
}

const DefaultNormalArrayFieldTemplate = ({
  uiSchema,
  idSchema,
  title,
  schema,
  items,
  canAdd,
  TitleField,
  DescriptionField,
  required,
  onAddClick,
  readonly,
  disabled,
}: ArrayFieldTemplateProps): ReactElement => {
  return (
    <Paper elevation={2}>
      <Box p={2}>
        <ArrayFieldTitle
          key={`array-field-title-${idSchema.$id}`}
          TitleField={TitleField}
          idSchema={idSchema}
          title={uiSchema['ui:title'] || title}
          required={required}
        />

        {(uiSchema['ui:description'] || schema.description) && (
          <ArrayFieldDescription
            key={`array-field-description-${idSchema.$id}`}
            DescriptionField={DescriptionField}
            idSchema={idSchema}
            description={uiSchema['ui:description'] || schema.description}
          />
        )}
        <Grid container key={`array-item-list-${idSchema.$id}`}>
          {items && items.map(p => DefaultArrayItem(p))}
          {canAdd && (
            <Grid container justifyContent='flex-end'>
              <Grid item>
                <Box mt={2}>
                  <IconButton
                    icon='AiOutlinePlus'
                    onClick={onAddClick}
                    disabled={disabled || readonly}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  )
}

const ArrayFieldTemplate = (props: ArrayFieldTemplateProps): ReactElement => {
  const { schema, registry = getDefaultRegistry() } = props

  if (isMultiSelect(schema, registry.rootSchema)) {
    return <DefaultFixedArrayFieldTemplate {...props} />
  }
  return <DefaultNormalArrayFieldTemplate {...props} />
}

export default ArrayFieldTemplate
