// libraries
import _ from 'lodash'

// constants
import {
  BAR_CHART_TYPES,
  DEFAULT_WIDGET_COLOUR,
  DEFAULT_MAX_BARS_NUM,
  DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE,
} from 'constants/widget'
import { NAME_PROPERTY_KEY } from 'constants/unipipe'

// utils
import {
  getSortedFeaturesValuesByProperty,
  getMemoizedWidgetColour,
  getGroupedValuesByProperty,
  getGroupedValuesByTimeBucket,
  getGroupedValuesByPropertySplitByProperty,
  getGroupedValuesByTimeBucketSplitByProperty,
} from 'helpers/widget'
import { colourArrToRgba } from 'helpers/colour'
import { isTimePropertyFromPropertyOptionAndValue } from 'helpers/filter'
import log from 'helpers/log'

const renderWidgetGroupedBar = async ({
  style,
  widgetData,
  timezone,
  propertyOptions,
  xAxisPropertyPath,
  selectedDateTimeRange,
}) => {
  const {
    groupByPropertyName: xAxisLabelPropertyName,
    splitByPropertyName: xAxisSplitPropertyName,
    aggregation = {},
    intervalUnit,
    valueRange,
    colour = DEFAULT_WIDGET_COLOUR,
    isSingleColour = true,
    sortYAxisValues = true,
    isAscendingOrder = true,
    maxBarsNum = DEFAULT_MAX_BARS_NUM,
    hideValuesOutsideOfValueRange = DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE,
  } = style || {}

  const { type: aggregationType = 'count', key: yAxisValuePropertyName } =
    aggregation

  if (!xAxisLabelPropertyName) return {}

  const sharedProps = {
    geojsonRows: widgetData,
    aggregationType,
    xAxisLabelPropertyName,
    yAxisValuePropertyName,
    sortYAxisValues,
    isAscendingOrder,
    timezone,
    xAxisPropertyPath,
  }

  let dataset

  const isXAxisTimeProperty = isTimePropertyFromPropertyOptionAndValue(
    xAxisLabelPropertyName,
    propertyOptions
  )
  if (isXAxisTimeProperty) {
    dataset = xAxisSplitPropertyName
      ? await getGroupedValuesByTimeBucketSplitByProperty({
          ...sharedProps,
          period: intervalUnit,
          xAxisSplitPropertyName,
          selectedDateTimeRange,
        })
      : await getGroupedValuesByTimeBucket({
          ...sharedProps,
          period: intervalUnit,
          selectedDateTimeRange,
        })
  } else {
    dataset = xAxisSplitPropertyName
      ? await getGroupedValuesByPropertySplitByProperty({
          ...sharedProps,
          xAxisSplitPropertyName,
          ...(hideValuesOutsideOfValueRange && { yAxisRange: valueRange }),
        })
      : await getGroupedValuesByProperty({
          ...sharedProps,
          maxBarsNum,
          ...(hideValuesOutsideOfValueRange && { yAxisRange: valueRange }),
        })
  }

  if (_.isEmpty(dataset)) return {}

  const options = {
    ...(isXAxisTimeProperty && { period: intervalUnit }),
    colour: getMemoizedWidgetColour({
      isSingleColour,
      colour,
      series: dataset.source,
    }),
    yAxisRange: valueRange,
    aggregationType,
    xAxisLabelPropertyName,
    yAxisValuePropertyName,
    xAxisSplitPropertyName,
    visualMapVisible: true,
    timezone,
  }

  return { dataset, options }
}

const renderWidgetFeaturesBar = async ({
  style,
  widgetData,
  xAxisLabelPropertyName = NAME_PROPERTY_KEY,
  xAxisPropertyPath,
}) => {
  const {
    aggregation = {},
    maxBarsNum = DEFAULT_MAX_BARS_NUM,
    hideValuesOutsideOfValueRange = DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE,
    valueRange,
    sortYAxisValues = true,
    isAscendingOrder = true,
    colour = DEFAULT_WIDGET_COLOUR,
    isSingleColour = true,
  } = style || {}

  const { type: aggregationType = 'count', key: yAxisValuePropertyName } =
    aggregation

  const widgetColour =
    isSingleColour || _.isNil(isSingleColour) ? colourArrToRgba(colour) : null

  const yAxisRange = valueRange
  const options = {
    yAxisRange,
    colour: widgetColour,
    ...(maxBarsNum > 0 && { maxBarsNum }),
    visualMapVisible: true,
    aggregationType,
    yAxisValuePropertyName,
  }

  const dataset = await getSortedFeaturesValuesByProperty({
    ...(hideValuesOutsideOfValueRange && { yAxisRange }),
    geojsonRows: widgetData,
    xAxisPropertyPath,
    xAxisLabelPropertyName,
    yAxisValuePropertyName,
    aggregationType,
    maxBarsNum,
    sortYAxisValues,
    isAscendingOrder,
  })

  return { dataset, options }
}

export const getWidgetBarOptions = props => {
  const { barType } = props
  return barType === BAR_CHART_TYPES.features
    ? renderWidgetFeaturesBar(props)
    : renderWidgetGroupedBar(props)
}

const renderWidgetBar = ({
  widgetData,
  widgetSetting,
  calculateWidgetData,
  dataMerge,
  updateWidgetProps,
  title,
  timezone,
  propertyOptions,
  selectedDateTimeRange,
}) => {
  const { type: barType = BAR_CHART_TYPES.features } = widgetSetting

  const style = widgetSetting[barType] || {}

  const props = {
    barType,
    style,
    widgetData,
    dataMerge,
    title,
    timezone,
    propertyOptions,
    selectedDateTimeRange,
  }

  const barWidget = getWidgetBarOptions(props)

  barWidget
    .then(({ dataset, options }) => {
      const value = dataset ? { dataset } : null
      updateWidgetProps(value, calculateWidgetData, options)
    })
    .catch(e => log.error('There was a problem rendering the bar widget', e))
}

export default renderWidgetBar
