// libraries
import { forwardRef, ReactElement, useMemo } from 'react'
import styled from '@emotion/styled'
import moment from 'moment-timezone'

// utils
import { useTimezone } from 'hooks'
import { getTimezoneTimeStringFromUtcDateTime } from 'helpers/datetime'

// constants
import {
  DATEPICKER_MONTHS,
  DATEPICKER_MONTHS_OPTIONS,
} from 'components/workflow/Canvas/panels/PropertyPanel/common/DateTimePicker/constants'

// components
import { Dropdown, IconButton } from 'components/common'
import AbsoluteTimePicker from 'components/common/DateTimePicker/AbsoluteTimePicker'

import scss from './index.module.scss'

// TODO: move the style to index.module.scss
const MonthLabel = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #141414;
`

const DatePickerLabel = styled.span`
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  color: #141414;
`

const DatePickerDiv = styled.div`
  position: relative;
  .react-datepicker__day-names {
    display: none;
  }
`

const CustomDatePickDiv = styled.div`
  min-width: 138px;
  padding: 2px 8px;
  background-color: white;
  border: solid 0.1em #cbd4c9;
  border-radius: 0;
`

const CustomHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px;
  margin: 0px 12px 8px;
  font-size: 15px;
`
const CustomHeaderLabel = styled.div`
  margin-top: 2px;
  font-weight: 600;
`

type DatePickerComponentProps = {
  yearOn: string | undefined
  dateFormat: string
  minDate: Date
  maxDate: Date
  isDisabled: boolean
  handleSetDate: (v: Date) => void
}

type CustomInputProps = {
  onClick?: () => void
  value?: string
}

const CustomInput = forwardRef<HTMLDivElement, CustomInputProps>(
  ({ value, onClick }, ref) => {
    return (
      <CustomDatePickDiv>
        <DatePickerLabel onClick={onClick} ref={ref}>
          {value}
        </DatePickerLabel>
        <IconButton
          icon='AiOutlineCalendar'
          size={16}
          onClick={onClick}
          style={{ padding: 0, color: '#222529', float: 'right' }}
        />
      </CustomDatePickDiv>
    )
  }
)

const DatePicker = ({
  yearOn,
  dateFormat,
  minDate,
  maxDate,
  isDisabled,
  handleSetDate,
}: DatePickerComponentProps): ReactElement => {
  const { timezone } = useTimezone()

  const selected = useMemo(
    () => getTimezoneTimeStringFromUtcDateTime(yearOn, timezone),
    [timezone, yearOn]
  )

  return (
    <DatePickerDiv>
      <AbsoluteTimePicker
        renderCustomHeader={({
          date,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <>
            <CustomHeaderWrapper>
              <CustomHeaderLabel>
                <Dropdown
                  toggleComponent={() => {
                    return (
                      <MonthLabel className='dropdown-toggle'>
                        {moment(date).format('MMMM')}
                      </MonthLabel>
                    )
                  }}
                  onChange={value =>
                    changeMonth(DATEPICKER_MONTHS.indexOf(value as string))
                  }
                  options={DATEPICKER_MONTHS_OPTIONS}
                />
              </CustomHeaderLabel>
              <div>
                <IconButton
                  icon='AiOutlineUp'
                  size={16}
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  style={{
                    padding: 0,
                    color: '#222529',
                    marginRight: 8,
                  }}
                />
                <IconButton
                  icon='AiOutlineDown'
                  size={16}
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  style={{ padding: 0, color: '#222529' }}
                />
              </div>
            </CustomHeaderWrapper>
          </>
        )}
        dateFormat={dateFormat}
        selectedTime={selected}
        onChange={(e: Date) => handleSetDate(e)}
        customInput={<CustomInput />}
        minDate={minDate}
        maxDate={maxDate}
        popperClassName={scss.datePickerDiv}
        timezone={timezone}
        inline={false}
        disabled={isDisabled}
      />
    </DatePickerDiv>
  )
}

export default DatePicker
