// libraries
import { ReactElement } from 'react'

// constants
import { MODAL_SIZES, MODAL_TYPES } from 'constants/settings'

// components
import { Modal, ModalContent } from 'components/common'

import { useAbility } from 'hooks'

import type { GalleryItem } from 'types/entity'

const DeleteModal = ({
  isShowing = false,
  deleteItem,
  subject,
  toggle,
  isLoading = false,
}: {
  subject?: GalleryItem
  isShowing?: boolean
  isLoading?: boolean
  toggle: () => void
  deleteItem: () => void
  isMine?: boolean
}): ReactElement => {
  const { id, name } = subject || ({} as GalleryItem)
  const { canUpdate } = useAbility(subject)
  return (
    <Modal
      key={`delete-${id}`}
      isShowing={isShowing}
      size={MODAL_SIZES.small}
      icon='AiFillWarning'
      hide={toggle}
      title={`Delete ${name || ''}`}
      modalType={MODAL_TYPES.danger}
    >
      <ModalContent
        content='This action cannot be undone'
        onCancel={toggle}
        canSubmit={canUpdate}
        submitContent='Delete'
        onSubmit={deleteItem}
        isLoading={isLoading}
        modalType={MODAL_TYPES.danger}
      />
    </Modal>
  )
}

export default DeleteModal
