// libraries
import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import _ from 'lodash'

// constants
import { FEATURES } from 'constants/settings'

// utils
import { listAssetProfiles } from 'services/api/asset'
import { allAssetProfilesState } from 'recoilStore/assetsStore'
import { useFeatureFlag } from 'hooks'
import { reportErrors } from 'helpers/log'

import type { AssetProfile } from 'types/asset'

const useAssetsProfiles = (): {
  fetchAssetSupportData: () => Promise<{ assetProfiles?: AssetProfile[] }>
} => {
  const { [FEATURES.ASSET]: assetsEnabled } = useFeatureFlag()

  const [allAssetProfiles, setAllAssetProfilesState] = useRecoilState(
    allAssetProfilesState
  )

  const fetchAssetSupportData = useCallback(async () => {
    if (!assetsEnabled) return {}

    const { data: assetProfiles } = await listAssetProfiles()

    const sortedAssetProfiles = _.sortBy(assetProfiles, ['title'])

    if (_.isEmpty(allAssetProfiles)) {
      // only check and report when first load
      const viewConfigurationList = _(assetProfiles)
        .flatMap('viewConfigurations')
        .reject(_.isEmpty)
        .compact()
        .value()

      if (_.isEmpty(viewConfigurationList)) {
        reportErrors('All asset profiles view configurations are empty', {
          viewConfigurationList,
        })
      }
    }

    setAllAssetProfilesState(sortedAssetProfiles)
    return { assetProfiles }
  }, [allAssetProfiles, assetsEnabled, setAllAssetProfilesState])

  return {
    fetchAssetSupportData,
  }
}

export default useAssetsProfiles
