import { ReactElement, ChangeEvent, FocusEvent } from 'react'
import { FormGroup, FormControlLabel, Switch } from '@mui/material'
import { WidgetProps, utils } from '@rjsf/core'
import _ from 'lodash'

const { schemaRequiresTrueValue } = utils

const ToggleWidget = ({
  schema,
  id,
  value,
  disabled,
  readonly,
  label,
  autofocus,
  onChange,
  onBlur,
  onFocus,
}: WidgetProps): ReactElement => {
  const required = schemaRequiresTrueValue(schema)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)
  }
  const handleBlur = ({
    target: { value: newValue },
  }: FocusEvent<HTMLButtonElement>) => onBlur(id, newValue)
  const handleFocus = ({
    target: { value: newValue },
  }: FocusEvent<HTMLButtonElement>) => onFocus(id, newValue)

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            id={id}
            checked={_.isUndefined(value) ? false : Boolean(value)}
            required={required}
            disabled={disabled || readonly}
            autoFocus={autofocus}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
        }
        label={label}
      />
    </FormGroup>
  )
}

export default ToggleWidget
