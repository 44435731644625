import _ from 'lodash'
import { useAsyncFn, useMount } from 'react-use'
import { getAssetById } from 'services/api/asset'
import { useFetchAppSupportData } from 'contexts/hooks'

import type { ID, AsyncState } from 'types/common'
import type { Asset, AssetWidgetSettings } from 'types/asset'

const useFetchAsset = ({
  fetchOnMount = false,
  assetId,
  withIssues = false,
  issuePickFields,
  settings,
  onAssetLoaded = _.noop,
}: {
  fetchOnMount?: boolean
  assetId: ID
  withIssues?: boolean
  issuePickFields: string[]
  settings: AssetWidgetSettings
  onAssetLoaded?: (asset: Asset) => void
}): { assetState: AsyncState<Asset>; fetchAsset: () => Promise<Asset> } => {
  const { assetProfileId, relatedAssetRelationshipId, relatedAssetProfileId } =
    settings

  const { fetchIssueTaskDataCollectionFormDefinition } =
    useFetchAppSupportData()

  const [assetState = { loading: false }, fetchAsset] = useAsyncFn(async () => {
    const asset = await getAssetById({
      id: assetId,
      profile: assetProfileId,
      relationship: relatedAssetRelationshipId,
      relationshipProfile: relatedAssetProfileId,
      withIssues,
      issuePickFields,
    })
    if (withIssues) {
      await fetchIssueTaskDataCollectionFormDefinition()
    }

    onAssetLoaded(asset)
    return asset
  }, [assetId])

  useMount(() => {
    if (fetchOnMount) {
      fetchAsset()
    }
  })

  return { assetState, fetchAsset }
}

export default useFetchAsset
