// libraries
import _ from 'lodash'

// constants
import { PROPERTY_TIME } from 'constants/common'

// utils
import {
  getUtcDateTimeString,
  getMaximumTimeRange,
  isDateTimeRangeValid,
  getMinimumTimeRange,
  isTimeValidWithIsoFormat,
} from 'helpers/datetime'
import { isHistoricalDataset, getLayerSpecParams } from 'helpers/unipipe'

/**
 * Get valid selected datetime range
 * @prop {Object} selectedDateTimeRange: global selected datetime range
 * @prop {Object} dateTimeRange: layer datetime range
 *
 * @return {Object} {start,end}
 */
export const getValidSelectedDateTimeRange = ({
  selectedDateTimeRange,
  dateTimeRange,
}) => {
  const timeRange = _.isEmpty(selectedDateTimeRange)
    ? dateTimeRange
    : getMinimumTimeRange(selectedDateTimeRange, dateTimeRange)
  return timeRange || {}
}

/**
 * Get layer date time range
 * @param {Object} layer
 *
 * @return {Object} {start, end}
 */
export const getLayerDatetimeRange = layer => {
  if (!layer) return {}
  const { startTime, endTime } = getLayerSpecParams(layer)
  return isDateTimeRangeValid(startTime, endTime)
    ? { start: startTime, end: endTime }
    : {}
}

export const getLayerSelectedDatetimeRange = (layer, selectedDateTimeRange) => {
  const dateTimeRange = getLayerDatetimeRange(layer)
  const { timeliness } = layer
  const { start, end } = isHistoricalDataset(timeliness)
    ? getValidSelectedDateTimeRange({
        selectedDateTimeRange,
        dateTimeRange,
      })
    : {}

  const startTime = getUtcDateTimeString(start)
  const endTime = getUtcDateTimeString(end)

  return { start: startTime, end: endTime }
}

/**
 * Get the valid datetime range among all layers
 * @param {Array} layers - input layers list
 *
 * @return {Object} {startTime,endTime}
 */
export const getLayersAvailableDatetimeRange = layers =>
  _.reduce(
    layers,
    (acc, layer) => {
      const { start, end } = layer.dateTimeRange || {}
      return start && end
        ? _.isEmpty(acc)
          ? { startTime: start, endTime: end }
          : getMaximumTimeRange(acc, { startTime: start, endTime: end })
        : acc
    },
    {}
  )

/**
 * Get the valid datetime range among all layers from user's setting
 * @param {Array} layers - input layers list
 *
 * @return {Object} {startTime,endTime}
 */
export const getLayersUserSettingDatetimeRange = layers =>
  _.reduce(
    layers,
    (acc, layer) => {
      const { startTime, endTime } = layer.specParams || {}
      return isTimeValidWithIsoFormat(startTime) &&
        isTimeValidWithIsoFormat(endTime)
        ? _.isEmpty(acc)
          ? { startTime, endTime }
          : getMaximumTimeRange(acc, { startTime, endTime })
        : acc
    },
    {}
  )

/**
 * Get Layer available data datetime range
 * @param {*} mapLayers
 * @param {*} layersData
 */
export const updateLayerAvailableDataDatetimeRange = (
  mapLayers,
  layersData
) => {
  return mapLayers.map(layer => {
    const { id, timeliness } = layer
    const layerData = layersData[id]
    const dataWithoutTimeFilter = _.get(
      layerData,
      'filteredDataWithoutTimeFilter'
    )
    if (_.isEmpty(dataWithoutTimeFilter) || !isHistoricalDataset(timeliness))
      return { ...layer, dateTimeRange: {} }

    const timeKey = PROPERTY_TIME
    const sortedByTime = _.sortBy(dataWithoutTimeFilter, [timeKey])
    const firstRowTime = _.get(_.first(sortedByTime), timeKey)
    const lastRowTime = _.get(_.last(sortedByTime), timeKey)
    if (!firstRowTime || !lastRowTime) return { ...layer, dateTimeRange: {} }

    const start = getUtcDateTimeString(firstRowTime)
    const end = getUtcDateTimeString(lastRowTime)
    return { ...layer, dateTimeRange: { start, end } }
  })
}
