import React, { useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import AvatarEditor from 'react-avatar-editor'
import { useDropzone } from 'react-dropzone'
import { MdCloudUpload } from 'react-icons/md'

// constants
import { FILE_MIME_TYPE_EXTENSIONS_MAPPING } from 'constants/common'

// utils
import { showWarn } from 'helpers/message'
import { useUserAbility } from 'hooks'

// component
import { ModalFooter, Slider, IconButton } from 'components/common'
import { AVATAR_UPLOADER_PLACEHOLDER } from 'constants/user'
import UserMedia from 'components/user/UserMedia'

import scss from '../index.module.scss'

const AVATAR_MIN_ZOOM_RANGE = 1
const AVATAR_MAX_ZOOM_RANGE = 5

const AVATAR_EDITOR_DEFAULT_OPTIONS = {
  width: 250,
  height: 250,
  border: [100, 2],
  borderRadius: 125,
  color: [240, 240, 240, 0.6],
}

const AvatarEditorContainer = ({ user, onChange, onCancel }) => {
  const ref = useRef()

  const { avatarUrl } = user
  const { canUpdate } = useUserAbility({ user })

  const [avatar, setAvatar] = useState(avatarUrl || AVATAR_UPLOADER_PLACEHOLDER)
  const [avatarImage, setAvatarImage] = useState(avatarUrl)
  const [scale, setScale] = useState(1)

  const onDelete = useCallback(() => {
    setAvatar(AVATAR_UPLOADER_PLACEHOLDER)
  }, [])

  const onImageChange = useCallback(() => {
    const avatarEditor = ref && ref.current
    if (!avatarEditor) return

    const newAvatarImage =
      avatar === AVATAR_UPLOADER_PLACEHOLDER
        ? ''
        : avatarEditor.getImageScaledToCanvas().toDataURL()
    setAvatarImage(newAvatarImage)
  }, [avatar, ref])

  const saveAvatarImage = useCallback(() => {
    onChange({ avatar: avatarImage })
    onCancel()
  }, [avatarImage, onChange, onCancel])

  const onDropAccepted = useCallback(dropped => {
    setAvatar(dropped[0])
  }, [])

  const onDropRejected = useCallback(dropped => {
    const { name } = dropped[0]
    showWarn(`Please select a valid image file. "${name}" cannot be used.`)
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    noClick: !!avatarImage,
    onDropAccepted,
    onDropRejected,
    accept: FILE_MIME_TYPE_EXTENSIONS_MAPPING.image,
    multiple: false,
    noKeyboard: true,
  })

  const zoomSlider = (
    <div
      className={`d-flex justify-content-between align-items-center ${scss.zoom}`}
    >
      <IconButton
        icon='SliderMinIcon'
        className='me-1'
        onClick={() => setScale(AVATAR_MIN_ZOOM_RANGE)}
      />

      <Slider
        title='Zoom'
        value={scale}
        step={0.2}
        range={[AVATAR_MIN_ZOOM_RANGE, AVATAR_MAX_ZOOM_RANGE]}
        onChange={setScale}
        className={scss.avatarZoomSlider}
      />
      <IconButton
        icon='SliderMaxIcon'
        className='ms-1'
        onClick={() => setScale(AVATAR_MAX_ZOOM_RANGE)}
      />
    </div>
  )

  return (
    <div>
      <div className={scss.useMediaHeader}>
        <UserMedia user={{ ...user, avatarUrl: avatarImage }} />
      </div>

      <hr />
      <div className={scss.dropzone}>
        {avatarImage && (
          <IconButton
            icon='FaTrash'
            size={18}
            className={scss.trashButton}
            onClick={onDelete}
          />
        )}
        <div {...getRootProps()}>
          <AvatarEditor
            {...AVATAR_EDITOR_DEFAULT_OPTIONS}
            ref={ref}
            image={avatar}
            scale={scale}
            onImageChange={onImageChange}
            onImageReady={onImageChange}
          />
          <label htmlFor='file-upload' className={scss.fileUpload}>
            <MdCloudUpload />
            Upload Image
          </label>
          <input
            {...getInputProps()}
            id='file-upload'
            style={{ display: 'none' }}
          />
        </div>
        {zoomSlider}
      </div>
      <ModalFooter
        onCancel={onCancel}
        submitContent='Update Profile Picture'
        onSubmit={saveAvatarImage}
        canSubmit={canUpdate}
      />
    </div>
  )
}

AvatarEditorContainer.propTypes = {
  user: PropTypes.shape({ avatarUrl: PropTypes.string }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default AvatarEditorContainer
