// libraries
import { ReactElement, useState } from 'react'

// components
import { GalleryPageHeader } from 'components/common'

import { PAGE_OPTIONS } from './constant'
import useList from './useList'

const { entity, customFiltersSpecs } = PAGE_OPTIONS

const FormGallery = (): ReactElement => {
  const [listConditions, setListConditions] = useState(
    PAGE_OPTIONS.listConditions
  )

  const {
    list,
    listState,
    renderContent,
    toggleCreateEntityModal,
    columns,
    visibleColumns,
    setVisibleColumns,
  } = useList({
    listConditions,
  })

  return (
    <>
      <GalleryPageHeader
        entity={entity}
        customFiltersSpecs={customFiltersSpecs}
        counts={list?.length}
        isLoading={listState.loading}
        actions={[
          {
            label: `Create ${entity}`,
            value: 'create-new',
            onClick: () => toggleCreateEntityModal(true),
          },
        ]}
        toolboxOptions={PAGE_OPTIONS.toolboxOptions}
        conditions={listConditions}
        onConditionsChange={setListConditions}
        columns={columns}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
      />
      <div className='galleryContainer'>{renderContent()}</div>
    </>
  )
}

export default FormGallery
