import { ReactElement, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import DatePicker from 'react-datepicker'
import _ from 'lodash'

// utils
import {
  getDatetimeTimezoneLocalTimeFromUtcISOString,
  getUtcISOStringFromTimezoneDate,
  getNowISOString,
} from 'helpers/datetime'

import type { UtcISOString, DateTimeRange, Timezone } from 'types/datetime'
import type { BrandingColours } from 'types/common'

// style
import './index.scss'

const DatePickerWrapper = styled.div<{ theme: BrandingColours }>`
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    display: none;
  }
  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text {
    &:hover {
      background-color: ${props => props.theme['primary-200']} !important;
    }

    &--highlighted {
      background-color: $datepicker__highlighted-color;

      &:hover {
        background-color: ${props => props.theme.primary};
      }
    }

    &--selected {
      background-color: ${props => props.theme.primary};

      &:hover {
        background-color: ${props => props.theme['primary-200']};
      }
    }

    &--in-selecting-range,
    &--in-range {
      border-radius: 0;
      color: #fff;
      background-color: ${props => props.theme['primary-200']};
      &:hover {
        background-color: ${props => props.theme.primary};
      }
    }

    &--in-range {
      background-color: ${props => props.theme.primary};
      &:hover {
        background-color: ${props => props.theme['primary-200']};
      }
    }

    &--keyboard-selected {
      background-color: ${props => props.theme.primary};

      &:hover {
        background-color: ${props => props.theme['primary-200']};
      }
    }

    &--selecting-range-start {
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
      background-color: ${props => props.theme.primary};
    }

    &--range-start {
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
      background-color: ${props => props.theme.primary};
    }

    &--selecting-range-end {
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
      background-color: ${props => props.theme.primary};
    }

    &--range-end {
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
      background-color: ${props => props.theme.primary};
    }

    &--in-selecting-range:not(&--in-range) {
      background-color: ${props => props.theme['primary-200']};
    }

    &--in-range:not(&--in-selecting-range) {
      .react-datepicker__month--selecting-range & {
        background-color: ${props => props.theme['primary-200']};
      }
    }

    &--disabled {
      cursor: not-allowed;

      &:hover {
        background-color: transparent !important;
      }
    }
  }

  .react-datepicker__month {
    margin: 8px;
  }
  .react-datepicker__day-names {
    display: block;
  }
  .react-datepicker-wrapper {
    display: inline-block;
    width: 100%;
    padding: 0;
    border: 0;
  }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: 700;
  }
  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 0px;
  }
  .react-datepicker-popper[data-placement^='top'] {
    margin-bottom: 0px;
  }
`

const AbsoluteTimePicker = ({
  selectedTime,
  startDate,
  endDate,
  onChange,
  timezone,
  minDate,
  maxDate,
  inline = true,
  ...rest
}: {
  selectedTime: UtcISOString
  startDate: UtcISOString
  endDate: UtcISOString
  minDate?: UtcISOString
  maxDate?: UtcISOString
  onChange: (v: UtcISOString | DateTimeRange) => void
  inline?: boolean
  timezone?: Timezone
  showTimeSelect?: boolean
}): ReactElement => {
  const newTimezoneLocalSelectedTime = useMemo(
    () => getDatetimeTimezoneLocalTimeFromUtcISOString(selectedTime, timezone),
    [selectedTime, timezone]
  )

  const newTimezoneLocalMinDate = useMemo(
    () => getDatetimeTimezoneLocalTimeFromUtcISOString(minDate, timezone),
    [minDate, timezone]
  )

  const newTimezoneLocalMaxDate = useMemo(
    () => getDatetimeTimezoneLocalTimeFromUtcISOString(maxDate, timezone),
    [maxDate, timezone]
  )

  const newTimezoneLocalStartDate = useMemo(
    () =>
      getDatetimeTimezoneLocalTimeFromUtcISOString(
        startDate || getNowISOString(),
        timezone
      ),
    [startDate, timezone]
  )

  const newTimezoneLocalEndDate = useMemo(
    () =>
      endDate
        ? getDatetimeTimezoneLocalTimeFromUtcISOString(endDate, timezone)
        : null,
    [endDate, timezone]
  )

  const onDatetimeChange = useCallback(
    date => {
      const newDate = _.isArray(date)
        ? _.map(date, d => getUtcISOStringFromTimezoneDate(d, timezone))
        : getUtcISOStringFromTimezoneDate(date, timezone)

      onChange(newDate)
    },
    [onChange, timezone]
  )

  return (
    <DatePickerWrapper>
      <DatePicker
        {...rest}
        {...(minDate && { minDate: newTimezoneLocalMinDate })}
        {...(maxDate && { maxDate: newTimezoneLocalMaxDate })}
        {...(selectedTime && { selected: newTimezoneLocalSelectedTime })}
        {...(startDate && { startDate: newTimezoneLocalStartDate })}
        {...(endDate && { endDate: newTimezoneLocalEndDate })}
        inline={inline}
        onChange={onDatetimeChange}
      />
    </DatePickerWrapper>
  )
}

export default AbsoluteTimePicker
