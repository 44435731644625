import { ReactElement } from 'react'
import styled from '@emotion/styled'
import Carousel, {
  Modal as ImagesModal,
  ModalGateway,
  ViewType,
} from '@sensorup/react-images'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// components
import { Tooltip } from 'components/common'
import * as Icons from 'components/icons'

import type { BrandingColours, TooltipPlacement } from 'types/common'
import type { IconType } from 'react-icons'
import type { DataCollectionFormImageView } from 'types/issue'

const headerStyles = (
  base: Record<string, unknown>
): Record<string, unknown> => ({
  ...base,
  paddingTop: 30,
  paddingRight: 30,
})

const ImageActions = styled.div<{
  theme: BrandingColours
  borderRight: boolean
}>`
  width: 58px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  border-right: ${props => (props.borderRight ? '1px solid #0a3a5f' : 'none')};
  &:hover {
    color: ${props => props.theme.primary};
  }
`

const NavNextActions = styled.div`
  display: flex;
  margin-top: -25px;
  position: absolute;
  top: 50%;
  right: 20px;
  justify-content: center;
`
const NavPrevActions = styled.div`
  display: flex;
  margin-top: -25px;
  position: absolute;
  top: 50%;
  left: 20px;
  justify-content: center;
`
const CloseModalActions = styled.div`
  align-items: center;
  background: 0;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  height: 44px;
  justify-content: center;
  outline: 0;
  padding: 0;
  position: relative;
  width: 44px;
  color: #fff;
  &:hover {
    color: ${props => props.theme.primary};
  }
`
const ActionIconDiv = ({
  icon,
  text,
  placement,
  handleClick,
  iconSize,
  borderRight,
  label,
}: {
  icon?: IconType
  text: string
  placement: TooltipPlacement
  handleClick?: () => void
  iconSize?: number
  borderRight?: boolean
  label?: string
}): ReactElement => {
  const Icon = icon

  return (
    <Tooltip
      placement={placement}
      trigger={['hover']}
      overlay={<span>{text}</span>}
    >
      <ImageActions
        borderRight={borderRight}
        {...(handleClick ? { onClick: handleClick } : {})}
      >
        {label || <Icon size={iconSize || 24} />}
      </ImageActions>
    </Tooltip>
  )
}

const ImageActionsDiv = (): ReactElement => {
  return (
    <div className='d-flex justify-content-center'>
      <ActionIconDiv
        icon={Icons.MdOutlineRotate90DegreesCcw as IconType}
        text='Rotate'
        placement={TOOLTIP_PLACEMENT.top}
        borderRight
      />
    </div>
  )
}

const ZoomInActionsDiv = (): ReactElement => {
  return (
    <div className='d-flex justify-content-center'>
      <ActionIconDiv
        icon={Icons.MdOutlineZoomIn as IconType}
        text='Zoom in'
        placement={TOOLTIP_PLACEMENT.top}
        iconSize={28}
      />
    </div>
  )
}

const ZoomOutActionsDiv = (): ReactElement => {
  return (
    <div className='d-flex justify-content-center'>
      <ActionIconDiv
        icon={Icons.MdOutlineZoomOut as IconType}
        text='Zoom out'
        placement={TOOLTIP_PLACEMENT.top}
        iconSize={28}
      />
    </div>
  )
}

const ResetButtonActionsDiv = (buttonLabel: string): ReactElement => {
  return (
    <div className='d-flex justify-content-center'>
      <ActionIconDiv
        text='Reset'
        placement={TOOLTIP_PLACEMENT.top}
        iconSize={28}
        label={buttonLabel}
      />
    </div>
  )
}

const NextNav = (props: ViewType): ReactElement => {
  const { innerProps } = props
  const handleButtonClick = () => {
    // eslint-disable-next-line no-console
    innerProps.onClick({ stopPropagation: () => console.log('event fired') })
  }
  return (
    <NavNextActions>
      <ActionIconDiv
        icon={Icons.MdArrowForward as IconType}
        text='Next'
        placement={TOOLTIP_PLACEMENT.top}
        handleClick={handleButtonClick}
      />
    </NavNextActions>
  )
}

const PrevNav = (props: ViewType): ReactElement => {
  const { innerProps } = props
  const handleButtonClick = () => {
    // eslint-disable-next-line no-console
    innerProps.onClick({ stopPropagation: () => console.log('event fired') })
  }
  return (
    <NavPrevActions>
      <ActionIconDiv
        icon={Icons.MdArrowBack as IconType}
        text='Previous'
        placement={TOOLTIP_PLACEMENT.top}
        handleClick={handleButtonClick}
      />
    </NavPrevActions>
  )
}

const CloseModalNav = (props: ViewType): ReactElement => {
  const { innerProps } = props
  const handleButtonClick = () => {
    // eslint-disable-next-line no-console
    innerProps.onClick({ stopPropagation: () => console.log('event fired') })
  }
  return (
    <CloseModalActions>
      <ActionIconDiv
        icon={Icons.MdClose as IconType}
        text='Close [ESC]'
        placement={TOOLTIP_PLACEMENT.bottom}
        handleClick={handleButtonClick}
      />
    </CloseModalActions>
  )
}

export const ModalCarousel = ({
  viewerIsOpen,
  closeLightBox,
  currentImage,
  imageResources,
}: {
  viewerIsOpen: boolean
  closeLightBox: () => void
  currentImage: number
  imageResources: DataCollectionFormImageView[]
}): ReactElement => {
  return (
    <ModalGateway>
      {viewerIsOpen && (
        <ImagesModal
          allowFullscreen={false}
          onClose={closeLightBox}
          styles={{
            blanket: base => ({
              ...base,
              backgroundColor: '#000',
            }),
          }}
        >
          <Carousel
            hideControlsWhenIdle={false}
            rotateIcon={<ImageActionsDiv />}
            zoomInIcon={<ZoomInActionsDiv />}
            zoomOutIcon={<ZoomOutActionsDiv />}
            zoomContainerStyle={{
              textAlign: 'center',
              backgroundColor: '#031d30',
              boxShadow: '0px 4px 16px #08366840',
              width: '235px',
              margin: '0px auto',
              padding: '12px 0px',
              borderRadius: '40px',
            }}
            zoomScaleStyle={{
              textAlign: 'center',
              cursor: 'pointer',
              fontSize: '14px',
              color: '#fff',
              minWidth: '38px',
            }}
            captionStyle={{
              fontWeight: 600,
              fontSize: '14px',
              color: '#fff',
            }}
            subTitleStyle={{
              fontWeight: 400,
              fontSize: '12px',
              color: '#fff',
            }}
            countStyle={{
              fontWeight: 400,
              fontSize: '12px',
              color: '#fff',
            }}
            currentIndex={currentImage}
            views={imageResources}
            resetContainer={ResetButtonActionsDiv}
            components={{
              NavigationNext: NextNav,
              NavigationPrev: PrevNav,
              HeaderClose: CloseModalNav,
            }}
            styles={{
              header: headerStyles,
            }}
          />
        </ImagesModal>
      )}
    </ModalGateway>
  )
}
