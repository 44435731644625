import { ReactElement } from 'react'
import { FieldProps } from '@rjsf/core'

import scss from './index.module.scss'

export interface TitleFieldProps extends Partial<FieldProps> {
  title: string
}

const TitleField = ({ title, uiSchema }: Partial<FieldProps>): ReactElement => (
  <>
    <div className='my-1'>
      <h5 className={scss.title}>
        {(uiSchema && uiSchema['ui:title']) || title}
      </h5>
    </div>
  </>
)

export default TitleField
