// libraries
import { ReactElement, useCallback, useMemo } from 'react'
import _ from 'lodash'

// utils
import { getOptions, switchcaseF } from 'helpers/utils'

// constants
import {
  DAYS_IN_MONTH,
  MONTH_REPEAT_BY,
  WEEKS_IN_MONTH,
} from 'constants/datetime'

import { WeekDaysType } from 'components/workflow/Canvas/panels/PropertyPanel/common/DateTimePicker/types'
import {
  MONTHLY_TYPE,
  ON_DAYS_IN_WEEK_OPTIONS,
  REPEAT_MONTHLY_ON_DAY,
  REPEAT_MONTHLY_ON_WEEK,
  REPEAT_MONTHLY_TYPE_FIELDS_DEFAULTS,
  WEEK_DAYS,
} from 'components/workflow/Canvas/panels/PropertyPanel/common/DateTimePicker/constants'
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// components
import { Dropdown, MultiSelect } from 'components/common'

import scss from '../index.module.scss'

type MonthProps = {
  monthOnDay: number | undefined
  monthOnWeek: number | undefined
  monthOn: WeekDaysType | undefined
  monthRepeatPattern: string | undefined
  onChange: (v) => void
  isDisabled: boolean
}

type RepeatPattern = string

const getNumberOptions = (pattern: RepeatPattern): [] => {
  return switchcaseF({
    [MONTH_REPEAT_BY.week]: () => getOptions(WEEKS_IN_MONTH),
    [MONTH_REPEAT_BY.day]: () => getOptions(DAYS_IN_MONTH),
  })(() => getOptions(DAYS_IN_MONTH))(pattern)
}

const SHARED_OPTIONS_PROPS = {
  placeholder: 'Select',
  useOptionValueOnly: true,
  isClearable: false,
  isMulti: false,
  className: 'su-date-picker',
}

const Month = ({
  monthRepeatPattern,
  monthOnDay,
  monthOnWeek,
  monthOn,
  onChange,
  isDisabled,
}: MonthProps): ReactElement => {
  const monthOptions = useMemo(
    () => getNumberOptions(MONTHLY_TYPE[monthRepeatPattern]),
    [monthRepeatPattern]
  )

  const renderWeekOptions = useCallback(
    () => (
      <div className='row mb-1'>
        <div className='col-sm-5'>
          <div className={scss.label}>On</div>
        </div>
        <div className='col-sm-7 ps-0'>
          <MultiSelect
            {...SHARED_OPTIONS_PROPS}
            value={monthOn ? WEEK_DAYS.indexOf(monthOn) + 1 : 0}
            options={ON_DAYS_IN_WEEK_OPTIONS}
            onChange={(value: number) =>
              onChange({
                monthlyOnWeekWeekday: WEEK_DAYS[value - 1],
              })
            }
            isDisabled={isDisabled}
          />
        </div>
      </div>
    ),
    [monthOn, onChange, isDisabled]
  )

  return (
    <div className='col-sm-12'>
      <div className='row mb-1'>
        <div className='col-sm-5'>
          {isDisabled ? (
            <div className={scss.label}>
              On {MONTHLY_TYPE[monthRepeatPattern]}
            </div>
          ) : (
            <Dropdown
              toggleComponent={() => {
                return (
                  <div className={`${scss.label} cursor-pointer`}>
                    On {MONTHLY_TYPE[monthRepeatPattern]}
                  </div>
                )
              }}
              options={_.map(MONTH_REPEAT_BY, (value: RepeatPattern) => ({
                value,
                onClick: () =>
                  onChange(REPEAT_MONTHLY_TYPE_FIELDS_DEFAULTS[value]),
              }))}
              placement={TOOLTIP_PLACEMENT.right}
            />
          )}
        </div>
        <div className='col-sm-7 ps-0'>
          <MultiSelect
            {...SHARED_OPTIONS_PROPS}
            value={
              monthRepeatPattern === REPEAT_MONTHLY_ON_DAY
                ? monthOnDay
                : monthOnWeek
            }
            options={monthOptions}
            onChange={(value: RepeatPattern) =>
              onChange({
                [monthRepeatPattern === REPEAT_MONTHLY_ON_DAY
                  ? 'monthlyOnDay'
                  : 'monthlyOnWeek']: value,
              })
            }
            isDisabled={isDisabled}
          />
        </div>
      </div>
      {monthRepeatPattern === REPEAT_MONTHLY_ON_WEEK && renderWeekOptions()}
      <hr />
    </div>
  )
}

Month.defaultProps = {}

export default Month
