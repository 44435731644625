// libraries
import { ElementType, ReactElement } from 'react'

// constants
import { MODAL_SIZES } from 'constants/settings'

// types
import type { Entity, EntityGroup } from 'types/entity'
import type { User } from 'types/user'
import type { Payload } from 'types/common'

// components
import { Modal } from 'components/common'
import CreateEntityForm from './CreateEntityForm'

export type TransferOwner = {
  group: EntityGroup
  user?: User
}

const CreateEntityModal = ({
  isShowing,
  toggle,
  entity,
  onSubmit,
  fields,
  image,
}: {
  isShowing?: boolean
  toggle: (v?: boolean | Entity) => void
  entity: string
  onSubmit: (payload: Payload) => Promise<void>
  fields: string[]
  image?: ElementType
}): ReactElement => {
  return (
    <Modal
      key='create-entity'
      isShowing={isShowing}
      hide={toggle}
      size={MODAL_SIZES.small}
      title={
        <>
          Create a <span className='text-capitalize'>{entity}</span>
        </>
      }
    >
      <CreateEntityForm
        onSubmit={onSubmit}
        onCancel={toggle}
        fields={fields}
        image={image}
      />
    </Modal>
  )
}

export default CreateEntityModal
