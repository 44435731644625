/* eslint-disable react/destructuring-assignment */
// libraries
import { ReactElement } from 'react'
import Form from '@rjsf/core'
import { ThemeProvider, createTheme } from '@mui/material/styles'

// constants
import { CUSTOM_WIDGETS_AND_FIELDS } from 'constants/formBuilder'

// types
import type { JSONFormSchema, JSONFormUiSchema } from 'types/formBuilder'
import type { Payload } from 'types/common'

// components
import CustomDescription from './DescriptionField'
import CustomTitle from './TitleField'
import CustomSelect from './SelectWidget'
import CustomCheckboxes from './CheckboxesWidget'
import CustomInput from './TextWidget'
import CustomGeoPoint from './GeoPointWidget'
import CustomFieldTemplate from './FieldTemplate'
import CustomSubmit from './SubmitButton'
import CustomImages from './ImageWidget'
import CustomToggle from './ToggleWidget'
import CustomArrayFieldTemplate from './ArrayFieldTemplate'
import CustomDatetime from './DatetimeWidget'

const theme = createTheme({
  palette: {},
})

const JsonForm = ({
  uischema,
  schema,
  formData,
  setFormData,
  formContext,
}: {
  schema: JSONFormSchema
  uischema?: JSONFormUiSchema
  formData?: Payload
  setFormData?: (v) => void
  formContext?: Payload
}): ReactElement => {
  const widgets = {
    SelectWidget: CustomSelect,
    TextWidget: CustomInput,
    SubmitButton: CustomSubmit,
    CheckboxesWidget: CustomCheckboxes,
    CheckboxWidget: CustomToggle,
    DateTimeWidget: CustomDatetime,
    [CUSTOM_WIDGETS_AND_FIELDS.images]: CustomImages,
  }

  const fields = {
    DescriptionField: CustomDescription,
    TitleField: CustomTitle,
    [CUSTOM_WIDGETS_AND_FIELDS.geopoint]: CustomGeoPoint,
  }

  return (
    <ThemeProvider theme={theme}>
      <Form
        {...(uischema && { uiSchema: uischema })}
        {...(setFormData && { onChange: e => setFormData(e.formData) })}
        {...(formContext && { formContext })}
        schema={schema}
        formData={formData}
        widgets={widgets}
        fields={fields}
        FieldTemplate={CustomFieldTemplate}
        ArrayFieldTemplate={CustomArrayFieldTemplate}
        liveValidate
        showErrorList={false}
        formContext={formContext}
      />
    </ThemeProvider>
  )
}

export default JsonForm
