import { ReactElement, useMemo } from 'react'
import { useToggle, useUpdateEffect } from 'react-use'
import _ from 'lodash'

// constants
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'

// utils
import { isNumericType } from 'helpers/filter'
import { isAggregatedLayer } from 'helpers/map'
import { colourArrToRgba, isColourClassesValid } from 'helpers/colour'
import type { MapLayerType } from 'types/map'
import type {
  Aggregation,
  ColourClasses,
  ColourRange,
  ThemeType,
} from 'types/common'

// components
import { IconButton } from 'components/common'
import ColourRamp from '../ColourRampBar'
import ColourTable from '../ColourTable'
import LegendTitle from '../LegendTitle'

const AdvancedColourLegend = ({
  name,
  colourRange = LAYER_VIS_CONFIGS.colourRange.defaultValue,
  colourClasses = LAYER_VIS_CONFIGS.colourClasses.defaultValue,
  colourProperty,
  aggregationForColour = LAYER_VIS_CONFIGS.aggregationForColour.defaultValue,
  ...rest
}: {
  id: string
  isVisible: boolean
  enableClustering: boolean
  name: string
  type: MapLayerType
  colourRange?: ColourRange
  colourClasses?: ColourClasses
  colourProperty?: string
  canToggleVisibility?: boolean
  canToggleClustering?: boolean
  aggregationForColour?: Aggregation
  theme: ThemeType
}): ReactElement => {
  const { id, type } = rest
  const isAggregated = useMemo(() => isAggregatedLayer({ type }), [type])
  const isNumericProperty = useMemo(
    () => isNumericType(colourProperty?.type),
    [colourProperty]
  )

  const [expandLegend, toggleLegendExpand] = useToggle(false)

  const isDetailAvailable = useMemo(() => {
    return isColourClassesValid(colourClasses, colourProperty?.type)
  }, [colourClasses, colourProperty])

  useUpdateEffect(() => {
    if (expandLegend && !isDetailAvailable) {
      toggleLegendExpand(false)
    }
  }, [isDetailAvailable])

  const propertyName = useMemo(() => {
    if (!isAggregated) return ''

    const key = colourProperty?.key || aggregationForColour?.key
    return key ? ` -${key}` : ''
  }, [colourProperty, aggregationForColour, isAggregated])

  const aggregationName = useMemo(() => {
    if (!isAggregated) return ''

    const aggregationType = aggregationForColour?.type
    return aggregationType ? ` (${aggregationType})` : ''
  }, [aggregationForColour, isAggregated])

  const validColourRange = useMemo(
    () => (isDetailAvailable ? _.map(colourClasses, 'colour') : colourRange),
    [isDetailAvailable, colourClasses, colourRange]
  )

  const colourRangeRgbaArray = useMemo(() => {
    return _.map(validColourRange, colourArrToRgba)
  }, [validColourRange])

  return (
    <>
      <LegendTitle
        {...rest}
        title={`${name} ${propertyName} ${aggregationName}`}
        {...(isDetailAvailable && {
          action: (
            <IconButton
              icon={expandLegend ? 'MdKeyboardArrowUp' : 'MdKeyboardArrowDown'}
              onClick={() => toggleLegendExpand(!expandLegend)}
              size={13}
            />
          ),
        })}
      >
        <ColourRamp colourStrArr={colourRangeRgbaArray} />
      </LegendTitle>
      {isDetailAvailable && expandLegend && (
        <div className='my-2'>
          <ColourTable
            id={id}
            colourClasses={colourClasses}
            isNumericProperty={isNumericProperty}
          />
        </div>
      )}
    </>
  )
}

export default AdvancedColourLegend
