import { useState, ReactElement, useMemo } from 'react'
import {
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
  verticalListSortingStrategy,
  SortableContext,
  arrayMove,
} from '@dnd-kit/sortable'
import {
  MeasuringStrategy,
  DragOverlay,
  DndContext,
  DragStartEvent,
  DragEndEvent,
} from '@dnd-kit/core'
import _ from 'lodash'
import { useUpdateEffect } from 'ahooks'
import update from 'immutability-helper'

// constants
import { BUTTON_VARIANTS } from 'components/common/Button'

// utils
import { getEnumOptions } from 'helpers/formBuilder'
import { uniqueStringId } from 'helpers/utils'

// components
import StyledField, { Title } from 'components/common/Form/StyledField'
import { Button } from 'components/common'
import SortableItem from './SortableItem'
import Item from './Item'

import scss from './index.module.scss'

type ItemsType = { label: string; value: string; id?: string }[]

const getDefaultOption = () => ({ value: '', label: '', id: uniqueStringId() })

const Sortable = ({
  value,
  onChange,
  className,
  disabled,
  isCheckboxList,
  required = false,
}: {
  value: ItemsType
  onChange: (v: { enumNames?: string[]; enum: string[] }) => void
  className?: string
  disabled?: boolean
  isCheckboxList?: boolean
  required?: boolean
}): ReactElement => {
  const [activeId, setActiveId] = useState<string | undefined>()
  const [items, setItems] = useState<ItemsType>(() => {
    const list = _.isEmpty(value) ? [getDefaultOption()] : value
    return _.map(list, item => ({ id: uniqueStringId(), ...item }))
  })

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event
    setActiveId(active.id)
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (over && active.id !== over.id) {
      setItems(sortableItems => {
        const oldIndex = _.findIndex(sortableItems, { id: active.id })
        const newIndex = _.findIndex(sortableItems, { id: over.id })
        return arrayMove(sortableItems, oldIndex, newIndex)
      })
    }
    setActiveId(undefined)
  }

  const onItemDataChange = (payload: {
    value: string
    label: string
    id: string
    index: number
  }) => {
    const { index, ...rest } = payload

    setItems(oldItems => {
      return update(oldItems, { [index]: { $merge: rest } })
    })
  }

  useUpdateEffect(() => {
    onChange({
      enum: _.map(items, 'value'),
      ...(!isCheckboxList && { enumNames: _.map(items, 'label') }),
    })
  }, [items])

  return (
    <>
      <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          <div className={`row g-0 ${scss.row}`}>
            <Title
              label='Options'
              labelClassName='col-6 groupOptionLabel form-label'
              required={required}
            />
            {!isCheckboxList && (
              <Title
                label='Values'
                labelClassName='col-6 groupOptionLabel form-label'
                required={required}
              />
            )}
          </div>
          {_.map(items, (item, index) => (
            <SortableItem
              key={item.id}
              id={item.id}
              item={item}
              className={className}
              onChange={onItemDataChange}
              index={index}
              onRemove={() => {
                setItems(_.reject(items, item))
              }}
              disabled={disabled}
              isCheckboxList={isCheckboxList}
            />
          ))}
        </SortableContext>
        <DragOverlay>
          {activeId && (
            <Item
              item={_.find(items, { id: activeId })}
              dragging
              isCheckboxList={isCheckboxList}
            />
          )}
        </DragOverlay>
      </DndContext>
      {!disabled && (
        <Button
          variant={BUTTON_VARIANTS.secondary}
          onClick={() => {
            setItems(old => [...old, getDefaultOption()])
          }}
          icon='AiOutlinePlus'
          className={scss.add}
        />
      )}
    </>
  )
}

const EnumeratedInput = ({
  input,
  label,
  tooltip,
  required,
  disabled,
  description,
  className,
  errorClassName,
  isCheckboxList = false,
}: {
  input: {
    onBlur?: (v: { enumNames?: string[]; enum: string[] }) => void
    onChange: (v: { enumNames?: string[]; enum: string[] }) => void
    onFocus?: (v: { enumNames?: string[]; enum: string[] }) => void
    name: string
    value: ItemsType
  }
  label?: string
  tooltip?: string
  required?: boolean
  disabled?: boolean
  description?: string
  className?: string
  errorClassName?: string
  isCheckboxList?: boolean
}): ReactElement => {
  const { name, value, onChange } = input

  const animateLayoutChanges: AnimateLayoutChanges = args =>
    defaultAnimateLayoutChanges({ ...args, wasDragging: true })

  const inputValue = useMemo(() => getEnumOptions(value), [value])

  return (
    <StyledField
      label={label}
      tooltip={tooltip}
      name={name}
      required={required}
      description={description}
      errorClassName={errorClassName}
    >
      <Sortable
        animateLayoutChanges={animateLayoutChanges}
        measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
        value={inputValue}
        onChange={onChange}
        disabled={disabled}
        className={className}
        isCheckboxList={isCheckboxList}
        required={required}
      />
    </StyledField>
  )
}

export default EnumeratedInput
