import { CSSProperties, ReactElement } from 'react'
import styled from '@emotion/styled'
import Color from 'color'
import _ from 'lodash'

// constants
import { BADGE_TYPES } from 'constants/common'

// helpers
import type {
  BadgeType,
  BrandingColour,
  StyledComponentProps,
} from 'types/common'
import { switchcase } from 'helpers/utils'

import scss from './index.module.scss'

const getBadgeClassName = (badgeType: BadgeType) => {
  return switchcase({
    [BADGE_TYPES.error]: scss.error,
    [BADGE_TYPES.warning]: scss.warning,
    [BADGE_TYPES.success]: scss.success,
    [BADGE_TYPES.infoGrey]: scss.infoGrey,
    [BADGE_TYPES.infoBlue]: scss.infoBlue,
  })(scss.default)(badgeType)
}

const getBadgeBrandingColourKey = (badgeType: BadgeType): BrandingColour => {
  return switchcase({
    [BADGE_TYPES.primary]: 'primary',
    [BADGE_TYPES.lightPrimary]: 'primary-100',
  })('')(badgeType)
}

const StyledBadge = styled.span(
  (
    props: StyledComponentProps & {
      backgroundColour?: string
      badgeType: BadgeType
    }
  ) => {
    const { theme, backgroundColour, badgeType } = props

    const brandingColour = getBadgeBrandingColourKey(badgeType)

    return {
      ...((brandingColour || backgroundColour) && {
        backgroundColor: theme[brandingColour] || backgroundColour,
      }),
      ...((backgroundColour || badgeType === BADGE_TYPES.lightPrimary) && {
        color:
          badgeType === BADGE_TYPES.lightPrimary
            ? theme.primary
            : Color(backgroundColour).isDark()
            ? '#fff'
            : '#000',
      }),
    }
  }
)

export type BadgeProps = {
  className?: string
  content: string | number
  onDismiss?: (v: string | number) => void
  style?: CSSProperties
  backgroundColour?: string
  type?: BadgeType
}

const Badge = ({
  content = '',
  style,
  className,
  onDismiss,
  backgroundColour,
  type = BADGE_TYPES.default,
}: BadgeProps): ReactElement => {
  return (
    <StyledBadge
      className={`${scss.badge} ${className || ''} ${getBadgeClassName(type)}`}
      style={style}
      backgroundColour={backgroundColour}
      badgeType={type}
      data-testid='badge'
    >
      {content}
      {_.isFunction(onDismiss) && (
        <button
          type='button'
          className={scss.close}
          aria-label='Dismiss'
          data-testid='badge-dismiss'
          {...(onDismiss && { onClick: () => onDismiss(content) })}
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      )}
    </StyledBadge>
  )
}

export default Badge
