import { ReactElement, useCallback, useMemo, PropsWithChildren } from 'react'
import _ from 'lodash'
import { Ripple } from 'primereact/ripple'

import type { Item } from 'types/common'

import scss from './index.module.scss'

const GroupHeaderTemplate = ({
  data,
  allColumnsCount,
  expandedRows,
  setExpandedRows,
  children,
}: PropsWithChildren<{
  data: Item
  allColumnsCount: number
  expandedRows: Item[]
  setExpandedRows: React.Dispatch<React.SetStateAction<Item[]>>
}>): ReactElement => {
  const expandedRowIndex = useMemo(
    () => _.findIndex(expandedRows, data),
    [expandedRows, data]
  )
  const isExpanded = expandedRowIndex !== -1
  const iconClassName = `pi ${
    isExpanded ? 'pi-chevron-down' : 'pi-chevron-right'
  }`

  const onGroupToggle = useCallback(() => {
    // Collapse
    if (isExpanded) {
      setExpandedRows(expandedRows.filter((__, i) => i !== expandedRowIndex))
    } else {
      // Expand
      setExpandedRows([...expandedRows, data])
    }
  }, [data, expandedRows, setExpandedRows, expandedRowIndex, isExpanded])

  return (
    <td
      className={scss.rowGroupHeader}
      colSpan={allColumnsCount}
      // Unlike in the default 'rowGroupHeader', make the whole row clickable
      // For the reference: 'createGroupHeader'
      // https://github.com/primefaces/primereact/blob/master/components/lib/datatable/TableBody.js#L820
      onClick={onGroupToggle}
    >
      <button type='button' className='p-row-toggler p-link me-1'>
        <span className={iconClassName} aria-hidden='true' />
        <Ripple />
      </button>
      <span className='p-rowgroup-header-name'>{children}</span>
    </td>
  )
}

export default GroupHeaderTemplate
