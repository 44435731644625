import {
  useCallback,
  ChangeEvent,
  ReactElement,
  useState,
  useEffect,
  useMemo,
} from 'react'
import { styled, ThemeProvider, createTheme } from '@mui/material/styles'
import TextField, { TextFieldProps } from '@mui/material/TextField'

// constants
import { TIME_FORMAT, DATE_UNIT_TYPES } from 'constants/datetime'

// utils
import { displayTime } from 'helpers/utils'
import { isTimeBetween } from 'helpers/filter'
import {
  getNowISOString,
  getSnappedDatetimeStr,
  setNewHourMinute,
} from 'helpers/datetime'
import { useTimezone } from 'hooks'

// components
import { IconButton } from 'components/common'

import type { Time, UtcISOString, Timezone } from 'types/datetime'

const TimePickerInputField = styled((props: TextFieldProps) => (
  <TextField {...props} />
))(() => ({
  '& .MuiFormHelperText-root': {
    color: '#ff0000',
    fontFamily: "'Open Sans', Helvetica, Arial, sans-serif !important;",
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none !important',
    },
  },
  '& .MuiInputBase-input': {
    borderRadius: 3,
    backgroundColor: '#fff',
    border: '1px solid #CCCCCC',
    fontSize: 13,
    width: '100%',
    padding: '6px 8px',
    transition: 'none !important;',
    fontFamily: "'Open Sans', Helvetica, Arial, sans-serif !important;",
    '&:focus': {
      borderColor: '#80bdff',
    },
  },
}))

const theme = createTheme({
  palette: {},
})

const getTime = (dateTime?: UtcISOString, timezone?: Timezone): Time => {
  return displayTime({
    datetime: dateTime || getNowISOString(),
    timezone,
    addFromNow: false,
    displayLabel: false,
    timeFormat: TIME_FORMAT,
  })
}

type TimePickerProps = {
  utcDateTime?: UtcISOString
  utcTime?: Time
  isDisabled: boolean
  minDateTime?: UtcISOString
  onChange: (v: string) => void
}

const getNewUtcDateTime = (utcDateTime?: UtcISOString, utcTime?: Time) => {
  const today = getSnappedDatetimeStr({
    baseDatetime: getNowISOString(),
    unit: DATE_UNIT_TYPES.minutes,
  })

  if (utcDateTime || !utcTime) {
    return utcDateTime || today
  }

  return setNewHourMinute({
    oldDateTime: today,
    newTime: utcTime,
  })
}

const TimePicker = ({
  utcDateTime,
  utcTime,
  isDisabled,
  onChange,
  minDateTime,
}: TimePickerProps): ReactElement => {
  const { timezone } = useTimezone()

  const [dateTimeValue, setDateTimeValue] = useState<UtcISOString>(() =>
    getNewUtcDateTime(utcDateTime, utcTime)
  )

  const timeValue = useMemo(
    () => getTime(dateTimeValue, timezone),
    [dateTimeValue, timezone]
  )

  const isTimeValid = useMemo(
    () =>
      isTimeBetween(dateTimeValue, minDateTime as string, undefined, 'minute'),
    [dateTimeValue, minDateTime]
  )

  useEffect(() => {
    setDateTimeValue(getNewUtcDateTime(utcDateTime, utcTime))
  }, [timezone, utcDateTime, utcTime])

  const onTimeUpdate = useCallback(() => {
    if (timeValue) {
      const newDateTime = isTimeValid ? dateTimeValue : minDateTime
      const newResult = utcDateTime
        ? getSnappedDatetimeStr({
            baseDatetime: newDateTime,
            unit: DATE_UNIT_TYPES.minutes,
          })
        : getTime(newDateTime)
      onChange(newResult)
    }
  }, [
    dateTimeValue,
    isTimeValid,
    minDateTime,
    onChange,
    timeValue,
    utcDateTime,
  ])

  const onKeyDown = useCallback(
    ({ key }) => {
      if (key === 'Enter') {
        onTimeUpdate()
      }
    },
    [onTimeUpdate]
  )

  const onTimeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      const newUTCDateTime = setNewHourMinute({
        oldDateTime: utcDateTime,
        timezone,
        newTime: value,
      })

      setDateTimeValue(newUTCDateTime)
    },
    [timezone, utcDateTime]
  )

  return (
    <ThemeProvider theme={theme}>
      <TimePickerInputField
        id='time'
        fullWidth
        type='time'
        InputProps={{
          inputProps: {
            step: 300, // 5 min
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        value={timeValue}
        onChange={onTimeChange}
        onBlur={onTimeUpdate}
        onKeyDown={onKeyDown}
        disabled={isDisabled}
        helperText={isTimeValid ? '' : 'Pick an earlier time'}
      />
    </ThemeProvider>
  )
}

const TimePickerContainer = (
  props: TimePickerProps & {
    inline?: boolean
    isClearable?: boolean
    onClear?: () => void
    isDisabled: boolean
  }
): ReactElement => {
  const { inline = false } = props

  return inline ? (
    <TimePicker {...props} />
  ) : (
    <div className='d-flex align-center-center flex-grow-1'>
      <IconButton
        icon='AiOutlineClockCircle'
        size={16}
        disabled
        style={{ padding: '0 10px 0 0', color: '#222529' }}
      />
      <TimePicker {...props} />
    </div>
  )
}

export default TimePickerContainer
