// libraries
import { Tile3DLayer as DeckGLTile3DLayer } from 'deck.gl'
import { CesiumIonLoader } from '@loaders.gl/3d-tiles'

// constants
import { LAYER_TYPES } from 'constants/map'

// components
import Layer from '../baseLayer'

export const tile3DVisConfigs = {
  pointSize: 'pointSize',
  ionAssetId: 'ionAssetId',
  ionToken: 'ionToken',
}

export default class Tile3DLayer extends Layer {
  constructor(props) {
    super(props)

    this.registerVisConfig(tile3DVisConfigs, props.style[this.type])
  }

  get type() {
    return LAYER_TYPES.tile3D
  }

  renderLayer = () => {
    const {
      visConfig: { pointSize, ionAssetId, ionToken },
      visible,
    } = this.config

    const TILESET_URL = `https://assets.cesium.com/${ionAssetId}/tileset.json`

    const basicProps = {
      ...this.getLayerBasicProps(),
      id: `${this.id}-${pointSize}-${visible}`,
      // the visible seems not working with deck.gl Tile3DLayer, the workaround is changing the opacity to 0
      opacity: visible ? 1 : 0,
    }

    const tileProps =
      ionAssetId && ionToken && visible
        ? {
            data: TILESET_URL,
            loader: CesiumIonLoader,
            loadOptions: { 'cesium-ion': { accessToken: ionToken } },
          }
        : {}

    const stylingProps = {
      pointSize,
    }

    return [
      new DeckGLTile3DLayer({
        ...tileProps,
        ...basicProps,
        ...stylingProps,
      }),
    ]
  }
}
