import styled from '@emotion/styled'
import { TextInput } from 'components/common'
import { ReactElement } from 'react'

import scss from '../index.module.scss'

const LabelWrapper = styled.div`
  width: 93px;
`

const SuEnd = (): ReactElement => {
  return (
    <>
      <div className='col-sm-12 mt-0 mb-1'>
        <div className='row mb-1'>
          <div className='col-sm-4'>
            <LabelWrapper>
              <div className={scss.label}>Never ends</div>
            </LabelWrapper>
          </div>
          <div className='col-sm-8'>
            {false && (
              <div>
                <div style={{ width: 45, float: 'left', marginLeft: 8 }}>
                  <TextInput
                    className='form-control'
                    value='10'
                    type='number'
                    style={{
                      padding: '5px',
                      border: '1px solid #ced4da',
                      height: 28,
                      marginTop: -2,
                    }}
                  />
                </div>
                <div
                  style={{
                    width: 60,
                    float: 'left',
                    marginLeft: 5,
                    marginTop: 4,
                    fontSize: 12,
                    color: '#868686',
                  }}
                >
                  occurrences
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SuEnd
