import { ReactElement, FocusEvent, ChangeEvent } from 'react'
import { WidgetProps, utils } from '@rjsf/core'
import { TextField } from '@mui/material'

const { getDisplayLabel } = utils

const TextWidget = ({
  id,
  placeholder,
  required,
  readonly,
  disabled,
  type,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  options,
  schema,
  uiSchema,
  rawErrors = [],
  formContext,
  registry,
  ...textFieldProps
}: WidgetProps): ReactElement => {
  const handleChange = ({
    target: { value: newValue },
  }: ChangeEvent<HTMLInputElement>) =>
    onChange(newValue === '' ? options.emptyValue : newValue)

  const handleBlur = ({
    target: { value: newValue },
  }: FocusEvent<HTMLInputElement>) => onBlur(id, newValue)

  const handleFocus = ({
    target: { value: newValue },
  }: FocusEvent<HTMLInputElement>) => onFocus(id, newValue)

  const { rootSchema } = registry
  const displayLabel = getDisplayLabel(schema, uiSchema, rootSchema)
  const inputType =
    (type || schema.type) === 'string' ? 'text' : `${type || schema.type}`

  return (
    <TextField
      id={id}
      placeholder={placeholder}
      label={displayLabel ? label || schema.title : false}
      autoFocus={autofocus}
      required={required}
      disabled={disabled || readonly}
      type={inputType as string}
      value={value || value === 0 ? value : ''}
      error={rawErrors.length > 0}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      {...textFieldProps}
    />
  )
}

export default TextWidget
