import _ from 'lodash'

// constants
import {
  ISSUE_FORM_SPEC_REF_SEPARATOR,
  ISSUE_TASK_TYPES,
} from 'constants/issue'

// utils
import { flattenObject } from 'helpers/utils'
import {
  getIssueXFormResultList,
  getActiveIssues,
  getIssueJSONFormResultList,
} from 'helpers/issue'
import {
  updateFormModelDataResult,
  getUpdatedSpecificationParameters,
} from 'components/issue/IssueFormEditModal/utils'
import log, { reportException } from 'helpers/log'

// types
import type { Timezone } from 'types/datetime'
import type {
  IssueId,
  SubTasksReference,
  SubTasksReferences,
  DataCollectionStateData,
  SubTaskDataCollectionStateData,
  IssueStateDataCollectionData,
  XFormReference,
  InternalDataCollectionStateData,
  IssueFlattenedProperties,
  InternalSubTaskDataCollectionStateData,
  XFormSpecificationParameters,
  IssueTaskType,
} from 'types/issue'
import type {
  SpecificationParameters,
  SpecificationParameterValue,
} from 'types/common'
import { IssueTaskDataCollectionForms } from 'recoilStore/issuesStore'

const getIssueSubtasksStatesData = (
  issueId: IssueId,
  subTasksReferences: SubTasksReferences = []
): InternalSubTaskDataCollectionStateData[] => {
  const subTasks = subTasksReferences.flatMap(
    ({ issue }: SubTasksReference) => {
      const { statesData, statesParameter, subject, ...rest } = issue
      return {
        ...rest,
        ..._.first(statesData),
        ..._.first(statesParameter),
        asset: subject?.assetReference?.asset?.displayName,
      } as InternalSubTaskDataCollectionStateData
    }
  )

  const uniqSubTasks = _.uniqBy(subTasks, 'id')

  if (uniqSubTasks.length !== subTasks.length) {
    reportException(`The issue ${issueId} has duplicated subTasks`, subTasks)
  }

  return getActiveIssues(uniqSubTasks)
}

export const getDataCollectionStateData = ({
  dataCollectionFormReference,
  issueId,
  statesData = [],
  taskType,
}: {
  dataCollectionFormReference: XFormReference
  issueId: IssueId
  statesData: DataCollectionStateData[]
  taskType: IssueTaskType
}): (IssueStateDataCollectionData | SubTaskDataCollectionStateData)[] => {
  if (!statesData) return []

  return statesData.flatMap(state => {
    const { subTasksReferences } = state as SubTaskDataCollectionStateData
    if (subTasksReferences) {
      return getIssueSubtasksStatesData(issueId, subTasksReferences)
    }
    return { ...state, dataCollectionFormReference, taskType }
  })
}

const getUpdateIssueXFormSpecs = ({
  issueFormSpecs,
  stateData,
  issueTaskDataCollectionFormsKeyByFormReference,
}: {
  issueFormSpecs: SpecificationParameters
  stateData: IssueStateDataCollectionData
  issueTaskDataCollectionFormsKeyByFormReference: IssueTaskDataCollectionForms
}): XFormSpecificationParameters | SpecificationParameters => {
  if (!issueTaskDataCollectionFormsKeyByFormReference) return issueFormSpecs

  const { dataCollectionFormReference, dataCollectionFormResult } = stateData

  const formModel =
    issueTaskDataCollectionFormsKeyByFormReference[dataCollectionFormReference]
      ?.xForm?.model

  const domModel = updateFormModelDataResult(
    formModel,
    dataCollectionFormResult
  )

  return getUpdatedSpecificationParameters({
    xFormModelDom: domModel,
    specificationParameters: issueFormSpecs,
  })
}

export const getPrettifiedDataCollectionStateData = ({
  dataCollectionStateData,
  formsSpecificationParameters,
  timezone,
  hideInvalidValues = true,
  issueTaskDataCollectionFormsKeyByFormReference,
}: {
  dataCollectionStateData: IssueStateDataCollectionData[]
  formsSpecificationParameters: {
    [key: string]: SpecificationParameterValue[]
  }
  timezone?: Timezone
  hideInvalidValues?: boolean
  issueTaskDataCollectionFormsKeyByFormReference: IssueTaskDataCollectionForms
}): (InternalDataCollectionStateData | Record<string, never>)[] => {
  const validXFormsList = _.reduce(
    issueTaskDataCollectionFormsKeyByFormReference,
    (acc, cur, key) => {
      if (!cur?.xForm?.model) {
        return acc
      }
      return { ...acc, [key]: cur }
    },
    {}
  )

  const validJsonFormList = _.reduce(
    issueTaskDataCollectionFormsKeyByFormReference,
    (acc, cur, key) => {
      if (!cur?.jsonFormBody) {
        return acc
      }
      return { ...acc, [key]: cur }
    },
    {}
  )

  return _.map(dataCollectionStateData, stateData => {
    const { dataCollectionResponses, dataCollectionFormReference, taskType } =
      stateData

    if (!dataCollectionFormReference) return {}

    if (taskType === ISSUE_TASK_TYPES.COMPLETE_ODK_FORM) {
      if (!validXFormsList[dataCollectionFormReference]) {
        const extras = {
          stateData,
          validXFormsList,
        }
        reportException(
          `Failed to find the ODK form [${dataCollectionFormReference}]. Supported ODK forms are [${_.keys(
            validXFormsList
          ).join()}]`,
          extras
        )

        log.info('State data and valid ODK forms', extras)
      }

      const issueFormSpecsList =
        formsSpecificationParameters[dataCollectionFormReference]

      const issueFormSpecs = _.keyBy(issueFormSpecsList, 'id')

      const updatedIssueFormSpecs = getUpdateIssueXFormSpecs({
        issueFormSpecs,
        stateData,
        issueTaskDataCollectionFormsKeyByFormReference: validXFormsList,
      })

      const originalFlattenedResultData = flattenObject(
        dataCollectionResponses || {},
        {
          keepPaths: true,
          separator: ISSUE_FORM_SPEC_REF_SEPARATOR,
        }
      ) as IssueFlattenedProperties

      return {
        ...stateData,
        dataCollectionResponses: originalFlattenedResultData,
        prettifiedDataCollectionResponses: getIssueXFormResultList({
          issueFormSpecs: updatedIssueFormSpecs,
          issueFormSpecsList,
          formResult: originalFlattenedResultData,
          timezone,
          hideInvalidValues,
        }),
      }
    }

    if (taskType === ISSUE_TASK_TYPES.COMPLETE_JSON_FORM) {
      const jsonForm = validJsonFormList[dataCollectionFormReference]
      const extras = {
        stateData,
        validJsonFormList,
      }

      if (!jsonForm) {
        reportException(
          `Failed to find JSON form [${dataCollectionFormReference}]. Supported JSON forms are [${_.keys(
            validJsonFormList
          ).join()}]`,
          extras
        )
        log.info('State data and valid JSON forms', extras)
      }

      return {
        ...stateData,
        dataCollectionResponses,
        prettifiedDataCollectionResponses: getIssueJSONFormResultList({
          formResult: dataCollectionResponses,
          form: jsonForm,
        }),
      }
    }

    reportException(`Unsupported taskType: ${taskType}}]`)
    return {}
  })
}
