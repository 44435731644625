import { PropsWithChildren, ReactElement, useMemo } from 'react'
import _ from 'lodash'
import { useToggle } from 'react-use'

// constants
import { THEMES } from 'constants/colour'

// utils
import { stopEventDefaultAndPropagation } from 'helpers/utils'

// components
import { IconButton, Tooltip } from 'components/common'

import type { ThemeType, TooltipPlacement, Value } from 'types/common'
import type { TooltipProps } from 'components/common/Tooltip'

import DropdownBody from './DropdownBody'
import scss from './index.module.scss'

export type DropdownOption<T = Value> = {
  key?: string
  value?: T
  label?: string | ReactElement
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>
  dataTestid?: string
}

export type CustomDropdownProps = {
  isVisible?: boolean
  toggleListVisible: (nextValue?: boolean) => void
}

const Dropdown = ({
  size,
  className,
  placement,
  icon,
  children,
  toggleComponent: ToggleComponent,
  containerClassName,
  onChange,
  visibilityChangeCallback,
  options = [],
  trigger = 'click',
  theme = THEMES.light,
  testId = 'dropdown-button',
  isDisabled = false,
  align,
  title,
  iconClassName,
  dropdownComponent: DropdownComponent,
}: PropsWithChildren<{
  size?: number
  className?: string
  theme?: ThemeType
  icon?: string
  placement?: TooltipPlacement
  options?: DropdownOption[]
  trigger?: 'click' | 'hover'
  onChange?: (v?: Value) => void
  visibilityChangeCallback?: (isVisible: boolean) => void
  containerClassName?: string
  toggleComponent?: () => ReactElement
  testId?: string
  isDisabled?: boolean
  title?: string
  iconClassName?: string
  align?: TooltipProps['align']
  dropdownComponent?: (props: CustomDropdownProps) => ReactElement
}>): ReactElement => {
  const [isListVisible, toggleListVisible] = useToggle(false)
  const isLightTheme = useMemo(() => theme === THEMES.light, [theme])

  return (
    <Tooltip
      theme={theme}
      visible={!isDisabled && isListVisible}
      onVisibleChange={() => {
        const isVisible = !isListVisible
        toggleListVisible(isVisible)
        if (visibilityChangeCallback) visibilityChangeCallback(isVisible)
      }}
      trigger={trigger}
      placement={placement}
      overlayClassName={`${theme} tooltipDropdown`}
      align={align}
      overlay={
        DropdownComponent ? (
          <DropdownComponent
            isVisible={isListVisible}
            toggleListVisible={toggleListVisible}
          />
        ) : (
          children || (
            <>
              {title && <div className={`bold ${scss.title}`}>{title}</div>}
              <DropdownBody
                className={className}
                data-testid='dropdown-options'
                onChange={onChange}
                toggleListVisible={toggleListVisible}
                isLightTheme={isLightTheme}
                options={options}
              />
            </>
          )
        )
      }
      showArrow={false}
    >
      <div
        data-testid='dropdown-button'
        className={containerClassName}
        onClick={stopEventDefaultAndPropagation}
      >
        {ToggleComponent ? (
          <ToggleComponent />
        ) : (
          <IconButton
            testId={testId}
            icon={icon}
            size={size}
            onClick={_.noop}
            className={iconClassName}
          />
        )}
      </div>
    </Tooltip>
  )
}

export default Dropdown
