// libraries
import { ReactElement, useState, FormEvent, useMemo, useEffect } from 'react'
import styled from '@emotion/styled'
import _ from 'lodash'
import { useBoolean, useInterval, useRequest } from 'ahooks'
import { v4 as uuidv4 } from 'uuid'
import to from 'await-to-js'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

// constants
import {
  DYNAMIC_SCHEDULING_CONFIGS,
  DYNAMIC_SCHEDULING_SETTINGS_DEFAULTS,
} from 'constants/dynamicScheduling'

// utils
import { useConfigStateValue } from 'contexts/ConfigContext'
import AuthService from 'services/authentication'
import { getNormalizedFilename } from 'helpers/utils'
import log from 'helpers/log'
import { getFetchParams } from 'services/utils'
import {
  getUploadPayload,
  getDownloadPayload,
} from 'components/common/DynamicScheduling/utils'

import type { Payload } from 'types/common'

// components
import { SpecificationParametersList } from 'components/common'
import UploadFile from './UploadFile'
import SectionHeader from './SectionHeader'
import SubmitButton from './SubmitButton'
import ResultPreview from './ResultPreview'

import scss from './index.module.scss'

const PROCESS_TIME = 2 * 60

const DEFAULT_INTERVAL_MS = 1 * 1000

const POLLING_DELAY_S = 10

const POLLING_INTERVAL_MS = 5 * 1000

const OPTIMIZATION_TABS = [...Array(2).keys()]

export const Section = styled.div<{ isDisabled?: boolean }>`
  padding-right: 25px;
  padding-bottom: 30px;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.isDisabled ? 0.6 : 1)};
`

const getEstimateSeconds = (fileSize: number): number => {
  const totalSizeKB = fileSize / 1024 ** 1
  return totalSizeKB < 50
    ? POLLING_DELAY_S
    : POLLING_DELAY_S + (fileSize - 50) / 25
}

const getFetchOptions = async (body: Payload): Promise<RequestInit> => {
  const credentials = await AuthService.getCredentials()
  return getFetchParams({
    method: 'POST',
    body: JSON.stringify(body),
    credentials,
  })
}

type OptimizationSettings = Payload

const DynamicScheduling = ({
  onExport = _.noop,
}: {
  onExport?: (v: string) => void
}): ReactElement => {
  const [selectedOptimizationTab, setSelectedOptimizationTab] = useState(0)
  const [
    isSubmitting,
    { setTrue: setSubmittingTrue, setFalse: setSubmittingFalse },
  ] = useBoolean(false)
  const [submitted, { setTrue: fileSubmitted }] = useBoolean(false)
  const [seconds, setSeconds] = useState(0)
  const [interval, setInterval] = useState<number | undefined>()
  const [downloadBase64Files, setDownloadedBase64Files] = useState<
    Record<number, string>
  >({})
  const [importedFile, setImportedFile] = useState<File[]>([])
  const [optimizationSettings, setOptimizationSettings] = useState<
    Record<number, OptimizationSettings>
  >({})

  const [estimateSeconds, setEstimateSeconds] = useState(PROCESS_TIME)
  const [uniqFileName, setUniqFileName] = useState<string>()
  const [pollingFetchOption, setPollingFetchOption] = useState<
    RequestInit | undefined
  >()

  const {
    baseUrl,
    logging: { environment },
  } = useConfigStateValue()

  const currentOptimizationSetting =
    optimizationSettings[selectedOptimizationTab] ||
    DYNAMIC_SCHEDULING_SETTINGS_DEFAULTS

  const isFileEmpty = useMemo(
    () => _.isEmpty(importedFile) || !_.first(importedFile),
    [importedFile]
  )

  const endpoint = `${baseUrl}up/retrieve`

  const getProcessedResult = async () => {
    if (!uniqFileName || downloadBase64Files[selectedOptimizationTab]) return

    const body = getDownloadPayload({ environment, fileName: uniqFileName })
    let fetchOptions = pollingFetchOption
    if (!pollingFetchOption) {
      fetchOptions = await getFetchOptions(body)
      setPollingFetchOption(fetchOptions)
    }

    const result = await fetch(endpoint, fetchOptions)
      .then(r => (r.status === 200 ? r.json() : undefined))
      .then((r: { base64Data: string }) => {
        const { base64Data } = r || {}
        if (!base64Data) {
          log.debug('The file is not ready')
        }
        return base64Data
      })

    if (result) {
      setSubmittingFalse()
      setDownloadedBase64Files(old => ({
        ...old,
        [selectedOptimizationTab]: result,
      }))
      fileSubmitted()
      setSeconds(0)
      onExport(result)
    }
  }

  const { runAsync, cancel: cancelGetProcessedResult } = useRequest(
    getProcessedResult,
    {
      pollingInterval: POLLING_INTERVAL_MS,
      pollingWhenHidden: false,
      manual: true,
    }
  )

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!currentOptimizationSetting || isFileEmpty) return

    const file = importedFile[0]
    if (!file) return

    setEstimateSeconds(getEstimateSeconds(file.size))
    const fileName = `${Date.now()}_${uuidv4()}_${getNormalizedFilename(
      file.name
    )}`
    setUniqFileName(fileName)
    setSubmittingTrue()

    const body = await getUploadPayload({
      settings: currentOptimizationSetting,
      uploadFile: file,
      environment,
      fileName,
    })

    const fetchOptions = await getFetchOptions(body)
    const [err, response] = await to(fetch(endpoint, fetchOptions))
    if (err || !response?.ok) {
      log.error(err)
      log.error(response)
      setSubmittingFalse()
    } else {
      log.info(
        `File is submitted, will fetch the result after ${POLLING_DELAY_S} seconds`
      )
      setTimeout(() => {
        runAsync()
      }, POLLING_DELAY_S * 1000)
    }
  }

  useEffect(() => {
    if (downloadBase64Files[selectedOptimizationTab]) {
      cancelGetProcessedResult()
      setPollingFetchOption(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadBase64Files])

  useEffect(() => {
    const newInterval = isSubmitting ? DEFAULT_INTERVAL_MS : undefined
    setInterval(newInterval)
  }, [isSubmitting])

  useInterval(() => {
    setSeconds((s: number) => s + 1)
  }, interval)

  const onTabSelect = (index: number) => {
    setSelectedOptimizationTab(index)
    onExport(downloadBase64Files[index])
  }

  const renderOptimizationSettings = () => (
    <form onSubmit={handleSubmit}>
      <Section isDisabled={isSubmitting}>
        <SectionHeader number={1} title='Import' />
        <UploadFile
          onChange={v => {
            setImportedFile(v)
          }}
          files={importedFile}
          disabled={isSubmitting}
        />
      </Section>
      <Section isDisabled={isFileEmpty || isSubmitting}>
        <SectionHeader
          number={2}
          title='Optimization Settings'
          subTitle={
            <div
              className='smallText cursorPointer'
              onClick={() =>
                setOptimizationSettings(oldFormDataValue => ({
                  ...oldFormDataValue,
                  [selectedOptimizationTab]:
                    DYNAMIC_SCHEDULING_SETTINGS_DEFAULTS,
                }))
              }
            >
              RESET TO DEFAULT (RECOMMENDED)
            </div>
          }
        />
        <div className={scss.parametersListWrapper}>
          <SpecificationParametersList
            disabled={isFileEmpty}
            specificationParameters={DYNAMIC_SCHEDULING_CONFIGS}
            specParams={currentOptimizationSetting}
            onChange={(newPayload: Payload) => {
              setOptimizationSettings(oldFormDataValue => ({
                ...oldFormDataValue,
                [selectedOptimizationTab]: newPayload,
              }))
            }}
            labelClassName='smallText'
          />
        </div>
      </Section>
      <Section isDisabled={isFileEmpty}>
        <SectionHeader number={3} title='Run' />
        <SubmitButton
          disabled={!currentOptimizationSetting || isFileEmpty}
          submitting={isSubmitting}
          progress={
            submitted
              ? 100
              : (seconds / estimateSeconds) * 100 === 100
              ? 99
              : (seconds / estimateSeconds) * 100
          }
          seconds={estimateSeconds}
        />
      </Section>
    </form>
  )

  const renderOptimizationTabs = () => (
    <Tabs onSelect={onTabSelect} className={scss.tab}>
      <TabList>
        {OPTIMIZATION_TABS.map(idx => {
          return (
            <Tab key={idx} disabled={isSubmitting}>
              Optimization {idx}
            </Tab>
          )
        })}
      </TabList>
      {OPTIMIZATION_TABS.map(idx => {
        return (
          <TabPanel key={idx} className={`react-tabs__tab-panel ${scss.panel}`}>
            <ResultPreview result={downloadBase64Files[idx]} />
          </TabPanel>
        )
      })}
    </Tabs>
  )

  return (
    <>
      <div className='h-100'>
        <div className='d-flex h-100'>
          <div className='col-3'>{renderOptimizationSettings()}</div>
          <div className={`col-9 ${scss.preview}`}>
            {renderOptimizationTabs()}
          </div>
        </div>
      </div>
    </>
  )
}

export default DynamicScheduling
