// libraries
import { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import isEqual from 'fast-deep-equal'
import { useUpdateEffect } from 'react-use'
import { useRecoilValue, useSetRecoilState } from 'recoil'

// contexts
import { useStateValue, useMapStateValue } from 'contexts'
import {
  assetsDataState,
  assetsDataByIdSelector,
} from 'recoilStore/assetsStore'

// constants
import { LAYER_TYPES, BASE_LAYER_TYPES } from 'constants/map'
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'

// utils
import { useCurrentUser } from 'hooks'
import {
  isLiveDataset,
  isHistoricalDataset,
  getDatasetServiceIdentifier,
  getDatasetsSpecificationRequests,
  getIdentityProperty,
} from 'helpers/unipipe'
import {
  applyFilters,
  getSpatialFilterFn,
  getLayerTimeFilteredData,
  getCrossfilterTimeDimension,
} from 'helpers/filter'
import {
  shouldUseDeckglTimeFilter,
  getLayerSelectedDatetimeRange,
  getLayerDataFromDatasetServices,
  getColourClassification,
  getLayerPolygonForAggregation,
  isAssetLayer,
} from 'helpers/map'
import { getVisiblePropertiesValuesSet } from 'helpers/layerProfile'
import { switchcaseF, isRangeValid } from 'helpers/utils'
import { isColourClassesValid } from 'helpers/colour'
import { assignPointsToPolygon } from 'components/map/layers/deckLayers/dataAggregation'
import log from 'helpers/log'
import { getAssetByProfileId } from 'services/api/asset'

// utils - layers
import { getIconLayerData } from 'components/map/layers/deckLayers/iconLayer/utils'
import { getPointLayerData } from 'components/map/layers/deckLayers/pointLayer/utils'
import { getArcLayerData } from 'components/map/layers/deckLayers/arcLayer/utils'
import { getGeoJsonLayerData } from 'components/map/layers/deckLayers/upGeojsonLayer/utils'
import {
  getPolygonLayerData,
  LAYER_DEFAULT_STYLE as POLYGON_LAYER_DEFAULT_STYLE,
  aggregatePolygonValues,
} from 'components/map/layers/deckLayers/polygonLayer/utils'
import { getUpPolygonLayerData } from 'components/map/layers/deckLayers/upPolygonLayer/utils'
import { getDefaultLayerStyle } from 'helpers/layerStyle'
import { getAssetProfileData } from 'helpers/asset'

export const shouldUseCachedDataBasedOnFilters = ({
  layerFilters,
  cachedLayerData,
  isHistoricalData,
  applyGlobalTimeFilterOnly,
}) => {
  const { validFilters, timeFilter, visiblePropertiesSet } = layerFilters
  const {
    validFilters: oldValidFilters,
    timeFilter: oldTimeFilter,
    visiblePropertiesSet: oldVisiblePropertiesSet,
  } = cachedLayerData

  return isHistoricalData
    ? applyGlobalTimeFilterOnly
      ? isEqual(timeFilter, oldTimeFilter)
      : isEqual(timeFilter, oldTimeFilter) &&
        _.isEqual(visiblePropertiesSet, oldVisiblePropertiesSet) &&
        isEqual(validFilters, oldValidFilters)
    : _.isEqual(visiblePropertiesSet, oldVisiblePropertiesSet) &&
        isEqual(validFilters, oldValidFilters)
}

const getLayerFilters = ({
  layer,
  isHistoricalData,
  selectedDateTimeRange,
}) => {
  const timeFilter = isHistoricalData
    ? getLayerSelectedDatetimeRange(layer, selectedDateTimeRange)
    : undefined
  return { validFilters: layer.filters, timeFilter }
}

/**
 * Get the time filtered data and crossfilter datetimeDim
 *
 * @prop {Object} timeFilter
 * @prop {Object} filteredDataWithoutTimeFilter
 *
 * @return {Object} { filteredData, datetimeDim}
 */
export const getTimeFilteredData = ({
  layer,
  layerFilters,
  filteredDataWithoutTimeFilter = [],
}) => {
  const { timeFilter, datetimeDim } = layerFilters

  return _.isNil(timeFilter)
    ? filteredDataWithoutTimeFilter
    : _.isEmpty(timeFilter)
    ? []
    : getLayerTimeFilteredData({
        layer,
        datetimeDim,
        selectedDateTimeRange: timeFilter,
      })
}

const getPolygonAutoGlobalDataClassification = (layer, data) => {
  const { type, style } = layer
  if (type !== LAYER_TYPES.polygon) return undefined

  const polygonsWithBbox = getLayerPolygonForAggregation(layer)

  if (_.isEmpty(polygonsWithBbox)) {
    return undefined
  }

  const colourPropertyType = PROPERTY_VARIABLE_TYPES.number
  const colourPropertyValue = 'colorValue'

  const {
    colourClasses,
    colourRange,
    aggregationForColour,
    aggregationForHeight,
    valueRangeForColour,
  } = getDefaultLayerStyle(style[type], POLYGON_LAYER_DEFAULT_STYLE)

  if (
    isRangeValid(valueRangeForColour) &&
    isColourClassesValid(colourClasses, colourPropertyType)
  ) {
    log.info('Classifying the data: found custom data classification found')
    return colourClasses
  }

  log.info(
    'Classifying the data: no custom data classification found, the classes will be automatically determined based on the available data (equal interval)'
  )

  const applySpatialFilterWithoutTimeFilter = getSpatialFilterFn(data)

  const aggregatedPolygonsWithoutTimeFilter = assignPointsToPolygon(
    polygonsWithBbox,
    applySpatialFilterWithoutTimeFilter
  )

  const polygonsWithAggregatedValuesWithoutTimeFilter = aggregatePolygonValues(
    aggregatedPolygonsWithoutTimeFilter,
    aggregationForColour,
    aggregationForHeight
  )

  const classification = getColourClassification(
    polygonsWithAggregatedValuesWithoutTimeFilter,
    colourRange,
    { type: colourPropertyType, key: colourPropertyValue },
    colourClasses,
    valueRangeForColour
  )

  if (isColourClassesValid(classification)) {
    const dataClassification = _.map(classification, ({ range }) => {
      return `<${range[0]} - ${range[1]}>`
    })

    log.info(`Data classification: ${dataClassification}`)
  }

  return classification
}

const getLayerFilteredDataAndTimeFilter = ({
  layer,
  layerFilters,
  geojsonData,
  filterCondition,
  cachedLayerData,
  applyGlobalTimeFilterOnly,
}) => {
  const {
    filteredDataWithoutTimeFilter: oldFilteredDataWithoutTimeFilter,
    datetimeDim: oldDatetimeDim,
    mapLayerData: oldMapLayerData,
    dataClassification: oldDataClassification,
  } = cachedLayerData

  const { validFilters } = layerFilters

  let filteredDataWithoutTimeFilter = []
  let useCachedMapLayerData = false
  let dataClassification = []
  if (applyGlobalTimeFilterOnly && oldFilteredDataWithoutTimeFilter) {
    // only apply the global time filter && has filtered data
    filteredDataWithoutTimeFilter = oldFilteredDataWithoutTimeFilter
    useCachedMapLayerData = true
  } else {
    // each dataset has two data source: (1) local cached historical data
    // and (2) last known locations.The data source of the dataset can be
    // switched as needed.
    // The historical and feature dataset default data source are historical
    // data. The live dataset default data source is last known locations
    const propertyPathPrefix = 'properties'
    if (geojsonData) {
      filteredDataWithoutTimeFilter = applyFilters(
        geojsonData,
        validFilters,
        propertyPathPrefix,
        filterCondition
      )
    }
  }

  if (useCachedMapLayerData && !_.isEmpty(oldDataClassification)) {
    log.info('Found cached data classification')
    dataClassification = oldDataClassification
  } else {
    dataClassification = getPolygonAutoGlobalDataClassification(
      layer,
      filteredDataWithoutTimeFilter
    )
  }

  const datetimeDim =
    useCachedMapLayerData && oldDatetimeDim
      ? oldDatetimeDim
      : getCrossfilterTimeDimension({ data: filteredDataWithoutTimeFilter })

  const filteredData = getTimeFilteredData({
    layer,
    layerFilters: { ...layerFilters, datetimeDim },
    filteredDataWithoutTimeFilter,
  })

  return {
    datetimeDim,
    filteredData,
    filteredDataWithoutTimeFilter,
    dataClassification,
    cachedMapLayerData: useCachedMapLayerData ? oldMapLayerData : undefined,
  }
}

export const getLayerData = props => {
  const {
    layer: { type },
  } = props

  return switchcaseF({
    [LAYER_TYPES.point]: () => getPointLayerData(props),
    [LAYER_TYPES.icon]: () => getIconLayerData(props),
    [LAYER_TYPES.upGeojson]: () => getGeoJsonLayerData(props),
    [LAYER_TYPES.upPolygon]: () => getUpPolygonLayerData(props),
    [LAYER_TYPES.polygon]: () => getPolygonLayerData(props),
    [LAYER_TYPES.arc]: () => getArcLayerData(props),
  })(_.noop)(type)
}

/**
 * Responsible for sending requests for layer raw data and generating layer
 * filtered data for visualization and other data analysis.
 */
const useMapData = ({
  layerSpecParamsHash,
  layerAssetProfilesHash,
  updateMapSuggestions = _.noop,
}) => {
  const {
    state: {
      unipipeState: { datasetServices },
    },
    actions: {
      unipipeActions: {
        dispatchDatasetFetchRequest,
        abortUnipipeDatasetServices,
      },
    },
    selectors: {
      unipipeSelectors: { mapEligibleDatasetOptions, pickedDatasetsMetadata },
    },
  } = useStateValue()
  const setAssetBatchData = useSetRecoilState(assetsDataByIdSelector)

  const assetsData = useRecoilValue(assetsDataState)

  const {
    map,
    getLayerDataById,
    setLayersFilteredData,
    layersWithExternalConfig: layers = [],
  } = useMapStateValue()
  const { selectedDateTimeRange } = map
  const [oldLayersDatasetRequests, setOldLayersDatasetRequests] = useState({})
  const { issueSeverityOptions, issueAssigneesOptions } = useCurrentUser()

  /**
   * Prune all unreachable dataset services from the context
   */
  const deleteStaleDatasetService = useCallback(
    mapLayers => {
      const activeDatasetServiceIdentifies = _(mapLayers)
        .map(getDatasetServiceIdentifier)
        .uniq()
        .compact()
        .value()
      abortUnipipeDatasetServices(activeDatasetServiceIdentifies)
    },
    [abortUnipipeDatasetServices]
  )

  const dispatchDatasetFetchRequests = useCallback(
    datasetRequests => {
      _.forEach(datasetRequests, dispatchDatasetFetchRequest)
    },
    [dispatchDatasetFetchRequest]
  )

  useEffect(() => {
    const getDatasetRequests = layersWithValidDatasets => {
      const { layers: oldLayers = [], datasetRequestsIdentifiers } =
        oldLayersDatasetRequests

      const hasNewLayer = !_.isEmpty(
        _.differenceBy(layers, oldLayers, 'dataset')
      )
      // merge requests from the same dataset with the same specification parameters
      const datasetRequests = getDatasetsSpecificationRequests(
        layers,
        mapEligibleDatasetOptions,
        hasNewLayer
      )
      const newDatasetRequestsIdentifiers = _.keys(datasetRequests)
      const staleDatasetRequestsIdentifiers = _.difference(
        datasetRequestsIdentifiers,
        newDatasetRequestsIdentifiers
      )
      if (!_.isEmpty(staleDatasetRequestsIdentifiers)) {
        deleteStaleDatasetService(layers)
      }

      const addedDatasetRequestsIdentifiers = _.difference(
        newDatasetRequestsIdentifiers,
        datasetRequestsIdentifiers
      )
      setOldLayersDatasetRequests({
        layers: layersWithValidDatasets,
        datasetRequestsIdentifiers: newDatasetRequestsIdentifiers,
      })
      if (_.isEmpty(addedDatasetRequestsIdentifiers)) return []

      return _.pick(datasetRequests, addedDatasetRequestsIdentifiers)
    }

    const dispatchDatasetRequests = () => {
      if (_.isEmpty(mapEligibleDatasetOptions) || !layers) return

      const { layers: oldLayers = [] } = oldLayersDatasetRequests
      if (oldLayers.length > layers.length) {
        deleteStaleDatasetService(layers)
        return
      }

      const layersWithValidDatasets = _.filter(layers, 'dataset')
      // do not need to generate datasets requests when adding a new layer without a valid dataset yet
      if (isEqual(layersWithValidDatasets, oldLayers)) return

      const newDatasetRequests = getDatasetRequests(layersWithValidDatasets)
      dispatchDatasetFetchRequests(newDatasetRequests)
    }
    dispatchDatasetRequests()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerSpecParamsHash])

  const dispatchLiveDatasetRequests = useCallback(
    mapLayers => {
      const layersWithLiveDatasets = _.filter(mapLayers, ({ timeliness }) =>
        isLiveDataset(timeliness)
      )
      const datasetRequests = getDatasetsSpecificationRequests(
        layersWithLiveDatasets,
        mapEligibleDatasetOptions
      )
      dispatchDatasetFetchRequests(datasetRequests)
      return _.keys(datasetRequests).length
    },
    [dispatchDatasetFetchRequests, mapEligibleDatasetOptions]
  )

  const generateFilteredDataState = useCallback(
    ({
      layer,
      isHistoricalData,
      filteredData,
      cachedMapLayerData,
      filteredDataWithoutTimeFilter,
      dataClassification,
      ...rest
    }) => {
      const useDeckglTimeFilter = shouldUseDeckglTimeFilter(
        layer,
        isHistoricalData
      )
      const { id, dataset } = layer
      const identityProperty = getIdentityProperty({
        pickedDatasetsMetadata,
        dataset,
      })

      // filteredData refers to the data which applies all valid filters
      const applySpatialFilter = getSpatialFilterFn(filteredData)

      const applySpatialFilterWithoutTimeFilter = getSpatialFilterFn(
        filteredDataWithoutTimeFilter
      )

      const filteredDataState = {
        filteredData,
        applySpatialFilter,
        cachedMapLayerData,
        filteredDataWithoutTimeFilter,
        applySpatialFilterWithoutTimeFilter,
        dataClassification,
      }

      const mapLayerData = getLayerData({
        layer,
        filteredDataState,
        identityProperty,
      })

      return {
        [id]: {
          ...rest,
          ...filteredDataState,
          useDeckglTimeFilter,
          mapLayerData, // layer type specific data,
        },
      }
    },
    [pickedDatasetsMetadata]
  )

  const getVisiblePropertiesGeojsonData = useCallback(
    layer => {
      const {
        selectedProperties: {
          enable: enableSelectedProperties = false,
          properties = [],
        } = {},
        profile: { assetProfileId } = {},
        dataset,
      } = layer

      const geojsonData = isAssetLayer(layer)
        ? getAssetProfileData(assetsData, assetProfileId)
        : getLayerDataFromDatasetServices(layer, datasetServices)

      if (_.isEmpty(geojsonData)) return []

      if (!enableSelectedProperties)
        return {
          geojsonData,
        }

      const { identityProperty } = pickedDatasetsMetadata[dataset]
      const visiblePropertiesSet = getVisiblePropertiesValuesSet(
        properties,
        identityProperty
      )

      const filterPropertiesGeojsonData = geojsonData.map(data => {
        return {
          ...data,
          properties: _.pick(data.properties, [...visiblePropertiesSet]),
        }
      })

      return {
        geojsonData: filterPropertiesGeojsonData,
        visiblePropertiesSet,
      }
    },
    [assetsData, datasetServices, pickedDatasetsMetadata]
  )

  /**
   * Update layer filtered data based on layer's filters, datetimeRange
   * and global datetime range
   * @prop {Boolean}[useCachedData=false]
   * @prop {Boolean}[updateLiveDatasetOnly=false]
   * @prop {Boolean}[applyGlobalTimeFilterOnly=false]
   */
  const updateLayersData = useCallback(
    ({
      useCachedData = false,
      updateLiveDatasetOnly = false,
      applyGlobalTimeFilterOnly = false,
    } = {}) => {
      const newLayersFilteredData = layers.reduce((acc, layer) => {
        const { id, timeliness, filterCondition } = layer
        const { geojsonData, visiblePropertiesSet } =
          getVisiblePropertiesGeojsonData(layer)
        if (_.isEmpty(geojsonData)) {
          return acc
        }

        const cachedLayerData = getLayerDataById(id)
        const hasCachedData = useCachedData && !_.isEmpty(cachedLayerData)
        const cachedData = { ...acc, [id]: cachedLayerData }
        // use cached data for layers with non-live dataset (i.e., history and
        // feature dataset) when layers with the live dataset needs to be updated
        if (
          hasCachedData &&
          updateLiveDatasetOnly &&
          !isLiveDataset(timeliness)
        ) {
          return cachedData
        }

        const isHistoricalData = isHistoricalDataset(timeliness)

        // for the layer with the historical data,the timeFilter is based on the value of selectedDateTimeRange
        // for the layer with the live and feature data, it doesn't have the timeFilter
        const { validFilters, timeFilter } = getLayerFilters({
          layer,
          isHistoricalData,
          selectedDateTimeRange,
        })

        // use the cached data if valid filters are not changed
        const layerFilters = {
          validFilters,
          timeFilter,
          visiblePropertiesSet,
        }
        if (
          hasCachedData &&
          shouldUseCachedDataBasedOnFilters({
            layerFilters,
            cachedLayerData,
            isHistoricalData,
            applyGlobalTimeFilterOnly,
          })
        ) {
          return cachedData
        }

        const {
          datetimeDim,
          filteredData,
          cachedMapLayerData,
          filteredDataWithoutTimeFilter,
          dataClassification,
        } = getLayerFilteredDataAndTimeFilter({
          layer,
          layerFilters,
          geojsonData,
          filterCondition,
          cachedLayerData,
          applyGlobalTimeFilterOnly,
        })

        updateMapSuggestions(layer, filteredData)

        const filteredDataState = generateFilteredDataState({
          layer,
          validFilters,
          timeFilter,
          filteredData,
          datetimeDim,
          isHistoricalData,
          filteredDataWithoutTimeFilter,
          cachedMapLayerData,
          dataClassification,
        })

        return {
          ...acc,
          ...filteredDataState,
        }
      }, {})

      setLayersFilteredData(newLayersFilteredData)
      return newLayersFilteredData
    },
    [
      layers,
      setLayersFilteredData,
      getVisiblePropertiesGeojsonData,
      getLayerDataById,
      selectedDateTimeRange,
      updateMapSuggestions,
      generateFilteredDataState,
    ]
  )

  useUpdateEffect(() => {
    if (!layerAssetProfilesHash) return

    const fetchAssetDetailById = profileId => {
      if (!profileId || !_.isString(profileId)) return

      const onBatch = (props = {}) => setAssetBatchData({ ...props, profileId })

      onBatch()
      getAssetByProfileId({
        profileId,
        onBatch,
        issueAssigneesOptions,
        issueSeverityOptions,
      })
    }

    const dispatchAssetProfileRequests = () => {
      const assetLayers = _.filter(layers, { baseType: BASE_LAYER_TYPES.asset })
      const toBeFetchedAssetProfiles = _.compact(
        _.reduce(
          assetLayers,
          (acc, layer) => {
            const { profile: { assetProfileId } = {} } = layer
            const assetProfile = assetsData?.[assetProfileId]
            const hasAssetData = assetProfile && !_.isEmpty(assetProfile?.data)
            return hasAssetData ? acc : [...acc, assetProfileId]
          },
          []
        )
      )

      if (_.isEmpty(toBeFetchedAssetProfiles)) return

      _.forEach(toBeFetchedAssetProfiles, fetchAssetDetailById)
    }
    dispatchAssetProfileRequests()
  }, [layerAssetProfilesHash])

  useUpdateEffect(() => {
    updateLayersData()
  }, [assetsData, layerAssetProfilesHash])

  return {
    updateLayersData,
    dispatchLiveDatasetRequests,
  }
}

export default useMapData
