import React from 'react'
import PropTypes from 'prop-types'
import { useBranding } from 'hooks'

import { Paper } from 'components/common'

import scss from './index.module.scss'

const LoginLayout = ({ children }) => {
  const {
    backgroundImageUrl,
    logo: {
      fullLogo: { fullColourLogo },
    },
  } = useBranding()

  return (
    <div
      className={scss.page}
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      <div className={scss.signin}>
        <Paper width='400px'>
          <div className={scss.logoContainer}>
            <img
              alt='logo'
              className={`${scss.logo} mx-auto d-block`}
              src={fullColourLogo}
            />
          </div>
          <hr className={scss.hr} />
          <div className={scss.form}>{children}</div>
          <div className={scss.footer}>
            <a
              href='https://sensorup.com'
              target='_blank'
              rel='noreferrer'
              className={scss.poweredBy}
            >
              Powered by
              <img src='/assets/logo/sensorup-logo-name.svg' alt='SensorUp' />
            </a>
          </div>
        </Paper>
      </div>
    </div>
  )
}

LoginLayout.propTypes = {
  children: PropTypes.node,
}

LoginLayout.defaultProps = {
  children: undefined,
}

export default LoginLayout
