import React, { useMemo, useCallback } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

// utils
import { getWidgetScatterOptions } from 'components/widget/common/widgetRenderHelper/scatter'

// constants
import { WIDGET_TYPES } from 'constants/widget'
import { THEMES } from 'constants/colour'
import { ASSET_PROFILE_MEDIA_TYPES } from 'constants/assets'

// components
import { ScatterWidget, NoAvailableWidget } from 'components/widget'
import { BaseWidgetWithAssetState } from 'components/assets/assetsProfile/widgets/BaseWidget'

const DisplayRelatedAssetsValues = ({ value, timezone, settings, theme }) => {
  const { assets, asset } = value?.related || {}
  const { properties } = value?.assetProfile || {}

  const propertyOptions = useMemo(() => {
    return _.map(properties, data => {
      const { name, displayName } = data
      return { ...data, value: name, label: displayName || name }
    })
  }, [properties])

  const options = getWidgetScatterOptions({
    widgetData: assets || [asset],
    widgetSetting: settings,
    dataMerge: false,
    timezone,
    propertyOptions,
  })

  return options && !_.isEmpty(options?.dataset?.source) ? (
    <ScatterWidget {...options} theme={theme} />
  ) : (
    <NoAvailableWidget widgetType={WIDGET_TYPES.scatter} content='no data' />
  )
}

DisplayRelatedAssetsValues.propTypes = {
  value: PropTypes.shape({
    related: PropTypes.shape({
      asset: PropTypes.shape({
        id: PropTypes.string,
        displayName: PropTypes.string,
        properties: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      assets: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          displayName: PropTypes.string,
          properties: PropTypes.arrayOf(PropTypes.shape({})),
        })
      ),
    }),
    assetProfile: PropTypes.shape({
      properties: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  timezone: PropTypes.string,
  settings: PropTypes.shape({}),
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
}

DisplayRelatedAssetsValues.defaultProps = {
  value: {},
  timezone: undefined,
  settings: PropTypes.shape({}),
  theme: THEMES.dark,
}

const AssetScatterWidget = ({
  assetId,
  settings,
  theme,
  name,
  onWidgetLoad,
  mediaType,
}) => {
  const isPrintable = useMemo(
    () => mediaType === ASSET_PROFILE_MEDIA_TYPES.PRINTABLE,
    [mediaType]
  )
  const render = useCallback(
    props => {
      const { relatedAssetRelationshipId } = settings
      const sharedProps = {
        ...props,
        settings,
        theme,
      }
      return (
        relatedAssetRelationshipId && (
          <DisplayRelatedAssetsValues {...sharedProps} />
        )
      )
    },
    [settings, theme]
  )

  return (
    <BaseWidgetWithAssetState
      assetId={assetId}
      settings={settings}
      render={render}
      theme={theme}
      name={name}
      onWidgetLoad={onWidgetLoad}
      isPrintable={isPrintable}
    />
  )
}

AssetScatterWidget.propTypes = {
  assetId: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    assetProfileId: PropTypes.string.isRequired,
    relatedAssetRelationshipId: PropTypes.string,
    relatedAssetProfileId: PropTypes.string,
    properties: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
  name: PropTypes.string,
  onWidgetLoad: PropTypes.func,
  mediaType: PropTypes.string.isRequired,
}

AssetScatterWidget.defaultProps = {
  theme: THEMES.dark,
  name: undefined,
  onWidgetLoad: undefined,
}

export default AssetScatterWidget
