import { useState, useEffect, useRef, useCallback, ReactElement } from 'react'
import _ from 'lodash'
import scss from './index.module.scss'

const NumericInput = ({
  value,
  min = -Infinity,
  max = Infinity,
  step = 1,
  className,
  disabled = false,
  onChange,
  autoFocus = false,
  onFocus = _.noop,
  onBlur = _.noop,
  canEmpty = false,
  testId,
}: {
  value?: number | string

  min?: number
  max?: number
  step?: number
  disabled?: boolean
  className?: string
  testId?: string
  onChange?: (v: number) => void
  autoFocus?: boolean
  canEmpty?: boolean
  onFocus?: (v: number) => void
  onBlur?: (v: number) => void
}): ReactElement => {
  const inputRef = useRef(null)
  const [number, setNumber] = useState(() => _.toNumber(value))

  const [isEditing, setIsEditing] = useState(false)

  const onChangeValue = useCallback(() => {
    const numberValue = _.toNumber(number)
    const newValue = canEmpty ? numberValue : _.clamp(numberValue, min, max)
    onChange(_.toNumber(newValue))
  }, [min, max, number, onChange, canEmpty])

  const handleFocus = useCallback(() => {
    const newValue = inputRef.current.value
    setNumber(_.toNumber(newValue))
    setIsEditing(true)
    onFocus()
  }, [onFocus])

  const handleKeypress = useCallback(
    e => {
      if (e.key === 'Enter') {
        inputRef.current.blur()
        onChangeValue()
        setIsEditing(false)
      }
    },
    [onChangeValue]
  )

  const handleBlur = useCallback(() => {
    setIsEditing(false)
    onChangeValue()
    onBlur()
  }, [onBlur, onChangeValue])

  useEffect(() => {
    if (!isEditing && inputRef.current.value !== value) {
      inputRef.current.value = value
    }
  }, [isEditing, value])

  return (
    <input
      ref={inputRef}
      type='number'
      disabled={disabled}
      className={`${scss.input} ${className}`}
      autoComplete='off'
      min={min}
      max={max}
      step={step}
      onKeyPress={handleKeypress}
      onInput={handleFocus}
      onClick={handleFocus}
      onBlur={handleBlur}
      onFocus={onFocus}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      data-testid={testId}
    />
  )
}

NumericInput.defaultProps = {
  value: undefined,
  min: -Infinity,
  max: Infinity,
  step: 1,
  className: '',
  testId: '',
  disabled: false,
  onChange: undefined,
  autoFocus: false,
  canEmpty: false,
  onBlur: _.noop,
  onFocus: _.noop,
}

export default NumericInput
