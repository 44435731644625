// libraries
import { useState, useCallback, useMemo } from 'react'
import to from 'await-to-js'
import { useRecoilState } from 'recoil'
import _ from 'lodash'

// constants
import {
  DEFAULT_ISSUE_SEVERITY_TYPES,
  ISSUE_TASK_DATA_COLLECTION_FORM_TYPES,
} from 'constants/issue'
import { FEATURES } from 'constants/settings'
import {
  issueDataCollectionRelatedState,
  issueTaskDataCollectionFormDefinitionState,
} from 'recoilStore/issuesStore'
import { sitesListState } from 'recoilStore/sitesStore'
import { GALLERY_LIST_FILTER_TYPES } from 'constants/common'

// utils
import { fetchListData, dispatchConcurrentRequests } from 'helpers/utils'
import { listUsers, getCurrentUserConsentDocuments } from 'services/api/user'
import { listSitesNames } from 'services/api/site'
import { listUserGroups } from 'services/api/group'
import {
  getIssueAssigneesAndSeverityTypes,
  listIssueTaskDataCollectionFormDefinition,
  listIssueTaskDataCollectionFormMetadata,
} from 'services/api/issue'
import log, { reportException } from 'helpers/log'
import { useFeatureFlag } from 'hooks'
import { useStateValue } from 'contexts'
import { useAssetsProfiles } from 'components/assets/assetsProfile/hooks'
import { displayAndLogErrorMessage } from 'helpers/message'

import type { Options } from 'types/common'
import type { User, UserConsent } from 'types/user'
import type { AssigneeOption } from 'types/issue'
import type { Site } from 'types/sites'

const useFetchAppSupportData = ({
  toggleShowingEndUserConsent = _.noop,
  signOut = _.noop,
}: {
  toggleShowingEndUserConsent?: (v: boolean) => void
  signOut?: () => void
} = {}): {
  issueAssigneesOptions: AssigneeOption[]
  issueSeverityOptions: Options<string>
  userConsents: UserConsent[]
  userGroups: string[]
  userGroupsOptions: Options<string>
  setUserConsents: React.Dispatch<React.SetStateAction<UserConsent[]>>
  fetchAppSupportData: () => Promise<void>
  fetchAppSupportDataWithConcentCheck: (user: User) => Promise<void>
  fetchIssueTaskDataCollectionFormMetadata: (
    forceRefetch?: boolean
  ) => Promise<void>
  fetchIssueTaskDataCollectionFormDefinition: () => Promise<void>
  fetchSiteSupportData: (force?: boolean) => Promise<void>
  fetchUpCatalogSupportData: () => Promise<void>
} => {
  const {
    actions: {
      unipipeActions: { getUnipipeCatalog, updateDatasetGroups },
      userActions: { updateUsersPageList, updateUsersList },
    },
  } = useStateValue()

  const {
    [FEATURES.ISSUE]: issuesEnabled,
    [FEATURES.ASSET]: assetsEnabled,
    [FEATURES.JSON_FORM]: jsonFormEnabled,
  } = useFeatureFlag()

  const [issueAssigneesOptions, setIssueAssigneesOptions] = useState<
    AssigneeOption[]
  >([])

  const [issueSeverityOptions, setIssueSeverityOptions] = useState<
    Options<string>
  >([])

  const [userConsents, setUserConsents] = useState<UserConsent[]>([])

  const [userGroupsOptions, setUserGroupsOptions] = useState<Options<string>>(
    []
  )

  const userGroups = useMemo(() => {
    return _.map(userGroupsOptions, 'value')
  }, [userGroupsOptions])

  const [issueDataCollectionFormRelated, setIssueDataCollectionFormRelated] =
    useRecoilState(issueDataCollectionRelatedState)

  const [
    issueTaskDataCollectionFormDefinition,
    setIssueTaskDataCollectionFormDefinition,
  ] = useRecoilState(issueTaskDataCollectionFormDefinitionState)

  const [sitesList, setSitesList] = useRecoilState(sitesListState)

  const fetchUserSupportData = useCallback(async () => {
    const fetchUserGroups = async () => {
      const newGroupsOptions = (await listUserGroups()) || []
      setUserGroupsOptions(newGroupsOptions)
      updateDatasetGroups(newGroupsOptions)
    }

    const fetchUsersList = async () => {
      const users = await fetchListData(listUsers)
      if (_.isEmpty(users)) {
        displayAndLogErrorMessage('The user list is empty')
      }
      updateUsersList(users)
      updateUsersPageList(users)
    }

    await dispatchConcurrentRequests([fetchUserGroups, fetchUsersList])
  }, [updateDatasetGroups, updateUsersList, updateUsersPageList])

  const fetchIssueTaskDataCollectionFormMetadata = useCallback(
    async (forceRefetch = false) => {
      if (!forceRefetch && !_.isEmpty(issueDataCollectionFormRelated)) return

      const formTypes = _.compact([
        ISSUE_TASK_DATA_COLLECTION_FORM_TYPES.ODK_FORM,
        jsonFormEnabled && ISSUE_TASK_DATA_COLLECTION_FORM_TYPES.JSON_FORM,
      ])
      const issueDataCollectionRelated =
        await listIssueTaskDataCollectionFormMetadata({
          formTypes,
        })

      setIssueDataCollectionFormRelated(issueDataCollectionRelated)
    },
    [
      issueDataCollectionFormRelated,
      jsonFormEnabled,
      setIssueDataCollectionFormRelated,
    ]
  )

  const fetchIssueTaskDataCollectionFormDefinition = useCallback(
    async (withMetadata = false) => {
      if (!_.isEmpty(issueTaskDataCollectionFormDefinition)) return

      const formTypes = _.compact([
        ISSUE_TASK_DATA_COLLECTION_FORM_TYPES.ODK_FORM,
        jsonFormEnabled && ISSUE_TASK_DATA_COLLECTION_FORM_TYPES.JSON_FORM,
      ])

      const forms = withMetadata
        ? await listIssueTaskDataCollectionFormMetadata({
            formTypes,
            withFormDefinition: true,
          })
        : await listIssueTaskDataCollectionFormDefinition({ formTypes })

      const formsKeyById = _.keyBy(forms?.issueTask?.dataCollectionForms, 'id')

      setIssueTaskDataCollectionFormDefinition(formsKeyById)
      if (withMetadata) {
        setIssueDataCollectionFormRelated(forms)
      }
    },
    [
      issueTaskDataCollectionFormDefinition,
      jsonFormEnabled,
      setIssueDataCollectionFormRelated,
      setIssueTaskDataCollectionFormDefinition,
    ]
  )

  const fetchSiteSupportData = useCallback(
    async (force = false) => {
      if (force || _.isEmpty(sitesList)) {
        log.debug('Fetch site support data')
        const { data } = await listSitesNames({
          queryParams: {
            first: 1000,
            filter: { [GALLERY_LIST_FILTER_TYPES.active]: [true] },
          },
        })
        setSitesList(data as Site[])
      }
    },
    [setSitesList, sitesList]
  )

  const fetchIssueSupportData = useCallback(async () => {
    if (!issuesEnabled) return

    const fetchIssueAssigneesAndSeverityTypes = async () => {
      const [error, response] = await to(getIssueAssigneesAndSeverityTypes())
      if (error) {
        reportException(error)
      }
      const {
        assigneesOptions = [],
        issueSeverityTypes = DEFAULT_ISSUE_SEVERITY_TYPES,
      } = response || {}

      setIssueAssigneesOptions(_.sortBy(assigneesOptions, 'label'))
      setIssueSeverityOptions(issueSeverityTypes)
    }

    await dispatchConcurrentRequests([fetchIssueAssigneesAndSeverityTypes])
  }, [issuesEnabled])

  const { fetchAssetSupportData } = useAssetsProfiles()

  const fetchUpCatalogSupportData = useCallback(async () => {
    const fetchUpCatalog = async () => {
      const pipesOmitFields = assetsEnabled ? [] : ['datasets.assetProfile']
      await getUnipipeCatalog(pipesOmitFields)
    }

    await dispatchConcurrentRequests([fetchUpCatalog, fetchAssetSupportData])
  }, [assetsEnabled, fetchAssetSupportData, getUnipipeCatalog])

  const fetchAppSupportData = useCallback(async () => {
    await dispatchConcurrentRequests([
      fetchUpCatalogSupportData,
      fetchUserSupportData,
      fetchIssueSupportData,
    ])
  }, [fetchUpCatalogSupportData, fetchUserSupportData, fetchIssueSupportData])

  const fetchAppSupportDataWithConcentCheck = useCallback(
    async (user: User) => {
      const { hasConsented } = user
      if (hasConsented) {
        await fetchAppSupportData()
      } else {
        const currentUserConsents = await getCurrentUserConsentDocuments()
        const unConfirmedConsents = _.filter(
          currentUserConsents,
          'needMeConsent'
        )
        if (currentUserConsents && _.isEmpty(unConfirmedConsents)) {
          reportException(
            new Error(
              'Me.hasConsent is false but no consent documents were found'
            ),
            { unConfirmedConsents, hasConsented }
          )
          signOut()
          return
        }
        log.info(
          `Found ${unConfirmedConsents.length} unconfirmed user consent(s)`,
          unConfirmedConsents
        )
        setUserConsents(unConfirmedConsents)
        toggleShowingEndUserConsent(true)
      }
    },
    [fetchAppSupportData, signOut, toggleShowingEndUserConsent]
  )

  return {
    issueAssigneesOptions,
    issueSeverityOptions,
    userConsents,
    userGroups,
    userGroupsOptions,
    setUserConsents,
    fetchAppSupportData,
    fetchAppSupportDataWithConcentCheck,
    fetchIssueTaskDataCollectionFormMetadata,
    fetchIssueTaskDataCollectionFormDefinition,
    fetchSiteSupportData,
    fetchUpCatalogSupportData,
  }
}

export default useFetchAppSupportData
