import { ReactRenderer } from '@tiptap/react'
import { SuggestionProps, SuggestionOptions } from '@tiptap/suggestion'
import tippy, { Instance } from 'tippy.js'

import { getPropertyOptionLabel } from 'components/common/PropertyPicker'
import type { PropertiesMetadata } from 'types/common'

import { MentionList } from './MentionList'

type SuggestionPluginConfig = {
  items: SuggestionOptions['items']
  char: string
  render: SuggestionOptions['render']
}

export const getSuggestionPluginConfig = ({
  options,
  triggerChar,
}: {
  options: PropertiesMetadata
  triggerChar: string
}): SuggestionPluginConfig => ({
  items: ({ query }: { query: string }): PropertiesMetadata =>
    options.filter(({ label }) =>
      label.toLowerCase().startsWith(query.toLowerCase())
    ),

  char: triggerChar,

  render: () => {
    let reactRenderer: ReactRenderer
    let popup: Instance[]

    return {
      onStart: (props: SuggestionProps) => {
        reactRenderer = new ReactRenderer(MentionList, {
          props: { ...props, formatOptionLabel: getPropertyOptionLabel },
          editor: props.editor,
        })

        if (!props.clientRect) return

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: reactRenderer.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props: SuggestionProps) {
        reactRenderer.updateProps(props)

        if (!props.clientRect) return

        popup[0].setProps({ getReferenceClientRect: props.clientRect })
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide()
          return true
        }

        return reactRenderer.ref?.onKeyDown(props)
      },

      onExit() {
        if (!popup[0].state.isDestroyed) popup[0].destroy()
        reactRenderer.destroy()
      },
    }
  },
})
