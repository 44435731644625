import { useMemo, CSSProperties } from 'react'
import { useTheme } from '@emotion/react'
import _ from 'lodash'
import type { StylesConfig } from 'react-select'
import type { BrandingColours, ColourArray } from 'types/common'

const BORDER_RADIUS = '4px'

const getInvalidStyle = (
  data: { isValid?: boolean },
  invalidStyle: string,
  validStyle: string
) => (_.get(data, 'isValid', true) ? validStyle : invalidStyle)

const useSelectStyles = ({
  bgColour,
  withBorder = false,
  removeControl = false,
  dropdownOnly = false,
}: {
  withBorder?: boolean
  bgColour?: ColourArray | string
  removeControl?: boolean
  dropdownOnly?: boolean
}): {
  customStyles: StylesConfig
} => {
  const theme = useTheme() as BrandingColours
  const dropdownWithControl = dropdownOnly && !removeControl

  const customStyles = useMemo((): StylesConfig => {
    const fontStyle = {
      fontFamily: 'Open Sans',
      fontSize: '12px',
    }

    return {
      control: (
        styles: CSSProperties,
        { isFocused }: { isFocused: boolean }
      ) => {
        // https://github.com/JedWatson/react-select/blob/b0411ff46bc1ecf45d9bca4fb58fbce1e57f847c/packages/react-select/src/components/Control.js#L29-L58

        return {
          ...styles,
          display: removeControl ? 'none' : 'flex',
          minHeight: '31px',
          borderRadius: '0px',
          backgroundColor: bgColour || 'white',
          outline: '0 !important',
          margin: '0',
          boxShadow: null,
          borderColor: withBorder
            ? isFocused
              ? theme.primary
              : '#ced4da'
            : 'transparent',
          ...(dropdownWithControl && {
            borderTopLeftRadius: BORDER_RADIUS,
            borderTopRightRadius: BORDER_RADIUS,
          }),
          '&:hover': {
            borderColor: withBorder
              ? isFocused
                ? theme.primary
                : '#ced4da'
              : 'transparent',
          },
        }
      },
      option: (
        styles: CSSProperties,
        {
          data,
          isDisabled: isOptionDisabled,
          isFocused,
          isSelected,
        }: {
          data: { colour?: string }
          isDisabled: boolean
          isFocused: boolean
          isSelected: boolean
        }
      ) => {
        return {
          ...styles,
          ...fontStyle,
          fontWeight: isSelected ? 'bold' : 'regular',
          textTransform: 'uppercase',
          backgroundColor: isOptionDisabled
            ? null
            : isSelected
            ? theme['secondary-light-500']
            : isFocused
            ? theme['secondary-light-500']
            : null,
          color: isOptionDisabled
            ? '#ccc'
            : isSelected
            ? theme['primary-500']
            : data?.colour || '#4A4A4A',
          cursor: isOptionDisabled ? 'not-allowed' : 'default',
          ':active': {
            ...styles[':active'],
            backgroundColor:
              !isOptionDisabled &&
              (isSelected ? theme.primary : theme['secondary-light-500']),
          },
        }
      },
      input: (styles: CSSProperties) => ({ ...styles }),
      placeholder: (styles: CSSProperties) => ({
        ...styles,
        ...fontStyle,
      }),
      valueContainer: (styles: CSSProperties) => ({
        ...styles,
        color: theme['primary-500'],
      }),
      singleValue: (styles: CSSProperties) => ({
        ...styles,
        ...fontStyle,
        textTransform: 'capitalize',
        color: '#222529',
      }),
      multiValue: (
        styles: CSSProperties,
        { data }: { data: { isValid?: boolean } }
      ) => ({
        ...styles,
        backgroundColor: getInvalidStyle(data, '#ce354b', theme['primary-100']),
      }),
      multiValueLabel: (
        styles: CSSProperties,
        { data }: { data: { isValid?: boolean } }
      ) => ({
        ...styles,
        color: getInvalidStyle(data, '#fff', theme.primary),
      }),
      multiValueRemove: (
        styles: CSSProperties,
        { data }: { data: { isValid?: boolean } }
      ) => ({
        ...styles,
        color: getInvalidStyle(data, '#fff', theme.primary),
      }),
      dropdownIndicator: (
        styles: CSSProperties,
        { isDisabled: isOptionDisabled }: { isDisabled: boolean }
      ) => ({
        ...styles,
        padding: '0 3px',
        display: isOptionDisabled ? 'none' : 'block',
        borderLeft: withBorder ? '1px solid #ced4da' : 'none',
      }),
      clearIndicator: (styles: CSSProperties) => ({
        ...styles,
        padding: '5px',
      }),
      menu: (styles: CSSProperties) => ({
        ...styles,
        marginTop: '0.5px',
        ...(dropdownWithControl && {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }),
      }),
    }
  }, [bgColour, theme, withBorder, removeControl, dropdownWithControl])

  return { customStyles }
}

export default useSelectStyles
