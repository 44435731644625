import _ from 'lodash'
import { file2Base64, getFileNameAndExt } from 'helpers/utils'
import { Payload } from 'types/common'

export const getUploadPayload = async ({
  settings,
  uploadFile,
  environment,
  fileName,
}: {
  uploadFile: File
  settings: Payload
  environment: string
  fileName: string
}): Promise<Payload> => {
  const base64FileBody = await file2Base64(uploadFile)

  const UPLOAD_PATH_PREFIX = 'input/excel_uploads/'
  const Bucket = `${environment}-tasker`
  const module = 'awsS3'
  const operation = 'putObject'

  const configFile = {
    module,
    params: {
      data: {
        inputTemplate: {
          Bucket,
          Key: `${UPLOAD_PATH_PREFIX}${_.first(
            getFileNameAndExt(fileName)
          )}.json`,
          Body: {
            ...settings,
            enableBalance: settings?.minVisitPerWorker > 0,
          },
        },
      },
      operation,
      inputConverter: [
        '$data.inputTemplate',
        "$input ~> | $ | { 'Body': $string(Body) } |",
      ],
      outputConverter: "{ 'firstResponse': $s3Response }",
    },
  }

  const xlsx = {
    module,
    params: {
      data: {
        inputTemplate: {
          Bucket,
          Key: `${UPLOAD_PATH_PREFIX}${fileName}`,
          Body: base64FileBody,
        },
      },
      operation,
      inputConverter: [
        '$data.inputTemplate',
        "$input ~> | $ | { 'Body': $bufferFromBase64(Body) } |",
      ],
      outputConverter: "$row ~> | $ | { 'secondResponse': $s3Response } |",
    },
  }

  return {
    dataSource: {
      source: 'inline',
      rows: [
        {
          name: 'TRIGGER',
        },
      ],
    },
    postProcess: _.compact([configFile, xlsx]),
    dataTarget: [],
  }
}

export const getDownloadPayload = ({
  environment,
  fileName,
}: {
  environment: string
  fileName: string
}): Payload => {
  return {
    dataSource: {
      source: 'inline',
      rows: [
        {
          name: 'TRIGGER',
        },
      ],
    },
    postProcess: [
      {
        module: 'awsS3',
        params: {
          operation: 'getObject',
          data: {
            inputTemplate: {
              Bucket: `${environment}-tasker`,
              Key: `output/excel_uploads/multi_day_optimization_result/${fileName}_optimization_result.xlsx`,
            },
          },
          inputConverter: ['$data.inputTemplate'],
          outputConverter:
            "{ 'base64Data': $bufferToBase64($s3Response.Body) }",
          onError: 'emit',
        },
      },
    ],
    dataTarget: [],
  }
}
