import { ReactElement, useMemo, useCallback } from 'react'
import _ from 'lodash'
import DOMPurify from 'dompurify'
import styled from '@emotion/styled'

// constants
import { DEFAULT_WIDGET_COLOUR, WIDGET_TYPES } from 'constants/widget'
import {
  ASSET_PROFILE_RELATIONSHIP_TYPES,
  ASSET_PROFILE_MEDIA_TYPES,
} from 'constants/assets'
import { DEFAULT_AGGREGATION_KEY_TYPE } from 'constants/aggregation'
import NoAvailableWidget from 'components/widget/common/NoAvailableWidget'
import { THEMES } from 'constants/colour'

// components
import NumericWidget from 'components/widget/NumericWidget'
import { BaseWidgetWithAssetState } from 'components/assets/assetsProfile/widgets/BaseWidget'

// types
import type { PropertiesMetadata, ThemeType } from 'types/common'
import type {
  AssetProfile,
  AssetBaseWidget,
  BaseWidgetSettings,
  RelatedAssetWidgetSettings,
} from 'types/asset'

// utils
import { displayValue, getPropertiesMetadataKeyByName } from 'helpers/utils'
import { getSimpleAggregatedResultValueLabel } from 'helpers/widget'
import { getCompiledText } from './utils'

import scss from './index.module.scss'

type Property = { [key: string]: string }

type DisplaySingleValueProps = {
  properties: Property
  propertiesMetadata: PropertiesMetadata
  theme: ThemeType
} & BaseWidgetSettings

const Container = styled.div`
  p {
    margin-bottom: 0.1rem;
  }
  color: ${(props: { isLightTheme: boolean }) =>
    props.isLightTheme ? 'black' : 'white'};
`

const DisplaySingleValue = ({
  propertiesMetadata,
  properties,
  timezone,
  textTemplate = '',
  mediaType,
  theme,
}: DisplaySingleValueProps): ReactElement => {
  const propertiesMetadataKeyByName =
    getPropertiesMetadataKeyByName(propertiesMetadata)

  const displayedPropertiesValue = useMemo(() => {
    return _.reduce(
      properties,
      (acc, val, key) => {
        const { terms = {} } = propertiesMetadataKeyByName[key] || {}

        const newValue = terms[val]?.displayName || val

        return {
          ...acc,
          [key]: displayValue(newValue, timezone, false) || '[no data]',
        }
      },
      {}
    )
  }, [properties, propertiesMetadataKeyByName, timezone])

  const isPrintable = useMemo(
    () => mediaType === ASSET_PROFILE_MEDIA_TYPES.PRINTABLE,
    [mediaType]
  )

  const isLightTheme = useMemo(() => theme === THEMES.light, [theme])

  const safeHtml = useMemo(() => {
    const content = getCompiledText({
      template: textTemplate,
      data: { properties: displayedPropertiesValue },
      isPrintable,
    })
    return DOMPurify.sanitize(content.replace(/<p><br><\/p>/g, ''))
  }, [displayedPropertiesValue, isPrintable, textTemplate])

  return _.isEmpty(textTemplate) ? (
    <NoAvailableWidget widgetType={WIDGET_TYPES.text} content='no data' />
  ) : (
    // eslint-disable-next-line react/no-danger
    <Container
      dangerouslySetInnerHTML={{ __html: safeHtml }}
      isLightTheme={isLightTheme}
    />
  )
}

const DisplayAssetSingleValue = ({
  value,
  ...rest
}: {
  value: {
    properties: Property
    assetProfile: AssetProfile
  }
} & BaseWidgetSettings): ReactElement => {
  const { properties = {} } = value || {}
  const { properties: propertiesMetadata = [] } = value?.assetProfile || {}

  return (
    <DisplaySingleValue
      properties={properties}
      propertiesMetadata={propertiesMetadata}
      {...rest}
    />
  )
}

const DisplayRelatedAssetSingleValues = ({
  value,
  ...rest
}: {
  value: {
    related: {
      asset: {
        properties: Property
      }
    }
    assetProfile: AssetProfile
  }
} & BaseWidgetSettings): ReactElement => {
  const { properties } = value?.related?.asset || {}
  const { properties: propertiesMetadata = [] } = value?.assetProfile || {}

  return (
    <DisplaySingleValue
      properties={properties}
      propertiesMetadata={propertiesMetadata}
      {...rest}
    />
  )
}

const DisplayRelatedAssetsValues = ({
  value,
  colour = DEFAULT_WIDGET_COLOUR,
  overridePropertyTitle,
  relatedAssetAggregation = DEFAULT_AGGREGATION_KEY_TYPE,
  theme = THEMES.dark,
}: {
  value: {
    related: { assets: { properties: Property } }
  }
} & RelatedAssetWidgetSettings): ReactElement => {
  const { assets } = value?.related || {}

  const {
    value: data,
    label,
    widgetColour,
  } = getSimpleAggregatedResultValueLabel({
    geojsonRows: assets,
    aggregation: relatedAssetAggregation,
    overridePropertyTitle,
    colour,
  })

  return data ? (
    <NumericWidget
      colour={widgetColour}
      value={data}
      label={label}
      theme={theme}
    />
  ) : (
    <NoAvailableWidget widgetType={WIDGET_TYPES.text} content='no data' />
  )
}

const AssetTextWidget = ({
  assetId,
  name,
  settings,
  theme = THEMES.dark,
  mediaType,
  onWidgetLoad,
}: AssetBaseWidget & {
  mediaType: string
}): ReactElement => {
  const isPrintable = useMemo(
    () => mediaType === ASSET_PROFILE_MEDIA_TYPES.PRINTABLE,
    [mediaType]
  )

  const {
    relatedAssetRelationshipId,
    relatedAssetRelationshipType = ASSET_PROFILE_RELATIONSHIP_TYPES.MANY,
  } = settings

  const isAssetRelationshipMany = useMemo(
    () =>
      relatedAssetRelationshipType === ASSET_PROFILE_RELATIONSHIP_TYPES.MANY,
    [relatedAssetRelationshipType]
  )

  const render = useCallback(
    props => {
      const {
        overridePropertyTitle,
        relatedAssetAggregation,
        property,
        colour,
        textTemplate,
      } = settings

      const sharedProps = {
        ...props,
        property,
        overridePropertyTitle,
        colour,
        theme,
        mediaType,
      }

      if (relatedAssetRelationshipId) {
        return isAssetRelationshipMany ? (
          <DisplayRelatedAssetsValues
            {...sharedProps}
            relatedAssetRelationshipId={relatedAssetRelationshipId}
            relatedAssetAggregation={relatedAssetAggregation}
          />
        ) : (
          <DisplayRelatedAssetSingleValues
            {...sharedProps}
            relatedAssetRelationshipId={relatedAssetRelationshipId}
            textTemplate={textTemplate}
          />
        )
      }

      return (
        <DisplayAssetSingleValue {...sharedProps} textTemplate={textTemplate} />
      )
    },
    [
      isAssetRelationshipMany,
      mediaType,
      relatedAssetRelationshipId,
      settings,
      theme,
    ]
  )

  return (
    <BaseWidgetWithAssetState
      assetId={assetId}
      settings={settings}
      render={render}
      name={name}
      theme={theme}
      className={
        relatedAssetRelationshipId && isAssetRelationshipMany ? '' : scss.text
      }
      onWidgetLoad={onWidgetLoad}
      isPrintable={isPrintable}
    />
  )
}

export default AssetTextWidget
