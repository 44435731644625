import { ReactElement, MouseEvent, KeyboardEvent, FocusEvent } from 'react'
import { utils } from '@rjsf/core'
import { JSONSchema7 } from 'json-schema'
import { Grid, FormControl, InputLabel, Input } from '@mui/material'
import { IconButton } from 'components/common'

const { ADDITIONAL_PROPERTY_FLAG } = utils

type WrapIfAdditionalProps = {
  children: React.ReactElement
  classNames: string
  disabled: boolean
  id: string
  label: string
  onDropPropertyClick: (index: string) => (event?: MouseEvent) => void
  onKeyChange: (index: string) => (event?: KeyboardEvent) => void
  readonly: boolean
  required: boolean
  schema: JSONSchema7
}

const WrapIfAdditional = ({
  children,
  disabled,
  id,
  label,
  onDropPropertyClick,
  onKeyChange,
  readonly,
  required,
  schema,
}: WrapIfAdditionalProps): ReactElement => {
  const keyLabel = `${label} Key`
  const additional = Object.prototype.hasOwnProperty.call(
    schema,
    ADDITIONAL_PROPERTY_FLAG
  )

  const btnStyle = {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: 'bold',
  }

  if (!additional) {
    return <>{children}</>
  }

  const handleBlur = ({ target }: FocusEvent<HTMLInputElement>) =>
    onKeyChange(target.value)

  return (
    <Grid container key={`${id}-key`} alignItems='center' spacing={2}>
      <Grid item xs>
        <FormControl fullWidth required={required}>
          <InputLabel>{keyLabel}</InputLabel>
          <Input
            defaultValue={label}
            disabled={disabled || readonly}
            id={`${id}-key`}
            name={`${id}-key`}
            onBlur={!readonly ? handleBlur : undefined}
            type='text'
          />
        </FormControl>
      </Grid>
      <Grid item xs>
        {children}
      </Grid>
      <Grid item>
        <IconButton
          icon='mdClose'
          style={btnStyle}
          disabled={disabled || readonly}
          onClick={onDropPropertyClick(label)}
        />
      </Grid>
    </Grid>
  )
}

export default WrapIfAdditional
