// libraries
import { ReactElement, useCallback } from 'react'

// constants
import { WEEK_DAYS } from 'components/workflow/Canvas/panels/PropertyPanel/common/DateTimePicker/constants'

import { DaysOptions } from 'components/workflow/Canvas/panels/PropertyPanel/common/DateTimePicker/types'

// components
import ButtonGroup, { ButtonGroupOnChange } from 'components/common/ButtonGroup'
import { BUTTON_SIZES } from 'components/common/Button'

type WeekProps = {
  daysOfWeek: number[] | undefined
  daysOptions: DaysOptions[]
  isDisabled: boolean
  onChange: (v: { weeklyWeekdays: string[] }) => void
}

const Week = ({
  daysOfWeek,
  daysOptions,
  isDisabled,
  onChange,
}: WeekProps): ReactElement => {
  const onDaysOfWeekChange = useCallback(
    (days: number[]) => {
      const weekDays = days.map(day => WEEK_DAYS[day - 1])
      onChange({ weeklyWeekdays: weekDays })
    },
    [onChange]
  )

  return (
    <>
      <div className='col-sm-12'>
        <div className='row mb-0 mt-1'>
          <div className='col-sm-12'>
            <span style={{ fontSize: 13, fontWeight: 'bold' }}>
              On these days
            </span>
          </div>
        </div>
        <div className='row mt-1 mb-1'>
          <div className='col-sm-12'>
            <ButtonGroup
              selectedValue={daysOfWeek || []}
              buttonOptions={daysOptions}
              onChange={onDaysOfWeekChange as ButtonGroupOnChange}
              size={BUTTON_SIZES.small}
              isDisabled={isDisabled}
            />
          </div>
        </div>
        <div className='row ml-0 mr-0'>
          <div className='col-sm-12'>
            <hr />
          </div>
        </div>
      </div>
    </>
  )
}

export default Week
