// libraries
import { useCallback, useMemo, memo, PropsWithChildren } from 'react'
import _ from 'lodash'
import styled from '@emotion/styled'

// constants
import { TIME_RANGE_MODES, PROPERTY_VARIABLE_FORMATS } from 'constants/filter'
import {
  SPEC_PARAMETERS_TYPES,
  SPEC_PARAMETERS_FORMATS,
} from 'constants/common'

// utils
import { isRequiredKey } from 'helpers/unipipe'
import { switchcaseF, sanitizeString, isRangeValid } from 'helpers/utils'
import { useTimezone } from 'hooks'
import { getTimeRangeString } from 'helpers/datetime'
import { reportMessage } from 'helpers/log'
import { getResultForMultiSelect } from 'helpers/issue'
import useImageCarousel from 'components/common/Image/useImageCarousel'

// components
import {
  TitleWithTooltip,
  NumericInput,
  TextInput,
  Toggle,
  MultiSelect,
  DateTimePicker,
  RangeSlider,
} from 'components/common'
import AbsoluteTimePicker from 'components/common/DateTimePicker/AbsoluteTimePicker'

import type {
  Payload,
  SpecificationParameterOption,
  SpecificationParameters,
  SpecificationParameterValue,
} from 'types/common'

// scss
import scss from './index.module.scss'

const DatePickerStyled = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      width: 100%;
    }
    .react-datepicker__input-container input {
      width: inherit;
      height: 100%;
      border: none;
      outline: none;
      height: 31px;
      font-size: 12px;
      padding-left: 8px;
    }
  }
  .react-datepicker {
    padding: 16px 16px 16px 0;
  }
`

const CalendarContainer = ({
  className,
  children,
}: PropsWithChildren<{ className: string }>) => {
  return (
    <div className={className}>
      <div style={{ position: 'relative' }}>{children}</div>
    </div>
  )
}

const SpecificationParametersList = ({
  // The specifications for rendering backend-oriented filtering controls
  specificationParameters = {},
  // The key-value pairs of the specification parameters
  specParams = {},
  imageResources = [],
  onChange,
  disabled = false,
  cachedSpecParams = {},
  keepQuickSelectOpen = false,
  labelClassName,
}: {
  specificationParameters?: SpecificationParameters
  cachedSpecParams?: SpecificationParameters
  specParams: Payload
  imageResources: []
  onChange: (val: Payload) => void
  disabled?: boolean
  keepQuickSelectOpen?: boolean
  labelClassName?: string
}) => {
  const { timezone, timezoneAbbr } = useTimezone()
  const { renderCarousel, renderImages } = useImageCarousel({ imageResources })

  const { startTime, endTime, ...restSpecificationParameters } =
    specificationParameters

  const timeParametersList = useMemo(() => {
    if (!startTime || !endTime) return undefined

    const datetimeRangeKey = getTimeRangeString({
      start: cachedSpecParams?.startTime,
      end: cachedSpecParams?.endTime,
    })

    return (
      <>
        <TitleWithTooltip title={`Date Time Range (${timezoneAbbr})`} />
        <DateTimePicker
          key={datetimeRangeKey}
          timezone={timezone}
          startTime={{
            value: cachedSpecParams?.startTime,
            mode: TIME_RANGE_MODES.absoluteTime,
          }}
          endTime={{
            value: cachedSpecParams?.endTime,
            mode: TIME_RANGE_MODES.absoluteTime,
          }}
          onChange={onChange}
          keepQuickSelectOpen={keepQuickSelectOpen}
        />
      </>
    )
  }, [
    cachedSpecParams?.endTime,
    cachedSpecParams?.startTime,
    endTime,
    keepQuickSelectOpen,
    onChange,
    startTime,
    timezone,
    timezoneAbbr,
  ])

  const renderSpecParameter = useCallback(
    (propertyName: string, specs: SpecificationParameterValue) => {
      const { type, necessity, format, ...restSpecs } = specs

      const onValueChange = (value: unknown) => {
        onChange({ [propertyName]: value })
      }

      const sharedProps = {
        ...restSpecs,
        value: specParams[propertyName],
        onChange: onValueChange,
        className: 'form-control',
        disabled,
      }

      const renderNumber = () => {
        if (isRangeValid(specs?.range)) {
          const props = _.pick(sharedProps, [
            'range',
            'defaultValue',
            'step',
            'value',
            'onChange',
            'disabled',
          ])

          return <RangeSlider {...props} noGroupStyle />
        }

        return <NumericInput canEmpty {...sharedProps} />
      }

      const renderString = () => {
        if (_.includes([SPEC_PARAMETERS_FORMATS.dateTime], format)) {
          return (
            <DatePickerStyled>
              <AbsoluteTimePicker
                timezone={timezone}
                onChange={onValueChange}
                selectedTime={specParams[propertyName] as string}
                inline={false}
                showTimeSelect
                calendarContainer={CalendarContainer}
                dateFormat='MMMM d, yyyy h:mm aa'
              />
            </DatePickerStyled>
          )
        }

        if (format === PROPERTY_VARIABLE_FORMATS.image) {
          return renderImages(specParams[propertyName] as [])
        }

        return <TextInput {...sharedProps} />
      }

      const renderBoolean = () => {
        const checked = Boolean(specParams[propertyName])
        const { displayName, ...rest } = sharedProps
        return (
          <Toggle
            {...rest}
            label={displayName}
            checked={checked}
            onToggle={() =>
              onChange({
                [propertyName]: !checked,
              })
            }
          />
        )
      }

      const renderEnum = () => {
        const optionKey = 'key'
        const { isMulti = false, options, separator = ' ' } = specs
        const { creatable = true, value } = sharedProps

        const newValue = getResultForMultiSelect({
          value,
          isMulti,
          separator,
        })

        const additionalOptions =
          isMulti ||
          !creatable ||
          _.isNil(value) ||
          _.find(options, { [optionKey]: value })
            ? []
            : [{ [optionKey]: value }]

        const optionsWithDefaultedDisplayNames = _.map(
          [...options, ...additionalOptions] as SpecificationParameterOption[],
          option => ({
            ...option,
            label: option.displayName || option[optionKey],
            value: option[optionKey],
          })
        )

        return (
          <MultiSelect
            isMulti={isMulti}
            placeholder='Select ...'
            isClearable={!isRequiredKey(necessity)}
            {...sharedProps}
            value={newValue}
            className=''
            creatable={creatable}
            options={optionsWithDefaultedDisplayNames}
            onChange={(optionValue: unknown) => {
              onChange({ [propertyName]: optionValue || '' })
            }}
            useOptionValueOnly
            isDisabled={disabled}
          />
        )
      }
      return switchcaseF({
        [SPEC_PARAMETERS_TYPES.number]: renderNumber,
        [SPEC_PARAMETERS_TYPES.string]: renderString,
        [SPEC_PARAMETERS_TYPES.boolean]: renderBoolean,
        [SPEC_PARAMETERS_TYPES.enum]: renderEnum,
      })(() => {
        reportMessage(
          `The spec parameters type-${type} is not supported yet`,
          'log',
          { specs }
        )
      })(type)
    },
    [disabled, onChange, renderImages, specParams, timezone]
  )

  const nonTimeParametersList = useMemo(
    () =>
      _.compact(
        _.map(restSpecificationParameters, (specs, dKey) => {
          const { necessity, displayName, hint, visible = true } = specs
          if (!visible) return undefined

          const title = `${sanitizeString(displayName) || dKey} ${
            isRequiredKey(necessity) ? '*' : ''
          }`

          const tooltip = sanitizeString(hint)
          return (
            <div key={dKey} className={scss.row}>
              <TitleWithTooltip
                title={title}
                tooltip={tooltip}
                className={labelClassName}
              />
              {renderSpecParameter(dKey, specs)}
            </div>
          )
        })
      ),
    [labelClassName, renderSpecParameter, restSpecificationParameters]
  )

  return (
    <>
      <div data-testid='specificationParametersList'>
        {timeParametersList}
        {nonTimeParametersList}
      </div>
      {renderCarousel()}
    </>
  )
}

export default memo(SpecificationParametersList)
