// libraries
import { ReactElement, useCallback, useMemo } from 'react'
import _ from 'lodash'

// constants
import {
  BUTTON_VARIANTS,
  BUTTON_ICON_POSITIONS,
} from 'components/common/Button'
import {
  BADGE_TYPES,
  GALLERY_LIST_TYPES,
  GALLERY_LIST_TYPES_ICONS,
} from 'constants/common'
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// components
import {
  SortButton,
  SearchBar,
  IconButton,
  Button,
  Badge,
  Dropdown,
  TableColumnToggler,
} from 'components/common'

import type { Options, Payload } from 'types/common'
import type {
  ColumnsWithGroupable,
  ColumnOptions,
} from 'components/common/DataTable/useDataTableColumns'

import scss from './index.module.scss'

const ICON_SIZE = 16

type Conditions = {
  search: string
  isSearching: boolean
  selectedSearchFields: string[]
  sortProperty: string
  ascOrder: boolean
  listType: string
  tableGroupedBy?: string
  sortField: string
}

type ToolboxProps = {
  onChange: (v: Conditions) => void
  onRefresh: () => void
  options: {
    enableSearch: boolean
    enableListTypes: string[]
    sortOptions: Options
    displaySort: boolean
    searchBarPlaceholder: string
    changeOnBlur: boolean
    enableSearchFields: boolean
    searchFieldsLoading: boolean
    searchFieldsOptions: { value: string; label: string }[]
    tableGroupableColumns: ColumnsWithGroupable
  }
  conditions: Conditions
  filtersCount?: number
  toggleShowFilters?: (v: boolean) => void
  isShowingFilters?: boolean
  columns?: ColumnOptions[]
  visibleColumns?: ColumnOptions[]
  setVisibleColumns?: (columns: ColumnOptions[]) => void
}

export const TABLE_GROUP_BY_OPTION = {
  label: 'None',
  value: 'default_none_table_group_by',
}

const Toolbox = ({
  conditions,
  options,
  onChange,
  onRefresh,
  filtersCount = 0,
  toggleShowFilters,
  isShowingFilters,
  columns,
  visibleColumns,
  setVisibleColumns,
}: ToolboxProps): ReactElement => {
  const {
    enableSearch = true,
    enableListTypes = [GALLERY_LIST_TYPES.card, GALLERY_LIST_TYPES.table],
    sortOptions = [],
    searchBarPlaceholder,
    changeOnBlur = false,
    enableSearchFields = false,
    searchFieldsLoading = false,
    searchFieldsOptions = [],
    displaySort = true,
    tableGroupableColumns,
  } = options

  const {
    search = '',
    selectedSearchFields = [],
    sortField = _.get(sortOptions, '[0].value]') || 'audit.updatedTime',
    ascOrder = true,
    listType = GALLERY_LIST_TYPES.card,
    tableGroupedBy,
  } = conditions

  const onChangeConditions = useCallback(
    (payload: Payload) => {
      onChange({ ...conditions, ...payload })
    },
    [conditions, onChange]
  )

  const visibleListType = useMemo(() => {
    return _.without(enableListTypes, listType)
  }, [enableListTypes, listType])

  const columnsOptions = useMemo(
    () =>
      _.map(tableGroupableColumns, ({ header, field }) => ({
        label: header,
        value: field,
      })),
    [tableGroupableColumns]
  )

  const renderSearchBar = () =>
    enableSearch && (
      <SearchBar
        filter={search}
        onChange={({ selectedFields, value }) => {
          onChangeConditions({
            search: value,
            selectedSearchFields: selectedFields,
            isSearching: !!value,
          })
        }}
        onClear={() => onChangeConditions({ search: '', isSearching: false })}
        placeholder={searchBarPlaceholder}
        className='me-1'
        changeOnBlur={changeOnBlur}
        enableSearchFields={enableSearchFields}
        selectedFields={selectedSearchFields}
        fieldsLoading={searchFieldsLoading}
        fieldsOptions={searchFieldsOptions}
      />
    )

  const renderSortBy = () =>
    !_.isEmpty(sortOptions) &&
    displaySort &&
    listType !== GALLERY_LIST_TYPES.table && (
      <SortButton
        className='mx-2'
        sortOptions={sortOptions}
        property={sortField}
        onChange={val => onChangeConditions({ sortField: val })}
        ascSortOrder={ascOrder}
        onOrderChange={val => onChangeConditions({ ascOrder: val })}
      />
    )

  const renderFilterToggle = () =>
    _.isFunction(toggleShowFilters) && (
      <Button
        className={`${scss.toolbarButton} ${
          isShowingFilters ? '' : 'text-secondary'
        } p-2 me-2`}
        variant={BUTTON_VARIANTS.link}
        onClick={() => toggleShowFilters(!isShowingFilters)}
        icon='MdFilterAlt'
        iconPosition={BUTTON_ICON_POSITIONS.left}
      >
        <span>Filter</span>
        {filtersCount > 0 && (
          <Badge
            content={filtersCount}
            className='ms-2'
            type={BADGE_TYPES.primary}
          />
        )}
      </Button>
    )

  const renderListTypeSwither = () =>
    visibleListType.map(type => {
      return (
        <Button
          key={type}
          className={`${scss.toolbarButton} text-secondary p-2 me-2`}
          variant={BUTTON_VARIANTS.link}
          onClick={() => onChangeConditions({ listType: type })}
          icon={GALLERY_LIST_TYPES_ICONS[type]}
          iconPosition={BUTTON_ICON_POSITIONS.left}
        >
          {_.capitalize(type)} view
        </Button>
      )
    })

  const renderTableGroupBy = () =>
    !_.isEmpty(tableGroupableColumns) &&
    listType === GALLERY_LIST_TYPES.table && (
      <div className='d-flex align-items-enter me-2'>
        <Dropdown
          toggleComponent={() => {
            return (
              <Button
                className={`${scss.toolbarButton} text-secondary p-2`}
                variant={BUTTON_VARIANTS.link}
                icon='TableGroupBy'
                iconPosition={BUTTON_ICON_POSITIONS.left}
              >
                <>
                  Group by{' '}
                  {_.find(tableGroupableColumns, { field: tableGroupedBy })
                    ?.header || ''}
                </>
              </Button>
            )
          }}
          placement={TOOLTIP_PLACEMENT.bottom}
          options={
            tableGroupedBy
              ? [TABLE_GROUP_BY_OPTION, ...columnsOptions]
              : columnsOptions
          }
          onChange={(v: string) =>
            onChangeConditions({
              tableGroupedBy: v === TABLE_GROUP_BY_OPTION.value ? undefined : v,
            })
          }
        />
      </div>
    )

  const renderListRefetch = () =>
    onRefresh && (
      <IconButton
        icon='MdRefresh'
        className={scss.refreshBtn}
        size={ICON_SIZE}
        onClick={() => onRefresh()}
      />
    )

  const renderTableColumnToggler = () =>
    listType === GALLERY_LIST_TYPES.table &&
    !!columns?.length && (
      <TableColumnToggler
        className={`${scss.toolbarButton} me-2`}
        columns={columns}
        visibleColumns={visibleColumns as ColumnOptions[]}
        setVisibleColumns={
          setVisibleColumns as (selectedOptions: ColumnOptions[]) => void
        }
      />
    )

  return (
    <div className='d-flex align-items-center'>
      {renderSearchBar()}
      {renderSortBy()}
      {renderFilterToggle()}
      {renderTableGroupBy()}
      {renderTableColumnToggler()}
      {renderListTypeSwither()}
      {renderListRefetch()}
    </div>
  )
}

Toolbox.defaultProps = {
  onRefresh: undefined,
  conditions: {},
  options: {},
}

export default Toolbox
