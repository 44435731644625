// libraries
import { useCallback, ReactElement } from 'react'
import { useToggle, useUpdateEffect } from 'react-use'
import { useBus } from 'ts-bus/react'

// utils
import { toggleMainNav } from 'helpers/eventBus'

// component
import { Sidebar, IconButton } from 'components/common'
import MainContentLayout from '../MainContentLayout'

import scss from './index.module.scss'

const MainLayout = ({
  children,
  options = {},
}: {
  children: ReactElement
  options?: {
    showSideBar: boolean
    showToggle: boolean
  }
}): ReactElement => {
  const { showSideBar = true, showToggle = false } = options || {}
  const bus = useBus()
  const [isSideBarVisible, toggleSideBarVisible] = useToggle(showSideBar)
  const toggleSidebar = useCallback(() => {
    const newIsSideBarVisible = !isSideBarVisible
    toggleSideBarVisible(newIsSideBarVisible)
    bus.publish(toggleMainNav(newIsSideBarVisible))
  }, [bus, toggleSideBarVisible, isSideBarVisible])

  useUpdateEffect(() => {
    toggleSideBarVisible(showSideBar)
  }, [showSideBar])

  return (
    <div className={scss.app}>
      {isSideBarVisible && <Sidebar />}
      {showToggle && (
        <IconButton
          icon={isSideBarVisible ? 'RiMenu3Line' : 'RiMenu2Line'}
          size={16}
          onClick={toggleSidebar}
          style={{
            left: isSideBarVisible ? '72px' : 0,
          }}
          className={scss.button}
        />
      )}
      <MainContentLayout>{children}</MainContentLayout>
    </div>
  )
}

export default MainLayout
