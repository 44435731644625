// libraries
import { useMemo, ReactElement, useCallback } from 'react'
import _ from 'lodash'

// utils
import { displayValue, switchcase } from 'helpers/utils'
import useImageCarousel from 'components/common/Image/useImageCarousel'
import { useTimezone } from 'hooks'

// components
import { IconButton } from 'components/common'

// constants
import { NO_AVAILABLE_DATA } from 'constants/common'
import { FORM_QUESTION_TYPES } from 'constants/formBuilder'

import type { UtcISOString } from 'types/datetime'
import type { DataCollectionFormImageView, JSONFormBody } from 'types/issue'

type PropertyValue = string | number | boolean | [] | UtcISOString
type Property = {
  label: string
  value: PropertyValue
  questionType: string
  questionProps?: JSONFormBody
}

type IssueFormResultDisplayProps = {
  result: Property
  views: DataCollectionFormImageView[]
}

const IssueFormResultDisplay = ({
  result,
  views,
}: IssueFormResultDisplayProps): ReactElement => {
  const { value, questionType, questionProps } = result
  const { timezone } = useTimezone()

  const { renderCarousel, renderImages } = useImageCarousel({
    imageResources: views,
  })

  const renderCheckboxList = useCallback(
    (selected: string[]): ReactElement => {
      const { schema: { items: { enum: list } = {} } = {} } = questionProps

      return (
        <>
          {_.map(list, item => {
            const isSelected = _.includes(selected, item)
            return (
              <div key={item}>
                <IconButton
                  icon={isSelected ? 'MdCheckBox' : 'MdCheckBoxOutlineBlank'}
                  size={16}
                />
                {item}
              </div>
            )
          })}
        </>
      )
    },
    [questionProps]
  )

  const display = useMemo(() => {
    if (_.isNil(value)) return NO_AVAILABLE_DATA
    if (questionType === FORM_QUESTION_TYPES.DATETIME) {
      return displayValue(value, timezone)
    }

    const fn = switchcase({
      [FORM_QUESTION_TYPES.IMAGE_UPLOADER]: renderImages,
      [FORM_QUESTION_TYPES.CHECKBOX_LIST]: renderCheckboxList,
    })(displayValue)(questionType)

    return fn(value)
  }, [questionType, renderCheckboxList, renderImages, timezone, value])

  return (
    <>
      {display}
      {renderCarousel()}
    </>
  )
}

export default IssueFormResultDisplay
