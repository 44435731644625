// libraries
import { ReactElement } from 'react'
import { useTimezone } from 'hooks'

import type { FieldInputProps } from 'react-final-form'
import type { Repeat } from 'components/workflow/Canvas/panels/PropertyPanel/common/DateTimePicker/types'

// components
import { RepeatOptions } from 'components/workflow/Canvas/panels/PropertyPanel/common/DateTimePicker'
import StyledField from '../StyledField'

import scss from './index.module.scss'

type SimpleScheduleFieldType = {
  input: FieldInputProps<Repeat>
  label: string
  tooltip: string
  required: boolean
  isDisabled: boolean
  description: string
}

const SimpleScheduleField = ({
  input,
  label,
  tooltip,
  required,
  isDisabled,
  description,
}: SimpleScheduleFieldType): ReactElement => {
  const { timezoneAbbr } = useTimezone()
  const { name, value: InitialValue, onChange } = input
  const sectionTitle = `${label} (${timezoneAbbr})`

  return (
    <StyledField
      label={sectionTitle}
      tooltip={tooltip}
      name={name}
      required={required}
      description={description}
    >
      <div className={scss.container}>
        <RepeatOptions
          key={name}
          repeat={InitialValue}
          onChange={(schedule: Repeat) => {
            onChange(schedule)
          }}
          isDisabled={isDisabled}
        />
      </div>
    </StyledField>
  )
}

export default SimpleScheduleField
