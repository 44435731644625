/** @jsxImportSource @emotion/react */
// libraries
// eslint-disable-next-line no-unused-vars
import { jsx, useTheme } from '@emotion/react'
import React, { useMemo, forwardRef } from 'react'
import PropTypes from 'prop-types'

// utilities
import { getActiveStatus } from 'helpers/utils'

// utils
import scss from './index.module.scss'

const ListGroupItem = forwardRef(
  (
    { bgColour, borderLeft, className, children, active, testId, ...rest },
    ref
  ) => {
    const theme = useTheme()

    const overwrite = useMemo(() => {
      const brandingStyle = theme.primary && {
        border: 'none',
        borderRadius: '4px !important',
        padding: '0px 3px !important',
        color: active ? theme.primary : '#000000',
        fontWeight: active ? 600 : 'normal',
        ...(bgColour
          ? {
              border: 'none',
              backgroundColor: theme.primary,
              color: '#fff',
            }
          : {
              '&:hover,&:focus,&:active,&:disabled': {
                backgroundColor: theme['secondary-light-500'],
                color: active ? theme.primary : '#000000',
              },
              backgroundColor: active
                ? theme['secondary-light-500']
                : '#f3f3f3',
            }),
      }
      return { ...brandingStyle }
    }, [active, bgColour, theme])

    return (
      <li
        ref={ref}
        className={`list-group-item d-flex flex-row align-items-center ${scss.listGroupItem} ${className}`}
        css={overwrite}
        data-testid={testId}
        aria-label={getActiveStatus(active)}
        {...rest}
      >
        {borderLeft && (
          <span
            className={scss.borderLeftBar}
            style={{
              backgroundColor: active ? theme.primary : 'transparent',
            }}
          />
        )}
        {children}
      </li>
    )
  }
)

ListGroupItem.propTypes = {
  bgColour: PropTypes.bool,
  borderLeft: PropTypes.bool,
  active: PropTypes.bool,
  testId: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}

ListGroupItem.defaultProps = {
  bgColour: false,
  borderLeft: true,
  active: true,
  testId: 'list-group-item',
  className: undefined,
  children: null,
}

export default ListGroupItem
