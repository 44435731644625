import { ReactElement, useState, useMemo } from 'react'
import _ from 'lodash'

// components
import { StyledField } from 'components/common/Form'
import { RichTextEditor, MultiSelect } from 'components/common'

// types
import type { EditorProps } from 'components/common/RichTextEditor'
import type { AssetProfileOption } from 'types/workflow'
import type { AssetProfileId } from 'types/asset'
import type { PropertiesMetadata, Option } from 'types/common'

const FormRichEditor = ({
  input,
  label,
  required = false,
  checkable = false,
  tooltip,
  onChecked,
  options,
  assetProfile,
  assetProfileOptions = [],
  readOnly,
  ...rest
}: {
  input: {
    onChange: (v: string) => void
    name: string
    value: string
  }
  label?: string
  required?: boolean
  checkable?: boolean
  tooltip?: string
  onChecked?: (isChecked: boolean) => void
  options: PropertiesMetadata
  assetProfile: AssetProfileId
  assetProfileOptions: AssetProfileOption[]
  readOnly: boolean
} & EditorProps): ReactElement => {
  const { onChange, name, value } = input
  const [selectedAssetProfile, setSelectedAssetProfile] =
    useState<AssetProfileId>(assetProfile)

  const disablePropertyPickerOrDisableAssetProfilePicker = useMemo(
    () => _.isEmpty(options) || !assetProfile,
    [assetProfile, options]
  )

  const suggestions = useMemo(() => {
    if (disablePropertyPickerOrDisableAssetProfilePicker) return options

    return assetProfileOptions.reduce<PropertiesMetadata>(
      (acc, profile) => [...acc, ...(profile.propertyOptions || [])],
      []
    )
  }, [
    assetProfileOptions,
    disablePropertyPickerOrDisableAssetProfilePicker,
    options,
  ])

  const popoverSuggestions = useMemo(() => {
    if (disablePropertyPickerOrDisableAssetProfilePicker) return options

    return _.get(
      _.find(assetProfileOptions, { value: selectedAssetProfile }),
      'propertyOptions'
    )
  }, [
    assetProfileOptions,
    disablePropertyPickerOrDisableAssetProfilePicker,
    options,
    selectedAssetProfile,
  ])

  return (
    <StyledField
      required={required}
      label={label}
      checkable={checkable}
      onChecked={onChecked}
      tooltip={tooltip}
      name={name}
    >
      {!disablePropertyPickerOrDisableAssetProfilePicker && (
        <MultiSelect
          value={selectedAssetProfile}
          options={assetProfileOptions}
          onChange={({ value: newAssetProfile }: Option) =>
            setSelectedAssetProfile(newAssetProfile)
          }
          isMulti={false}
          isClearable={false}
          placeholder='Please select asset profile'
          withBorder
          isDisabled={readOnly}
        />
      )}
      <RichTextEditor
        {...rest}
        value={value}
        onChange={onChange}
        popoverSuggestions={popoverSuggestions}
        suggestions={suggestions}
        readOnly={readOnly}
      />
    </StyledField>
  )
}

export default FormRichEditor
