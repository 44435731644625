/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import { jsx } from '@emotion/react'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement

// constants
import { TIME_RANGE_MODES } from 'constants/filter'
import { THEMES } from 'constants/colour'

// utils
import { useBranding } from 'hooks'
import { displayTime, switchcaseF } from 'helpers/utils'

const DatetimeAnchor = ({
  value,
  toggleActive,
  isRangeValid,
  timezone,
  theme,
}) => {
  const {
    colour: { primary },
  } = useBranding()

  const { value: timeValue, mode } = value

  const displayValue = useMemo(
    () =>
      switchcaseF({
        [TIME_RANGE_MODES.relativeTime]: () => timeValue,
        [TIME_RANGE_MODES.absoluteTime]: () =>
          displayTime({
            datetime: timeValue,
            timezone,
            addFromNow: false,
            displayLabel: false,
          }),
        [TIME_RANGE_MODES.now]: () => TIME_RANGE_MODES.now,
      })(() => timeValue)(mode),
    [timezone, mode, timeValue]
  )

  const isLightTheme = useMemo(() => theme === THEMES.light, [theme])

  const inputCss = useMemo(
    () => ({
      ...(isRangeValid
        ? {
            backgroundColor: 'transparent',
            color: isLightTheme ? '#000' : '#fff',
          }
        : {
            backgroundColor: isLightTheme ? '#f8e9e9' : '#d3d3d3',
            color: isLightTheme ? '#b4251d' : '#ce354b',
            borderBottom: '1px solid #b4251d',
          }),
      fontSize: '10px',
      border: 'none',
      padding: '0',
      '&:focus': {
        borderBottom: `1px solid ${primary}`,
        outline: 'none',
      },
    }),
    [isRangeValid, primary, isLightTheme]
  )

  return (
    <input
      className='w-50'
      onClick={toggleActive}
      value={displayValue}
      readOnly
      css={inputCss}
      data-testid='time-value'
      type='button'
      data-valid={isRangeValid}
    />
  )
}

DatetimeAnchor.propTypes = {
  value: PropTypes.shape({
    mode: PropTypes.string,
    value: PropTypes.string,
  }),
  toggleActive: PropTypes.func.isRequired,
  isRangeValid: PropTypes.bool,
  timezone: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
}

DatetimeAnchor.defaultProps = {
  value: {},
  isRangeValid: true,
  timezone: null,
  theme: THEMES.light,
}

export default DatetimeAnchor
