// libraries
import { useState, useCallback, useEffect, useMemo } from 'react'
import { FlyToInterpolator } from 'deck.gl'
import { useUpdateEffect, useDebounce, useSetState } from 'react-use'
import { useBus } from 'ts-bus/react'
import _ from 'lodash'
import { featureCollection } from '@turf/helpers'
import { useSize } from 'ahooks'

// utils
import { getMapZoom, getFeaturesViewport, getLayersViewport } from 'helpers/map'
import { saveMapViewState, onMapZoomChange } from 'helpers/eventBus'
import { useQueryParameters } from 'hooks'
import { arrayOfStringToArrayOfNumber } from 'helpers/utils'

// contexts
import { useStateValue, useMapStateValue } from 'contexts'

// constants
import { INITIAL_MAP_STATE, MAP_VIEW_STATE_ACTIONS } from 'constants/map'
import { MAP_VIEWPORT_BOUNDS_DEBOUNCE_DELAY } from 'constants/common'

import type { GeojsonData, MapLayer, MapViewState, Viewport } from 'types/map'
import type { QueryParams } from 'types/services'

const VIEW_STATE_CORE = [
  'altitude',
  'bearing',
  'latitude',
  'longitude',
  'pitch',
  'zoom',
]

const getViewState = (
  queryParams: QueryParams,
  initialViewState?: MapViewState
): MapViewState => {
  const { view } = queryParams
  if (_.isEmpty(view) && _.isEmpty(initialViewState)) return INITIAL_MAP_STATE

  const [latitude, longitude, zoom, pitch, bearing] =
    arrayOfStringToArrayOfNumber(view)

  return _.pick(
    {
      ...(initialViewState || {}),
      ..._.pickBy({ latitude, longitude, zoom, pitch, bearing }, _.isNumber),
    },
    VIEW_STATE_CORE
  )
}
/**
 * Responsible for managing map view state
 */
const useMapViewState = ({
  mapRef,
  mapCanvasRef,
  enableEventBus = false,
  enableAnimation = false,
}: Partial<{
  mapRef: React.MutableRefObject<HTMLElement | undefined>
  mapCanvasRef: React.MutableRefObject<HTMLElement | undefined>
  enableEventBus: boolean
  enableAnimation: boolean
}> = {}): {
  viewState: MapViewState
  setViewState: (
    patch:
      | Partial<MapViewState>
      | ((prevState: MapViewState) => Partial<MapViewState>)
  ) => void
  resetViewState: () => void
  onViewStateChange: (props: { viewState: MapViewState }) => void
  fitFeatureBound: (feature: GeojsonData, updateZoom?: boolean) => void
  fitFeaturesBound: (features: GeojsonData[], updateZoom?: boolean) => void
  mapLoaded: boolean
  setMapLoaded: React.Dispatch<React.SetStateAction<boolean>>
  currentZoom: number
} => {
  const bus = useBus()

  const {
    state: {
      unipipeState: { catalog },
    },
  } = useStateValue()

  const {
    map,
    updateMapConfigs,
    zoomToLayer,
    setZoomToLayer,
    zoomToMap,
    setZoomToMap,
    setViewportBounds,
  } = useMapStateValue()

  const { queryParams } = useQueryParameters()

  const { viewState: initialViewState } = map

  const mapCanvasSize = useSize(mapCanvasRef)

  const [viewState, setViewState] = useSetState<MapViewState>(() =>
    getViewState(queryParams, initialViewState)
  )

  const [mapLoaded, setMapLoaded] = useState(false)

  const updateViewState = useCallback((viewport: Viewport) => {
    if (_.isEmpty(viewport)) return
    const { latitude, longitude, zoom: newZoom } = viewport

    setViewState({
      ...(enableAnimation && {
        transitionDuration: 2000,
        transitionInterpolator: new FlyToInterpolator(),
      }),
      latitude,
      longitude,
      zoom: newZoom,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fitFeatureBound = useCallback(
    (feature: GeojsonData[], updateZoom = true) => {
      const viewport = getFeaturesViewport(feature, mapCanvasSize)
      if (!viewport) return

      const newZoom = _.clamp(
        updateZoom ? viewport.zoom - 9 : viewport.zoom * 0.95,
        3,
        20
      )

      updateViewState({ ...viewport, zoom: newZoom })
    },
    [updateViewState, mapCanvasSize]
  )

  const fitFeaturesBound = useCallback(
    (features: GeojsonData[], updateZoom = true) => {
      fitFeatureBound(featureCollection(features), updateZoom)
    },
    [fitFeatureBound]
  )

  const fitLayersBound = useCallback(
    (mapLayers: MapLayer[]) => {
      const viewport = getLayersViewport(catalog, mapLayers, mapCanvasSize)
      if (!viewport) return
      // Zoom out slightly
      const newZoom = viewport.zoom * 0.9
      updateViewState({ ...viewport, zoom: newZoom })
    },
    [catalog, mapCanvasSize, updateViewState]
  )

  /**
   * Save the view state and trigger re-render
   */
  const onViewStateChange = useCallback(
    (props: { viewState: MapViewState }) => setViewState(props.viewState),
    [setViewState]
  )

  /**
   * Fit map view state to the bounds of all layers
   */
  const resetViewState = () => setZoomToMap(_.cloneDeep(map.layers))

  /**
   * Set a map view that contains the given geographical bounds
   * when the catalog or any layer's dataset changed.
   */
  useUpdateEffect(() => {
    if (!_.isEmpty(catalog) && zoomToMap) {
      fitLayersBound(zoomToMap)
      setZoomToMap(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomToMap])

  /**
   * Zoom to the given layer
   */
  useUpdateEffect(() => {
    if (!_.isEmpty(catalog) && zoomToLayer) {
      fitLayersBound([zoomToLayer])
      setZoomToLayer(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomToLayer])

  /**
   * Map canvas size changed handler
   */
  useEffect(() => {
    const { width, height } = mapCanvasSize || {}

    if (width && height) {
      setViewState({
        width,
        height,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapCanvasSize])

  useUpdateEffect(() => {
    const unsubscribeEvents = enableEventBus
      ? bus.subscribe(saveMapViewState, ({ payload }) => {
          const validViewState = _.pick(viewState, VIEW_STATE_CORE)
          updateMapConfigs({
            viewState:
              payload === MAP_VIEW_STATE_ACTIONS.save ? validViewState : null,
          })
        })
      : _.noop

    return () => {
      unsubscribeEvents()
    }
  }, [viewState])

  const { zoom } = viewState
  const currentZoom = useMemo(() => getMapZoom(zoom), [zoom])

  useDebounce(
    () => {
      if (enableEventBus) {
        bus.publish(onMapZoomChange(currentZoom))
      }
    },
    MAP_VIEWPORT_BOUNDS_DEBOUNCE_DELAY,
    [currentZoom]
  )

  useDebounce(
    () => {
      if (mapLoaded) {
        const viewport = mapRef?.current?.deck.getViewports()[0]
        // https://github.com/visgl/deck.gl/blob/1049956d723fe7a92c39ea203bc2b5c5fe00cc36/test/interaction/map-controller.js#L4
        // https://github.com/visgl/deck.gl/blob/743e230a3ae30f4ba433ed2e9fbb99a7fba1c99f/modules/core/src/viewports/viewport.js#L216
        if (_.isFunction(viewport?.getBounds)) {
          const bounds = viewport.getBounds()
          setViewportBounds(bounds)
        }
      }
    },
    MAP_VIEWPORT_BOUNDS_DEBOUNCE_DELAY,
    [mapLoaded, mapRef, viewState]
  )

  return {
    viewState,
    setViewState,
    resetViewState,
    onViewStateChange,
    fitFeatureBound,
    fitFeaturesBound,
    setMapLoaded,
    currentZoom,
  }
}

export default useMapViewState
