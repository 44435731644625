import styled from '@emotion/styled'
import { Theme, css, SerializedStyles } from '@emotion/react'

import {
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  DEFAULT_BUTTON_VARIANT,
  DEFAULT_BUTTON_SIZE,
  BUTTON_ICON_CLASS_NAME,
  SECONDARY_BUTTON_BORDER_COLOR,
  BUTTON_BORDER_RADIUS,
} from './constants'
import { ButtonProps, ButtonIconPosition } from '.'
import { getIconMargin } from './ButtonIcon/style'

type ButtonBaseProps = Pick<
  ButtonProps,
  'variant' | 'size' | 'block' | 'isIconFixed' | 'iconPosition'
>

const BUTTON_TRANSITION_DURATION = '0.15s'

const PRIMARY_BUTTON_STYLES_BY_SIZE = {
  // Here we have uneven y-paddings to compensate border width
  [BUTTON_SIZES.large]: css`
    padding: 7px 20px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
  `,
  [BUTTON_SIZES.default]: css`
    padding: 7px 16px;
    font-size: 12px;
    line-height: 17px;
    font-weight: 600;
  `,
  [BUTTON_SIZES.small]: css`
    padding: 5px 12px;
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
  `,
}

const BUTTON_STYLES_BY_SIZE = {
  [BUTTON_VARIANTS.primary]: PRIMARY_BUTTON_STYLES_BY_SIZE,
  [BUTTON_VARIANTS.danger]: PRIMARY_BUTTON_STYLES_BY_SIZE,
  [BUTTON_VARIANTS.warning]: PRIMARY_BUTTON_STYLES_BY_SIZE,
  [BUTTON_VARIANTS.secondary]: PRIMARY_BUTTON_STYLES_BY_SIZE,
  [BUTTON_VARIANTS.link]: {
    [BUTTON_SIZES.large]: css`
      padding: 8px 16px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
    `,
    [BUTTON_SIZES.default]: css`
      padding: 8px 8px;
      font-size: 12px;
      line-height: 17px;
      font-weight: 600;
    `,
    [BUTTON_SIZES.small]: css`
      padding: 6px 8px;
      font-size: 10px;
      line-height: 14px;
      font-weight: 700;
    `,
  },
}

const getButtonStylesBySize = ({ variant, size }: ButtonBaseProps) =>
  BUTTON_STYLES_BY_SIZE[variant || DEFAULT_BUTTON_VARIANT][
    size || DEFAULT_BUTTON_SIZE
  ]

const getVariantStyles = ({
  variant,
  theme,
}: ButtonBaseProps & { theme: Theme }): SerializedStyles => {
  const variations = {
    [BUTTON_VARIANTS.primary]: css`
      color: #ffffff;
      background-color: ${theme['primary-500']};
      border: 1px solid ${theme['primary-500']};
      box-shadow: 2px 4px 10px #00000040;
      &:hover {
        background-color: ${theme['primary-600']};
      }
      &:active {
        background-color: ${theme['primary-700']};
      }
      &:disabled {
        background-color: ${theme['primary-300']};
        border-color: ${theme['primary-300']};
        // 80% opacity color
        color: #ffffffcc;
        box-shadow: none;
      }
    `,
    [BUTTON_VARIANTS.secondary]: css`
      color: ${theme['primary-500']};
      background-color: #ffffff;
      border: 1px solid ${SECONDARY_BUTTON_BORDER_COLOR};
      box-shadow: 0px 1px 3px #0000000d;
      &:hover {
        background-color: ${theme['secondary-light-500']};
      }
      &:active {
        background-color: ${theme['secondary-light-600']};
      }
      &:disabled {
        // 50% opacity color
        color: ${theme['primary-500']}80;
        background-color: #ffffff;
        box-shadow: none;
      }
    `,
    [BUTTON_VARIANTS.link]: css`
      color: ${theme['primary-500']};
      background-color: transparent;
      border: none;
      &:hover {
        color: ${theme['primary-600']};
      }
      &:active {
        color: ${theme['primary-700']};
      }
      &:disabled {
        color: ${theme['primary-500']}80;
      }
    `,
    [BUTTON_VARIANTS.danger]: css`
      color: #ffffff;
      background-color: ${theme.danger};
      border: 1px solid ${theme.danger};
      box-shadow: 2px 4px 10px #00000040;
      &:hover {
        background-color: ${theme['red-600']};
        border-color: ${theme['red-600']};
      }
      &:active {
        background-color: ${theme['red-700']};
        border-color: ${theme['red-700']};
      }
      &:disabled {
        background-color: ${theme['red-200']};
        border-color: ${theme['red-200']};
        box-shadow: none;
      }
    `,
    [BUTTON_VARIANTS.warning]: css`
      color: #141414;
      background-color: ${theme.warning};
      border: 1px solid ${theme.warning};
      box-shadow: 2px 4px 10px #00000040;
      &:hover {
        background-color: ${theme['yellow-600']};
        border-color: ${theme['yellow-600']};
      }
      &:active {
        background-color: ${theme['yellow-700']};
        border-color: ${theme['yellow-700']};
      }
      &:disabled {
        background-color: ${theme['yellow-200']};
        border-color: ${theme['yellow-200']};
        box-shadow: none;
      }
    `,
  }

  return variant ? variations[variant] : variations[DEFAULT_BUTTON_VARIANT]
}

export const FlexIconStub = styled.span<{
  size: number
  iconPosition: ButtonIconPosition
}>`
  display: inline-block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  ${({ iconPosition }) => getIconMargin(iconPosition)};
`

export const ButtonBase = styled.button<ButtonBaseProps>`
  display: inline-flex;
  justify-content: ${({ isIconFixed }) =>
    isIconFixed ? 'space-between' : 'center'};
  align-items: center;
  text-transform: uppercase;
  border-radius: ${BUTTON_BORDER_RADIUS};
  outline: none;
  width: ${({ block }) => (block ? '100%' : 'auto')};
  cursor: pointer;
  user-select: none;
  transition: color ${BUTTON_TRANSITION_DURATION} ease-in-out,
    background-color ${BUTTON_TRANSITION_DURATION} ease-in-out,
    border-color ${BUTTON_TRANSITION_DURATION} ease-in-out,
    box-shadow ${BUTTON_TRANSITION_DURATION} ease-in-out;

  ${getButtonStylesBySize}
  ${getVariantStyles};

  &:disabled {
    cursor: default;
  }

  &:hover:not(:disabled) {
    .${BUTTON_ICON_CLASS_NAME} {
      transform: ${({ iconPosition, isIconFixed }) =>
        isIconFixed
          ? iconPosition === 'left'
            ? 'translateX(-5px)'
            : 'translateX(5px)'
          : 'none'};
    }
  }
`
