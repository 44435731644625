// libraries
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { Node, Edge, XYPosition } from 'reactflow'
import { ValidationErrors } from 'final-form'

// constants
import {
  FORM_QUESTION_TYPES,
  FORM_BUILDER_WIDGET_SPECS_KEY_BY_WIDGET_TYPE,
  FORM_BUILDER_CANVAS_NODE_TYPES,
  FORM_BUILDER_CANVAS_EDGE_TYPES,
  JSON_DATA_TYPES,
  FORM_BUILDER_CANVAS_LAYOUT_NODES,
  DEFAULT_FORM_WIDGETS,
  STRING_FORMATS,
  CUSTOM_WIDGETS_AND_FIELDS,
} from 'constants/formBuilder'

import type {
  FormEnum,
  FormEnumNames,
  CustomInputNode,
  FormSchemaFormValues,
  JSONFormSchema,
  JSONFormWidgetUiSchema,
  CustomNodeData,
  JSONFormUiSchema,
  FormQuestionType,
} from 'types/formBuilder'
import type { Options } from 'types/common'

export const getNodeId = (): string => uuidv4()

export const isMultipleChoiceList = (
  schema: JSONFormSchema['properties']
): boolean => {
  const { items, type, uniqueItems } = schema
  return (!_.isEmpty(items) &&
    !_.isNil(items.enum) &&
    type === JSON_DATA_TYPES.array &&
    uniqueItems) as boolean
}

export const isImageFile = (
  schema?: JSONFormSchema,
  uiSchema?: JSONFormWidgetUiSchema
): boolean => {
  if (
    _.get(uiSchema, '[ui:options].mediaType') === 'image' ||
    _.get(uiSchema, '[ui:widget]') === CUSTOM_WIDGETS_AND_FIELDS.images
  ) {
    return true
  }
  if (schema?.items) {
    return (
      schema?.items.type === JSON_DATA_TYPES.string &&
      schema?.items.format === STRING_FORMATS['data-url']
    )
  }
  return false
}

export const isCheckboxList = (uiSchema?: JSONFormWidgetUiSchema): boolean => {
  if (_.get(uiSchema, 'ui:widget') === CUSTOM_WIDGETS_AND_FIELDS.checkboxes) {
    return true
  }

  return false
}

export const isGeojsonPoint = (
  schema?: JSONFormSchema,
  uiSchema?: JSONFormWidgetUiSchema
): boolean => {
  const type = _.get(schema, 'properties.type.enum')
  const field = _.get(uiSchema, 'ui:field')

  return (
    _.isEqual(type, ['Point']) && field === CUSTOM_WIDGETS_AND_FIELDS.geopoint
  )
}

export const getFormBuilderWidgetType = ({
  schema,
  uischema,
}: {
  schema?: JSONFormSchema
  uischema?: JSONFormWidgetUiSchema
}): FormQuestionType | undefined => {
  const { enum: nodeEnum, items: nodeItems, type, format } = schema || {}

  if (nodeEnum || nodeItems) {
    if (nodeItems) {
      if (isImageFile(schema, uischema)) {
        return FORM_QUESTION_TYPES.IMAGE_UPLOADER
      }
      if (isCheckboxList(uischema)) {
        return FORM_QUESTION_TYPES.CHECKBOX_LIST
      }
    }

    return FORM_QUESTION_TYPES.DROPDOWN
  }

  if (type === JSON_DATA_TYPES.string) {
    if (format === STRING_FORMATS['date-time']) {
      return FORM_QUESTION_TYPES.DATETIME
    }
    return FORM_QUESTION_TYPES.TEXT_INPUT
  }

  if (type === JSON_DATA_TYPES.boolean) {
    return FORM_QUESTION_TYPES.TOGGLE
  }

  if (type === JSON_DATA_TYPES.object) {
    if (isGeojsonPoint(schema, uischema)) {
      return FORM_QUESTION_TYPES.GEO_POINT
    }
  }

  return undefined
}

export const getFormValuesFromNodes = (
  nodes: CustomInputNode[]
): FormSchemaFormValues => {
  const omitNodes = _.reject(nodes, node =>
    _.includes(FORM_BUILDER_CANVAS_LAYOUT_NODES, node.type)
  )

  return _.reduce(
    omitNodes,
    (acc, param, index) => {
      const { id, schema, uischema } = param.data
      return id ? { ...acc, [id]: { id, schema, uischema, order: index } } : acc
    },
    {} as FormSchemaFormValues
  )
}

export const getStartNode = (): CustomInputNode => {
  const startNodeId = getNodeId()

  return {
    id: startNodeId,
    data: { showActionMenu: false, id: startNodeId },
    height: 18,
    position: { x: 257, y: 120 },
    selected: false,
    type: FORM_BUILDER_CANVAS_NODE_TYPES.START,
    width: 35,
  }
}

export const getOptionsStartNode = (): Node[] => {
  const startNodeId = getNodeId()

  return [
    {
      id: startNodeId,
      type: FORM_BUILDER_CANVAS_NODE_TYPES.OPTIONS_START,
      data: {
        id: startNodeId,
      },
      position: { x: 160, y: 100 },
    },
  ]
}

export const getOptionsNode = (): Node => {
  const newOptionNodeId = getNodeId()

  return {
    id: newOptionNodeId,
    type: FORM_BUILDER_CANVAS_NODE_TYPES.OPTIONS,
    data: {
      id: newOptionNodeId,
    },
    position: { x: 139, y: 100 },
  }
}

export const getCustomInputNode = ({
  schema,
  uischema,
  icon,
}: {
  schema: JSONFormSchema
  uischema: JSONFormWidgetUiSchema
  icon: string
}): Node<CustomNodeData> => {
  const newNodeId = getNodeId()

  return {
    id: newNodeId,
    type: FORM_BUILDER_CANVAS_NODE_TYPES.INPUT,
    selected: true,
    data: {
      id: newNodeId,
      schema,
      uischema,
      showActionMenu: true,
      icon,
    },
    style: { border: '1px solid #ddd' },
    position: { x: 220, y: 200 },
  }
}

export const getEndNode = (): CustomInputNode => {
  const endNodeId = getNodeId()

  return {
    id: endNodeId,
    data: {
      id: endNodeId,
    },
    position: { x: 261, y: 520 },
    type: FORM_BUILDER_CANVAS_NODE_TYPES.END,
  }
}

export const getNodesFromFormValues = ({
  formValues,
  error,
  selectedNodeId,
  isDisabled,
}: {
  formValues: FormSchemaFormValues
  error: ValidationErrors
  selectedNodeId?: string
  isDisabled: boolean
}): CustomInputNode[] => {
  if (_.isEmpty(formValues)) return getOptionsStartNode()

  const nodesLength = _.keys(formValues).length

  const sortedNodes = _(formValues)
    .sortBy('order')
    .map((formValue, idx) => {
      const { id, schema, uischema } = formValue as CustomNodeData
      const widgetType =
        getFormBuilderWidgetType({ schema, uischema }) ||
        FORM_QUESTION_TYPES.TEXT_INPUT
      const { icon } = FORM_BUILDER_WIDGET_SPECS_KEY_BY_WIDGET_TYPE[widgetType]
      const errors = _.get(error, [id, 'schema'])
      const isSelected = selectedNodeId === id

      return {
        id,
        position: { x: 220, y: 320 },
        selected: isSelected,
        style: errors
          ? { boxShadow: '0 0 0 2.5px #e30000' }
          : { border: '1px solid #ddd' },
        type: FORM_BUILDER_CANVAS_NODE_TYPES.INPUT,
        width: 108,
        height: 53,
        data: {
          id,
          schema,
          uischema,
          icon,
          error: errors,
          errorMessage: _.values(errors),
          showActionMenu: isSelected,
          isLast: idx === nodesLength - 1,
        },
      }
    })
    .value()
  return [
    getStartNode(),
    ...sortedNodes,
    isDisabled ? getEndNode() : getOptionsNode(),
  ]
}

export const getEdgesFromNodes = (
  nodes: CustomInputNode[],
  isStraight = false
): Edge[] => {
  if (nodes.length === 1) return []

  const edges = []
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i + 1]) {
      edges.push({
        id: `e_${nodes[i].id}_${nodes[i + 1].id}`,
        source: nodes[i].id,
        target: nodes[i + 1].id,
        type: isStraight
          ? FORM_BUILDER_CANVAS_EDGE_TYPES.DEFAULT
          : FORM_BUILDER_CANVAS_EDGE_TYPES.DROP,
        hidden: false,
      })
    }
  }

  return edges
}

export const setEdgePositions = ({
  nodes,
  setSelected,
  clonedNodeIndex,
  clientX,
}: {
  nodes: CustomInputNode[]
  setSelected?: boolean
  clonedNodeIndex?: number
  clientX: XYPosition
}): CustomInputNode[] => {
  const newNodes = nodes
  let newPosition = clientX.y / 2

  for (let i = newNodes.length - 1; i >= 0; i--) {
    if (setSelected && i !== clonedNodeIndex) {
      newNodes[i].selected = false
      newNodes[i].data.showActionMenu = false
    }

    newNodes[i].position.x = clientX.x - 54
    newNodes[i].position.y = newPosition
    newPosition -= 130
  }

  const firstNodeYPosition = newNodes[0].position.y
  newNodes[0].position.y = firstNodeYPosition + 60
  newNodes[0].position.x = clientX.x - 16
  newNodes[newNodes.length - 1].position.x =
    newNodes[newNodes.length - 1].type === FORM_BUILDER_CANVAS_NODE_TYPES.END
      ? clientX.x - 12
      : clientX.x - 134

  return newNodes
}

export const isFormBuilderCanvasLayoutNode = (nodeType?: string): boolean =>
  _.includes(FORM_BUILDER_CANVAS_LAYOUT_NODES, nodeType)

export const setEdgeStyles = (nodes: CustomInputNode[]): CustomInputNode[] => {
  return nodes.map(obj => {
    if (!isFormBuilderCanvasLayoutNode(obj.type)) {
      obj.style = obj.data.error
        ? { boxShadow: '0 0 0 2.5px #e30000' }
        : { border: '1px solid #ddd' }
    }

    return obj
  })
}

export const getEnumOptions = (props: {
  enum: FormEnum
  enumNames?: FormEnumNames
  default?: string
}): Options<string> => {
  const {
    enum: inputEnum = [],
    enumNames = [],
    default: defaultValue,
  } = props || {}
  return _.map(inputEnum, (value, idx) => {
    return {
      value,
      label: _.isEmpty(enumNames) ? value : enumNames[idx] || value,
      ...(value === defaultValue && { default: value === defaultValue }),
    }
  })
}

export const getDefaultJsonFormSchema = (
  jsonFormBody = {}
): {
  schema: JSONFormSchema
  uischema: JSONFormUiSchema
} => {
  const { schema, uischema } = jsonFormBody || {}
  const defaultKeys = _.map(DEFAULT_FORM_WIDGETS, 'key')
  if (!_.has(schema, defaultKeys)) {
    return {
      schema: {
        ...schema,
        required: _.compact([...defaultKeys, ...(schema?.required || [])]),
        properties: {
          ..._.reduce(
            DEFAULT_FORM_WIDGETS,
            (acc, cur) => {
              const { widgetType, title, key } = cur
              return {
                ...acc,
                [key]: {
                  ...FORM_BUILDER_WIDGET_SPECS_KEY_BY_WIDGET_TYPE[widgetType]
                    .schema,
                  title,
                },
              }
            },
            {}
          ),
          ...schema?.properties,
        },
      },
      uischema: {
        ...uischema,
        'ui:order': _.compact([
          ...defaultKeys,
          ...(_.get(uischema, 'ui:order') || []),
        ]),
        ..._.reduce(
          DEFAULT_FORM_WIDGETS,
          (acc, cur) => {
            const { widgetType, key } = cur
            const uiSchema =
              FORM_BUILDER_WIDGET_SPECS_KEY_BY_WIDGET_TYPE[widgetType].uischema
            const uiOptions = _.get(cur, 'ui:options')

            if (!uiSchema && !uiOptions) return acc

            return {
              ...acc,
              [key]: { ...uiSchema, 'ui:options': uiOptions },
            }
          },
          {}
        ),
      },
    }
  }

  return { schema, uischema }
}

export const isJsonFormWidgetHidden = (uiSchema: JSONFormUiSchema): boolean =>
  _.get(uiSchema, '[ui:options].hidden')

type DefaultValue = string

export const getValueWhenSwitchingBetweenSingleSelectAndMultiSelect = (
  oldValue: DefaultValue | DefaultValue[] | undefined,
  isMulti: boolean
): DefaultValue | DefaultValue[] | undefined => {
  if (!oldValue) return undefined

  if (isMulti) {
    return _.isEmpty(oldValue)
      ? []
      : _.isArray(oldValue)
      ? oldValue
      : [oldValue]
  }
  return _.isArray(oldValue) ? '' : oldValue
}
