import keymirror from 'keymirror'
import _ from 'lodash'

import pointSchema from './schema/point.json'

export const FORM_BUILDER_WIDGET_CATEGORIES = keymirror({
  LAYOUT: null,
  CONTROL: null,
})

export const FORM_QUESTION_TYPES = keymirror({
  TEXT_INPUT: null,
  DROPDOWN: null,
  CHECKBOX_LIST: null,
  TOGGLE: null,
  IMAGE_UPLOADER: null,
  GEO_POINT: null,
  DATETIME: null,
  END: null,
})

export const FORM_BUILDER_WIDGET_LAYOUT_TYPES = keymirror({
  DIVIDER: null,
})

export const FORM_BUILDER_CANVAS_NODE_TYPES = keymirror({
  START: null,
  END: null,
  OPTIONS: null,
  INPUT: null,
  OPTIONS_START: null,
  DROP: null,
})

export const FORM_BUILDER_CANVAS_EDGE_TYPES = keymirror({
  DROP: null,
  DEFAULT: null,
})

export const JSON_DATA_TYPES = keymirror({
  string: null,
  number: null,
  integer: null,
  boolean: null,
  array: null,
  object: null,
})

export const STRING_FORMATS = keymirror({
  'date-time': null,
  'data-url': null,
})

export const CUSTOM_WIDGETS_AND_FIELDS = keymirror({
  checkboxes: null,
  images: null,
  geopoint: null,
})

export const FORM_BUILDER_IMAGE_CONFIGS = {
  max: 10,
  default: 5,
  min: 1,
  step: 1,
}

export const FORM_BUILDER_WIDGET_SPECS = [
  {
    label: 'Text input',
    widgetType: FORM_QUESTION_TYPES.TEXT_INPUT,
    icon: 'BsInputCursorText',
    category: FORM_BUILDER_WIDGET_CATEGORIES.CONTROL,
    schema: { type: JSON_DATA_TYPES.string },
  },
  {
    label: 'Dropdown',
    widgetType: FORM_QUESTION_TYPES.DROPDOWN,
    category: FORM_BUILDER_WIDGET_CATEGORIES.CONTROL,
    icon: 'MdListAlt',
    schema: { type: JSON_DATA_TYPES.string, enum: [] },
  },
  {
    label: 'Toggle (Yes/No)',
    widgetType: FORM_QUESTION_TYPES.TOGGLE,
    category: FORM_BUILDER_WIDGET_CATEGORIES.CONTROL,
    icon: 'MdToggleOn',
    schema: {
      type: JSON_DATA_TYPES.boolean,
    },
  },
  {
    label: 'Checklist',
    widgetType: FORM_QUESTION_TYPES.CHECKBOX_LIST,
    category: FORM_BUILDER_WIDGET_CATEGORIES.CONTROL,
    icon: 'MdOutlineCheckBox',
    schema: {
      type: JSON_DATA_TYPES.array,
      items: {
        type: 'string',
        enum: [],
      },
      uniqueItems: true,
    },
    uischema: { 'ui:widget': CUSTOM_WIDGETS_AND_FIELDS.checkboxes },
  },
  {
    label: 'Photo',
    widgetType: FORM_QUESTION_TYPES.IMAGE_UPLOADER,
    category: FORM_BUILDER_WIDGET_CATEGORIES.CONTROL,
    icon: 'BiCamera',
    schema: {
      type: JSON_DATA_TYPES.array,
      maxItems: FORM_BUILDER_IMAGE_CONFIGS.default,
      items: {
        type: JSON_DATA_TYPES.object,
        properties: {
          mediaKey: {
            type: 'string',
            format: STRING_FORMATS['data-url'],
          },
          description: {
            type: 'string',
          },
        },
      },
    },
    uischema: { 'ui:widget': CUSTOM_WIDGETS_AND_FIELDS.images },
  },
  {
    label: 'Geo Point',
    widgetType: FORM_QUESTION_TYPES.GEO_POINT,
    category: FORM_BUILDER_WIDGET_CATEGORIES.CONTROL,
    icon: 'MdOutlineLocationOn',
    schema: pointSchema,
    uischema: {
      'ui:field': CUSTOM_WIDGETS_AND_FIELDS.geopoint,
    },
    isAddable: false,
  },
  {
    label: 'Datetime',
    widgetType: FORM_QUESTION_TYPES.DATETIME,
    category: FORM_BUILDER_WIDGET_CATEGORIES.CONTROL,
    icon: 'MdCalendarToday',
    schema: {
      type: JSON_DATA_TYPES.string,
      format: STRING_FORMATS['date-time'],
    },
    isAddable: false,
  },
]

export const DEFAULT_FORM_WIDGETS = _.map(
  [
    {
      widgetType: FORM_QUESTION_TYPES.GEO_POINT,
      title: 'Geolocation',
      key: 'currentLocation',
    },
    {
      widgetType: FORM_QUESTION_TYPES.DATETIME,
      title: 'Date and time',
      key: 'currentDatetime',
    },
  ],
  widget => ({
    ...widget,
    'ui:options': {
      hidden: true,
      locked: true,
    },
  })
)

export const FORM_BUILDER_WIDGET_SPECS_KEY_BY_WIDGET_TYPE = _.keyBy(
  FORM_BUILDER_WIDGET_SPECS,
  'widgetType'
)

export const FORM_BUILDER_DEFAULT_WIDGET_KEY_BY_WIDGET_KEY = _.keyBy(
  DEFAULT_FORM_WIDGETS,
  'key'
)

export const FORM_BUILDER_PROPERTY_IDENTIFIER = 'id'

export const FORM_BUILDER_PROPERTY_NAME = 'key'

export const FORM_BUILDER_WIDGET_DROPDOWN_NAME = 'select'

export const FORM_BUILDER_WIDGET_HIDDEN_WIDGET_NAME = 'isHidden'

export const FORM_BUILDER_CANVAS_LAYOUT_NODES = [
  FORM_BUILDER_CANVAS_NODE_TYPES.END,
  FORM_BUILDER_CANVAS_NODE_TYPES.START,
  FORM_BUILDER_CANVAS_NODE_TYPES.OPTIONS,
  FORM_BUILDER_CANVAS_NODE_TYPES.OPTIONS_START,
]
