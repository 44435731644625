import { CompositeLayer, GeoJsonLayer, TextLayer } from 'deck.gl'
import { getTextSubLayerProps } from 'components/map/layers/deckLayers/textLayerHelper'

export default class LabeledGeoJsonLayer extends CompositeLayer {
  renderLayers() {
    return [
      // the bg icons
      new GeoJsonLayer(
        this.getSubLayerProps({
          // `getSubLayerProps` will concat the parent layer id with this id
          id: `${this.props.id}-tile`,
          data: this.props.data,

          stroked: this.props.stroked,
          filled: this.props.filled,
          extruded: this.props.extruded,
          wireframe: this.props.wireframe,

          lineWidthUnits: this.props.lineWidthUnits,
          lineWidthScale: this.props.lineWidthScale,
          lineWidthMinPixels: this.props.lineWidthMinPixels,
          lineWidthMaxPixels: this.props.lineWidthMaxPixels,
          lineJointRounded: this.props.lineJointRounded,
          lineMiterLimit: this.props.lineJointRounded,

          elevationScale: this.props.elevationScale,

          pointRadiusScale: this.props.elevationScale,
          pointRadiusMinPixels: this.props.pointRadiusMinPixels,
          pointRadiusMaxPixels: this.props.pointRadiusMaxPixels,

          getLineColor: this.props.getLineColor,
          getFillColor: this.props.getFillColor,
          getRadius: this.props.getRadius,
          getLineWidth: this.props.getLineWidth,
          getElevation: this.props.getElevation,
          material: this.props.material,

          updateTriggers: {
            getLineColor: this.props.updateTriggers.getLineColor,
            getFillColor: this.props.updateTriggers.getFillColor,
            getLineWidth: this.props.updateTriggers.getLineWidth,
            getElevation: this.props.updateTriggers.getElevation,
          },
        })
      ),

      // the labels
      new TextLayer(this.getSubLayerProps(getTextSubLayerProps(this.props))),
    ]
  }
}

LabeledGeoJsonLayer.layerName = 'LabeledGeoJsonLayer'

LabeledGeoJsonLayer.defaultProps = {
  stroked: true,
  filled: true,
  extruded: false,
  wireframe: false,
  lineWidthUnits: 'meters',
  lineWidthScale: 1,
  lineWidthMinPixels: 0,
  lineWidthMaxPixels: Number.MAX_SAFE_INTEGER,
  lineJointRounded: false,
  lineMiterLimit: 4,
  elevationScale: 1,
  pointRadiusScale: 1,
  pointRadiusMinPixels: 0, //  min point radius in pixels
  pointRadiusMaxPixels: Number.MAX_SAFE_INTEGER, // max point radius in pixels

  // Line and polygon outline color
  getLineColor: { type: 'accessor', value: [0, 0, 0, 255] },
  // Point and polygon fill color
  getFillColor: { type: 'accessor', value: [0, 0, 0, 255] },
  // Point radius
  getRadius: { type: 'accessor', value: 1 },
  // Line and polygon outline accessors
  getLineWidth: { type: 'accessor', value: 1 },
  // Polygon extrusion accessor
  getElevation: { type: 'accessor', value: 1000 },
  // Optional material for 'lighting' shader module
  material: true,

  pickable: true,
  autoHighlight: true,
}
