// libraries
import { Suspense, useMemo, ReactElement, lazy, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import _ from 'lodash'

// constants
import { FEATURES } from 'constants/settings'
import { URLS } from 'constants/route'
import { HOME_PAGE_URL } from 'constants/common'

// utils
import {
  useFeatureFlag,
  usePlugins,
  useRoutesAbility,
  useSanitizedLocation,
} from 'hooks'
import { lazyWithRetry } from 'routers/utils'
import { isDevEnvironment } from 'helpers/utils'
import { logEvent, ANALYTICS_EVENTS } from 'helpers/firebase'

// components
import { Loading } from 'components/common'
import ProtectedElement from './ProtectedElement'
import { LoginLayout, FullPageLayout, LongContentLayout } from './layouts'

const MapEditor = lazy(() =>
  lazyWithRetry(() => import('components/map/MapEditor'))
)
const AncillaryDataGallery = lazy(() =>
  lazyWithRetry(() => import('routers/pages/AncillaryDataGallery'))
)
const AssetProfileGallery = lazy(() =>
  lazyWithRetry(() => import('routers/pages/AssetProfileGallery'))
)
const Login = lazy(() => lazyWithRetry(() => import('routers/pages/Login')))
const ResetPassword = lazy(() =>
  lazyWithRetry(() => import('routers/pages/ResetPassword'))
)
const NewPassword = lazy(() =>
  lazyWithRetry(() => import('routers/pages/NewPassword'))
)
const WorkflowGallery = lazy(() =>
  lazyWithRetry(() => import('routers/pages/Workflow/Gallery'))
)
const WorkflowEdit = lazy(() =>
  lazyWithRetry(() => import('routers/pages/Workflow/Edit'))
)
const WorkflowNew = lazy(() =>
  lazyWithRetry(() => import('routers/pages/Workflow/New'))
)
const WorkflowView = lazy(() =>
  lazyWithRetry(() => import('routers/pages/Workflow/View'))
)
const Settings = lazy(() =>
  lazyWithRetry(() => import('routers/pages/Settings'))
)
const UserGallery = lazy(() =>
  lazyWithRetry(() => import('routers/pages/UserGallery'))
)
const MapGallery = lazy(() =>
  lazyWithRetry(() => import('routers/pages/MapGallery'))
)
const IssueGallery = lazy(() =>
  lazyWithRetry(() => import('routers/pages/IssueGallery'))
)
const SiteGallery = lazy(() =>
  lazyWithRetry(() => import('routers/pages/SiteGallery'))
)
const SiteEdit = lazy(() =>
  lazyWithRetry(() => import('components/site/SiteEdit'))
)

const FormGallery = lazy(() =>
  lazyWithRetry(() => import('routers/pages/FormGallery'))
)
const FormBuilderEditor = lazy(() =>
  lazyWithRetry(() => import('components/formBuilder/Editor'))
)
const AssetProfileBuilder = lazy(() =>
  lazyWithRetry(() => import('components/assets/assetsProfile/Builder'))
)
const AssetReport = lazy(() =>
  lazyWithRetry(
    () => import('components/assets/assetsProfile/viewer/Printable')
  )
)
const Page404 = lazy(() => lazyWithRetry(() => import('routers/pages/Page404')))
const DynamicScheduling = lazy(() =>
  lazyWithRetry(() => import('components/common/DynamicScheduling'))
)
const DevUIPlayground = lazy(() =>
  lazyWithRetry(() => import('routers/pages/dev/UIPlayground'))
)
const SidebarRoutePlugin = lazy(() =>
  lazyWithRetry(() => import('routers/pages/SidebarRoutePlugin'))
)

const isDevEnv = isDevEnvironment()

const publicRoutes = [
  {
    path: URLS.LOGIN,
    element: (
      <LoginLayout>
        <Login />
      </LoginLayout>
    ),
  },
  {
    path: URLS.RESET_PASSWORD,
    element: (
      <LoginLayout>
        <ResetPassword />
      </LoginLayout>
    ),
  },
  {
    path: URLS.NEW_PASSWORD,
    element: (
      <LoginLayout>
        <NewPassword />
      </LoginLayout>
    ),
  },
  {
    path: '*',
    element: (
      <FullPageLayout>
        <Page404 />
      </FullPageLayout>
    ),
  },
]

const mapRoutes = [
  {
    path: HOME_PAGE_URL,
    component: MapGallery,
  },
  {
    path: URLS.MAPS,
    component: MapGallery,
  },
  {
    path: URLS.MAP_VIEW,
    component: MapEditor,
    layoutOptions: { showSideBar: false, showToggle: true },
  },
  {
    path: `${URLS.MAP}/*`,
    component: MapEditor,
  },
]

const workflowRoutes = [
  {
    path: URLS.WORKFLOWS,
    component: WorkflowGallery,
  },
  {
    path: URLS.WORKFLOW_NEW,
    component: WorkflowNew,
  },
  {
    path: URLS.WORKFLOW_EDIT,
    component: WorkflowEdit,
  },
  {
    path: URLS.WORKFLOW_VIEW,
    component: WorkflowView,
  },
]

const devRoutes = isDevEnv
  ? [
      {
        path: URLS.DEV_UI_PLAYGROUND,
        component: DevUIPlayground,
      },
      {
        path: URLS.FORMS,
        component: FormGallery,
      },
      {
        path: URLS.SCHEDULE_OPTIMIZATION,
        component: DynamicScheduling,
      },
    ]
  : []

export const SuRoutes = (): ReactElement => {
  const {
    [FEATURES.ANCILLARY_DATA]: ancillaryDataEnabled,
    [FEATURES.ISSUE]: issueEnabled,
    [FEATURES.SITE]: siteEnabled,
    [FEATURES.ASSET]: assetsEnabled,
    [FEATURES.JSON_FORM]: jsonFormEnabled,
  } = useFeatureFlag()

  const { pathname, search: searchParams } = useSanitizedLocation()
  // On route change, send analytics
  useEffect(() => {
    logEvent(ANALYTICS_EVENTS.page_view, { pathname, searchParams })
  }, [pathname, searchParams])

  const { canManageUser, canManageAssetProfile } = useRoutesAbility()

  const canManageJsonForm = true
  const pluginState = usePlugins()

  const routes = useMemo(() => {
    const ancillaryDataRoutes = ancillaryDataEnabled
      ? [
          ...(assetsEnabled
            ? []
            : [
                {
                  path: URLS.ASSETS_PROFILES,
                  element: <Navigate to={URLS.ANCILLARY_DATA} replace />,
                },
              ]),
          {
            path: URLS.ANCILLARY_DATA,
            component: AncillaryDataGallery,
          },
        ]
      : []

    const jsonFormRoutes = jsonFormEnabled
      ? [
          ...(canManageJsonForm
            ? [
                {
                  path: URLS.FORMS,
                  component: FormGallery,
                },
                {
                  path: URLS.FORM_DESIGNER_EDIT,
                  component: FormBuilderEditor,
                  componentProps: {
                    isEdit: true,
                  },
                },
                {
                  path: URLS.FORM_DESIGNER_VIEW,
                  component: FormBuilderEditor,
                  componentProps: { isEdit: false },
                },
                {
                  path: URLS.FORM_DESIGNER_NEW,
                  component: FormBuilderEditor,
                  componentProps: { isNew: true, isEdit: true },
                },
              ]
            : []),
        ]
      : []

    const assetRoutes = assetsEnabled
      ? [
          ...(canManageAssetProfile
            ? [
                {
                  path: URLS.ASSETS_PROFILES,
                  component: AssetProfileGallery,
                },
                {
                  path: URLS.ASSETS_PROFILE_VIEW,
                  component: AssetProfileBuilder,
                },
              ]
            : []),
          {
            path: URLS.ASSETS_PRINT_PREVIEW,
            component: AssetReport,
            layout: LongContentLayout,
            isTokenProtected: true,
          },
        ]
      : []

    const siteRoutes = siteEnabled
      ? [
          {
            path: URLS.SITES,
            component: SiteGallery,
          },
          {
            path: URLS.SITE_VIEW,
            component: SiteEdit,
          },
        ]
      : []

    const miscRoutes = [
      ...(canManageUser
        ? [
            {
              path: URLS.USERS,
              component: UserGallery,
            },
          ]
        : []),
      ...(issueEnabled
        ? [
            {
              path: URLS.ISSUES,
              component: IssueGallery,
            },
          ]
        : []),
      {
        path: URLS.SETTINGS,
        component: Settings,
      },
      {
        path: URLS.SETTING,
        component: Settings,
      },
    ]

    const privateRoutes = [
      ...mapRoutes,
      ...workflowRoutes,
      ...siteRoutes,
      ...assetRoutes,
      ...jsonFormRoutes,
      ...ancillaryDataRoutes,
      ...miscRoutes,
      ...devRoutes,
    ]

    return [...privateRoutes, ...publicRoutes]
  }, [
    ancillaryDataEnabled,
    assetsEnabled,
    canManageAssetProfile,
    canManageJsonForm,
    canManageUser,
    issueEnabled,
    jsonFormEnabled,
    siteEnabled,
  ])

  return (
    <Routes>
      {_.map(pluginState.sidebarRoute, (_route, idx) => {
        return (
          <Route
            path={pluginState.sidebarRoute[idx].normalizedRoutePath}
            key={pluginState.sidebarRoute[idx].normalizedRoutePath}
            element={
              <ProtectedElement
                component={SidebarRoutePlugin}
                componentProps={{ idx }}
              />
            }
          />
        )
      })}
      {_.map(routes, ({ path, element, ...rest }) => {
        return (
          <Route
            key={path}
            path={path}
            element={element || <ProtectedElement {...rest} />}
          />
        )
      })}
    </Routes>
  )
}

const SuRoutesContainer = (): ReactElement => {
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <SuRoutes />
      </BrowserRouter>
    </Suspense>
  )
}

export default SuRoutesContainer
