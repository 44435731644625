import { ReactElement } from 'react'
import _ from 'lodash'
import {
  useSortable,
  AnimateLayoutChanges,
  NewIndexGetter,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Option } from 'types/common'
import Item from '../Item'

const SortableItem = ({
  item,
  disabled,
  id,
  onRemove = _.noop,
  onChange,
  index,
  isCheckboxList = false,
}: {
  item: Option
  animateLayoutChanges?: AnimateLayoutChanges
  disabled?: boolean
  getNewIndex?: NewIndexGetter
  id: string | number
  index: number
  onRemove?: () => void
  onChange: () => void
  isCheckboxList?: boolean
}): ReactElement => {
  const { isDragging, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
      disabled,
    })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Item
      ref={setNodeRef}
      disabled={disabled}
      dragging={isDragging}
      onRemove={onRemove}
      style={style}
      listeners={listeners}
      item={item}
      onChange={onChange}
      index={index}
      isCheckboxList={isCheckboxList}
    />
  )
}

export default SortableItem
