// libraries
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import isEqual from 'fast-deep-equal'
import { useMountedState, useUpdateEffect } from 'react-use'
import delay from 'delay'
import { useRequest } from 'ahooks'

// constants
import { FILTER_CONDITIONS } from 'constants/filter'
import { GALLERY_LIST_FILTER_TYPES } from 'constants/common'

// utils
import {
  fetchListData,
  updateList,
  objectToBase64,
  fetchGraphQLListData,
} from 'helpers/utils'

import type { Filters } from 'types/filter'
import type { PageInfo } from 'types/graphql'
import type { AsyncState, Payload } from 'types/common'
import type { RelayStyleData, ListRelayStyleData } from 'services/api/utils'
import { QueryParams } from 'types/services'
import type { OnListItemChange } from './useListItemActions'

const encodeFilters = (filters: Filters, condition: string) =>
  objectToBase64(
    condition === FILTER_CONDITIONS.or ? { $or: filters } : filters
  )

export const getListFiltersParams = (
  filters: Filters,
  condition: string,
  isGraphql = false
): Payload => {
  const validFilters = _.omitBy(filters, filter =>
    _.isArray(filter) ? _.isEmpty(filter) : _.isNil(filter)
  )

  return _.isEmpty(validFilters)
    ? {}
    : {
        filter: isGraphql
          ? validFilters
          : encodeFilters(validFilters, condition),
      }
}

// https://ahooks.js.org/hooks/use-request/basic#options
const DEFAULT_USE_REQUEST_OPTIONS = {
  manual: true,
}

export type UseFetchListProps<T> = Partial<{
  initialList: T[]
  listFn: (payload: ListRelayStyleData<T>) => Promise<RelayStyleData<T>>
  updateListFn: (list: T[]) => void
  shouldFetchOnMount: boolean
  filters: Filters
  isGraphql: boolean
  first: number
  filtersCondition: string
  delayInSeconds: number
  listFnParams?: QueryParams
}>

export type UseFetchListState<T> = {
  list: T[]
  pageInfo: PageInfo
  listState: AsyncState<T[]>
  abortController?: AbortController
  onChange: OnListItemChange<T>
  setList: React.Dispatch<React.SetStateAction<T[]>>
  fetchMoreListData: () => Promise<T[]>
  fetchList: (enableLoadMore?: boolean) => Promise<T[]>
}

const useFetchList = <T>({
  listFn,
  updateListFn = _.noop,
  initialList,
  filters,
  filtersCondition = FILTER_CONDITIONS.and,
  isGraphql = true,
  delayInSeconds = 0,
  first,
  listFnParams,
}: UseFetchListProps<T>): UseFetchListState<T> => {
  const isMounted = useMountedState()

  const queryParams = useMemo(() => {
    if (!filters) return undefined

    const backendFilters = _.omit(filters, [
      GALLERY_LIST_FILTER_TYPES.isFavorite,
    ])
    return getListFiltersParams(backendFilters, filtersCondition, isGraphql)
  }, [filters, filtersCondition, isGraphql])

  const [list, setList] = useState(() => initialList || [])
  const [pageInfo, setPageInfo] = useState<PageInfo>({} as PageInfo)
  const [controller, setAbortController] = useState<AbortController>()

  const {
    // https://ahooks.js.org/hooks/use-request/basic#result
    runAsync: fetchList,
    loading,
    error,
    data,
  } = useRequest(async (enableLoadMore = false): Promise<T[]> => {
    if (!_.isFunction(listFn) || !queryParams) return []

    if (controller) {
      controller.abort()
      setAbortController(undefined)
    }

    if (!enableLoadMore) {
      setPageInfo({} as PageInfo)
      if (delayInSeconds) {
        await delay(delayInSeconds * 1000)
      }
    }

    const abortController = new AbortController()
    setAbortController(abortController)

    const result = isGraphql
      ? await fetchGraphQLListData({
          ...listFnParams,
          fetchFunc: listFn,
          queryParams: {
            ...queryParams,
            ...(first && { first }),
            ...(enableLoadMore && { after: pageInfo.endCursor || null }),
          },
          abortController,
        })
      : await fetchListData(listFn, { ...queryParams, ...listFnParams })

    if (isMounted()) {
      if (isGraphql) {
        const { data: resultData } = result
        setPageInfo(result.pageInfo)
        setList(oldList =>
          enableLoadMore ? [...oldList, ...resultData] : resultData
        )
      } else {
        setList(result)
      }
    }

    return result
  }, DEFAULT_USE_REQUEST_OPTIONS)

  const onChange = useCallback(
    (type: string, payload?: Partial<T>) => {
      const newList: T[] = updateList(list, type, payload)
      setList(newList)
    },
    [list]
  )

  useUpdateEffect(() => {
    fetchList()
  }, [queryParams])

  useUpdateEffect(() => {
    if (!isEqual(initialList, list)) {
      updateListFn(list)
    }
  }, [list])

  useUpdateEffect(() => {
    if (!initialList) return

    setList(oldList => (isEqual(initialList, oldList) ? oldList : initialList))
  }, [initialList])

  const fetchMoreListData = useCallback(() => fetchList(true), [fetchList])

  return {
    list,
    setList,
    onChange,
    fetchList,
    fetchMoreListData,
    pageInfo: pageInfo || {},
    abortController: controller,
    listState: { loading, error, value: data },
  }
}

export default useFetchList
