import moment from 'moment-timezone'
import keymirror from 'keymirror'
import _ from 'lodash'
import { switchcase } from 'helpers/utils'
import { TIME_RANGE_MODES } from 'constants/filter'

import type { Options } from 'types/common'
import type { IntervalUnit } from 'types/datetime'

export const UTC_ABBR = 'UTC'
export const YEAR_FORMAT = 'YYYY'
export const MONTH_FORMAT = `MMM, ${YEAR_FORMAT}`
export const DATE_FORMAT = `MMM DD, ${YEAR_FORMAT}`
export const DATE_HOUR_FORMAT = `${DATE_FORMAT} hA`
export const DATE_HOUR_FORMAT_NO_AM_PM = 'YYYY-MM-DD HH'
export const DATE_HOUR_TIMEZONE_FORMAT = 'YYYY-MM-DDTHH'
export const DATE_HOUR_MINUTE_FORMAT = `${DATE_FORMAT} HH:mm`
export const DATE_HOUR_MINUTE_TIMEZONE_FORMAT = `${DATE_HOUR_TIMEZONE_FORMAT}:mm`
export const DATE_HOUR_MINUTE_SECOND_TIMEZONE_FORMAT = `${DATE_HOUR_MINUTE_TIMEZONE_FORMAT}:ss`
export const DATE_HOUR_MINUTE_SECOND_FORMAT = `${DATE_HOUR_MINUTE_FORMAT}:ss`
export const DATE_HOUR_MINUTE_SECOND_FORMAT_STANDARD = 'YYYY-MM-DD HH:mm:ss'
export const DATE_TIME_AM_PM_FORMAT = 'YYYY-MM-DD h:mm:ss a'
export const TIME_FORMAT = 'HH:mm'

export const DATE_TIME_FORMAT = `YYYY-MM-DD ${TIME_FORMAT}`

export const MONTH_DAY_YEAR_FORMAT = 'MM/DD/YYYY'
export const HOUR_MINUTE_AM_PM = 'h:mm A'

export const DEFAULT_DATE_TIME_FORMAT = `${MONTH_DAY_YEAR_FORMAT} ${HOUR_MINUTE_AM_PM}`

export const DATE_UNIT_TYPES = keymirror({
  seconds: null,
  minutes: null,
  hours: null,
  days: null,
  weeks: null,
  months: null,
  years: null,
})

export const UNIT_TYPES_PRIORITY = [
  DATE_UNIT_TYPES.days,
  DATE_UNIT_TYPES.hours,
  DATE_UNIT_TYPES.minutes,
  DATE_UNIT_TYPES.seconds,
  DATE_UNIT_TYPES.months,
  DATE_UNIT_TYPES.years,
]

export const UNIT_TYPES_AFFECT_TIMEZONE = [
  DATE_UNIT_TYPES.days,
  DATE_UNIT_TYPES.months,
  DATE_UNIT_TYPES.years,
]

export const INTERVAL_UNIT_OPTIONS = [
  {
    value: DATE_UNIT_TYPES.years,
    label: 'Year',
  },
  {
    value: DATE_UNIT_TYPES.months,
    label: 'Month',
  },
  {
    value: DATE_UNIT_TYPES.days,
    label: 'Day',
  },
  {
    value: DATE_UNIT_TYPES.hours,
    label: 'Hour',
  },
  {
    value: DATE_UNIT_TYPES.minutes,
    label: 'Minute',
  },
  {
    value: DATE_UNIT_TYPES.seconds,
    label: 'Second',
  },
]

export const getIntervalUnitOptions = (append: boolean): Options => {
  return INTERVAL_UNIT_OPTIONS.map(({ value, label }) => {
    return {
      value,
      label: append ? `${label} ${append}` : label,
    }
  })
}

export const getDisplayTimeFormatter = (unit: IntervalUnit): string => {
  return switchcase({
    [DATE_UNIT_TYPES.years]: YEAR_FORMAT, // 2020
    [DATE_UNIT_TYPES.months]: MONTH_FORMAT, // Feb, 2020
    [DATE_UNIT_TYPES.weeks]: DATE_FORMAT, // Feb 08, 2020
    [DATE_UNIT_TYPES.days]: DATE_FORMAT, // Feb 08, 2020
    [DATE_UNIT_TYPES.hours]: DATE_HOUR_FORMAT, // Feb 08, 2020 4PM
    [DATE_UNIT_TYPES.minutes]: DATE_HOUR_MINUTE_FORMAT, // Feb 08, 2020 16:00
    [DATE_UNIT_TYPES.seconds]: DATE_HOUR_MINUTE_SECOND_FORMAT, // Feb 08, 2020 16:00:00
  })(DATE_HOUR_MINUTE_FORMAT)(unit)
}

export const getTimeFormatter = (unit: IntervalUnit): string => {
  return switchcase({
    [DATE_UNIT_TYPES.years]: YEAR_FORMAT, // 2020
    [DATE_UNIT_TYPES.months]: MONTH_FORMAT, // Feb, 2020
    [DATE_UNIT_TYPES.weeks]: DATE_FORMAT, // Feb 08, 2020
    [DATE_UNIT_TYPES.days]: DATE_FORMAT, // Feb 08, 2020
    [DATE_UNIT_TYPES.hours]: DATE_HOUR_MINUTE_FORMAT, // Feb 08, 2020 16:00 (otherwise the charting library won't be able recognize the time)
    [DATE_UNIT_TYPES.minutes]: DATE_HOUR_MINUTE_FORMAT, // Feb 08, 2020 16:00
    [DATE_UNIT_TYPES.seconds]: DATE_HOUR_MINUTE_SECOND_FORMAT, // Feb 08, 2020 16:00:00
  })(DATE_HOUR_MINUTE_FORMAT)(unit)
}

// Getting all Zones
export const TIME_ZONES_LIST = moment.tz.names()

export const TIME_ZONES_OPTIONS = _(TIME_ZONES_LIST)
  .filter(region => region.startsWith('America/'))
  .map(name => {
    const newName = name.replace(/[_\s]/g, ' ')
    return { value: name, label: newName }
  })
  .value()

const COMMONLY_USED_RANGE = [
  { value: `1 ${DATE_UNIT_TYPES.days}`, label: 'Last 1 day' },
  { value: `15 ${DATE_UNIT_TYPES.minutes}`, label: 'Last 15 minutes' },
  { value: `30 ${DATE_UNIT_TYPES.minutes}`, label: 'Last 30 minutes' },
  { value: `24 ${DATE_UNIT_TYPES.hours}`, label: 'Last 24 hours' },
  { value: `7 ${DATE_UNIT_TYPES.days}`, label: 'Last 7 days' },
  { value: `this ${DATE_UNIT_TYPES.weeks}`, label: 'This week' },
  { value: `30 ${DATE_UNIT_TYPES.days}`, label: 'Last 30 days' },
  { value: `90 ${DATE_UNIT_TYPES.days}`, label: 'Last 90 days' },
  { value: `1 ${DATE_UNIT_TYPES.years}`, label: 'Last 1 year' },
]

export const QUICK_DATETIME_PICKER_OPTIONS = [
  { value: `1 day ago`, label: 'Last 1 day' },
  { value: `2 days ago`, label: 'Last 2 days' },
  { value: `1 week ago`, label: 'Last 1 week' },
  { value: `1 month ago`, label: 'Last 1 month' },
]

export const DEFAULT_QUICK_DATETIME_PICKER_VALUE = '2 days ago'

export const getCommonlyUsedOptions = (): Options => {
  return COMMONLY_USED_RANGE.map(item => {
    const { value, label } = item
    return {
      label,
      value: {
        startTime: {
          mode: TIME_RANGE_MODES.relativeTime,
          value,
        },
        endTime: { mode: TIME_RANGE_MODES.now, value: TIME_RANGE_MODES.now },
      },
    }
  })
}

const DEFAULT_RELATIVE_TIME_NUM = 15
const DEFAULT_RELATIVE_UNIT = DATE_UNIT_TYPES.minutes
export const DEFAULT_RELATIVE_TIME = `${DEFAULT_RELATIVE_TIME_NUM} ${DEFAULT_RELATIVE_UNIT}`

export const MOMENT_LOCALE_RELATIVE_TIME = {
  future: 'in %s',
  past: '%s ago',
  s: '%d seconds',
  ss: '%d seconds',
  m: '%d minutes',
  mm: '%d minutes',
  h: '%d hours',
  hh: '%d hours',
  d: '%d days',
  dd: '%d days',
  M: '%d months',
  MM: '%d months',
  y: '%d years',
  yy: '%d years',
}

export const MONTH_REPEAT_BY = keymirror({
  week: null,
  day: null,
})

export const WEEKS_IN_MONTH = _.range(1, 6)

export const DAYS_IN_MONTH = _.range(1, 32)

export const DATE_TIME_PICKER_POPPER_MODIFIERS = [
  {
    name: 'offset',
    options: {
      offset: [0, 10],
    },
  },
]
