import { ReactElement, useCallback } from 'react'

// constants
import { TIME_ZONES_OPTIONS } from 'constants/datetime'
import { USER_PREFERENCES } from 'constants/settings'

// utils
import { getUserEnableTimezone } from 'helpers/user'

// components
import { MultiSelect } from 'components/common'

import type { User } from 'types/user'

const DEFAULT_UTC = 'Default (UTC)'
const TIME_ZONES_OPTIONS_WITH_DEFAULT = [
  {
    label: DEFAULT_UTC,
    value: DEFAULT_UTC,
  },
  ...TIME_ZONES_OPTIONS,
]

export type UserTimeZoneSettingType = {
  user: User
  onChange: (v: Partial<User>) => void
  className?: string
}

const UserTimeZoneSetting = ({
  user,
  onChange,
  className,
}: UserTimeZoneSettingType): ReactElement => {
  const { timezone, username } = user
  const enableTimezone = getUserEnableTimezone(user)

  const onSubmit = useCallback(
    async (newTimezone: string) => {
      const validUsername = username
      const newPayload = {
        ...(newTimezone !== DEFAULT_UTC && { timezone: newTimezone }),
        preferences: {
          username: validUsername,
          preference: USER_PREFERENCES.enableTimezone,
          value: newTimezone !== DEFAULT_UTC,
        },
        username: validUsername,
      }
      onChange(newPayload as Partial<User>)
    },
    [username, onChange]
  )

  return (
    <MultiSelect
      className={className}
      placeholder='select a alarm sound'
      value={enableTimezone ? timezone : DEFAULT_UTC}
      options={TIME_ZONES_OPTIONS_WITH_DEFAULT}
      onChange={onSubmit}
      isMulti={false}
      isClearable={false}
      isDisabled={false}
      useOptionValueOnly
      withBorder
    />
  )
}

export default UserTimeZoneSetting
