// libraries
import _ from 'lodash'
import { EnumType } from 'json-to-graphql-query'

// constants
import {
  ISSUE_USER_ASSIGNEES_FIELDS,
  ENTITY_OWNER_FIELDS,
} from 'constants/graphql'
import {
  ISSUE_DETAIL_FRAGMENT,
  ISSUE_SEVERITY_TYPES_FIELDS,
  ISSUE_ACTION_TYPES,
  ISSUE_TYPE_FILTER,
} from 'constants/issue'
import { ENTITIES } from 'constants/common'

// utils
import {
  getConnectionData,
  getArgs,
  getEdges,
  getPageInfo,
  getQuery,
  getMutationQuery,
  getGraphqlQuery,
  reportGraphqlError,
  getQueryFields,
} from 'helpers/graphql'
import {
  mutateEntity,
  listRelayStyleData,
  MutateEntity,
} from 'services/api/utils'
import { getUserOption } from 'helpers/user'
import { capitalizeFirstLetter } from 'helpers/utils'
import { getIssueFilterConditions, getActiveIssues } from 'helpers/issue'

import type { Payload, Options } from 'types/common'
import type { QueryParams } from 'types/services'
import type { User } from 'types/user'
import type {
  DataCollectionForm,
  Issue,
  IssueDataCollectionFormReferences,
  IssueTask,
} from 'types/issue'

import { PageInfo } from 'types/graphql'
import GraphqlApi from './graphql'

const queryDomain = ENTITIES.issue

const domain = queryDomain

export const reportIssueError = reportGraphqlError(queryDomain)

export const getIssuesQueryFields = getQueryFields(ISSUE_DETAIL_FRAGMENT)

export const getIssuesQuery = (
  queryParams: QueryParams,
  omitFields: string[]
): Payload => {
  const issues = {
    filteredBy: {
      ...getArgs(queryParams),
      ...getEdges(getIssuesQueryFields({ omitFields })),
      ...getPageInfo(),
    },
  }
  return {
    issues,
  }
}

const getJsonForm = () => {
  return {
    jsonFormBody: {
      schema: true,
      uischema: true,
    },
  }
}

const getXFormFields = () => {
  return {
    xForm: {
      model: true,
      body: {
        id: true,
        __on: [
          {
            __typeName: 'XFormBodyControlNode',
            control: true,
            label: true,
            hint: true,
            ref: true,
            mediatype: true,
            accept: true,
            items: {
              label: true,
              value: true,
            },
            itemset: {
              nodeset: true,
              labelRef: true,
              valueRef: true,
            },
          },
          {
            __typeName: 'XFormBodyGroupNode',
            appearance: true,
            childNodeIds: true,
            ref: true,
            nodeset: true,
            label: true,
          },
        ],
      },
      binds: {
        type: true,
        nodeset: true,
        required: true,
        relevant: true,
        readonly: true,
        constraint: true,
        calculate: true,
      },
    },
  }
}

const getSubjectRules = () => {
  return {
    subjectRules: {
      assetFilter: {
        profile: true,
        displayName: true,
      },
    },
  }
}

const ISSUE_TASK_DATA_COLLECTION_FORM_COMMON_FIELDS = {
  id: true,
  title: true,
  formType: true,
  isActive: true,
  description: true,
  subjectAssetProfile: true,
}

const getIssueTaskDataCollectionFormCommon = (formTypes: string[]) => {
  return {
    ...(!_.isEmpty(formTypes) &&
      getArgs({
        formTypes: _.map(formTypes, formType => new EnumType(formType)),
      })),
    ...ISSUE_TASK_DATA_COLLECTION_FORM_COMMON_FIELDS,
  }
}

const getIssueTaskDataCollectionFormDefinition = ({
  formTypes,
}: {
  formTypes: string[]
}) => {
  return {
    issueTask: {
      dataCollectionForms: {
        ...getIssueTaskDataCollectionFormCommon(formTypes),
        ...getXFormFields(),
        ...getJsonForm(),
      },
    },
  }
}

const getIssueDataCollectionFields = ({
  formTypes,
  withFormDefinition = false,
}: {
  formTypes: string[]
  withFormDefinition: boolean
}) => {
  return {
    issueMultitask: {
      procedures: {
        id: true,
        title: true,
        subTasksStaticFormReferences: true,
        subTasksDynamicFormReferences: true,
        ...getSubjectRules(),
      },
    },
    issueTask: {
      dataCollectionForms: {
        ...getIssueTaskDataCollectionFormCommon(formTypes),
        isSubtaskForm: true,
        isTaskForm: true,
        filterQuestions: {
          displayName: true,
          xFormRef: true,
          items: {
            label: true,
            value: true,
          },
        },
        ...ENTITY_OWNER_FIELDS,
        ...getSubjectRules(),
        modifiedAt: true,
        ...(withFormDefinition && { ...getXFormFields(), ...getJsonForm() }),
      },
    },
  }
}

const getIssueQuery = (issueId: string) => {
  return {
    issues: {
      byId: {
        ...getArgs({ issueId }),
        ...getIssuesQueryFields({ omitFields: [] }),
      },
    },
  }
}

export const getIssueAssigneesAndSeverityTypes = async (): Promise<{
  assigneesOptions: Options<User>
  issueSeverityTypes: string[]
}> => {
  const fnName = 'getIssueAssigneesAndSeverityTypes'
  const query = getQuery(
    {
      issueUserAssignees: getEdges(ISSUE_USER_ASSIGNEES_FIELDS),
      issueSeverityTypes: ISSUE_SEVERITY_TYPES_FIELDS,
    },
    fnName
  )

  const response = await GraphqlApi.fetch({ query, queryDomain, fnName })
  const { issueUserAssignees, issueSeverityTypes } = response
  const assigneesOptions =
    getConnectionData(issueUserAssignees).map(getUserOption)
  return { assigneesOptions, issueSeverityTypes }
}

const getIssueFormFilter = (filter?: { form: Payload<string[]> }) => {
  const { form } = filter || {}
  return (
    form && {
      formFilter: _.reduce(
        form,
        (acc, cur, key) => {
          return {
            ...acc,
            [key]:
              key === 'taskStatus' ? _.map(cur, d => new EnumType(d)) : cur,
          }
        },
        {}
      ),
    }
  )
}

const getQueryFn = ({ queryParams }: { queryParams: QueryParams }) =>
  getIssuesQuery(queryParams, ['statesData'])

export const listIssues = async ({
  queryParams = {},
  abortController,
}: {
  queryParams?: QueryParams
  abortController: AbortController
}): Promise<{
  data: Issue[]
  error?: string
  pageInfo: PageInfo
}> => {
  const fnName = 'issues.filteredBy'
  const { enableLoadMore = true, filter, ...restQueryParams } = queryParams

  const formFilterPayload = getIssueFormFilter(filter)
  // TODO add deleted:false when backend supports
  const defaultFilters = { ...ISSUE_TYPE_FILTER }
  const newQueryParams = {
    ...restQueryParams,
    filter: getIssueFilterConditions({ ...filter, ...defaultFilters }),
    ...formFilterPayload,
  }

  const { data, ...rest } = await listRelayStyleData({
    queryParams: newQueryParams,
    queryDomain,
    fnName,
    getQueryFn,
    abortController,
    enableLoadMore,
  })

  return { ...rest, data: getActiveIssues(data as Issue[]) }
}

export const listIssueTaskDataCollectionFormDefinition = (variables: {
  formTypes: string[]
}): Promise<{ issueTask: IssueTask } & { error?: string; code?: string }> => {
  const fnName = 'listIssueTaskDataCollectionFormDefinition'
  const query = getQuery(
    getIssueTaskDataCollectionFormDefinition(variables),
    fnName
  )
  return GraphqlApi.fetch({ query, queryDomain, fnName, variables })
}

export const listIssueTaskDataCollectionFormMetadata = (variables: {
  formTypes: string[]
}): Promise<
  IssueDataCollectionFormReferences & { error?: string; code?: string }
> => {
  const fnName = 'listIssueTaskDataCollectionFormMetadata'
  const query = getQuery(getIssueDataCollectionFields(variables), fnName)
  return GraphqlApi.fetch({ query, queryDomain, fnName, variables })
}

export const getIssue = async (id: string): Promise<Issue> => {
  const fnName = 'issues.byId'
  const query = getQuery(getIssueQuery(id), fnName)
  const response = await GraphqlApi.fetch({
    query,
    queryDomain,
    fnName,
    variables: { id },
  })
  return _.get(response, fnName)
}

export const getJsonFormQueryFields = getQueryFields({
  ...ISSUE_TASK_DATA_COLLECTION_FORM_COMMON_FIELDS,
  ...getJsonForm(),
  ...getSubjectRules(),
  ...ENTITY_OWNER_FIELDS,
  modifiedAt: true,
})

const getJsonFormQuery = (dataCollectionFormId: string): Payload => {
  return {
    issueTask: {
      dataCollectionForm: {
        ...getArgs({ id: dataCollectionFormId }),
        ...getJsonFormQueryFields(),
      },
    },
  }
}

export const getIssueTaskDataCollectionForm = async (
  id: string
): Promise<DataCollectionForm> => {
  const fnName = 'issueTask.dataCollectionForm'
  const query = getQuery(getJsonFormQuery(id), fnName)
  const response = await GraphqlApi.fetch({
    query,
    queryDomain,
    fnName,
    variables: { id },
  })
  return _.get(response, fnName)
}

export const mutateIssueFactory =
  (mutationName: string, inputName: string) =>
  async (argsValue: Payload): Promise<{ error?: string } & Issue> => {
    const variableKey = 'input'
    const mutationInCapitalized = capitalizeFirstLetter(mutationName)
    const fnName = mutationName
    const variableFormat = inputName || `${mutationInCapitalized}Input!`
    const fields = { issue: getIssuesQueryFields() }
    const variables = { [variableKey]: argsValue }
    const query = getMutationQuery({
      fields,
      variableKey,
      mutationName,
      variableFormat,
    })

    const response = await GraphqlApi.fetch({
      query,
      queryDomain,
      variables,
      fnName,
    })
    const result = _.get(response, [fnName, 'issue']) || {}
    return { ...result, error: response.error }
  }

const getBulkUpdateIssuesMutationQuery = (variables: Payload) => {
  return getGraphqlQuery({
    mutation: {
      __name: 'BulkUpdateIssues',
      bulkUpdateIssues: {
        __args: variables,
        foundIssuesCount: true,
      },
    },
  })
}

export const bulkUpdateIssues = async ({
  variables,
}: {
  variables: Payload
}): Promise<{ error?: string } & { foundIssuesCount?: number }> => {
  const fnName = ISSUE_ACTION_TYPES.bulkUpdateIssues
  const query = getBulkUpdateIssuesMutationQuery(variables)
  const response = await GraphqlApi.fetch({
    query,
    variables,
    queryDomain,
    fnName,
  })
  const result = _.get(response, [fnName]) || {}
  return { ...result, error: response.error }
}

const mutateIssue = (props: Omit<MutateEntity, 'queryDomain'>) =>
  mutateEntity<Issue>({
    queryDomain,
    responseFields: {
      [domain]: getIssuesQueryFields(),
    },
    identifier: 'issueId',
    responsePath: [domain],
    ...props,
  })

export const updateIssueStateData = mutateIssue({
  fnName: 'updateIssueStateData',
  variableFormat: 'UpdateIssueStateDataInput!',
})

export const triggerIssueTask = mutateIssue({
  fnName: 'triggerIssueTask',
  variableFormat: 'TriggerIssueTaskInput!',
  withIdentifier: false,
  ignoreError: true,
})

export const triggerIssueMultitask = mutateIssue({
  fnName: 'triggerIssueMultitask',
  variableFormat: 'TriggerIssueMultitaskInput!',
  withIdentifier: false,
  ignoreError: true,
})

export const deleteIssue = mutateIssue({
  fnName: 'deleteIssue',
  variableFormat: 'DeleteIssueInput!',
  withIdentifier: false,
  ignoreError: true,
})
