// libraries
import { ReactElement, useState, useEffect, useMemo, useCallback } from 'react'
import { useMount, useUpdateEffect } from 'react-use'
import RcSlider from 'rc-slider'
import _ from 'lodash'

// component
import { NumericInput } from 'components/common'

// util
import { useRangeSliderStyle } from 'hooks'

const { createSliderWithTooltip } = RcSlider
const TooltipSlider = createSliderWithTooltip(RcSlider)

const getValidRange = (
  val: number,
  valRange: [number, number],
  changeable = false
) => {
  if (!changeable) return valRange

  let newRange = valRange
  const [min, max] = valRange
  if (val > max) {
    newRange = [min, val]
  } else if (val < min) {
    newRange = [val, max]
  }
  return newRange
}

export const Slider = ({
  value, // current value of slider
  range, // minimum and maximum value of the slider
  onChange,
  defaultValue,
  ...rest
}: {
  onChange: (v: number) => void
  step?: number
  value?: number
  range?: [number, number]
  className?: string
  sliderSize?: number
  disabled: boolean
  defaultValue?: number
}): ReactElement => {
  const [sliderValue, setSliderValue] = useState(value)

  const styleOverride = useRangeSliderStyle('slider')

  useEffect(() => {
    setSliderValue(value)
  }, [value])

  return _.isNumber(sliderValue) ? (
    <TooltipSlider
      {...styleOverride}
      {...rest}
      min={range[0]}
      max={range[1]}
      value={sliderValue}
      // only change the internal value when dragging the slider
      onChange={setSliderValue}
      // change the value when the dragging action is finished
      onAfterChange={onChange}
    />
  ) : (
    <></>
  )
}

Slider.defaultProps = {
  step: 1,
  sliderSize: 8,
}

const getValidValue = (
  value: number,
  range = [],
  defaultValue: number
): number => {
  const [min, max] = range
  const newValue = _.isNil(value) ? defaultValue : value
  return _.clamp(newValue, min, max)
}

export const SliderContainer = ({
  value,
  range,
  onChange,
  changeable,
  sliderSize,
  onBlur,
  onFocus,
  className,
  defaultValue,
  ...rest
}: {
  onChange: (v: number) => void
  step?: number
  value?: number
  range?: [number, number]
  changeable?: boolean
  className?: string
  sliderSize?: number
  onBlur?: () => void
  onFocus?: () => void
  disabled?: boolean
  defaultValue?: number
}): ReactElement => {
  const [sliderValue, setSliderValue] = useState<number>()

  const [rangeValue, setRangeValue] = useState(() =>
    getValidRange(value, range, changeable)
  )

  const rangeProps = useMemo(
    () =>
      !changeable && rangeValue && { min: rangeValue[0], max: rangeValue[1] },
    [changeable, rangeValue]
  )

  const onSliderNumberChange = useCallback(
    (v: number) => {
      let val
      if (changeable) {
        const validRange = getValidRange(v, rangeValue)
        setRangeValue(validRange)
        val = v
      } else {
        val = getValidValue(v, range)
      }
      setSliderValue(val)
      onChange(val)
    },
    [changeable, onChange, range, rangeValue]
  )

  useMount(() => {
    setSliderValue(getValidValue(value, range, defaultValue))
  })

  useUpdateEffect(() => {
    setSliderValue(getValidValue(value, range))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useUpdateEffect(() => {
    setRangeValue(getValidRange(value, range, changeable))
  }, [value, range, changeable])

  return (
    <div className={`row g-3 ${className} d-flex align-items-center`}>
      <div className={`col-${sliderSize}`}>
        <Slider
          {...rest}
          value={sliderValue}
          range={rangeValue}
          onBeforeChange={() => {
            onFocus()
          }}
          onChange={val => {
            onChange(val)
            onBlur()
          }}
        />
      </div>
      <div className={`col-${12 - sliderSize} text-center`}>
        <NumericInput
          {...rest}
          {...rangeProps}
          value={sliderValue}
          className='form-control rangeSliderInput'
          onChange={onSliderNumberChange}
          testId='slider-number'
        />
      </div>
    </div>
  )
}

SliderContainer.defaultProps = {
  step: 1,
  changeable: false,
  className: '',
  sliderSize: 8,
  onBlur: _.noop,
  onFocus: _.noop,
  disabled: false,
}

export default SliderContainer
