import { ReactElement } from 'react'
import { TitleWithTooltip } from 'components/common'
import Range from 'components/common/RangeSlider/Range'
import Slider from 'components/common/RangeSlider/Slider'
import 'rc-slider/assets/index.css'

const RangeSliderContainer = ({
  title,
  tooltip,
  isSlider,
  rangeValue,
  children,
  noGroupStyle,
  ...rest
}: {
  title?: string
  tooltip?: string
  isSlider?: boolean
  rangeValue: [number, number] // range
  range: [number, number] // slider
  noGroupStyle?: boolean
  disabled?: boolean
  children?: ReactElement
}): ReactElement => {
  const renderRangeSlider = () => (
    <>
      {isSlider ? (
        <Slider {...rest} />
      ) : (
        <Range {...rest} rangeValue={rangeValue || []} />
      )}
      {children}
    </>
  )

  return noGroupStyle ? (
    renderRangeSlider()
  ) : (
    <div className='groupOption'>
      <div className='groupOptionTitle d-flex justify-content-between'>
        <TitleWithTooltip title={title} tooltip={tooltip} />
        {isSlider ? null : rangeValue.join(' - ')}
      </div>
      <div className='groupOptionContent'>{renderRangeSlider()}</div>
    </div>
  )
}

RangeSliderContainer.defaultProps = {
  title: '',
  tooltip: '',
  isSlider: true,
  noGroupStyle: false,
  disabled: false,
}

export default RangeSliderContainer
