import _ from 'lodash'
import { ReactElement, ReactNode } from 'react'
import styled from '@emotion/styled'

// constants
import { PAGE_HEADER_TAB_POSITION } from 'constants/common'

import type { PageHeaderTabPosition } from 'types/common'
import scss from './index.module.scss'

export type PageHeaderTabsProps = {
  tabs: {
    label?: string
    value: string
  }[]
  selectedTab?: string
  onTabSelected: (v: string) => void
  tabsPosition: PageHeaderTabPosition
}

const StyledTab = styled.div<{
  isActive: boolean
}>`
  border-bottom: 2px solid
    ${props => (props.isActive ? props.theme.primary : 'none')};
  cursor: pointer;
`

const Tabs = ({
  tabs,
  className,
  selectedTab,
  onTabSelected,
}: Omit<PageHeaderTabsProps, 'tabsPosition'> & { className?: string }) => {
  return (
    <div className={`${scss.tabs} ${className}`}>
      <ul className={scss.tabNav}>
        {_.map(tabs, ({ label, value }) => (
          <StyledTab
            className={scss.tabHeader}
            isActive={selectedTab === value}
            onClick={() => onTabSelected(value)}
            key={value}
          >
            {label || _.capitalize(value)}
          </StyledTab>
        ))}
      </ul>
    </div>
  )
}

const StyledContainer = styled.div<{
  withTabs: boolean
}>`
  height: ${props => (props.withTabs ? '72px' : '65px')};
`

const PageHeader = ({
  className,
  children,
  iconComponent,
  titleComponent,
  titleAddonComponent,
  subtitleComponent,
  rightComponent,
  toolbarComponent,
  tabsProps,
  titleFullWidth,
}: {
  iconComponent?: ReactNode
  titleComponent: ReactNode
  subtitleComponent?: ReactNode
  rightComponent?: ReactNode
  titleAddonComponent?: ReactNode
  className?: string
  children?: ReactNode
  toolbarComponent?: ReactNode
  tabsProps?: PageHeaderTabsProps
  titleFullWidth?: boolean
}): ReactElement => {
  const { tabsPosition, tabs } = tabsProps || {}
  return (
    <header className='sticky-top flex-md-nowrap'>
      <StyledContainer
        className={`d-flex align-items-center justify-content-between ${scss.container} ${className}`}
        withTabs={
          !_.isEmpty(tabs) && tabsPosition === PAGE_HEADER_TAB_POSITION.left
        }
      >
        <div className='flex-grow-1'>
          <div className='d-flex align-items-center'>
            {iconComponent}
            <div className={`flex-grow-1 ${scss.leftComponent}`}>
              <div className='d-flex flex-grow-1 align-items-center'>
                <h1
                  className={`${scss.title} ${titleFullWidth ? 'w-100' : ''}`}
                >
                  {titleComponent}
                </h1>
                {titleAddonComponent}
              </div>
              {!_.isEmpty(tabs) &&
              tabsPosition === PAGE_HEADER_TAB_POSITION.left ? (
                <Tabs {...tabsProps} />
              ) : (
                <>{subtitleComponent}</>
              )}
            </div>
          </div>
        </div>
        {!_.isEmpty(tabs) &&
          tabsPosition === PAGE_HEADER_TAB_POSITION.middle && (
            <Tabs className={scss.center} {...tabsProps} />
          )}
        <div className='d-flex align-items-center'>
          {children}
          {rightComponent}
        </div>
      </StyledContainer>
      {toolbarComponent && (
        <div className={scss.toolbar}>{toolbarComponent}</div>
      )}
    </header>
  )
}

export default PageHeader
