import { ReactElement, useMemo, useState, useEffect } from 'react'
import { GeoJsonLayer } from 'deck.gl'
import keymirror from 'keymirror'
import _ from 'lodash'
import { point, lineString } from '@turf/helpers'
import type { Tabulator as TabulatorTypes } from 'tabulator-tables'
import * as XLSX from 'xlsx'

// constants
import { MAP_STYLE_TYPES } from 'constants/map'

// utils
import { useMapViewState } from 'components/map/hooks'
import { MapProvider, useMapStateValue } from 'contexts'
import { getNewMap } from 'helpers/mapUtils'
import { getObjectHash, CSVArrayToObj } from 'helpers/utils'

// components
import { Map, Table } from 'components/common'
import LabeledScatterplotLayer from 'components/map/layers/deckLayers/pointLayer/labeledPointLayer'

// style
import { THEMES } from 'constants/colour'
import { Payload } from 'types/common'
import scss from './index.module.scss'

const SCHEDULE_OPTIMIZATION_PROCESSED_RESULT = keymirror({
  summary: null,
  worker_summary: null,
  result: null,
})

type Sheet = {
  [SCHEDULE_OPTIMIZATION_PROCESSED_RESULT.result]: Payload[]
  [SCHEDULE_OPTIMIZATION_PROCESSED_RESULT.summary]: Payload[]
  // eslint-disable-next-line camelcase
  [SCHEDULE_OPTIMIZATION_PROCESSED_RESULT.worker_summary]: Payload[]
}

type ResultPreviewProp = {
  result: string
}

const ResultPreview = ({ result }: ResultPreviewProp): ReactElement => {
  const sheetData = useMemo(() => {
    if (!result) return []

    const wb = XLSX.read(result, { type: 'base64' })
    return wb.SheetNames.reduce(
      (acc, sheetName) => ({
        ...acc,
        [sheetName]: CSVArrayToObj(
          _.compact(
            XLSX.utils.sheet_to_json(wb.Sheets[sheetName], { header: 1 })
          )
        ),
      }),
      {}
    )
  }, [result]) as Sheet

  const { map, mapRef, mapCanvasRef } = useMapStateValue()

  const [selectedData, setSelectedData] = useState<{
    date: string
    asset: string
    sites: string[]
    tasks: string[]
    coordinates: [number, number][]
  }>()

  const { coordinates } = selectedData || {}

  const onResultRowClick = (
    _e: PointerEvent,
    row: TabulatorTypes.RowComponent
  ) => {
    const rowData = row.getData()
    const data = {
      ...rowData,
      ...(rowData.coordinates && {
        coordinates: JSON.parse(rowData.coordinates),
      }),
    }

    setSelectedData(data)
  }

  const { viewState, onViewStateChange, fitFeaturesBound } = useMapViewState({
    mapRef,
    mapCanvasRef,
    enableAnimation: false,
  })

  useEffect(() => {
    if (!selectedData) return

    const features = _.map(coordinates, coordinate => {
      return point(coordinate)
    })
    fitFeaturesBound(features, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData])

  const layersList = useMemo(() => {
    if (!selectedData) return []

    const { coordinates: data, asset } = selectedData

    if (!data) return []

    const id = `${asset}-${getObjectHash(data)}`

    const line = lineString(data, { name: 'asset' })

    return [
      new GeoJsonLayer({
        id: `${id}-geojson`,
        data: line,
        lineWidthScale: 10,
        getLineColor: [47, 158, 227],
        getLineWidth: 10,
      }),
      new LabeledScatterplotLayer({
        id: `${id}-point`,
        data,
        radiusScale: 10,
        radiusMinPixels: 1,
        radiusMaxPixels: 100,
        getPosition: d => d,
        getRadius: 32,
        getFillColor: [0, 0, 0],
        getText: (_d, { index }: { index: string }) => `${index + 1}`,
        getTextColor: [252, 252, 252],
        getTextSize: 1,
        getTextSizeScale: 20,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
      }),
    ]
  }, [selectedData])

  return (
    <>
      <div className='row row-cols-1 row-cols-md-5 g-4'>
        {_.map(
          sheetData[SCHEDULE_OPTIMIZATION_PROCESSED_RESULT.summary],
          ({
            title,
            description,
            result: value,
            note,
          }: {
            title: string
            description?: string
            result: string | number
            note?: string
          }) => {
            return (
              <div className='col'>
                <div className='card h-100'>
                  <div className='card-body'>
                    <h5 className='card-title'>{title}</h5>
                    <div className='card-text'>
                      <div className='text-secondary defaultText'>
                        {description || ' '}
                      </div>
                      <div>
                        <span className={scss.number}>{value}</span>
                        {note && <span>{note}</span>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        )}
      </div>
      {sheetData[SCHEDULE_OPTIMIZATION_PROCESSED_RESULT.result] && (
        <div className='row mt-4'>
          <div className={`${_.isEmpty(selectedData) ? 'col-12' : 'col-8'}`}>
            <Table
              data={sheetData[SCHEDULE_OPTIMIZATION_PROCESSED_RESULT.result]}
              className={scss.table}
              options={{ downloadFileTypes: [] }}
              onRowClick={onResultRowClick}
              theme={THEMES.light}
            />
          </div>
          {!_.isEmpty(selectedData) && !_.isEmpty(coordinates) && (
            <div className='col-4'>
              <Map
                map={map}
                mapRef={mapRef}
                mapCanvasRef={mapCanvasRef}
                layersList={layersList}
                viewState={viewState}
                onViewStateChange={onViewStateChange}
                className='position-relative h-100'
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}
const ResultPreviewContainer = (props: ResultPreviewProp): ReactElement => {
  const { result } = props
  const [map] = useState(() => getNewMap({ style: MAP_STYLE_TYPES.light }))
  return result ? (
    <MapProvider initialMap={map}>
      <ResultPreview {...props} />
    </MapProvider>
  ) : (
    <div className='d-flex justify-content-center align-items-center h-100'>
      <img
        src='/assets/logo/sensorup-logo-icon-light.svg'
        alt='SensorUp Logo'
        width='500'
        height='500'
      />
      <div className='position-absolute'>Start with Importing a file</div>
    </div>
  )
}

export default ResultPreviewContainer
