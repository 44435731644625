// libraries
import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useToggle } from 'react-use'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import _ from 'lodash'

// constants
import {
  WIDGET_TYPES,
  DEFAULT_WIDGET_COLOUR,
  BAR_CHART_TYPES,
  DEFAULT_MAX_BARS_NUM,
  DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE,
} from 'constants/widget'
import { SUPPORTED_FILTER_PROPERTY_TYPES } from 'constants/filter'
import { INTERVAL_UNIT_OPTIONS, DATE_UNIT_TYPES } from 'constants/datetime'
import { DEFAULT_AGGREGATION_KEY_TYPE } from 'constants/aggregation'

// utils
import { isRangeValid } from 'helpers/utils'
import {
  getNonDatetimeProperties,
  validNumericProperties,
  isTimePropertyFromPropertyOptionAndValue,
} from 'helpers/filter'
import { useUpdateWidgetSettings } from 'hooks'

// components
import {
  BladeTabHeader,
  MultiSelect,
  PropertyPicker,
  TitleWithTooltip,
  Toggle,
  NumericInput,
  DataAggregation,
  HideValueToggle,
  TitleWithSort,
} from 'components/common'
import ColourSetting from 'components/map/layers/widgets/common/ColourSetting'

const widgetType = WIDGET_TYPES.bar

const NoLimitBarToggle = ({ maxBarsNum, onChange }) => {
  const [noLimitBars, toggleNoLimitBars] = useToggle(maxBarsNum === -1)

  const noLimitBarsHandler = useCallback(() => {
    toggleNoLimitBars()
    const num = noLimitBars ? DEFAULT_MAX_BARS_NUM : -1
    onChange({ maxBarsNum: num })
  }, [noLimitBars, onChange, toggleNoLimitBars])

  return (
    <div className='groupOption'>
      <div className='groupOptionTitle d-flex justify-content-between align-items-center'>
        Maximum Bars
        <Toggle
          label={noLimitBars ? 'No Limit' : ''}
          checked={noLimitBars}
          onToggle={noLimitBarsHandler}
        />
      </div>
      {!noLimitBars && (
        <div className='groupOptionContent'>
          <NumericInput
            min={1}
            value={maxBarsNum}
            onChange={val => onChange({ maxBarsNum: val })}
            className='form-control'
          />
        </div>
      )}
    </div>
  )
}

NoLimitBarToggle.propTypes = {
  maxBarsNum: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

NoLimitBarToggle.defaultProps = {
  maxBarsNum: DEFAULT_MAX_BARS_NUM,
}

const BarFeaturesOptionsTab = ({
  options,
  propertyOptions,
  updateSettings,
}) => {
  const barType = BAR_CHART_TYPES.features
  const {
    aggregation = DEFAULT_AGGREGATION_KEY_TYPE,
    valueRange = [],
    hideValuesOutsideOfValueRange = DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE,
    sortYAxisValues,
    maxBarsNum = DEFAULT_MAX_BARS_NUM,
    isAscendingOrder = true,
    colour = DEFAULT_WIDGET_COLOUR,
    isSingleColour = true,
  } = options

  const aggregationConfigs = {
    aggregation,
    valueRange,
  }

  const updateBarFeaturesOptions = useCallback(
    payload => {
      updateSettings({
        [barType]: { ...options, ...payload },
      })
    },
    [barType, options, updateSettings]
  )

  return (
    <>
      <div className='groupOption'>
        <div className='groupOptionContent'>
          <TitleWithSort
            title='Y-Axis Value'
            tooltip='The value to plot'
            enableSort={sortYAxisValues}
            enableSortKey='sortYAxisValues'
            isAscendingOrder={isAscendingOrder}
            onChange={updateBarFeaturesOptions}
          />
          <DataAggregation
            tooltip='Aggregation'
            propertyOptions={propertyOptions}
            aggregationConfigs={aggregationConfigs}
            onAggregationChangeHandler={(property, val) =>
              updateBarFeaturesOptions({ [property]: val })
            }
          />
          <HideValueToggle
            isValid={isRangeValid(valueRange)}
            hideValue={hideValuesOutsideOfValueRange}
            onChange={updateBarFeaturesOptions}
          />
        </div>
      </div>
      <NoLimitBarToggle
        maxBarsNum={maxBarsNum}
        onChange={updateBarFeaturesOptions}
      />
      <ColourSetting
        isSingleColour={isSingleColour}
        colour={colour}
        updateSettings={updateBarFeaturesOptions}
      />
    </>
  )
}

BarFeaturesOptionsTab.propTypes = {
  options: PropTypes.shape({
    aggregation: PropTypes.shape({
      key: PropTypes.string,
      type: PropTypes.string,
    }),
    valueRange: PropTypes.array,
    hideValuesOutsideOfValueRange: PropTypes.bool,
    sortYAxisValues: PropTypes.bool,
    maxBarsNum: PropTypes.number,
    isAscendingOrder: PropTypes.bool,
    colour: PropTypes.arrayOf(PropTypes.number),
    isSingleColour: PropTypes.bool,
  }).isRequired,
  propertyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateSettings: PropTypes.func.isRequired,
}

const BarGroupOptionsTab = ({ options, propertyOptions, updateSettings }) => {
  const barType = BAR_CHART_TYPES.group
  const {
    aggregation = DEFAULT_AGGREGATION_KEY_TYPE,
    valueRange = [],
    intervalUnit,
    groupByPropertyName = '',
    splitByPropertyName = '',
    colour = DEFAULT_WIDGET_COLOUR,
    isSingleColour = true,
    sortYAxisValues = true,
    maxBarsNum = DEFAULT_MAX_BARS_NUM,
    isAscendingOrder,
    hideValuesOutsideOfValueRange = DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE,
  } = options

  const isGroupedByTime = useMemo(() => {
    return isTimePropertyFromPropertyOptionAndValue(
      groupByPropertyName,
      propertyOptions
    )
  }, [groupByPropertyName, propertyOptions])

  const updateBarGroupedOptions = useCallback(
    payload => {
      updateSettings({
        [barType]: { ...options, ...payload },
      })
    },
    [barType, options, updateSettings]
  )

  const aggregationConfigs = {
    aggregation,
    valueRange,
  }

  const splitByPropertyOptions = useMemo(
    () => getNonDatetimeProperties(propertyOptions),
    [propertyOptions]
  )

  const renderTimeGroupOptions = useCallback(
    () => (
      <MultiSelect
        value={intervalUnit}
        options={_.reject(INTERVAL_UNIT_OPTIONS, [
          'value',
          DATE_UNIT_TYPES.seconds,
        ])}
        onChange={value => updateBarGroupedOptions({ intervalUnit: value })}
        isMulti={false}
        isClearable={false}
        useOptionValueOnly
      />
    ),
    [intervalUnit, updateBarGroupedOptions]
  )

  return (
    <>
      <div className='groupOption'>
        <div className='groupOptionContent'>
          <TitleWithSort
            title='Group By (a property)'
            tooltip='The label to display on the x-axis or the categories for each group'
            displaySort={!isGroupedByTime}
            enableSort={sortYAxisValues}
            enableSortKey='sortYAxisValues'
            isAscendingOrder={isAscendingOrder}
            onChange={updateBarGroupedOptions}
          />
          <div className='row g-3'>
            <div className={isGroupedByTime ? 'col-7' : 'col-12'}>
              <PropertyPicker
                property={groupByPropertyName}
                onChange={option =>
                  updateBarGroupedOptions({
                    groupByPropertyName: option.value,
                  })
                }
                propertyTypes={SUPPORTED_FILTER_PROPERTY_TYPES}
                propertyOptions={propertyOptions}
                isMulti={false}
                isClearable={false}
              />
            </div>
            <div className='col-5'>
              {isGroupedByTime && renderTimeGroupOptions()}
            </div>
          </div>
          <TitleWithTooltip
            title='Split By (the other property)'
            tooltip='Pick a property to split one serial into multiple data series'
          />
          <PropertyPicker
            property={splitByPropertyName}
            onChange={option =>
              updateBarGroupedOptions({
                splitByPropertyName: option?.value,
                maxBarsNum: DEFAULT_MAX_BARS_NUM,
              })
            }
            propertyTypes={SUPPORTED_FILTER_PROPERTY_TYPES}
            propertyOptions={splitByPropertyOptions}
            isMulti={false}
          />
        </div>
      </div>
      <div className='groupOption'>
        <div className='groupOptionContent'>
          <TitleWithTooltip title='Y-Axis Value' tooltip='The value to plot' />
          <DataAggregation
            tooltip='Aggregation'
            propertyOptions={propertyOptions}
            aggregationConfigs={aggregationConfigs}
            onAggregationChangeHandler={(property, val) =>
              updateBarGroupedOptions({ [property]: val })
            }
          />
          <HideValueToggle
            isValid={isRangeValid(valueRange)}
            hideValue={hideValuesOutsideOfValueRange}
            onChange={updateBarGroupedOptions}
          />
        </div>
      </div>
      {!splitByPropertyName && (
        <NoLimitBarToggle
          maxBarsNum={maxBarsNum}
          onChange={updateBarGroupedOptions}
        />
      )}
      <ColourSetting
        isSingleColour={isSingleColour}
        colour={colour}
        updateSettings={updateBarGroupedOptions}
      />
    </>
  )
}

BarGroupOptionsTab.propTypes = {
  options: PropTypes.shape({
    aggregation: PropTypes.shape({
      key: PropTypes.string,
      type: PropTypes.string,
    }),
    valueRange: PropTypes.array,
    intervalUnit: PropTypes.string,
    groupByPropertyName: PropTypes.string,
    splitByPropertyName: PropTypes.string,
    colour: PropTypes.arrayOf(PropTypes.number),
    isSingleColour: PropTypes.bool,
    sortYAxisValues: PropTypes.bool,
    maxBarsNum: PropTypes.number,
    isAscendingOrder: PropTypes.bool,
    hideValuesOutsideOfValueRange: PropTypes.bool,
  }).isRequired,
  propertyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateSettings: PropTypes.func.isRequired,
}
export const BAR_CHART_TABS = [BAR_CHART_TYPES.features, BAR_CHART_TYPES.group]

export const BarWidgetConfig = ({
  settings,
  selectedIndex,
  onSelect,
  propertyOptions,
  isFeaturesBarDisabled,
  onChange,
}) => {
  const {
    [BAR_CHART_TYPES.features]: featuresOptions = {},
    [BAR_CHART_TYPES.group]: groupedOptions = {},
  } = settings

  const barWidgetsTabs = useMemo(
    () => [
      {
        label: BAR_CHART_TYPES.features,
        isDisabled: isFeaturesBarDisabled,
      },
      {
        label: BAR_CHART_TYPES.group,
        isDisabled: false,
      },
    ],
    [isFeaturesBarDisabled]
  )

  return (
    <Tabs selectedIndex={selectedIndex} onSelect={onSelect}>
      <TabList>
        {_.map(barWidgetsTabs, ({ label, isDisabled }, index) => (
          <Tab key={label} disabled={isDisabled}>
            <BladeTabHeader
              key={label}
              label={label}
              isDisabled={isDisabled}
              isActive={index === selectedIndex}
            />
          </Tab>
        ))}
      </TabList>
      <TabPanel>
        <p className='tabDescription'>
          Each feature will represent a bar where the data points are the
          aggregated observations.
        </p>
        <BarFeaturesOptionsTab
          options={featuresOptions}
          propertyOptions={propertyOptions}
          updateSettings={onChange}
        />
      </TabPanel>
      <TabPanel>
        <p className='tabDescription'>
          All the observations are being grouped or subgroups by user selected
          property and aggregated by user selected aggregation.
        </p>
        <BarGroupOptionsTab
          options={groupedOptions}
          propertyOptions={propertyOptions}
          updateSettings={onChange}
        />
      </TabPanel>
    </Tabs>
  )
}

BarWidgetConfig.propTypes = {
  settings: PropTypes.shape({}).isRequired,
  selectedIndex: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  propertyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFeaturesBarDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

const BarWidgetTab = ({ settings, propertyOptions, updateWidgetSettings }) => {
  const currentSettings = settings[widgetType] || {}

  const isFeaturesBarDisabled = useMemo(
    () => _.isEmpty(validNumericProperties(propertyOptions)),
    [propertyOptions]
  )

  const {
    type = isFeaturesBarDisabled
      ? BAR_CHART_TYPES.group
      : BAR_CHART_TYPES.features,
  } = currentSettings

  const { updateWidget, onSelect, selectedIndex } = useUpdateWidgetSettings({
    widgetType,
    settings,
    type,
    updateWidgetSettings,
    tabs: BAR_CHART_TABS,
  })

  return (
    <>
      <h6 className='tabTitle d-flex justify-content-between'>
        <div>Bar Chart</div>
      </h6>
      <BarWidgetConfig
        settings={currentSettings}
        propertyOptions={propertyOptions}
        onChange={updateWidget}
        onSelect={onSelect}
        selectedIndex={selectedIndex}
        isFeaturesBarDisabled={isFeaturesBarDisabled}
      />
    </>
  )
}

BarWidgetTab.propTypes = {
  settings: PropTypes.shape({}),
  propertyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateWidgetSettings: PropTypes.func.isRequired,
}

BarWidgetTab.defaultProps = {
  settings: {},
}

export default BarWidgetTab
