import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, VisibilityToggle } from 'components/common'

import scss from './index.module.scss'

const GroupItemToolbox = ({
  onDelete,
  onClone,
  isVisible,
  onVisibleChange,
  onView,
}) => {
  return (
    <div className={scss.listGroupToolBox}>
      <div className='d-flex flex-row-reverse bd-highlight'>
        <IconButton icon='MdChevronRight' onClick={onView} size={16} />
        <VisibilityToggle
          className='hidden'
          isVisible={isVisible}
          onChange={onVisibleChange}
          size={14}
        />
        <IconButton
          icon='MdContentCopy'
          className='hidden'
          size={14}
          onClick={onClone}
          label='Duplicate'
        />
        <IconButton
          icon='FaTrash'
          className='hidden'
          size={14}
          onClick={onDelete}
          label='Delete'
        />
      </div>
    </div>
  )
}

GroupItemToolbox.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onVisibleChange: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
}

export default GroupItemToolbox
