import { ReactElement } from 'react'
import { FieldProps } from '@rjsf/core'

export interface DescriptionFieldProps extends Partial<FieldProps> {
  description?: string
}

const DescriptionField = ({
  description,
}: Partial<FieldProps>): ReactElement => {
  if (description) {
    return (
      <div>
        <div className='text-secondary smallText'>{description}</div>
      </div>
    )
  }

  return null
}

export default DescriptionField
