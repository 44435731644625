import { ReactElement, memo } from 'react'
import { BsCircleFill } from 'react-icons/bs'

// utils
import { colourArrToRgba } from 'helpers/colour'

// constants
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'
import { ColourArray } from 'types/common'

const ColourDot = memo(
  ({
    fillColour = LAYER_VIS_CONFIGS.fillColour.defaultValue,
  }: {
    fillColour: ColourArray
  }): ReactElement => {
    return <BsCircleFill color={colourArrToRgba(fillColour)} size={16} />
  }
)

export default ColourDot
