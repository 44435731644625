import { useMemo, CSSProperties, ReactElement } from 'react'
import _ from 'lodash'

// components
import { StyledField } from 'components/common/Form'
import { MultiSelect } from 'components/common/'

import type { Options, Option, InputValue, Value } from 'types/common'
import type { MultiSelectProp } from 'components/common/MultiSelect'

export const getSelectedValue = (
  inputValue: InputValue<Value>
): Value | Value[] => {
  if (!_.isObject(inputValue)) return inputValue
  if (!_.isArray(inputValue)) return inputValue.value

  return _.map(inputValue, v =>
    _.isObject(v) ? (v as Option<Value>).value : v
  )
}

export type FormSelectProps = {
  options: Options
  input: {
    name: string
    value: InputValue
    onChange: (v: InputValue) => void
    onBlur: (v: InputValue) => void
    onFocus: (v: InputValue) => void
  }
  label: string
  required: boolean
  description: string
  useOptionValueOnly: boolean
  groupOptionStyle: boolean
  checkable: boolean
  onChecked: () => void
  labelClassName: string
  onCreatableOptionsChange: () => void
  convertStringType: string
  style: CSSProperties
  ignoreError: boolean
  areGroupedOptions?: boolean
} & MultiSelectProp

const FormSelect = ({
  input,
  label,
  required = false,
  description,
  groupOptionStyle = false,
  style,
  checkable,
  onChecked,
  labelClassName = 'form-label',
  isMulti = false,
  useOptionValueOnly = true,
  ignoreError = false,
  areGroupedOptions = false,
  ...rest
}: FormSelectProps): ReactElement => {
  const { value: inputValue } = input

  const selectedValue = useMemo(
    () => getSelectedValue(inputValue),
    [inputValue]
  )

  const sharedProps = {
    ...rest,
    ...input,
    ...(areGroupedOptions &&
      _.isObject(inputValue) &&
      !_.isArray(inputValue) &&
      inputValue),
    value: selectedValue,
    useOptionValueOnly,
    isMulti,
  }

  return (
    <StyledField
      name={input.name}
      groupOptionStyle={groupOptionStyle}
      label={label}
      required={required}
      description={description}
      style={style}
      checkable={checkable}
      onChecked={onChecked}
      labelClassName={labelClassName}
      ignoreError={ignoreError}
    >
      <MultiSelect {...sharedProps} />
    </StyledField>
  )
}

export default FormSelect
