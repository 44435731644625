import { CompositeLayer, GeoJsonLayer } from 'deck.gl'
import { DEFAULT_MAP_COLOUR } from 'constants/map'
import LabeledIconLayer from './labeledIconLayer'

export default class LabeledGeojsonIconLayer extends CompositeLayer {
  renderLayers() {
    const {
      id,
      data,
      stroked = true,
      filled = true,
      lineWidthScale = 20,
      getLineColor = DEFAULT_MAP_COLOUR,
      getFillColor = DEFAULT_MAP_COLOUR,
      getLineWidth = 10,
      highlightedObjectIndex,
      pickable,
      onHover,
      ...rest
    } = this.props

    const subLayerSharedProps = {
      pickable,
      onHover,
      bgIconHighlighIndex: highlightedObjectIndex,
    }

    return [
      new GeoJsonLayer({
        id: `${id}-geojson`,
        data,
        stroked,
        filled,
        lineWidthScale,
        getLineColor,
        getFillColor,
        getLineWidth,
        _subLayerProps: {
          // https://deck.gl/docs/api-reference/layers/geojson-layer#sub-layers
          // TODO: use points-icon and points-text, pointType:icon+text
          // ! For deck.gl >8.5, points->points-circle
          points: {
            ...subLayerSharedProps,
            ...rest,
            type: LabeledIconLayer,
          },
          linestrings: { ...subLayerSharedProps },
          'polygons-stroke': { ...subLayerSharedProps },
          'polygons-fill': { ...subLayerSharedProps },
        },
      }),
    ]
  }
}

LabeledGeojsonIconLayer.layerName = 'LabeledGeojsonIconLayer'
