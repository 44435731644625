// libraries
import { useMemo, ReactElement, CSSProperties, ReactNode } from 'react'
import _ from 'lodash'

// component
import { TitleWithTooltip } from 'components/common/'
import { useFieldError } from 'components/common/Form/FieldError'

// scss
import scss from 'components/common/Form/index.module.scss'

type TitleProps = {
  name?: string
  label: string
  required?: boolean
  labelClassName?: string
  tooltip?: string
  checkable?: boolean
  onChecked?: (name: string, isChecked: boolean) => void
}

export const Title = ({
  label,
  required = false,
  labelClassName = 'form-label',
  onChecked = _.noop,
  checkable = false,
  name,
  ...rest
}: TitleProps): ReactElement => {
  const title = useMemo(
    () => (
      <>
        {label} {required && <span className='asterisk'>*</span>}
      </>
    ),
    [label, required]
  )
  return (
    <TitleWithTooltip
      {...rest}
      title={title}
      checkable={checkable}
      className={labelClassName}
      onChecked={(isChecked: boolean) => {
        if (_.isFunction(onChecked) && !_.isNil(name)) {
          onChecked(name, isChecked)
        }
      }}
    />
  )
}

export type StyleFieldProps = {
  description?: string
  label?: string
  required?: boolean
  groupOptionStyle?: boolean
  name: string
  style?: CSSProperties
  children?: ReactNode
  addon?: ReactNode
  inline?: boolean
  labelClassName?: string
  labelContainerClassName?: string
  tooltip?: string
  checkable?: boolean
  onChecked?: (name: string, isChecked: boolean) => void
  errorClassName?: string
  ignoreError?: boolean
}

const StyledField = ({
  name,
  label = '',
  required = false,
  groupOptionStyle = false,
  description = '',
  tooltip,
  style = {},
  children,
  addon,
  inline = false,
  checkable = false,
  onChecked = _.noop,
  labelClassName = 'form-label',
  labelContainerClassName,
  errorClassName,
  ignoreError = false,
}: StyleFieldProps): ReactElement => {
  const { hasError, metaError } = useFieldError(name)
  return (
    <>
      <div
        className={
          inline
            ? 'd-flex justify-content-between align-items-center'
            : undefined
        }
      >
        <div className={labelContainerClassName}>
          {label && (
            <Title
              name={name}
              label={label}
              required={required}
              tooltip={tooltip}
              checkable={checkable}
              onChecked={onChecked}
              labelClassName={`${labelClassName} ${
                hasError ? 'is-invalid' : ''
              }`}
            />
          )}
          {description && (
            <div className='form-label-description'>{description}</div>
          )}
        </div>
        <div
          className={`${
            groupOptionStyle ? 'groupOptionContent' : inline ? '' : 'w-100'
          } position-relative ${hasError && 'is-invalid'}`}
          style={style}
        >
          {children}
          {addon}
        </div>
      </div>
      {hasError && !ignoreError && (
        <div className={`${scss.error} ${errorClassName}`}>{metaError}</div>
      )}
    </>
  )
}

export default StyledField
