// libraries
import { ReactElement, useMemo, useState } from 'react'
import _ from 'lodash'
import { useUpdateEffect } from 'react-use'

// constants
import { BUTTON_VARIANTS } from 'components/common/Button'
import { DEFAULT_LIST_FILTERS, GALLERY_LIST_TYPES } from 'constants/common'
import { DropdownOption } from 'components/common/Dropdown'

// components
import {
  Badge,
  Button,
  ListToolbox,
  ListFilterBuilder,
  PageHeader,
  SplitButton,
} from 'components/common'

import type { Options, Payload } from 'types/common'
import type { Filters, FilterSpec } from 'types/filter'
import type { ColumnOptions } from 'components/common/DataTable/useDataTableColumns'

import usePageFiltersBuilder from './usePageFiltersBuilder'

import scss from './index.module.scss'

export type Conditions = Partial<{
  ascOrder: boolean
  search: string
  isSearching: boolean
  selectedSearchFields: string[]
  sortField: string
  listType: keyof typeof GALLERY_LIST_TYPES
}>

type GalleryPageHeaderProps = Partial<{
  entity: string
  toolboxOptions: {
    sortOptions: Options
    enableFilter?: boolean
  }
  conditions: Conditions
  onConditionsChange: React.Dispatch<React.SetStateAction<Conditions>>
  fetchList: () => Promise<void>
  filterValues: Payload<string[]>
  setFilterValues: React.Dispatch<Filters>
  customizedFiltersKeys: string[]
  customFiltersSpecs: FilterSpec[]
  pageName: string
  counts: number
  isLoading: boolean
  actions?: DropdownOption[]
  bulkActions: DropdownOption[]
  columns?: ColumnOptions[]
  visibleColumns?: ColumnOptions[]
  setVisibleColumns?: (columns: ColumnOptions[]) => void
}>

const GalleryPageHeader = ({
  entity,
  pageName,
  conditions,
  onConditionsChange,
  toolboxOptions,
  fetchList,
  filterValues,
  setFilterValues,
  customizedFiltersKeys = DEFAULT_LIST_FILTERS,
  customFiltersSpecs,
  counts,
  isLoading = true,
  actions = [],
  bulkActions,
  columns,
  visibleColumns,
  setVisibleColumns,
}: GalleryPageHeaderProps): ReactElement => {
  const { filtersSpecs, onFiltersChange } = usePageFiltersBuilder({
    entity,
    customizedFiltersKeys,
    customFiltersSpecs,
    setFilterValues,
  })

  const { enableFilter = true } = toolboxOptions || {}

  const renderBulkActions = () => (
    <>
      {_.map(bulkActions, action => {
        const { label, onClick, value } = action
        return (
          <Button
            key={value}
            variant={BUTTON_VARIANTS.secondary}
            onClick={onClick}
            testId={value}
          >
            {label}
          </Button>
        )
      })}
    </>
  )

  const renderActions = () => {
    const content = _.first(actions)?.label
    const onClick = _.first(actions)?.onClick
    const testId = _.first(actions)?.value

    return actions.length > 1 ? (
      <SplitButton
        onClick={onClick}
        testId={testId as string}
        options={actions.slice(1)}
      >
        {content}
      </SplitButton>
    ) : (
      <Button onClick={onClick} testId={testId as string}>
        {content}
      </Button>
    )
  }

  const filtersCount = useMemo(
    () => _.keys(filterValues).length,
    [filterValues]
  )

  const [isShowingFilters, toggleShowFilters] = useState<boolean | undefined>(
    undefined
  )

  useUpdateEffect(() => {
    /*
  - Only display filters when there are saved filters (initial load)
  - Once the page loaded, the user decides to display filters or not by Filter toggle
  */
    if (_.isNil(isShowingFilters)) {
      toggleShowFilters(filtersCount > 0)
    }
  }, [filtersCount])

  const shouldRenderToolbarAndFilter =
    _.isFunction(setFilterValues) || _.isFunction(onConditionsChange)

  const renderToolbar = () =>
    shouldRenderToolbarAndFilter && (
      <div className='d-flex justify-content-between align-items-center'>
        {onConditionsChange && (
          <ListToolbox
            conditions={conditions}
            onChange={onConditionsChange}
            options={toolboxOptions}
            onRefresh={fetchList}
            columns={columns}
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
            {...(_.isFunction(setFilterValues) &&
              enableFilter && {
                filtersCount,
                toggleShowFilters,
                isShowingFilters,
              })}
          />
        )}
      </div>
    )

  const renderFilter = () =>
    enableFilter &&
    shouldRenderToolbarAndFilter &&
    !_.isEmpty(filtersSpecs) &&
    isShowingFilters && (
      <ListFilterBuilder
        filters={filterValues}
        filtersSpecs={filtersSpecs}
        onChange={onFiltersChange}
        className={scss.filters}
      />
    )

  return (
    <PageHeader
      titleComponent={pageName || `${entity}s`}
      titleAddonComponent={
        <>
          {!_.isNil(counts) && !isLoading && (
            <Badge content={counts} className='ms-2' />
          )}
        </>
      }
      toolbarComponent={
        <>
          <div className='d-flex justify-content-between'>
            {bulkActions && !_.isEmpty(bulkActions) ? (
              renderBulkActions()
            ) : actions && !_.isEmpty(actions) ? (
              renderActions()
            ) : (
              <div />
            )}
            {renderToolbar()}
          </div>
          {renderFilter()}
          <hr />
        </>
      }
    />
  )
}

export default GalleryPageHeader
