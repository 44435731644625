import { ReactElement, PropsWithChildren } from 'react'
import styled from '@emotion/styled'

import { Button } from 'components/common'
import { ButtonProps } from 'components/common/Button'

import scss from './index.module.scss'

const StyledFooter = styled.div<{
  gap: number
}>`
  gap: ${({ gap }) => gap}px;
`

/**
 *
 * @param props.buttons - array of props for <Button />
 * @param props.gap - gap between buttons, default 12px
 */
const BladeFooter = ({
  buttons,
  gap = 12,
}: PropsWithChildren<{
  buttons?: (ButtonProps & { id: string })[]
  gap?: number
}>): ReactElement => (
  <StyledFooter className={scss.bladeFooter} gap={gap}>
    {buttons?.map(({ children, ...props }) => (
      <Button key={props.id} {...props}>
        {children}
      </Button>
    ))}
  </StyledFooter>
)

export default BladeFooter
