import { ReactElement } from 'react'
import styled from '@emotion/styled'

import type { StyledComponentProps } from 'types/common'

const StyledSwitch = styled.div<StyledComponentProps>`
  .form-check-input {
    &:checked {
      background-color: ${props => props.theme.primary};
      border-color: ${props => props.theme.primary};
    }
    &:focus {
      box-shadow: 0 0 0 0.25rem ${props => props.theme['primary-100']};
    }
  }
`

const Toggle = ({
  id,
  label,
  checked,
  onToggle,
  disabled = false,
  labelClassName,
  labelPlacement = 'right',
  ...rest
}: {
  id?: string
  label?: string
  checked?: boolean
  onToggle: (v: boolean) => void
  optionLabels?: string[]
  disabled?: boolean
  onBlue?: (v: boolean) => void
  labelClassName?: string
  labelPlacement: 'right' | 'left'
}): ReactElement => {
  return (
    <StyledSwitch
      className={`form-check form-switch ${
        labelPlacement === 'left' ? 'form-check-reverse' : ''
      }`}
      onClick={e => e.stopPropagation()}
    >
      <input
        {...rest}
        className='form-check-input'
        type='checkbox'
        role='switch'
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={e => {
          onToggle(e.target.checked)
        }}
      />
      {label && (
        <label className={`${labelClassName} form-check-label `} htmlFor={id}>
          {label}
        </label>
      )}
    </StyledSwitch>
  )
}

export default Toggle
