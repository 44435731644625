import React from 'react'
import PropTypes from 'prop-types'
import { reportErrors } from 'helpers/log'

const SUBSTRING_LENGTH = 500

const ErrorMessage = ({ error }) => {
  if (error) {
    const errorMessage = `${error.name}:${error.message}`
    reportErrors(errorMessage)

    return (
      <div className='alert alert-danger'>
        {errorMessage.length > SUBSTRING_LENGTH
          ? `${errorMessage.substring(0, 500)}...`
          : errorMessage}
      </div>
    )
  }
  return <></>
}

ErrorMessage.propTypes = {
  error: PropTypes.shape({
    name: PropTypes.string,
    message: PropTypes.string.isRequired,
  }),
}

ErrorMessage.defaultProps = {
  error: undefined,
}

export default ErrorMessage
